
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../../components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class RegistrarEducacionPersona extends Vue {
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;

  inactiva = false;
  docValido = false;
  estadoPeticion = false;
  listarEducacion: any[] = [];
  funcion = null;
  listareducacionstring: any[] = [];
  contEduGuardada = 0;

  datosEducacion = {
    actualizado: "",
    certificado: null,
    creado: "",
    fecha_fin: "",
    fecha_inicio: "",
    id_educacion: "",
    institucion: "",
    persona_id: "",
    tipo_educacion: "",
    titulo_obtenido: "",
  };
  mounted() {}

  //-------------------------------------------------------------------------------
  ListarEducacionPersona() {
    this.estadoPeticion = true;
    //Creamos el formData
    var data = new FormData();
    //Añadimos la docinscrip seleccionada
    data.append("persona_id", this.id_persona_logeada);
    data.append("titulo_obtenido", this.datosEducacion.titulo_obtenido);
    data.append("institucion", this.datosEducacion.institucion);
    data.append("fecha_inicio", this.datosEducacion.fecha_inicio);
    data.append("fecha_fin", this.datosEducacion.fecha_fin);
    data.append("certificado", this.datosEducacion.certificado);
    data.append("tipo_educacion", this.datosEducacion.tipo_educacion);

    this.listarEducacion.push(data);
    this.listareducacionstring.push(this.datosEducacion);
    this.datosEducacion = {
      actualizado: "",
      certificado: "",
      creado: "",
      fecha_fin: "",
      fecha_inicio: "",
      id_educacion: "",
      institucion: "",
      persona_id: "",
      tipo_educacion: "",
      titulo_obtenido: "",
    };
  }
  // validarFormulario() {
  //   this.ListarEducacionPersona();
  // }

  //---------------------------------------------------------------------------
  obtenerDocumento(event) {
    if (event.target.files[0].size <= 5000000) {
      //5mb tope
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.datosEducacion.certificado = event.target.files[0];
        Swal.fire("PDF Cargado!", "Archivo cargado exitosamente!", "success");
        this.docValido = true;
      } else {
        this.docValido = false;
        this.datosEducacion.certificado = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.datosEducacion.certificado = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  }
  //----------------------------------------------------------------------
  registrarEducacionPersona() {
    this.estadoPeticion = true;
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    if (this.listarEducacion.length != 0) {
      for (var i = 0; i < this.listarEducacion.length; i++) {
        Axios()
          .post("almacenar_educacion_persona", this.listarEducacion[i], {
            headers: headers,
          })
          .then((respuesta) => {
            if (respuesta.data.status == "success") {
              this.contEduGuardada++;
              if (this.contEduGuardada == this.listarEducacion.length) {
                Swal.fire({
                  title: "Educación Guardada",
                  text: "Se guardaron " + this.contEduGuardada + " Educaciones",
                  icon: "success",
                  confirmButtonText: "Aceptar",
                  confirmButtonColor: "#007bb8",
                });
              }
              this.$router.push({ name: "GestionarHojaDeVidaENI" });
            } else {
              Swal.fire({
                title: "No se Guardo La Educación",
                icon: "error",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#007bb8",
              });
            }
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
        // .finally(() => {
        //   this.estadoPeticion = true;
        // });
      }
    } else {
      // alerta

      Swal.fire({
        title: "Un momento!",
        icon: "info",
        text: "No hay educación agregada, por favor llene el formulario al menos una vez",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#007bb8",
      });
    }
  }

  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        // this.registrarEducacionPersona(this.$route.params.id);
        this.ListarEducacionPersona();
      }
    });
  }
  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "No pude ser negativo",
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }
}
