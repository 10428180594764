
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";
import AnimacionTablasCargando from "../../../components/animacionTablasCargando.vue";


@Component({
  components: {
    AnimacionCargando,
    AnimacionTablasCargando,
  },
})
export default class Login extends Vue {
  modalRegistroNovedad = false;
  estadoPeticion = true;
  listaApoyos: any[] = [];
  listaNovedad: any[] = [];
  estadoPeticionTabla = false;
  paginate = ["listaNovedadApoyos"];
  listaNovedadApoyos = [];
  listaNovedadNoRepetidos = [];
  estadoNovedad=false;
  novedad = {
    tipo_apoyo_id: null,
    novedades_bienestar_id: null,
    novedad: null,
    clase_novedad: null,
    id_novedad_tipo_apoyo: null,
  };

  async mounted() {
    await this.obtenerApoyosNovedades();
  }

  obtenerApoyosNovedades() {
    Axios()
      .get("crear_tipo_novedad")
      .then((respuesta) => {
        this.listaApoyos = respuesta.data.results.tipos_apoyo;
        this.listaNovedad = respuesta.data.results.novedad;
        this.estadoPeticion = false;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }
  // obtener las novedades que son pertenecientes a (x) apoyo
  async obtenerNovedadesApoyo(id_apoyo) {
    await Axios()
      .get("listar_novedad_bienestar/" + id_apoyo)
      .then((respuesta) => {
        this.listaNovedadApoyos = respuesta.data.results;
        this.estadoPeticion = false;
        this.obtenerDatosNoRepetidosNovedades();
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }

  obtenerDatosNoRepetidosNovedades() {
    this.listaNovedadNoRepetidos = [];
    let repetido = false;
    for (const novedad in this.listaNovedad) {
      repetido = false;
      for (const novedadesApoyo in this.listaNovedadApoyos) {
        if (
          this.listaNovedadApoyos[novedadesApoyo].novedades_bienestar_id ==
          this.listaNovedad[novedad].id_novedad_bienestar
        ) {
          repetido = true;
          break;
        }
      }
      if (repetido == false) {
        this.listaNovedadNoRepetidos.push(this.listaNovedad[novedad]);
      }
    }
  }

  //registrarNovedad
  registrarNovedad() {
    Axios()
      .post("almacenar_novedad_bienestar", {
        novedad: this.novedad.novedad,
        clase_novedad: this.novedad.clase_novedad,
      })
      .then((respuesta) => {
        if ((respuesta.data.status = "success")) {
          this.registrarNovedadTipoApoyo(
            respuesta.data.results.id_novedad_bienestar
          );
        } else {
          Swal.fire("ERROR!", "No se creo la novedad ", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
        this.novedad.novedad=null;
        this.novedad.clase_novedad=null;
      });
  }
  // registar novedad relacionado con el apoyo
  registrarNovedadTipoApoyo(id_novedad) {
    this.estadoNovedad=true;
    Axios()
      .post("almacenar_novedad_bienestar_tipo/" + id_novedad, {
        tipo_apoyo_id: this.novedad.tipo_apoyo_id,
        novedades_bienestar_id: id_novedad,
      })
      .then((respuesta) => {
        if ((respuesta.data.status = "success")) {
          this.estadoNovedad=false;
          Swal.fire({
            title: "Novedad Guardado",
            text: "Se guardo la novedad en el apoyo",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        } else {
          Swal.fire("ERROR!", "No se creo la novedad ", "error");
        }
        this.modalRegistroNovedad = false;

      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
        this.actualizarListaNovedadApoyo();
      });
  }
  eliminarNovedadApoyo(id, index) {
    Swal.fire({
      title: "Eliminar Novedad ",
      icon: "warning",
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((respuesta) => {
      if (respuesta.isConfirmed) {
        Axios()
          .delete("eliminar_novedad_tipo_apoyo/" + id)
          .then((respuesta) => {
            if (respuesta.data.status == "success") {
              Swal.fire({
                title: "novedad Eliminada",
                icon: "success",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
            } else {
              Swal.fire({
                title: "No se pudo eliminar la novedad",
                text: respuesta.data.message,
                icon: "error",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.$router.back();
            }
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          })
          .finally(() => {
            this.actualizarListaNovedadApoyo();
          });
      }
    });
  }

  async actualizarListaNovedadApoyo() {
    await this.obtenerNovedadesApoyo(this.novedad.tipo_apoyo_id);
    await this.obtenerApoyosNovedades();
  }

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }
}
