import { render, staticRenderFns } from "./Modificar.vue?vue&type=template&id=6778b380&"
import script from "./Modificar.vue?vue&type=script&lang=ts&"
export * from "./Modificar.vue?vue&type=script&lang=ts&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports