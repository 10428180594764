
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class  RegistrarFuncionario extends Vue {
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;

  estadoPeticionRegistrar = false;
  docValido = false;
  rutadocumento = null;
  verModal = false;
  docResolucon = "";
  docVista = null;
  estado = false;

  Funcionario = {
    id_dirigido_a: "",
    nombre: "",
  };
  mounted() {}
  registrarfuncionarios() {

    this.estadoPeticionRegistrar = true;

    Axios()
      .post("almacenar_pic_dirigida", this.Funcionario)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.estado = true;
          Swal.fire({
            title: "Funcionario Guardado",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          //   this.estadoPeticion = false;
          this.$router.push({ name: "GestionarFuncionarios" });
        } else {
          Swal.fire("ERROR!", "No Se Registro el Funcionario", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(()=>{
        this.estadoPeticionRegistrar = false;
      });
  }
  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.registrarfuncionarios();
      }
    });
  }
}
