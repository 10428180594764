
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";

/* --------------------------------------------- COMPONENTS-------------------------------------------------*/
@Component({
  components: {
    AnimacionCargando,
  },
})
export default class ModificarHistorialEvidencia extends Vue {
  /* ------------------------------------------------ DATA -------------------------------------------------*/
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;
  evidencia = {
    /* persona_id: this.id_persona_logeada,
    ruta: "",
    documento: "", */
    id_evidencia: "",
    periodicidad_id: "",
    actividades_desarrolladas: "",
    avance_indicador: "",
    fuente_informacion: "",
    logros_indicador: "",
    hitos_alcanzar_meta: "",
    identificacion_riesgos: "",
    concluciones: "",
  };
  periodo_reportado: any[] = [];
  idevidencia = "";
  docResolucon = "";
  docVista = null;
  docValido = false;
  estadoPeticionActualizar = false;

  /* --------------------------------------------- MOUNTED -------------------------------------------------*/

  async mounted() {
    await this.mostrarEvidencia();
    await this.ObtenerDatosParaFiltros();
  }
  /* --------------------------------------------- METODOS -------------------------------------------------*/

  async mostrarEvidencia() {
    await Axios()
      .get(
        "/mostrar_evidencia_historial/" +
          this.$route.params.id_historial_evidencia
      )
      .then((respuesta) => {
        this.evidencia = respuesta.data.results;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  async ObtenerDatosParaFiltros() {
    await Axios()
      .get("/crear_historial_evidencias/" + this.$route.params.id_indicador)
      .then((respuesta) => {
        this.periodo_reportado = respuesta.data.results;
        this.idevidencia = respuesta.data.results[0].id_evidencia;
      });
  }

  /* obtenerEvidencia(event) {
    if (event.target.files[0].size <= 5000000) {
      //5mb tope
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.evidencia.ruta = event.target.files[0];
        this.docVista = null;
        Swal.fire("Pdf Cargado!", "Archivo cargado exitosamente!", "success");
        this.docValido = true;
        // this.docResolucon = null;
      } else {
        this.docValido = false;
        this.docResolucon = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.docResolucon = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  } */

  actualizarEvidenciaHistorial() {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    var data = new FormData();
    /* data.append("ruta", this.evidencia.ruta); 
    data.append("documento", this.evidencia.documento);
    data.append("persona_id", this.id_persona_logeada);  */
    data.append("id_evidencia", this.idevidencia); /*  */
    data.append("periodicidad_id", this.evidencia.periodicidad_id); /*  */
    data.append(
      "actividades_desarrolladas",
      this.evidencia.actividades_desarrolladas
    ); /*  */
    data.append("avance_indicador", this.evidencia.avance_indicador); /*  */
    data.append("fuente_informacion", this.evidencia.fuente_informacion); /*  */
    data.append("logros_indicador", this.evidencia.logros_indicador); /*  */
    data.append(
      "hitos_alcanzar_meta",
      this.evidencia.hitos_alcanzar_meta
    ); /*  */
    data.append(
      "identificacion_riesgos",
      this.evidencia.identificacion_riesgos
    ); /*  */
    data.append("concluciones", this.evidencia.concluciones); /*  */
    Axios()
      .post(
        "/actualizar_evidencia_historial/" +
          this.$route.params.id_historial_evidencia,
        data,
        { headers: headers }
      )
      .then((respuesta) => {
        if ((respuesta.data.status = "success")) {
          Swal.fire({
            title: "Evidencia Actualizada!",
            text: "Cargado exitosamente!",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionActualizar = false;
        this.$router.back();
      });
  }

  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.estadoPeticionActualizar = true;
        Axios()
          .get("verificacion_estados/" + this.$route.params.id_indicador + "/" + this.$route.params.id_historial_evidencia,)
          .then((respuesta) => {
            if (respuesta.data.status == "success") {
              this.actualizarEvidenciaHistorial();
            }
            if (respuesta.data.status == "info1") {
              Swal.fire({
                title: "Evidencia No Actualizada",
                text: respuesta.data.message,
                icon: "warning",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#007bb8",
              });
              this.estadoPeticionActualizar = false;
              this.$router.back();
            }
            if (respuesta.data.status == "info2") {
              Swal.fire({
                title: "Evidencia No Actualizada",
                text: respuesta.data.message,
                icon: "warning",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#007bb8",
              });
              this.estadoPeticionActualizar = false;
              this.$router.push({
                name: "MisIndicadores",
              });
            }
          });
      }
    });
  }
}
