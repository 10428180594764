
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";
import AnimacionTablasCargando from "../../../components/animacionTablasCargando.vue";
import Multiselect from "vue-multiselect";
@Component({
  components: {
    AnimacionCargando,
    AnimacionTablasCargando,
    Multiselect,
  },
})
export default class ListaEvidenciasIndicador extends Vue {
  estadoPeticion = true;
  listaEvidenciasIndicador: any[] = [];
  listaHistorialEvidencias: any[] = [];

  evidenciaIndicador = {
    id_evidencia: "",
    nombre_indicador: "",
    fecha_inicio: "",
    fecha_fin: "",
    documento_id: "",
  };

  rutadocumento = null;
  modalRequisito = false;
  estadoPeticionTabla = false;
  filter = "";
  paginate = ["listaHistorialEvidencias"];

  mounted() {
    this.obtenerListaEvidenciasIndicador(this.$route.params.id_indicador);
  }

  mostrarDocumento(id) {
    this.rutadocumento = this.$urlAPI + "visualizarDucumentoEvidencia/" + id;
  }
  obtenerListaEvidenciasIndicador(id) {
    // console.log(id);

    Axios()
      .get("evidencia_indicador/" + id)
      .then((respuesta) => {
        if (respuesta.data.results == null) {
          Swal.fire({
            title: "Sin Evidencias",
            text: "Este Indicador  no tiene evidencias registradas",
            icon: "warning",
            confirmButtonText: "Volver",
            confirmButtonColor: "#007bb8",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push("GestionarSigeaIndicador");
            }
          });
          this.$router.push("GestionarSigeaIndicador");
        } else {
          this.listaEvidenciasIndicador = [respuesta.data.results];
          this.evidenciaIndicador = respuesta.data.results;
          this.obtenerHistorialEvidencias(this.evidenciaIndicador.id_evidencia);
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }

  obtenerHistorialEvidencias(id) {
    Axios()
      .get("listar_historial_evidencia/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listaHistorialEvidencias = respuesta.data.results;
          for (var i = 0; i < this.listaHistorialEvidencias.length; i++) {
            this.mostrarDocumento(
              this.listaHistorialEvidencias[i].id_documento
            );
          }
        } else {
          this.listaHistorialEvidencias = null;
        }

        this.estadoPeticion = false;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }

  cambiarEstadoAprobado(id, id_evidencia) {
    Swal.fire({
      title: "Aprobar",
      text: "¿Desea cambiar el estado de este historial a Aprobado?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Aprobar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios()
          .put("cambiar_estado_aprobado/" + id)
          .then((respuesta) => {
            this.obtenerHistorialEvidencias(id_evidencia);
            Swal.fire({
              title: "Se cambio el Estado!",
              text: "El Estado ha sido actualizado exitosamente!",
              icon: "success",
              cancelButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
          })
          .catch((error) => {
            Swal.fire(
              "ERROR!",
              "Se ha detectado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }

  cambiarEstadoRechazado(id, id_evidencia) {
    Swal.fire({
      title: "Rechazar",
      text: "¿Desea cambiar el estado de este historial a Rechazado?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Rechazar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios()
          .put("cambiar_estado_rechazado/" + id)
          .then((respuesta) => {
            this.obtenerHistorialEvidencias(id_evidencia);
            Swal.fire({
              title: "Se cambio el Estado!",
              text: "El Estado ha sido actualizado exitosamente!",
              icon: "success",
              cancelButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
          })
          .catch((error) => {
            Swal.fire(
              "ERROR!",
              "Se ha detectado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }
}
