
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class Inscribir extends Vue {
  usuario = JSON.parse(sessionStorage.getItem("usuario"));
  listaAdjudicados: any[] = [];
  listaAdjudicadosFiltrado: any[] = [];
  EstadoConsulta=false;
  nombreObtenido = null;
  listaPersonas = [];
  listaPersonasB = [];
  listaNovedadApoyo: any[] = [];
  convocatoria = {
    centro_formacion_id: "",
    centro_formación: "",
    convocatoria: "",
    cupo_hombres_14_17: null,
    cupo_hombres_mayores: null,
    cupo_instructores: null,
    cupo_mujeres_14_17: null,
    cupo_mujeres_mayores: null,
    cupo_total: null,
    estimulo_mensual: "",
    fase_bienestar: "",
    fase_bienestar_id: "",
    fecha_final_convoca: "",
    fecha_inicio_convoca: "",
    id_convocatoria: "",
    ruta_documento: "",
    tipo_apoyo: "",
    tipo_apoyo_id: "",
    tipo_monitoria: "",
    tipo_monitoria_id: "",
    convocatoria_id: "",
  };
  modalNovedades = false;
  modalListaNovedades = false;
  modifcarNovedad = false;
  listaNovedades: [] = [];
  novedadesAdjudicado: any[] = [];
  posNovedad = 0;
  idConvocatoria = null;
  fecha_aplica: string = "";
  idadjudicadobienestar: number = -1;
  estadoAdj: string = "";
  verDocumento = false;
  mostrarDoc = null;
  docResolucon?: any;
  docValido = false;
  criteriosSeleccionados: [] = [];
  apoyoid = -1;
  miVariable = null;
  fecha_minima = "";
  modal = null;
  novedadactual = null;

  ocultar = false;
  modalverDetalle = false;
  modalInscribir = false;
  fichaSeleccionada = null;
  nuevaCC = "";
  btnVerFichas = false;
  fichasAprendiz: [] = [];
  listaCriteriosApoyo: [] = [];
  listaCriterios: [] = [];
  aprendicesInscritos: [] = [];
  fecha_inicio = null;
  fecha_fin = null;
  porc_estimulo_entero = null;
  porc_estimulo_decimal = null;
  nombre = "";
  adjudicar = false;
  filter = "";
  filtrado = "";
  listaRepresentantesFiltrado: any[] = [];
  paginate = ["listaAdjudicadosFiltrado"];
  listaRepresentantes = [];
  id_persona = null;
  usuariobusqueda;
  mostrarLista = [];
  identificacionbuscar = "";
  personaInscripcion = null;
  estadoPeticionRegistrar = false;
  estadoConsulta = false;
  //mi prueba
  lista: any = [];
  listaLength = "";
  listaCancelado=0;
  menu;
  clonedMenu;
  menuEliminar;
  prueba = false;
  id_usuario = "";

  constructor() {
    super();

    const dict = {
      messages: {
        required: () => "Este campo es obligatorio",
        min: () => "Mínimo 6 caracteres",
      },
    };
    this.$validator.localize("es", dict);
  }

  mounted() {
    this.idConvocatoria = this.$route.params.id;
    this.obtenerAdjudicadosConvocatoria(this.$route.params.id);
    this.obtenerConvocatoria(this.$route.params.id);
    this.obtenerNovedadesApoyo(this.$route.params.id);
    this.obtenerCriterios(this.$route.params.id);
    this.obtenerInscritosConvocatoria(this.$route.params.id);
    this.id_usuario = JSON.parse(sessionStorage.getItem("usuario")).id_usuario;
  }

  cambiarPostuacion(i) {
    // const id_usuario = JSON.parse(sessionStorage.getItem("usuario"));
    Axios()
      .post("/cambiar_estado_finalizado/" + this.convocatoria.id_convocatoria, {
        id_convocatoria: this.convocatoria.id_convocatoria,
        fase_bienestar_id: 7,
        // id_usuario_actualiza: id_usuario.id_usuario,
        id_usuario_actualiza: JSON.parse(sessionStorage.getItem("usuario")),
      })
      .then(() => {
        this.estadoPeticionRegistrar = true;
        Swal.fire({
          title: "Convocatoria ",
          text: "Pasó a fase de finalización",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#007bb8",
          icon: "success",
        });
        // router.back();
        this.$router.push("GestionarConvocatoria");
      })
      .catch(() => {
        this.estadoPeticionRegistrar = false;

        Swal.fire({
          title: "ERROR",
        });
      });
  }

  async obtenerConvocatoria(id) {
    await Axios()
      .get("listar_convocatoria/" + id)
      .then((respuesta) => {
        this.convocatoria = respuesta.data.results;
        // this.apoyoid = this.convocatoria.tipo_apoyo_id;
        this.obtenerCriteriosApoyo(this.convocatoria.tipo_apoyo_id);
        this.obtenerNovedades(this.convocatoria.tipo_apoyo_id);
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  eliminarNovedad(id: number, nombre: string, pos: number) {
    Swal.fire({
      title: "Eliminar novedad " + "?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#007bb8",
    }).then((resp) => {
      if (resp.isConfirmed) {
        Axios()
          .delete("eliminar_novedad/" + id)
          .then((respuesta) => {
            Swal.fire({
              title: "Novedad Eliminada",
              icon: "success",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
            // this.obtenerNovedadesAdjudicado(id);
            this.novedadesAdjudicado.splice(pos, 1);
            this.obtenerAdjudicadosConvocatoria(this.$route.params.id);
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }
  obtenerNovedadesAdjudicado(id) {
    Axios()
      .get("detalle_novedad/" + id)
      .then((respuesta) => {
        this.novedadesAdjudicado = respuesta.data.results;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  obtenerResolucionNovedad(id: number) {
    this.docResolucon = null;
    this.docResolucon =
      this.$urlAPI + "mostrar_resolucion_novedad/" + id;
    this.verDocumento = true;
  }

  novedadmodificar(novedad) {
    this.novedadactual = novedad;
  }
  modificarNovedad(idNovedad) {
    const id_usuario = JSON.parse(sessionStorage.getItem("usuario")).id_usuario;

    Axios()
      .put(
        "actualizar_novedad/" +
          this.novedadesAdjudicado[this.posNovedad].id_novedad,
        {
          id_novedad: this.novedadesAdjudicado[this.posNovedad].id_novedad,
          // tipo_novedad_id: idNovedad.id_novedad_bienestar,
          id_novedad_bienestar:
            this.novedadesAdjudicado[this.posNovedad].id_novedad,
          id_adjudicacion_bienestar: this.idadjudicadobienestar,
          aplica_el: this.novedadesAdjudicado[this.posNovedad].aplica_el,
          id_usuario: id_usuario,
        }
      )
      .then((respuesta) => {
        Swal.fire(
          "Novedad Modificada!".toString().toUpperCase(),
          "la causal de suspención o cancelación ha sido modificada!",
          "success"
        );
        this.modifcarNovedad = false;
        this.obtenerNovedadesAdjudicado(this.idadjudicadobienestar);
        this.obtenerAdjudicadosConvocatoria(this.$route.params.id);
        this.obtenerNovedadesApoyo(this.$route.params.id);
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  async obtenerNovedades(id) {
    // this.modalNovedades = true;
    // if para saber estado y segun eso mostrar las novedades
    await Axios()
      .post("crear_novedad/" + id, {
        tipo_apoyo_id: id,
        id_adjudicacion_bienestar: this.idadjudicadobienestar,
      })
      .then((respuesta) => {
        this.obtenerNovedadesApoyo(id);
        return respuesta.data.results;
      })
      .then((res) => {
        //mis cambios agregue.tipos_novedad
        this.fecha_minima = res.fecha_minima;
        this.listaNovedades = res.tipos_novedad;

        return true;
      })
      .then(() => {});
  }

  async obtenerAdjudicadosConvocatoria(id) {
    await Axios()
      .get("listar_adjudicados/" + id)
      .then((respuesta) => {
        return respuesta.data.results;
      })
      .then((res) => {
        setTimeout(() => {
          if (res != null) {
            this.listaAdjudicados = res;
            this.listaAdjudicadosFiltrado = res;
            this.listaLength = res.length;
            for (let i in this.listaAdjudicados) {
              this.lista = this.listaAdjudicados[i].estado;
            }
            this.contarCupos(this.listaAdjudicados);
          }
        }, 100);
      });
  }

  registrarNovedad(fecha_aplica, novedad) {
    // alert("al fin!!!")
     Swal.fire({
        title: "Confirmacion",
        text: "Estas seguro de registrar esta novedad?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#007bb8",
      }).then((respuesta)=>{
        if(respuesta.isConfirmed){
          this.EstadoConsulta=true;
          if (fecha_aplica != "" && this.docResolucon != null) {
      Axios()
        .post("almacenar_novedad/" + this.usuario.id_usuario, {
          id_tipo_novedad: novedad.novedades_bienestar_id,
          aplica_el: fecha_aplica,
          id_adjudicacion_bienestar: this.idadjudicadobienestar,
        })
        .then((respuesta) => {
         this.EstadoConsulta=false;
        this.docResolucon= null;

          Swal.fire(
            "Novedad Registrada".toString().toUpperCase(),
            "la causal de suspención o cancelación ha sido registrada!",
            "success"
          );
          if (this.docResolucon != null) {
            this.subirDocumento(respuesta.data.results.id_novedad);
          }
          this.fecha_aplica = "";
          
          this.modalNovedades = false;
          this.obtenerAdjudicadosConvocatoria(this.$route.params.id);
        });
    } else {
      this.EstadoConsulta=false;
      Swal.fire({
        title: "Campos vacíos",
        text: "Se debe ingresar una fecha de inicio de la novedad y un documento de soprte de la novedad",
        icon: "error",
        confirmButtonText: "Aceptar",
        customClass: {
          confirmButton: "btn btn-azul-sena",
        },
      });
    }
        }
      });

 
    
  }
//VALIDACION
  validarFormulario(novedad) {
    this.$validator.validate().then((valid) => {
     
      this.registrarNovedad(this.fecha_aplica, novedad);
    });
  }

  obtenerDocumento(event) {
    let newFileList = Array.from(event);
    if (event.target.files[0].size <= 5000000) {
      //5mb tope
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.docResolucon = event.target.files[0];
        //eliminar

        Swal.fire("Pdf Cargado!", "Archivo cargado exitosamente!", "success");
        this.docValido = true;
      } else {
        this.docValido = false;
        this.docResolucon = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.docResolucon = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  }
  subirDocumento(idNovedad) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    //Creamos el formData
    var data = new FormData();
    //Añadimos la docinscrip seleccionada
    data.append("documento", this.docResolucon);
    data.append("id_novedad", idNovedad);
    //Enviamos la petición
    Axios()
      .post("almacenar_resolucion_novedad/" + idNovedad, data, {
        headers: headers,
      })
      .then((respuesta) => {
        if ((respuesta.data.status = "success")) {
          Swal.fire(
            "Se subió la resolución!",
            "Archivo cargado exitosamente!",
            respuesta.data.status
          );
        } else {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        }
      });
  }
  // obtener las novedades que son pertenecientes a (x) apoyo
  obtenerNovedadesApoyo(id) {
    Axios()
      .get("listar_novedad_bienestar/" + id)
      .then((respuesta) => {
        this.listaNovedadApoyo = respuesta.data.results;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  async obtenerCriterios(id) {
    await Axios()
      .get("crear_inscripcion_convocatoria/" + id)
      .then((respuesta) => {
        return respuesta.data.results;
      })
      .then((res) => {
        this.listaCriterios = res;
        return true;
      })
      .then(() => {
        let checks = document.getElementsByTagName("input");
        for (let i = 0; i < checks.length; i++) {
          const element = checks[i];
          if (element.type == "checkbox") {
            element.checked = false;
          }
        }
      });
  }

  async obtenerCriteriosApoyo(id_apoyo) {
    await Axios()
      .get("listar_criterios/" + id_apoyo)
      .then((respuesta) => {
        this.listaCriteriosApoyo = respuesta.data.results;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  async obtenerInscritosConvocatoria(id) {
    await Axios()
      .get("listar_inscripciones_convocatoria/" + id)
      .then((respuesta) => {
        return respuesta.data.results;
      })
      .then((res) => {
        // setTimeout(() => {
        if (res != null) {
          this.aprendicesInscritos = res;
        }
        // }, 100);
      });
  }

  obtenerFichasAprendiz(cedula) {
    Axios()
      .get("buscar_fichas_aprendiz/" + cedula)
      .then((respuesta) => {
        if (respuesta.data.status.toLowerCase() == "info") {
          this.btnVerFichas = false;
          Swal.fire({
            title: "ERROR!",
            icon: "error",
            text: respuesta.data.message,
            confirmButtonText: "Ok",
            confirmButtonColor: "#007bb8",
          });
        } else if (respuesta.data.status.toLowerCase() == "success") {
          this.fichasAprendiz = respuesta.data.results;
        }

        // this.btnVerFichas = false
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  inscribirAprendizConvocatoria(nuevaCC, idConvocatoria) {
    Axios()
      .post("almacenar_inscripcion_convocatoria", {
        id_convocatoria: idConvocatoria,
        identificacion: nuevaCC,
        id_criterio_priorizacion: this.criteriosSeleccionados,
        id_ficha_caracterizacion: this.fichaSeleccionada,
      })
      .then((respuesta) => {
        return respuesta.data;
      })
      .then((res) => {
        if (res.status == "success") {
          Swal.fire(
            "Aprendiz Inscrito!".toString().toUpperCase(),
            "Se inscribió exitosamente el aprendiz",
            "success"
          );
          this.listaCriterios = [];
          this.listaCriteriosApoyo = [];
          this.criteriosSeleccionados = [];
          this.obtenerCriteriosApoyo(this.convocatoria.tipo_apoyo_id);
          this.obtenerInscritosConvocatoria(this.$route.params.id);
          this.modalInscribir = false;
          setTimeout(() => {
            this.obtenerCriterios(this.$route.params.id);
          }, 3000);
        } else {
          Swal.fire(
            res.status.toString().toUpperCase(),
            res.message,
            res.status
          );
        }
      })
      .catch((err) => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  validarFormularioAprendiz() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.inscribirAprendizConvocatoria(this.nuevaCC, this.idConvocatoria);
        this.nuevaCC = "";

        /* se quita la validacion por requeremiento de Johanna */

        // el formulario se validó y tiene los datos que corresponden por campo
        // if (this.criteriosSeleccionados.length <= 0) {
        //   Swal.fire({
        //     title: "Selección de criterios".toString().toUpperCase(),
        //     text: "Debe seleccionar al menos un criterio!",
        //     icon: "error",
        //     confirmButtonText: "Aceptar",
        //     confirmButtonColor: "#238276",
        //   });
        // } else {
        //   this.inscribirAprendizConvocatoria(this.nuevaCC, this.idConvocatoria);
        //   this.nuevaCC = "";
        // }
      }
    });
  }

  limpiarFichasAprendiz() {
    // if(this.nuevaCC.length < 7){
    this.btnVerFichas = false;
    this.fichasAprendiz = [];
    this.fichaSeleccionada = null;
    // this.obtenerFichasAprendiz(this.nuevaCC)
    // }
  }

  adjudicarInscrito() {
    this.estadoConsulta = true;
    Axios()
      .post("adjudicarInscrito", {
        id_persona: this.personaInscripcion.id_persona,
        id_convocatoria: this.convocatoria.id_convocatoria,
        fecha_inicio_apoyo: this.fecha_inicio,
        fecha_fin_apoyo: this.fecha_fin,
      })
      .then((respuesta) => {
        this.estadoConsulta = false;
        if (
          this.listaLength <= this.convocatoria.cupo_total ||
          this.lista == "c"
        ) {
          this.estadoConsulta = false;
          if (respuesta.data.status == "success") {
            this.nombreObtenido = null;
            this.nuevaCC = "";
            Swal.fire({
              title: "Aprendiz Adjudicado!",
              text: "Se ha registrado el aprendiz a la convocatoria exitosamente",
              icon: "success",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
             this.obtenerAdjudicadosConvocatoria(this.$route.params.id);
             this.modalInscribir=false;
          } else {
            Swal.fire({
              title: "Aprendiz NO Adjudicado!",
              text: respuesta.data.message,
              icon: "error",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
            this.modalInscribir=false;
            this.nombreObtenido = null;
            this.nuevaCC = "";
          }
        } else {
          Swal.fire({
            title: "Información",
            text:
              "Solo hay " +
              this.convocatoria.cupo_total +
              " cupos disponibles para esta convocatoria, verifique el numero de adjudicados.",
            icon: "warning",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
            customClass: {
              confirmButton: "btn btn-azul-sena",
            },
          });
          this.modalInscribir=false;
           this.nombreObtenido = null;
            this.nuevaCC = "";
        }
      })
      .catch(() => {
        this.estadoConsulta = false;
        this.nombreObtenido = null;
        this.nuevaCC = "";
        Swal.fire({
          title: "Error en el servidor!",
          text: "Cominiquese con el administrador del sistema",
          icon: "error",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#007bb8",
        });
      })
      .finally();
    this.estadoConsulta = false;
  }

  adjudicarAprendizConvocatoria(id) {
    Axios()
      .post("adjudicar_aprendiz_convocatoria", {
        id_convocatoria: this.$route.params.id,
        id_persona: id,
        fecha_inicio_apoyo: this.fecha_inicio,
        fecha_fin_apoyo: this.fecha_fin,
        porc_estimulo_mensual:
          this.porc_estimulo_entero + "," + this.porc_estimulo_decimal,
        id_usuario_autenticado: JSON.parse(sessionStorage.getItem("usuario"))
          .id_usuario,
      })
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Usuario " + this.nombre + " adjudicado",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.fecha_inicio = "";
          this.fecha_fin = "";
          this.porc_estimulo_entero = "";
          this.porc_estimulo_decimal = "";
          this.obtenerAdjudicadosConvocatoria(this.$route.params.id);
          this.adjudicar = false;
        } else {
          Swal.fire({
            title: "INFORMACIÓN",
            icon: "warning",
            text: respuesta.data.message,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        }

        // if (respuesta.data.status == "info") {
        //   Swal.fire({
        //     title: "INFORMACIÓN",
        //     icon: "warning",
        //     text:
        //       "El aprendiz " +
        //       this.nombre +
        //       " se encuentra adjudicado en otra convocatoria",
        //     confirmButtonText: "Aceptar",
        //     confirmButtonColor: "#007bb8",
        //   });
        this.obtenerAdjudicadosConvocatoria(this.$route.params.id);
        this.adjudicar = false;
        // }
      })
      .catch((err) => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  obtenerPersona(identificacion) {
    this.estadoConsulta = true;
    Axios()
      .post("crear_representante", { identificacion: identificacion })
      .then((respuesta) => {
        this.estadoConsulta = false;
        if (respuesta.data.status == "success") {
          let primer_nombre = respuesta.data.results.primer_nombre;
          let primer_apellido = respuesta.data.results.primer_apellido;
          let segundo_nombre = respuesta.data.results.segundo_nombre;

          if (segundo_nombre == null) {
            this.nombreObtenido =
              respuesta.data.results.primer_nombre + " " + primer_apellido;
          } else {
            this.nombreObtenido =
              respuesta.data.results.primer_nombre +
              " " +
              segundo_nombre +
              " " +
              primer_apellido;
          }
          this.personaInscripcion = respuesta.data.results;
        } else {
          this.estadoConsulta = false;

          this.nombreObtenido = null;
          this.nuevaCC = "";

          Swal.fire({
            title: "No se encontró la persona",
            icon: "error",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
            text: "El número de identificación no corresponde a una persona registrada en el sistema.",
          });
        }
      });
  }

  // async buscarUsuario() {
  //   await Axios()
  //     .post("buscar_usuario", { identificacion: this.identificacionbuscar })
  //     .then((respuesta) => {
  //       if (respuesta.data.status == "success") {
  //         this.usuariobusqueda = respuesta.data.results;
  //         this.listaAdjudicados = [];
  //         this.listaAdjudicados.push(this.usuariobusqueda);
  //         // this.listaUsuarios = []
  //         // this.listaUsuarios.push(respuesta.data.results.data)

  //         // this.estadoPeticion = false;
  //       } else {
  //         Swal.fire({
  //           title: "perfil no existe",
  //           icon: "error",
  //         });
  //       }
  //     })
  //     .catch(() => {});
  // }

  // filtrar() {
  //   if (this.identificacionbuscar == "") {
  //     this.listaAdjudicados = this.aprendicesInscritos;
  //     return this.listaAdjudicados;
  //   } else {
  //     return this.listaAdjudicados;
  //   }
  // }
contcancelados= 0;
  contarCupos(listaAdj){
    for (let adjudicado of listaAdj) {
      if(adjudicado.estado == "c") {
        this.contcancelados++;
      }
    }
  }

  FiltrarAdjudicados() {
    let Filter = [];
    for (let adjudicado of this.listaAdjudicados) {
      let identificacion = adjudicado.identificacion.toLowerCase();
      let primer_nombre = adjudicado.primer_nombre.toLowerCase();
      let primer_apellido = adjudicado.primer_apellido.toLowerCase();
      let segundo_nombre = "";
      let segundo_apellido = "";
      if(adjudicado.segundo_nombre != null){
        segundo_nombre = adjudicado.segundo_nombre.toLowerCase();
      }
      if(adjudicado.segundo_apellido != null){
        segundo_apellido = adjudicado.segundo_apellido.toLowerCase();
      }
      if (
        identificacion.indexOf(this.filtrado.toLowerCase()) >= 0 ||
        primer_nombre.indexOf(this.filtrado.toLowerCase()) >= 0 ||
        segundo_nombre.indexOf(this.filtrado.toLowerCase()) >= 0 ||
        primer_apellido.indexOf(this.filtrado.toLowerCase()) >= 0 ||
        segundo_apellido.indexOf(this.filtrado.toLowerCase()) >= 0
      ) {
        Filter.push(adjudicado);
      }
    }
    this.listaAdjudicadosFiltrado = Filter;
    if (this.listaAdjudicadosFiltrado.length == 0) {
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró la persona que coincida con la búsqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#007bb8",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.identificacionbuscar = "";
      this.listaAdjudicadosFiltrado = this.listaAdjudicados;
    }
  }
}
