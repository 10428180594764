
import { Component, Vue } from "vue-property-decorator";
// import { mapState, mapActions} from "vuex"
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from '../../../components/animacionCargando.vue';

@Component({
  components:{
    AnimacionCargando
  }
})
export default class Login extends Vue {
  usuario = { identificacion: "", correo: "" };
  estadoPeticion=false;
  constructor() {
    super();

    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        min: () => "Mínimo 8 caracteres",
        email: () => "Correo invalido",
      },
    };
    this.$validator.localize("es", dict);
  }

  mounted() {}
  recuperarContrasena(){
    Swal.fire({
      title: "Revisa tu correo",
              text: "Se ha enviado la información necesaria para continuar con el proceso de recuperación de contraseña.",
              icon: "success",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
  }

  public validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        // el formulario se validó y tiene los datos que corresponden por campo
        this.recuperarContrasena();
      }
    });
  }
}
