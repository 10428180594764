
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../../components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class ExperienciaPersonal extends Vue {
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;

  lista_departamentos = null;
  lista_municipios = null;
  departamentoActual = null;
  lista_funciones: any[] = [];
  funcion = "";
  lista_tipos_empresa = null;

  listarExperiencia: any[] = [];
  listarExperienciaString: any[] = [];

  docValido = false;
  estadoPeticion = false;

  inactivo = false;

  contExpGuardada = 0;

  experienciaPersona = {
    municipio_id: "",
    persona_id: "",
    tipo_empresa_id: "",
    tipo_contrato: "",

    empresa: "",
    direccion_empresa: "",
    correo_empresa: "",
    salario: "",
    fecha_ingreso_empresa: "",
    fecha_retiro_empresa: "",
    cargo: "",
    area: "",
    jefe_inmediato: "",
    cargo_jefe: "",
    funciones: "",
    actividad_empresa: "",
    certificado: null,

    //No
    tipo_empresa: "",
    municipio: "",
    departamento: "",
    id_departamento: "",
    pais: "",
    id_pais: "",
  };

  mounted() {
    this.obtenerDepartamentos();
    this.obtenerPaises();
    this.obtenerTiposEmpresa();
  }

  obtenerDepartamentos() {
    Axios()
      .get("listar_departamentos/57")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_departamentos = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Hubo un problema en el servidor",
          icon: "error",
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "btn btn-azul-sena",
          },
        });
      });
  }

  obtenerMunicipios(iddepa) {
    Axios()
      .get("listar_municipios/" + iddepa)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_municipios = respuesta.data.results.municipios;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Hubo un problema en el servidor",
          icon: "error",
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "btn btn-azul-sena",
          },
        });
      });
  }

  obtenerPaises() {
    Axios()
      .get("/listar_paises")
      .then((respu) => {})
      .catch((err) => {
        Swal.fire({
          title: "Hubo un problema en el servidor",
          icon: "error",
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "btn btn-azul-sena",
          },
        });
      });
  }

  //-----------------------------------------------------------
  agregarEliminarFunciones(tipo, pos?) {
    if (tipo == "agregar") {
      this.lista_funciones.push(this.funcion);
      this.funcion = "";
    } else {
      this.lista_funciones.splice(pos, 1);
    }
  }

  obtenerTiposEmpresa() {
    Axios()
      .get("crear_experiencia_persona")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_tipos_empresa = respuesta.data.results.tiposEmpresa;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Hubo un problema en el servidor",
          icon: "error",
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "btn btn-azul-sena",
          },
        });
      });
  }
  //-----------------------------------------------------------
  obtenerDocumento(event) {
    if (event.target.files[0].size <= 5000000) {
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.experienciaPersona.certificado = event.target.files[0];
        Swal.fire(
          "Certificación Cargada!",
          "Archivo cargado exitosamente!",
          "success"
        );
        this.docValido = true;
      } else {
        this.docValido = false;
        this.experienciaPersona.certificado = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.experienciaPersona.certificado = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  }
  //--------------------------------------------------------------------
  listarExperienciaPersona() {
    //Creamos el formData
    var data = new FormData();

    for (const perfil of this.lista_funciones) {
      this.experienciaPersona.funciones =
        this.experienciaPersona.funciones + perfil + ",";
    }
    //Añadimos la docinscrip seleccionada
    data.append("municipio_id", this.experienciaPersona.municipio_id);
    data.append("persona_id", this.id_persona_logeada);
    data.append("tipo_empresa_id", this.experienciaPersona.tipo_empresa_id);
    data.append("tipo_contrato", this.experienciaPersona.tipo_contrato);
    data.append("empresa", this.experienciaPersona.empresa);
    data.append("direccion_empresa", this.experienciaPersona.direccion_empresa);
    data.append("correo_empresa", this.experienciaPersona.correo_empresa);
    data.append("salario", this.experienciaPersona.salario);
    data.append(
      "fecha_ingreso_empresa",
      this.experienciaPersona.fecha_ingreso_empresa
    );
    data.append(
      "fecha_retiro_empresa",
      this.experienciaPersona.fecha_retiro_empresa
    );
    data.append("cargo", this.experienciaPersona.cargo);
    data.append("area", this.experienciaPersona.area);
    data.append("jefe_inmediato", this.experienciaPersona.jefe_inmediato);
    data.append("cargo_jefe", this.experienciaPersona.cargo_jefe);
    data.append("funciones", this.experienciaPersona.funciones);
    data.append("actividad_empresa", this.experienciaPersona.actividad_empresa);
    data.append("certificado", this.experienciaPersona.certificado);

    this.listarExperiencia.push(data);
    this.listarExperienciaString.push(this.experienciaPersona);
    this.experienciaPersona = {
      municipio_id: "",
      persona_id: "",
      tipo_empresa_id: "",
      tipo_contrato: "",

      empresa: "",
      direccion_empresa: "",
      correo_empresa: "",
      salario: "",
      fecha_ingreso_empresa: "",
      fecha_retiro_empresa: "",
      cargo: "",
      area: "",
      jefe_inmediato: "",
      cargo_jefe: "",
      funciones: "",
      actividad_empresa: "",
      certificado: null,

      //No
      tipo_empresa: "",
      municipio: "",
      departamento: "",
      id_departamento: "",
      pais: "",
      id_pais: "",
    };
  }
  //--------------------------------------------------------------------
  registrarExperienciaPersona() {
    this.estadoPeticion = true;
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    if (this.listarExperiencia.length != 0) {
      for (var i = 0; i < this.listarExperiencia.length; i++) {
        Axios()
          .post("almacenar_experiencia_persona", this.listarExperiencia[i], {
            headers: headers,
          })
          .then((respuesta) => {
            if ((respuesta.data.status = "success")) {
              this.contExpGuardada++;
              if (this.contExpGuardada == this.listarExperiencia.length) {
                Swal.fire({
                  title: "Experiencia Laboral Guardada",
                  text:
                    "Se guardaron " +
                    this.contExpGuardada +
                    " experiencias laborales",
                  icon: "success",
                  confirmButtonText: "Aceptar",
                  confirmButtonColor: "#007bb8",
                });
              }
              this.$router.push({ name: "GestionarHojaDeVidaENI" });
              // this.contExpGuardada++;
            } else {
              Swal.fire(
                "ERROR!",
                "No se Guardo La Expereiencia Laboral",
                "error"
              );
            }
            this.estadoPeticion = false;
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          })
          .finally(() => {
            this.estadoPeticion = true;
          });
      }
    } else {
      Swal.fire({
        title: "Un momento!",
        icon: "info",
        text: "No hay experiencia agregada, por favor llene el formulario al menos una vez",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#007bb8",
      });
    }
  }

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "El valor no puede ser menor a cero",
        max_value: () => "El valor no puede ser menor a cien",
        email: () => "El correo no es válido",
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }

  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid && this.lista_funciones.length != 0) {
        this.listarExperienciaPersona();
      }
    });
  }
}
