<template>
  <div class="mt-5 container-fluid">
    <div class="card">
      <div
        class="border-radius-lg bg-gradient-primary card-header p-0 position-relative mt-n4 mx-3 z-index-2 shadow-3"
      >
        <div class="pt-4 pb-3">
          <h1 class="text-white text-capitalize ps-3 ms-3 title-header">
            GESTIONAR ACTIVIDADES CENTRO DE FORMACIÓN
          </h1>
        </div>
      </div>

      <div class="card-body">
        <!-- ---------------------------BOTON SELECCIONAR ACTIVIDAD------------------------------->
        <div class="row justify-content-end">
          <div class="agregar mt-1 mb-2 mr-3">
            <router-link
              class="btn btn-block bg-gradient-primary text-white shadow-3 p-2"
              :to="{
                name: 'RegistrarActividadCentro',
              }"
            >
              Seleccionar Actividad
            </router-link>
          </div>
        </div>
        <!-- ---------------------------FILTROS DE BUSQUEDAS ------------------------------->
        <div class=" " id="contenedor_todo">
          <!--------------------------- BUSQUEDA DE ACTIVIDAD -------------------------->
          <div class="col-md-6 col-lg-5 col-xl-3 mt-2 pb-3">
            <input
              action="search/"
              type="text"
              class="form-control"
              placeholder="Buscar Actividad o Acción"
              v-model="filtro"
              @keyup.prevent="filtrarActividad()"
            />
          </div>

          <!-------------------------------- FILTRO DE EJES ----------------------------->
          <div class="col-md-4 col-lg-3 col-sm-12 mt-2 pb-3">
            <select
              class="form-control p-2"
              v-model="filtroEje"
              @change="filtrarEjes()"
            >
              <option value="" Disabled>Eje</option>
              <option value="">Todas</option>
              <option
              v-for="(eje, key) in lista_Eje"
              :key="key"
            >
              {{ eje.eje }}
            </option>
            </select>
          </div>

          <!-------------------------------- FILTRO DE RESPONSABLES ----------------------------->
          <div class="col-md-4 col-lg-3 col-sm-12 mt-2 pb-3">
            <select
              class="form-control p-2"
              v-model="filtroResponsable"
              @change="filtrarResponsable()"
            >
              <option value="" Disabled>Responsable</option>
              <option value="">Todas</option>
              <option>
                Coordinador Grupo de Bienestar al aprendiz y atención al
                egresado
              </option>
              <option>
                Coordinador grupo formación continua especializada
              </option>
              <option>Coordinador SENNOVA</option>
              <option>Coordinador Nacional de Emprendimiento</option>
              <option>Coordinador grupo de Relaciones laborales</option>
              <option>Coordinador de Gestión curricular - Redes</option>
              <option>
                Coordinador de Gestión curricular - Redes - Dirección de
                relacionamiento corporativo
              </option>
              <option>
                Coordinador de la Escuela Nacional de Instructores
              </option>
              <option>Coordinador Nacional de Emprendimiento</option>
              <option>
                Coordinador Nacional de la Agencia Pública de Empleo
              </option>
              <option>Coordinador Nacional Agencia Pública de Empleo</option>
              <option>
                Grupo de Gestión de Competencias Laborales GGCL - Mesas
                Sectoriales
              </option>
              <option>Coordinador Nacional APE</option>
              <option>Coodinador de Formación o Misional</option>
              <option>Coordinador misional</option>
              <option>
                Coordinador misional/líder de emprendimiento regional
              </option>
              <option>Director Regional</option>
              <option>
                Directores Regionales, Coordinador de formacion regional
              </option>
              <option>
                Líderes Regionales de los Centros de Desarrollo Empresarial
              </option>
              <option>Directores Regionales</option>
              <option>Gestor Regional SER</option>
              <option>
                Apoyo Administrativo y Secretaría Técnica de Mesa Sectorial del
                Centro de Formación respectivo
              </option>
              <option>
                Coordinadores regionales Agencia Pública de Empleo SENA
              </option>
              <option>Subdirector del Centro.</option>
              <option>Subdirector de Centro, Director Regional.</option>
              <option>Subdirector de Centro , Coordinador de formación</option>
              <option>
                subdirector del Centro, Coordinaciones de formación,
                Coordinaciones académicas
              </option>
              <option>Coordinador de formación</option>
              <option>Dinamizadores Tecnoparque</option>
              <option>
                Líder SENNOVA, líderes de Grupo y de Semilleros de Investigación
              </option>
              <option>Coordinador/Gestor empresarial</option>
              <option>
                Subdirector de Centro - Redes - Profesionales de diseño
                curricular
              </option>
              <option>Subdirectores, Coordinadores de centro</option>
              <option>Subdirectores, Coordinadores de centro</option>
              <option>Subdirector de Centro de Formación</option>
              <option>Gestor de Centro SER</option>
              <option>Director de Formación Profesional</option>
              <option>Director de Empleo, Trabajo y Emprendimiento</option>
              <option>Director de Empleo y Trabajo</option>
              <option>Director de Formación Profesional</option>
              <option>
                Director del Sistema Nacional de Formación para el Trabajo
              </option>
              <option>Secretaria General</option>
            </select>
          </div>

          <!---------------------------------- FILTRO PERIODICIDAD ------------------------->
          <div class="col-md-4 col-lg-3 col-sm-12 mt-2 pb-3">
            <select
              class="form-control p-2"
              v-model="filtroPeriodicidad"
              @change="filtrarPeriocidad()"
            >
              <option value="" Disabled>Periodicidad</option>
              <option value="">Todas</option>
              <option>Semanal</option>
              <option>Quincenal</option>
              <option>Mensual</option>
              <option>Bimestral</option>
              <option>Trimestral</option>
              <option>Cuatrimestral</option>
              <option>Semestral</option>
              <option>Anual</option>
            </select>
          </div>
        </div>

        <!-- ----------------------------------- PAGINACION ------------------------------->
        <paginate-links
          class="pagination justify-content-end mt-3"
          for="actividades"
          :limit="3"
          :hide-single-page="true"
          :show-step-links="true"
          :full-page="true"
          :async="true"
          :classes="{
            ul: 'simple-links-container',
            li: 'simple-links-item',
            liActive: ['simple-links-item', 'active1'],
            'li.active': 'active1',
          }"
        ></paginate-links>
        <!-- ----------------------------------- ANIMACION ------------------------------->
        <div class="text-center" v-if="estadoPeticion">
          <AnimacionCargando></AnimacionCargando>
        </div>
        <!-- ------------------------------- TABLA ------------------------------------------->
        <div class="table-responsive">
          <table class="table table-hover table-inverse">
            <paginate
              ref="paginator"
              name="actividades"
              :list="actividades"
              :per="8"
            >
              <thead class="bg-gradient-azul-sena text-white">
                <tr class="campos">
                  <th class="actividad">EJE</th>
                  <th class="actividad">ESTRATEGIA DE IMPLEMENTACIÓN</th>
                  <th class="actividad">ACCIÓN</th>
                  <th class="actividad">META/ INDICADOR</th>
                  <th class="actividad">RESPONSABLE</th>
                  <th class="actividad">FRECUENCIA</th>
                  <th class="acciones">ACCIONES</th>
                </tr>
              </thead>
              <tbody v-if="actividades != ''">
                <tr
                  v-for="(actividad, key) in paginated('actividades')"
                  :key="key"
                >
                  <td>{{ actividad.eje }}</td>
                  <td>{{ actividad.pes_estrategia }}</td>
                  <td>{{ actividad.actividad }}</td>
                  <td>{{ actividad.indicador }}</td>
                  <td>{{ actividad.rol_operativo }}</td>
                  <td>{{ actividad.periodicidad }}</td>

                  <td>
                    <div>
                      <div
                        class="btn-group btn-spc"
                        tooltip="Registrar Trazabilidad"
                        tooltip-flow="top"
                        v-if="actividad.cant_trazabilidad <= 0"
                      >
                        <router-link
                          class="btn btn-sm bg-oscuro-sena text-white"
                          :to="{
                            name: 'RegistrarActividadTrazabilidad',
                            params: {
                              id_actividad: actividad.id_actividad,
                              id_eje: actividad.id_eje,
                            },
                          }"
                        >
                          <i class="fas fa-clipboard-list"></i>
                        </router-link>
                      </div>
                      <div
                        class="btn-group btn-spc"
                        tooltip="Ver Actividad trazabilidad"
                        tooltip-flow="top"
                        v-if="actividad.cant_trazabilidad > 0"
                      >
                        <router-link
                          class="btn btn-sm bg-oscuro-sena text-white"
                          :to="{
                            name: 'VerDetalleActividadTrazabilidad',
                            params: {
                              id_actividad: actividad.id_actividad,
                              id_eje: actividad.id_eje,
                            },
                          }"
                        >
                          <i class="fas fa-eye"></i>
                        </router-link>
                      </div>
                      <div
                        class="btn-group btn-spc"
                        tooltip="Eliminar Actividad"
                        tooltip-flow="top"
                      >
                        <a
                          @click="
                            eliminarActividad(actividad.id_actividad, key)
                          "
                          class="btn btn-sm bg-oscuro-sena text-white"
                        >
                          <i class="fas fa-trash"></i>
                        </a>
                      </div>

                      <div
                        class="btn-group btn-spc"
                        tooltip="Lista seguimientos"
                        tooltip-flow="top"
                        v-if="
                          actividad.fecha_inicio_actividad != null &&
                          actividad.fecha_final_actividad != null
                        "
                      >
                        <a
                          @click="
                            listaSeguimiento(
                              actividad.id_actividad,
                              actividad.actividad
                            )
                          "
                          class="btn btn-sm bg-oscuro-sena text-white mr-1"
                        >
                          <i class="fas fa-paste"></i>
                        </a>
                      </div>

                      <div
                        class="btn-group btn-spc"
                        tooltip="Fecha programacion"
                        tooltip-flow="top"
                        v-if="
                          actividad.fecha_inicio_actividad == null &&
                          actividad.fecha_final_actividad == null
                        "
                      >
                        <a
                          @click="modal(actividad.id_actividad)"
                          class="btn btn-sm bg-oscuro-sena text-white"
                        >
                          <i class="far fa-calendar-alt"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <p v-else>No hay actividades registradas!</p>
            </paginate>
          </table>
        </div>
        <!-- ----------------------------------- PAGINACION ------------------------------->
        <paginate-links
          class="pagination justify-content-end mt-3"
          for="actividades"
          :limit="3"
          :hide-single-page="true"
          :show-step-links="true"
          :full-page="true"
          :async="true"
          :classes="{
            ul: 'simple-links-container',
            li: 'simple-links-item',
            liActive: ['simple-links-item', 'active1'],
            'li.active': 'active1',
          }"
        ></paginate-links>
        <!-------------------- MODAL PARA INICIO DE LA FECHA DE PROGRAMACION -------------------------->
        <div class="container">
          <modal
            :show.sync="verModal"
            body-classes="p-0"
            modal-classes="justify-content-center modal-dialog-centered modal-sm-12  "
          >
            <template slot="header">
              <h2
                class="text-azul-sena modal-title"
                id="modalNovedadesAprendiz"
              >
                FECHA PROGRAMACION
              </h2>
            </template>
            <card
              type="white"
              shadow
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0"
            >
              <template class="row">
                <!-- campo fecha -->
                <div class="col-lg-12 col-sm-12 py-2 center">
                  <div class="form-group">
                    <label class="mt-2 font-weight-bold" for="cedula">
                      Fecha Inicio
                      <span class="text-danger">*</span>
                      :
                    </label>
                    <input
                      v-model="fechas_actividad.fecha_inicio"
                      :max="fechas_actividad.fecha_fin"
                      type="date"
                      class="form-control shadow p-2"
                      placeholder="fecha en que aplica la suspensión"
                      name="fecha_inicio"
                      id="fecha_inicio"
                      required
                      v-validate="{ required: true }"
                    />
                    <small
                      v-show="errors.has('fecha_inicio')"
                      class="text-danger"
                    >
                      {{ errors.first("fecha_inicio") }}
                    </small>
                  </div>
                </div>

                <div class="col-lg-12 col-sm-12 py-2 center">
                  <div class="form-group">
                    <label class="font-weight-bold" for="cedula">
                      Fecha Fin
                      <span class="text-danger">*</span>
                      :
                    </label>
                    <input
                      type="date"
                      v-model="fechas_actividad.fecha_fin"
                      :min="fechas_actividad.fecha_inicio"
                      class="form-control shadow p-2"
                      placeholder="fecha en que aplica la suspensión"
                      name="fecha_fin"
                      id="fecha_fin"
                      required
                      v-validate="{ required: true }"
                    />
                    <small v-show="errors.has('fecha_fin')" class="text-danger">
                      {{ errors.first("fecha_fin") }}
                    </small>
                  </div>
                </div>
                <div class="d-flex justify-content-center mt-3">
                  <button
                    class="btn btn-azul-sena col-12 col-md-8 shadow-3 p-2"
                    @click="validarFormulario()"
                  >
                    registrar
                  </button>
                </div>
              </template>
            </card>
          </modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";

export default {
  components: {
    AnimacionCargando,
  },

  mounted() {
    this.cargarActividades();
    this.listaEjes();
  },
  created() {
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "No pude ser negativo",
        max: () =>
          "La suma de los cupos no puede ser mayor a " +
          this.convocatoria.cupo_total,
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  },

  data() {
    return {
      estadoPeticion: true,
      id_usuario: JSON.parse(sessionStorage.getItem("usuario")).id_usuario,
      actividades: [],
      lista_Eje: [],
      paginate: ["actividades"],
      verModal: false,
      fechas_actividad: {
        fecha_inicio: "",
        fecha_fin: "",
        id_actividad: "",
      },
      /* Filtro Actividades */
      /* 1 */ filtroActividad: [],
      /* 2 */ filtro: "",
      /* Filtro eje */
      /* 1 */ filtradoEje: [],
      /* 2 */ filtroEje: "",
      /* Filtro Responsable*/
      /* 1 */ filtradoResponsable: [],
      /* 2 */ filtroResponsable: "",
      /* Filtro Periodicidad 1 */
      /* 1 */ filtroPeriodicidad: "",
      /* 2 */ periocidadFiltrado: [],
    };
  },

  methods: {
    cargarActividades() {
      Axios()
        .get("listar_actividades_centro/" + this.id_usuario)
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            this.actividades = respuesta.data.results;
            this.activida = respuesta.data.results;
            this.estadoPeticion = false;console.log(respuesta)
          }
          if (respuesta.data.status == "info") {
            Swal.fire({
              title: "Informacion",
              text: respuesta.data.message,
              icon: "warning",
              confirmButtonText: "Aceptar",
              customClass: {
                confirmButton: "btn btn-azul-sena",
              },
            });
            this.estadoPeticion = false;
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        });
    },
    almacenarFechas() {
      Axios()
        .post("fechas_actividad", this.fechas_actividad)
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            Swal.fire({
              title: "Fechas Registradas",
              icon: "success",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
            this.verModal = false;
            this.cargarActividades();
          }
          if (respuesta.data.status == "info") {
            Swal.fire({
              title: "error",
              text: respuesta.data.message,
              icon: "warning",
              confirmButtonText: "Aceptar",
              customClass: {
                confirmButton: "btn btn-azul-sena",
              },
            });
            this.estadoPeticion = false;
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        });
    },

    eliminarActividad(id, index) {
      Swal.fire({
        title: "Eliminar Actividad " + this.actividades[index].actividad + "?",
        icon: "warning",
        confirmButtonText: "Eliminar",
        confirmButtonColor: "#007bb8",
        cancelButtonColor: "#2d2d2d",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
      }).then((respuesta) => {
        if (respuesta.isConfirmed) {
          Axios()
            .delete("eliminar_actividad_centro/" + id)
            .then((respuesta) => {
              if (respuesta.data.status == "success") {
                Swal.fire({
                  title: "Actividad Eliminada",
                  icon: "success",
                  confirmButtonText: "Aceptar",
                  customClass: {
                    confirmButton: "btn btn-azul-sena",
                  },
                });
                this.actividades.splice(index, 1);
              } else {
                Swal.fire({
                  title: "INFO",
                  text: respuesta.data.message,
                  icon: "warning",
                  confirmButtonText: "Aceptar",
                  customClass: {
                    confirmButton: "btn btn-azul-sena",
                  },
                });
              }
            })
            .catch(() => {
              Swal.fire(
                "ERROR!",
                "Se ha presentado un error en el servidor!",
                "error"
              );
            });
        }
      });
    },

    modal(id) {
      this.verModal = true;

      this.fechas_actividad.id_actividad = id;
    },

    listaSeguimiento(id, actividad) {
      const actividad_id = id;
      const nombreActividad = actividad;
      this.$router.push({
        name: "GestionarSeguimientoActividad",
        params: { actividad_id, nombreActividad },
      });
    },

    validarFormulario() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.almacenarFechas();
        }
      });
    },

    filtrarActividad() {
      let filtroActividad = [];
      for (let activi of this.activida) {
        let nombre = activi.actividad.toLowerCase();
        if (nombre.indexOf(this.filtro) >= 0) {
          filtroActividad.push(activi);
        }
      }
      this.actividades = filtroActividad;
      if (this.actividades.length == 0) {
        //busco no encontro
        Swal.fire({
          title: "No hay coincidencias",
          position: "center",
          timer: 2000,
          text: "No se encontró convocatorias que coincidan con las busqueda",
          showConfirmButton: false,
          backdrop: "rgba(0,0,0,0)",
          background: "#eeeeee",
        });
        this.filtro = "";
        this.actividades = this.activida;
      }
    },

    filtrarEjes() {
      let filtradoEje = [];

      for (let nombreejes of this.activida) {
        let nombre = nombreejes.eje;
        if (nombre.indexOf(this.filtroEje) >= 0) {
          filtradoEje.push(nombreejes);
        }
      }
      this.actividades = filtradoEje;

      if (this.actividades.length == 0) {
        //busco no encontro
        Swal.fire({
          title: "No hay coincidencias",
          position: "center",
          timer: 1000,
          text: "No se encontró convocatorias que coincidan con las busqueda",
          showConfirmButton: false,
          backdrop: "rgba(0,0,0,0)",
          background: "#eeeeee",
        });
        this.filtroEje = "";
        this.actividades = this.activida;
      }
    },

    filtrarResponsable() {
      let filtradoResponsable = [];
      for (let responsable of this.activida) {
        let respon = responsable.rol_operativo;
        if (respon.indexOf(this.filtroResponsable) >= 0) {
          filtradoResponsable.push(responsable);
        }
      }
      this.actividades = filtradoResponsable;
      if (this.actividades.length == 0) {
        //busco no encontro
        Swal.fire({
          title: "No hay coincidencias",
          position: "center",
          timer: 1000,
          text: "No se encontró convocatorias que coincidan con las busqueda",
          showConfirmButton: false,
          backdrop: "rgba(0,0,0,0)",
          background: "#eeeeee",
        });
        this.filtroResponsable = "";
        this.actividades = this.activida;
      }
    },

    filtrarPeriocidad() {
      let periocidadFiltrado = [];
      for (let periodicidad of this.activida) {
        let periodo = periodicidad.periodicidad;
        if (periodo.indexOf(this.filtroPeriodicidad) >= 0) {
          periocidadFiltrado.push(periodicidad);
        }
      }
      this.actividades = periocidadFiltrado;

      if (this.actividades.length == 0) {
        //busco no encontro
        Swal.fire({
          title: "No hay coincidencias",
          position: "center",
          timer: 1000,
          text: "No se encontró convocatorias que coincidan con las busqueda",
          showConfirmButton: false,
          backdrop: "rgba(0,0,0,0)",
          background: "#eeeeee",
        });
        this.filtroPeriocidad = "";
        this.actividades = this.activida;
      }
    },

    listaEjes() {
      Axios()
        .get("/listaEje")
        .then((respuesta) => {
          this.lista_Eje = respuesta.data.results;
        });
    },
  },
};
</script>

<style scoped>
.agregar {
  width: 200px;
}

.campos .actividad {
  width: 400px;
}
.campos .acciones {
  width: 150px;
}
.scroll-vertical {
  overflow: scroll;
  max-height: 400px;
}
#contenedor_todo {
  /* border: 1px seagreen solid; */
  display: flex;
  flex-wrap: wrap;
}
</style>
