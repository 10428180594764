var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-5 container-fluid"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"col-lg-8 col-md-10 col-sm-12 offset-lg-2 offset-md-1 mt-4"},[_c('div',{attrs:{"id":"campo"}},[_c('div',{staticClass:"col-lg-8 col-md-10 col-sm-12 offset-lg-2 offset-md-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6"},[_vm._m(1),_c('div',{attrs:{"id":"contenido"}},[_c('p',{attrs:{"id":"texto"}},[_vm._v(" "+_vm._s(_vm.convocatoria.convocatoria)+" ")])])]),_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6"},[_vm._m(2),_c('div',{attrs:{"id":"contenido"}},[_c('p',{attrs:{"id":"texto"}},[_vm._v(" "+_vm._s(_vm.convocatoria.tipo_apoyo)+" ")])])])])])])])]),(_vm.convocatoria.ruta_documento == 'Sin Documento')?_c('div',[_c('h1',{staticClass:"text-azul-sena text-center"},[_vm._v("Sin documento aún")])]):_vm._e(),(
        _vm.convocatoria.ruta_documento != 'Sin Documento' &&
        _vm.estadoPeticion == false
      )?_c('div',[_c('embed',{attrs:{"src":_vm.rutadocumento,"type":"application/pdf","width":"100%","height":"600px"}})]):_vm._e(),(_vm.estadoPeticion)?_c('div',{staticClass:"text-center"},[_c('AnimacionCargando')],1):_vm._e(),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{attrs:{"id":"campo"}},[_c('button',{staticClass:"btn bg-azul-sena mt-2 text-white shadow-3 p-2",on:{"click":function($event){$event.preventDefault();_vm.$refs.botonResolución.click()}}},[_vm._v(" Cargar Circular ")]),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"botonResolución",staticClass:"form-control",attrs:{"type":"file","name":"urlResolucion","id":"","required":"true"},on:{"change":function($event){return _vm.obtenerDocumento($event)}}})]),(_vm.docValido)?_c('div',{attrs:{"id":"campo"}},[_c('button',{staticClass:"btn bg-azul-sena mt-2 text-white shadow-3 p-2",on:{"click":function($event){return _vm.subirDocumento()}}},[_vm._v(" Subir resolución ")])]):_vm._e()]),_c('div',{staticClass:"row justify-content-center mt-2"},[(_vm.docResolucon != null)?_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-8",attrs:{"id":"campo"}},[_vm._v(" Documento seleccionado : "+_vm._s(_vm.docResolucon.name)+" ")]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"border-radius-lg bg-gradient-primary card-header p-0 position-relative mt-n4 mx-3 z-index-2 shadow-3"},[_c('div',{staticClass:"pt-4 pb-3"},[_c('h1',{staticClass:"text-white text-capitalize ps-3 ms-3 title-header"},[_vm._v(" Cargar Circular ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v("Convocatoria:")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v("Tipo de apoyo:")])])
}]

export { render, staticRenderFns }