
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import Multiselect from "vue-multiselect";
import AnimacionCargando from "../../../components/animacionCargando.vue";

@Component({
  components: {
    BaseCheckbox,
    Multiselect,
    AnimacionCargando

  },
})
export default class ModificarPerfil extends Vue {
  usuariocurrent = JSON.parse(sessionStorage.getItem("usuario"));
  estadoPeticionRegister = false;
  convocatoria = {
    // centro_formacion_id: this.usuariocurrent.id_centro_formacion,
    // dato quemado mientras se soluciona el sesion storage
    centro_formacion_id: this.usuariocurrent.centro_formacion_id,
    convocatoria: "",
    cupo_hombres_14_17: 0,
    cupo_hombres_mayores: 0,
    cupo_mujeres_14_17: 0,
    cupo_mujeres_mayores: 0,
    cupo_total: null,
    estimulo_mensual: "",
    // fase_bienestar: "Creación",
    fase_bienestar_id: 1,
    fecha_final_convoca: "",
    fecha_inicio_convoca: "",
    // id_convocatoria: null,
    ruta_documento: null,
    // tipo_apoyo: null,
    tipo_apoyo_id: null,
    tipo_alimentacion_id: null,
    tipo_transporte_id: null,
    subtipo_transporte_id: null,
    // tipo_monitoria: "",
    tipo_monitoria_id: null,
    id_medio_divulgacion: [],
    id_usuario_crea: this.usuariocurrent.id_usuario,
  };
  fecha_inicio = null;
  fecha_fin = null;

  tiposdeapoyo = [];

  tiposdealimentacion: any[] = [];
  tiposdetransporte: any[] = [];
  subtiposdetransporte: any[] = [];

  tiposdemonitoria: any[] = [];
  mediosdivulgacion = [];
  monitoriaEscogido: any[] = [];
  monitoria: any[] = [];

  constructor() {
    super();

    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "No pude ser negativo",
        max: () =>
          "La suma de los cupos no puede ser mayor a " +
          this.convocatoria.cupo_total,
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }

  mounted() {
    this.obtenerDatosParaFormulario();
    this.verAnio();
    this.obtenerTiposAlimentacion();
    this.obtenerTiposTransporte();
    this.obtenerSubtiposTransporte();
  }

  verAnio() {
    var fecha = new Date();
    var anio = fecha.getFullYear();
    // alert("El año actual es: " + anio);
    this.fecha_inicio = anio;
    this.fecha_fin = anio;
  }

  obtenerDatosParaFormulario() {
    // aquí consultamos los tipos de apoyo y tipos de monitoria 22/diciembre/2020
    Axios()
      .get("crear_convocatoria")
      .then((respuesta) => {
        this.tiposdeapoyo = respuesta.data.results.tipos_apoyo;
        this.tiposdemonitoria = respuesta.data.results.tipos_monitoria;
        this.mediosdivulgacion = respuesta.data.results.medios_divulgacion;
      });
  }

  obtenerTiposAlimentacion() {
    Axios()
      .get("listar_tipo_alimentacion")
      .then((respuesta) => {
        this.tiposdealimentacion = respuesta.data.results;
      });
  }
  obtenerTiposTransporte() {
    Axios()
      .get("listar_tipo_transporte")
      .then((respuesta) => {
        this.tiposdetransporte = respuesta.data.results;
      });
  }
  obtenerSubtiposTransporte() {
    Axios()
      .get("listar_subtipo_transporte")
      .then((respuesta) => {
        this.subtiposdetransporte = respuesta.data.results;
      });
  }

  // varioas monitorias
  addTag(newTag) {
    const tag = {
      monitoria: newTag,
      tipo_monitoria: "",
      id_tipo_monitoria:
        newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
    };
    this.tiposdemonitoria.push(tag);
    this.monitoriaEscogido.push(tag);
  }

  registrarTiposMonitoria(id_convocatoria) {
    for (let lista of this.monitoriaEscogido) {
      Axios()
        .post("almacenar_tipos_monitorias", {
          tipo_monitoria_id: lista.id_tipo_monitoria,
          convocatoria_id: id_convocatoria,
        })
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
          } else {
          }
        });
    }
  }
  // varioas monitorias

  RegistrarConvocatoria() {
    this.estadoPeticionRegister = true;
    // cuando tipo de apoyo no es monitoria deja el tipo de monitoria vacio
    Axios()
      .post("almacenar_convocatoria", this.convocatoria)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Convocatoria Registrada!",
            text: "Se ha registrado la convocatoria exitosamente",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.registrarTiposMonitoria(respuesta.data.results.id_convocatoria);
        } else {
          Swal.fire({
            title: "Convocatoria No registrada!",
            icon: respuesta.data.status,
            text: respuesta.data.message.convocatoria,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        }
        this.$router.push("GestionarConvocatoria");
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionRegister = false;
      });
  }

  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        if (this.convocatoria.tipo_apoyo_id == 3) {
          if (
          (this.convocatoria.cupo_hombres_mayores +
            this.convocatoria.cupo_mujeres_14_17 +
            this.convocatoria.cupo_hombres_14_17 +
            this.convocatoria.cupo_mujeres_mayores) !=
          this.convocatoria.cupo_total
        ) {
           Swal.fire({
            title: "Convocatoria no registrada!",
            icon: "error",
            text: "El número de cupos totales no está cubierto",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        } else {
          this.RegistrarConvocatoria();
        }
        }else{
           this.RegistrarConvocatoria()
        }
        // el formulario se validó y tiene los datos que corresponden por campo
        
      }
    });
  }
}
