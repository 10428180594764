
  import { Component, Vue } from "vue-property-decorator";
  import Axios from "@/Axios";
  import Swal from "sweetalert2";
  import AnimacionCargando from "@/components/animacionCargando.vue";
  import Docs from "@/components/Documentos/Documento.vue";


  @Component({
    components: {
      AnimacionCargando,
      Docs
    },
  })
  export default class Documento extends Vue {
    listaDocumentos = [];
    listaDocumentosFiltrado: any[] = [];
    paginate = ["listaDocumentosFiltrado"];

    estadoPeticionTabla = false;
    filter = "";

    // documentos = {
    //   id_documento: "",
    //   ruta: "",
    //   documento: "",
    //   persona_id: "",
    //   primer_nombre: "",
    //   segundo_nombre: "",
    //   primer_apellido: "",
    //   segundo_apellido: "",
    //   fecha: ""
    // }

    obtenerDocs() {
      Axios()
        .get("listar_documento")
        .then((respuesta) => {
          this.listaDocumentos = respuesta.data.results;
          this.listaDocumentosFiltrado = respuesta.data.results;
        })
        .finally(() => {
          this.estadoPeticionTabla = true;
        });
    }

    constructor() {
      super();
      const dict = {
        messages: {
          required: () => "Campo obligatorio",
          min: () => "Mínimo 8 caracteres",
        },
      };
      this.$validator.localize("es", dict);
    }
    mounted() {
      this.obtenerDocs();
    }

    eliminarDocs(id, documento, index) {
      Swal.fire({
        title: "Eliminar Documento ",
        text: documento + "?",
        icon: "warning",
        confirmButtonText: "Eliminar",
        confirmButtonColor: "#007bb8",
        cancelButtonColor: "#2d2d2d",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
      })
        .then((respuesta) => {
          if (respuesta.isConfirmed) {
            Axios()
              .delete("eliminar_documento/" + id)
              .then((respuesta) => {
                if (respuesta.data.status.toLowerCase() == "success") {
                  Swal.fire({
                    title: "Documento eliminado",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                    confirmButtonColor: "#007bb8",
                    cancelButtonColor: "#2d2d2d",
                  });
                  this.listaDocumentosFiltrado.splice(index, 1);
                } else if (respuesta.data.status.toLowerCase() == "error") {
                  Swal.fire({
                    title: "El Documento No fue eliminado",
                    text: respuesta.data.message,
                    icon: "error",
                    confirmButtonText: "Aceptar",
                    confirmButtonColor: "#007bb8",
                    cancelButtonColor: "#2d2d2d",
                  });
                }
              });
          }
        });
    }

    documentoFiltrado() {
      let docsFilter = [];
      // this.listaConvocatoriasFiltrado = []

      for (let docs of this.listaDocumentos) {
        let documento = docs.documento.toLowerCase();

        if (
          documento.indexOf(this.filter) >= 0
        ) {
          docsFilter.push(docs);
        }
      }

      this.listaDocumentosFiltrado = docsFilter;
      if (this.listaDocumentosFiltrado.length == 0) {
        //busco no encontro
        Swal.fire({
          title: "No hay coincidencias",
          position: "center",
          timer: 1000,
          text: "No se encontró el documento, que coincida con la búsqueda",
          showConfirmButton: false,
          // confirmButtonText: "Aceptar",
          // confirmButtonColor: "#238276",
          backdrop: "rgba(0,0,0,0)",
          background: "#eeeeee",
        });
        this.filter = "";
        this.listaDocumentosFiltrado = this.listaDocumentos;
      }
    }
  }
