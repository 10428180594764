
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";
import AnimacionTablasCargando from "../../../components/animacionTablasCargando.vue";
import Multiselect from "vue-multiselect";

@Component({
  components: {
    AnimacionCargando,
    AnimacionTablasCargando,
    Multiselect,
  },
})
export default class Login extends Vue {
  areas: any[] = [];
  picTipos: any[] = [];
  redesConocimiento: any[] = [];
  areasEscogidas;
  redesEscogidas;
  // areasEscogidas: any[] = [];
  lista_redes: any[] = [];
  red_conocimiento_seleccionada = null;

  estadoPeticionTabla = false;
  estadoPeticion = true;
  listaPicFiltrado: any[] = [];
  listaPic = [];
  filter = "";
  filter2 = "";
  filterArea = "";
  value = "";
  fecha_pic ;
  fechas_pic = [];
  paginate = ["listaPicFiltrado"];
  filterPicTipo = "";
  existe_tipo_tecnico = false;
  existe_tipo_pedagogico = false;
  existe_tipo_clave = false;
  estadopeticionAnio = false;
  tipoPicFilter = "";
  norpetidas: any = [];
  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        min: () => "Mínimo 8 caracteres",
        // email: () => "Correo invalido",
      },
    };
    this.$validator.localize("es", dict);
  }

  async mounted() {
    this.obtenYearNow();
    this.obtenerCapacitaciones();
    await this.ontenerPlanInstitucional();
    await this.ObtenerDatosPIC();
    this.obtenerRedConocimiento();
  }

  async updated() {
    await this.listarTiposPic();
  }
   obtenYearNow ()
  {
    this.fecha_pic = new  Date().getFullYear().toString();
    // console.log(this.fecha_pic);
  }

  async ObtenerDatosPIC() {
    await Axios()
      .get("/crear_pic")
      .then((respuesta) => {
        this.areas = respuesta.data.results.areas;

        this.picTipos = respuesta.data.results.picTipos;
        this.redesConocimiento = respuesta.data.results.redesConocimiento;
      });
  }

  async obtenerCapacitaciones() {
    this.filter = "";
    this.areasEscogidas = "";
    this.redesEscogidas = "";
    this.filterPicTipo = "";
    this.estadopeticionAnio = true;
    Axios()
      .get("listar_pics/" + this.fecha_pic)
      .then((respuesta) => {
        this.listaPic = respuesta.data.results.pics;
        this.listaPicFiltrado = respuesta.data.results.pics;
        this.estadopeticionAnio = false;
      })
      .finally(() => {
        this.estadopeticionAnio = false;
      });
  }
  obtenerCapacitacionesNorepetidas() {
    this.norpetidas = [];
    let repetido = false;
    for (const redCono in this.listaPic) {
      if (
        this.listaPicFiltrado[redCono].redes_relacionadas ==
        "Todas"
      ) {
        repetido = true;
        alert(redCono);
        break;
      }else{
        alert("no ha")
      }
      // eslint-disabled
      if (repetido) {
        this.norpetidas.push(this.listaPicFiltrado[redCono]);
        // console.log(this.norpetidas);
      }
    }
  }

  async ontenerPlanInstitucional() {
    this.estadoPeticionTabla = false;
    await Axios()
      .get("listar_plan_institucional")
      .then((respuesta) => {
        this.fechas_pic = respuesta.data.results;
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }

  async eliminarPic(id, capacitacion) {
    Swal.fire({
      title: "Eliminar Capacitación ",
      text: capacitacion + "?",
      icon: "warning",
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#565656",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    })
      .then((respuesta) => {
        if (respuesta.isConfirmed) {
          Axios()
            .delete("eliminar_pic/" + id)
            .then((respuesta) => {
              if (respuesta.data.status.toLowerCase() == "success") {
                Swal.fire({
                  title: "Capacitación eliminada",
                  icon: "success",
                  confirmButtonText: "Aceptar",
                  confirmButtonColor: "#007bb8",
                  cancelButtonColor: "#2d2d2d",
                });
                // this.listaPicFiltrado.splice(index, 1);
                this.obtenerCapacitaciones();
              } else {
                Swal.fire({
                  title: "Capacitación No fue eliminada",
                  text: respuesta.data.message,
                  icon: "error",
                  confirmButtonText: "Aceptar",
                  confirmButtonColor: "#007bb8",
                  cancelButtonColor: "#2d2d2d",
                });
              }
              // this.listaPic.splice(index, 1);
            });
          // this.eliminarAreasEscogidasPic(id);
        }
      })
      .finally(() => {
        this.obtenerCapacitaciones();
      });
  }

  picAreasFiltrado() {
    let arregloFiltrado = [];

    for (let capacitacion of this.listaPic) {
      let areasRelacionadas = capacitacion.areas_relacionadas.toLowerCase();
      // MACHETAZO ASPERO
      this.listaPicFiltrado = this.listaPic;

      if (
        areasRelacionadas.indexOf(
          this.areasEscogidas.area_ruta.toLowerCase()
        ) >= 0
      ) {
        arregloFiltrado.push(capacitacion);
      }
    }

    this.listaPicFiltrado = arregloFiltrado;
    if (this.listaPicFiltrado.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró areas que coincidan con las busqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#238276",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.areasEscogidas = {};
      this.listaPicFiltrado = this.listaPic;
      // this.paginate = ['listaConvocatoriasFiltrado']
    }
  }

  picRedFiltrado() {
    let arregloFiltrado = [];

    for (let capacitacion of this.listaPic) {
      let redesRelacionadas = capacitacion.redes_relacionadas.toLowerCase();
      // MACHETAZO ASPERO
      this.listaPicFiltrado = this.listaPic;

      if (
        redesRelacionadas.indexOf(
          this.redesEscogidas.red_conocimiento.toLowerCase()
        ) >= 0
      ) {
        arregloFiltrado.push(capacitacion);
      }
    }

    this.listaPicFiltrado = arregloFiltrado;
    if (this.listaPicFiltrado.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró areas que coincidan con las busqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#238276",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.redesEscogidas = {};
      this.listaPicFiltrado = this.listaPic;
      // this.paginate = ['listaConvocatoriasFiltrado']
    }
  }

  obtenerRedConocimiento() {
    Axios()
      .get("listar_red_conocimiento")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_redes = respuesta.data.results;
        }
      });
  }

  async eliminarAreasEscogidasPic(id_pic) {
    await Axios()
      .delete("eliminar_areas_asociadas_capacitacion/" + id_pic)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
        } else {
        }
      });
  }

  picFiltrado() {
    let picFilter = [];

    for (let capa of this.listaPic) {
      let capacitacion = capa.capacitacion
        .normalize("NFD")
        .replace(/[^\w]/g, "")
        .toLowerCase();

      let pictipo = capa.pic_tipo.pic_tipo
        .normalize("NFD")
        .replace(/[^\w]/g, "")
        .toLowerCase();

      let areas_relacionadas = capa.areas_relacionadas
        .normalize("NFD")
        .replace(/[^\w]/g, "")
        .toLowerCase();

      if (
        capacitacion.indexOf(
          this.filter.normalize("NFD").replace(/[^\w]/g, "").toLowerCase()
        ) >= 0 ||
        pictipo.indexOf(
          this.filter.normalize("NFD").replace(/[^\w]/g, "").toLowerCase()
        ) >= 0 ||
        areas_relacionadas.indexOf(
          this.filter.normalize("NFD").replace(/[^\w]/g, "").toLowerCase()
        ) >= 0
      ) {
        picFilter.push(capa);
      }
    }

    this.listaPicFiltrado = picFilter;
    if (this.listaPicFiltrado.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró la capacitación, que coincida con la búsqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#238276",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filter = "";
      this.listaPicFiltrado = this.listaPic;
    }
  }

  async picTipoFiltrado() {
    this.listaPicFiltrado = [];
    this.listarTiposPic();
    for (let conv of this.listaPic) {
      let picTipo = conv.pic_tipo.pic_tipo;
      if (this.filterPicTipo != "") {
        if (picTipo.indexOf(this.filterPicTipo) >= 0) {
          this.listaPicFiltrado.push(conv);
        } else {
          // no hay coincidencias
        }
      } else {
        this.listaPicFiltrado = this.listaPic;
      }
    }
  }

  listarTiposPic() {
    for (let conv of this.listaPic) {
      if (conv.pic_tipo.pic_tipo == "Técnico") {
        this.existe_tipo_tecnico = true;
      } else if (conv.pic_tipo.pic_tipo == "Pedagógico") {
        this.existe_tipo_pedagogico = true;
      } else if (conv.pic_tipo.pic_tipo == "Clave y Transversal") {
        this.existe_tipo_clave = true;
      }
    }
  }
}
