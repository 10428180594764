
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal  from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";

@Component({
    components: {
    AnimacionCargando,
},
})
export default class Logic extends Vue{

    // variables
    listIncrpcionesEstados = [];
    listIncrpcionesEstadosFiltrada = [];
    listnull = []
    boll_lista = false;
    animationlist = false;

    copylist = []

    filter_estados_inscrpcion = ""
    list_estados_inscription = []
    list_filter_estado_inscrpcion =[]

    filter_buscador = ""
    //fin variables

    mounted() {
        this.obtenerListaInscritos();
        // this.obtenerEstadosTheInscripion();
    }
    // consumo de la lista de inscritos
    obtenerListaInscritos ()
    {
        Axios()
        .get("lista_incritos/" + this.$route.params.id)
        .then((response)=>{
            let data = response.data.results ;
            if(response.data.status == "success"){
                this.boll_lista = true ;
                this.listIncrpcionesEstados = data;
                this.listIncrpcionesEstadosFiltrada = data;
                this.filterEstadosMethot()
                // this.copylist = this.listIncrpcionesEstadosFiltrada

            }else if (response.data.status == "INFO"){
                this.boll_lista = false ;
            }
        }).catch((err)=>{
            Swal.fire("ERROR" ,"Error de servidor","error")
        }).finally(()=>{
            //logic

        });
    }
    // consumo de la lista de estdos de inscrpcion
    obtenerEstadosTheInscripion ()
    {
        Axios()
        .get("lista_estados_inscripcion")
        .then((response)=>
        {
            let data = response.data.results
            this.list_estados_inscription = data
        }).catch((err)=>
        {
            Swal.fire("ERROR" , "Error de servidor" , "error")
        }).finally(()=>
        {
            //font
        });
    }

    filtrar_estados_inscrpcion ()
    {
        if(this.filter_estados_inscrpcion == 'all'){
            this.listIncrpcionesEstadosFiltrada =  this.listIncrpcionesEstados
        }else{
            this.listIncrpcionesEstadosFiltrada = [];
            this.listIncrpcionesEstados.forEach(element => {
                let data = element.pic_estado_inscripcion_id
                if (data == this.filter_estados_inscrpcion) {
                this.listIncrpcionesEstadosFiltrada.push(element);
                }
            });
            if (this.listIncrpcionesEstadosFiltrada.length == 0 ){
                this.SwalFireNotConcidens()
                this.boll_lista = false;
            }else{
                this.boll_lista = true;
            }
        }
        this.copylist = this.listIncrpcionesEstadosFiltrada
    }

    filtroPorBuscador()
    {
        this.subfilterbusqueda()
    }

    subfilterbusqueda ()
    {
        if (this.copylist.length == 0 )
        {
            this.filterBuscadorMetho(this.listIncrpcionesEstados)
            this.accionesMethos(this.listIncrpcionesEstados)
        }else
        {
            this.filterBuscadorMetho(this.copylist)
            this.accionesMethos(this.copylist)
        }
    }

    SwalFireNotConcidens ()
    {
        Swal.fire({
            title: 'No hay coinsidencias',
            icon: 'question',
            showConfirmButton : false,
            timer : 1000
            })
    }

    filterBuscadorMetho(lista)
    {
        let locallist = []
        lista.forEach(element => {
        let identificacion =element.identificacion.toLowerCase();
        let namefirst =element.primer_nombre.toLowerCase() + "";
        let namesecond =element.segundo_nombre.toLowerCase() + "";
        let surnamefirst =element.primer_apellido.toLowerCase() + "";
        let surnamesecond =element.segundo_apellido.toLowerCase() + "";
        let centroformacion = element.centro_formacion.toLowerCase() + "";
        let area = element.area_ruta.toLowerCase() + "";
        let filterlocal = this.filter_buscador.toLowerCase() + "";
        //condicional
        if(identificacion.indexOf(filterlocal) == 0 ||
        namefirst.indexOf(filterlocal) == 0 ||
        namesecond.indexOf(filterlocal) == 0 ||
        surnamefirst.indexOf(filterlocal) == 0 ||
        surnamesecond.indexOf(filterlocal) == 0 ||
        centroformacion.indexOf(filterlocal) == 0 ||
        area.indexOf(filterlocal) == 0)
        {
            locallist.push(element);
        }
        this.listIncrpcionesEstadosFiltrada  = locallist
        });
    }

    accionesMethos(listlocal)
    {
        if (this.listIncrpcionesEstadosFiltrada .length == 0 ){
                this.SwalFireNotConcidens()
                this.filter_buscador = ""
                this.listIncrpcionesEstadosFiltrada = listlocal
                this.boll_lista = true;
            }else
            {
                this.listIncrpcionesEstadosFiltrada
            }
            if (this.filter_buscador == '') {
                this.listIncrpcionesEstadosFiltrada = listlocal
            }
    }

    filterEstadosMethot()
    {
        let contador = 0
        console.log();
        this.listIncrpcionesEstados.forEach(element => {
            let pic_estado = element.pic_estado_inscripcion.toLowerCase()

            this.listIncrpcionesEstados.forEach(element => {
                var newww = element.pic_estado_inscripcion
                if (pic_estado == newww) {
                    contador ++
                }
            });
            console.log(pic_estado);
        });
    }
}
