
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import AnimacionCargando from "@/components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class VerDetalleConvenio extends Vue {
  estadoPeticion = false;

  viabilidad = {
    id_viabilidad: "",
    convenio_id: "",
    convenio: "",
    colegio_id: "",
    colegio: "",
    apto: "",
    fecha: "",
  };
  mounted() {
    this.obtenerViabilidad(this.$route.params.id);
  }
  obtenerViabilidad(id) {
    Axios()
      .get("ver_detalle_viabilidad/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.viabilidad = respuesta.data.results[0];
        }
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }
}
