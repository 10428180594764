
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import router from "@/router";
import AnimacionCargando from "@/components/animacionCargando.vue";
import Multiselect from "vue-multiselect";
import { color } from "highcharts";

@Component({
  components: {
    AnimacionCargando,
    Multiselect,
  },
})
export default class ModificarConvocatoriaENI extends Vue {
  estadoPeticionCargando = false;
  errorRequisito: any = false;
  docResolucon = null;
  docValido = false;
  estadoPeticion = false;
  infoInstructorResponsable = "";
  // variables para capacitacion nacional e internacional
  estadoPeticionModificar = false;
  tipo_inter_nacio = "";

  listarLineaTecnologica: any[] = [];
  listarEntidadCapacitadora: any[] = [];

  convocatoria = {
    convocatoria: "",
    fecha_inicio: "",
    fecha_final: "",
    lugar: "",
    perfiles_requisitos: "",
    financiacion: "",
    id_pic_convocatoria: "",
    para_funcionarios: "",
    pertenece_a_red: "SI", //dato quemado
    pic_id: "",
    plan_institucional_id: "",
    id_pic: "",
    pic_fase_id: 0,
    id_usuario_autenticado: JSON.parse(sessionStorage.getItem("usuario"))
      .id_usuario, //esta quemado, verificar
    nacional: "",
    id_departamento: "",
    id_municipio: "",
    municipio: "",
    id_pic_tipo: "",
    tipo_financiacion: "",
    linea_tecnologica: "",
    linea_tecnologica_id: "",
    entidad_capacitadora_id: "",
    entidad_capacitadora: "",
    resultado_aprendizaje: "",
    competencia_programa_oferta: "",
    tipo_pic: "",
    //nuevos
    fecha_fin_conv: "",
    fecha_inicio_conv: "",
    horas_capacitacion: "",
    identificacion_responsable: "",
    modalidad_id: "",
    tipos_funcionarios: [],
  };

  fechas_pic = [];

  otro = false;
  modalBuscarInstructor = false;
  estadobusquedainstructor = false;
  lista_capacitaciones: any[] = [];
  modalidades: any[] = [];

  requisito = "";
  aprendizaje = "";
  oferta = "";

  mostar_aprendizaje: any = false;
  mostar_oferta: any = false;

  lista_perfiles_requisitos: any[] = [];
  lista_aprendizaje: any[] = [];
  lista_oferta: any[] = [];
  // anexos
  anexos_convocatoria: any[] = [];
  id_anexo = "";
  modalAnexo = false;
  rutadocumento = "";
  //departamentos
  lista_departamentos: any[] = [];
  id_pais = 57;
  lista_paises = null;
  //municipios
  lista_municipios = null;
  departamento_actual = null;
  lista_tipos_capacitaciones: any[] = [];
  picTipos: any[] = [];

  paraFuncionarios: any[] = [];
  paraFuncionariosEscogidos: any[] = [];

  async mounted() {
    await Axios()
      .get("/crear_convocatoria_pic")
      .then((respuesta) => {
        this.lista_capacitaciones = respuesta.data.results.pics;
        this.modalidades = respuesta.data.results.modalidades;
      });

    this.convocatoria.id_pic_convocatoria = this.$route.params.id;
    await this.listarTiposPics();

    await this.obtenerPic(this.convocatoria.id_pic_convocatoria);

    await this.ontenerPlanInstitucional();
    await this.obtenerDepartamento(57);
    await this.listarCapacitacionesTipoAnio();
    await this.obtenerLineaTecnologica();
    await this.obtenerEntidadCapacitadora();
    this.obtenerFuncionarios();
    this.obtenerMunicipio(this.convocatoria.id_departamento);
    // await this.buscarInstructor();
    this.estadoPeticionCargando = true;
  }
  obtenerFuncionarios() {
    Axios()
      .get("listar_pic_dirigida")
      .then((respuesta) => {
        this.paraFuncionarios = respuesta.data.results;
      });
  }
  addTag(newTag) {
    const tag = {
      para_funcionarios: newTag,
      nombre: "",
      id_dirigido_a:
        newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
    };
    this.paraFuncionarios.push(tag);
    this.paraFuncionariosEscogidos.push(tag);
  }

  //obtener municipios inicio
  obtenerMunicipio(id: any) {
    Axios()
      .get("listar_municipios/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_municipios = respuesta.data.results.municipios;
        }
      });
  }
  //obtener municipios fin
  obtenerOtro() {
    if (this.convocatoria.linea_tecnologica == "Otro") {
      this.otro = true;
    } else {
      this.otro = false;
    }
  }

  validarInstructorBuscado() {
    if (this.estadobusquedainstructor) {
      this.buscarInstructor();
      this.convocatoria.identificacion_responsable = "";
      this.estadobusquedainstructor = false;
    }
  }

  buscarInstructor() {
    if (this.convocatoria.identificacion_responsable != "") {
      Axios()
        .get("buscar_usuario/" + this.convocatoria.identificacion_responsable)
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            this.infoInstructorResponsable =
              respuesta.data.results.identificacion +
              " - " +
              respuesta.data.results.nombres +
              " " +
              respuesta.data.results.apellidos;
            this.modalBuscarInstructor = false;
            this.estadobusquedainstructor = true;
            // Swal.fire({
            //   title: 'Instructor encontrado',
            //   text:
            //     'Instructor resposable escogido: ' +
            //     respuesta.data.results.nombres +
            //     ' ' +
            //     respuesta.data.results.apellidos,
            //   icon: respuesta.data.status,
            //   confirmButtonText: 'Aceptar',
            //   customClass: {
            //     confirmButton: 'btn btn-azul-sena',
            //   },
            // })
          } else {
            this.convocatoria.identificacion_responsable = "";
            this.infoInstructorResponsable = "";
            Swal.fire({
              title: "Instructor no encontrado",
              text: respuesta.data.message,
              icon: "error",
              confirmButtonText: "Aceptar",
              customClass: {
                confirmButton: "btn btn-azul-sena",
              },
            });
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        });
    } else {
      //campo vacio
      Swal.fire({
        title: "Campo vacío",
        text: "Por favor ingresa una identificación",
        icon: "error",
        confirmButtonText: "Aceptar",
        customClass: {
          confirmButton: "btn btn-azul-sena",
        },
      });
    }
  }
  updated() {
    //revisar muchas peticiones error 429
    // this.listarTiposPics();
    // if (this.convocatoria.id_pic_tipo == "1") {
    //   this.convocatoria.identificacion_responsable = "";
    //   this.infoInstructorResponsable = "";
    // }
  }
  obtenerCapacitaciones() {
    Axios()
      .get("/crear_convocatoria_pic")
      .then((respuesta) => {
        this.lista_capacitaciones = respuesta.data.results.pics;
        this.modalidades = respuesta.data.results.modalidades;
      });
  }
  listarCapacitacionesTipoAnio() {
    Axios()
      .post("listar_capacitaciones_anio_tipo", {
        pic_tipo_id: this.convocatoria.id_pic_tipo,
        plan_institucional_id: this.convocatoria.plan_institucional_id,
      })
      .then((respuesta) => {
        this.lista_tipos_capacitaciones = respuesta.data.results;
      });
  }

  async listarTiposPics() {
    await Axios()
      .get("/crear_pic")
      .then((respuesta) => {
        this.picTipos = respuesta.data.results.picTipos;
        this.picTipos = respuesta.data.results.picTipos;
        this.picTipos = respuesta.data.results.picTipos;
        this.picTipos = respuesta.data.results.picTipos;
        this.picTipos = respuesta.data.results.picTipos;
        this.picTipos = respuesta.data.results.picTipos;
        this.picTipos = respuesta.data.results.picTipos;
        this.picTipos = respuesta.data.results.picTipos;
        this.picTipos = respuesta.data.results.picTipos;
        this.picTipos = respuesta.data.results.picTipos;
        this.picTipos = respuesta.data.results.picTipos;
        this.picTipos = respuesta.data.results.picTipos;
        this.picTipos = respuesta.data.results.picTipos;
        this.picTipos = respuesta.data.results.picTipos;
        this.picTipos = respuesta.data.results.picTipos;
        this.picTipos = respuesta.data.results.picTipos;
        this.picTipos = respuesta.data.results.picTipos;
        this.picTipos = respuesta.data.results.picTipos;
        this.picTipos = respuesta.data.results.picTipos;
        this.picTipos = respuesta.data.results.picTipos;
        this.picTipos = respuesta.data.results.picTipos;
        this.picTipos = respuesta.data.results.picTipos;
        this.picTipos = respuesta.data.results.picTipos;
      });
  }

  //obtener departamentos inicio
  obtenerDepartamento(id_pais) {
    Axios()
      .get("listar_departamentos/" + id_pais)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_departamentos = respuesta.data.results;
        } else {
          Swal.fire({
            title: respuesta.data.message,
          });
        }
      });
  }

  //obtener departamentos fin

  async obtenerPic(id) {
    await Axios()
      .get("editar_convocatoria_pic/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.convocatoria = respuesta.data.results;
          if (this.convocatoria.entidad_capacitadora == "SENA") {
            this.buscarInstructor();
          }
          // console.log(b);
          this.lista_perfiles_requisitos =
            this.convocatoria.perfiles_requisitos.split(",");
          this.lista_perfiles_requisitos.splice(
            this.lista_perfiles_requisitos.length - 1,
            1
          );

          this.lista_aprendizaje =
            this.convocatoria.resultado_aprendizaje.split(",");
          this.lista_aprendizaje.splice(this.lista_aprendizaje.length - 1, 1);

          this.lista_oferta =
            this.convocatoria.competencia_programa_oferta.split(",");
          this.lista_oferta.splice(this.lista_oferta.length - 1, 1);

          this.convocatoria.id_pic = respuesta.data.results.pic_id;
        } else {
          Swal.fire({
            title: respuesta.data.message,
          }).then(() => {
            this.$router.push("GestionarConvocatoriaENI");
          });
        }
      });
  }
  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "No pude ser negativo",
        // max: () => "La suma de los cupos no puede ser mayor a " + this.convocatoria.cupo_total,
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }

  // anexos

  ontenerPlanInstitucional() {
    Axios()
      .get("listar_plan_institucional")
      .then((respuesta) => {
        this.fechas_pic = respuesta.data.results;
      });
  }

  agregarEliminarRequisito(tipo, pos?) {
    if (tipo == "agregar") {
      this.lista_perfiles_requisitos.push(this.requisito);
      this.requisito = "";
    } else {
      this.lista_perfiles_requisitos.splice(pos, 1);
    }
  }
  agregarEliminarAprendizaje(tipo, pos?) {
    if (tipo == "agregar") {
      this.lista_aprendizaje.push(this.aprendizaje);
      this.aprendizaje = "";
    } else {
      this.lista_aprendizaje.splice(pos, 1);
    }
  }
  agregarEliminarOferta(tipo, pos?) {
    if (tipo == "agregar") {
      this.lista_oferta.push(this.oferta);
      this.oferta = "";
    } else {
      this.lista_oferta.splice(pos, 1);
    }
  }
  modificarPIC() {
    this.estadoPeticionModificar = true;
    this.convocatoria.perfiles_requisitos = "";
    this.convocatoria.resultado_aprendizaje = "";
    this.convocatoria.competencia_programa_oferta = "";

    for (const perfil of this.lista_perfiles_requisitos) {
      this.convocatoria.perfiles_requisitos =
        this.convocatoria.perfiles_requisitos + perfil + ",";
    }
    for (const perfil of this.lista_aprendizaje) {
      this.convocatoria.resultado_aprendizaje =
        this.convocatoria.resultado_aprendizaje + perfil + ",";
    }
    for (const perfil of this.lista_oferta) {
      this.convocatoria.competencia_programa_oferta =
        this.convocatoria.competencia_programa_oferta + perfil + ",";
    }

    // this.convocatoria.id_pic = this.convocatoria.pic_id; //el paso se realiza porque en el modificar no sabemos cual de las dos variables recoge el metodo
    Axios()
      .put(
        "actualizar_convocatoria_pic/" + this.convocatoria.id_pic_convocatoria,
        this.convocatoria
      )
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Convocatoria Modificada",
            text: "Se modificó la convocatoria exitosamente",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
            customClass: {
              confirmButton: "btn btn-azul-sena",
            },
          });
          this.estadoPeticionModificar = true;
          this.$router.back();
        } else {
          Swal.fire({
            title: "Error no se pudo modificar",
            icon: "error",
            confirmButtonText: "Aceptar",
            customClass: {
              confirmButton: "btn btn-azul-sena",
            },
          });
          this.$router.back();
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionModificar = false;
      });
  }
  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (
        valid &&
        this.lista_aprendizaje.length != 0 &&
        this.lista_perfiles_requisitos.length != 0 &&
        this.lista_oferta.length != 0
      ) {
        this.modificarPIC();
      }
    });
  }
  obtenerLineaTecnologica() {
    Axios()
      .get("/listar_lineas_tecnologica")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listarLineaTecnologica = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  obtenerEntidadCapacitadora() {
    Axios()
      .get("listar_entidad_capacitadora")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listarEntidadCapacitadora = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
}
