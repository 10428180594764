
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";
import Documentos from '../../../../components/Documentos/Documento.vue';
import Documento from '../../../Documento/Index.vue';

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class ModificarEntidadCapacitadora extends Vue {
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;


  interaccion_input_documento = false;
  name_pdf = "";
estadoPeticionRegister = false;
  docValido = false;
  estadoPeticion = false;
  rutadocumento = null;
  verModal = false;
  docResolucon = "";
  docVista = null;
  docUpReady = false ;
  docPdf = null ;


  Entidad_Capacitadora = {
    id_pic_entidad_capacitadora: "",
    entidad_capacitadora: "",
    nit: "",
    direccion: "",
    correo: "",
    numero_telefono: "",
    camara_comercio: null,
    url_pdf:"",
  };

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "El valor no puede ser menor a cero",
        email: () => "El correo no es válido",
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }

  mounted()
  {
  }
  eliminardoc ()
  {
    this.Entidad_Capacitadora.camara_comercio = null;
  }

  mirarModal()
  {
    this.verModal = true;
    this.vistapreviaDocumento();
  }
  vistapreviaDocumento()
  {
    if (this.Entidad_Capacitadora.camara_comercio != "") {
      this.docVista = URL.createObjectURL(this.Entidad_Capacitadora.camara_comercio);
    }
  }

  select_file(event)
  {
    this.Entidad_Capacitadora.camara_comercio = event.target.files[0].name.toString();
  }
  obtenerMinuta(event) {
    this.name_pdf = event.target.files[0].name;
    if (event.target.files[0].size <= 5000000) {
      //5mb tope
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.Entidad_Capacitadora.camara_comercio = event.target.files[0];
        this.Entidad_Capacitadora.url_pdf = event.target.files[0];
        this.docVista = null;
        Swal.fire(
          "Certificación Cargada!",
          "Archivo cargado exitosamente!",
          "success"
        );
        this.docValido = true;
        this.docUpReady = true;
        // this.docResolucon = null;
      } else {
        this.docValido = false;
        this.Entidad_Capacitadora.camara_comercio = "Sin Documento";
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.Entidad_Capacitadora.camara_comercio = "Sin Documento";
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  }
  registrarEntidadCapacitadora() {
    this.estadoPeticionRegister = true;
    this.estadoPeticion = true;
    this.docPdf = URL.createObjectURL(this.Entidad_Capacitadora.camara_comercio).toString();
    var data = new FormData();
    //Añadimos la docinscrip seleccionada
    data.append(
      "entidad_capacitadora",
      this.Entidad_Capacitadora.entidad_capacitadora
    );
    data.append("nit", this.Entidad_Capacitadora.nit);
    data.append("direccion", this.Entidad_Capacitadora.direccion);
    data.append("correo", this.Entidad_Capacitadora.correo);
    data.append("numero_telefono", this.Entidad_Capacitadora.numero_telefono);
    data.append("camara_comercio", this.Entidad_Capacitadora.camara_comercio);
    data.append("url_pdf", this.docPdf);

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    Axios()
      .post("almacenar_entidad_capacitadora", data, {
        headers: headers,
      })
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Entidad Capacitadora Guardada",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.$router.push({ name: "GestionarEntidadCapacitadora" });
        }else if (respuesta.data.message == "El valor del campo nit ya está en uso.")
        {
          Swal.fire("INFO!",
          "EL NIT esta en uso",
          "info");
        }else if (respuesta.data.message.numero_telefono =="El campo numero telefono debe ser un número.")
        {
          Swal.fire("INFO!",
          "EL Numero de telefono debe ser un número",
          "info");
        }
        else if (respuesta.data.status == "forbiden"){
          Swal.fire("INFO!",
          respuesta.data.message,
          "info");
        }
        else if (respuesta.data.message.nit =="El campo nit debe ser un número."){
          Swal.fire("INFO!",
          "El campo nit debe ser un número.",
          "info");
        }else{
          Swal.fire("ERROR!",
          "Alguno de los campos no cumple con las condiciones ",
          "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(()=>{
        this.estadoPeticionRegister = false;
      });
  }
  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        if (this.Entidad_Capacitadora.camara_comercio == null ) {
          Swal.fire(
            "INFO!",
            "Es obligatorio el documento de la camara de comercio",
            "info"
          );
        }else
        {
          this.registrarEntidadCapacitadora();
        }
      }
    });
  }
}
