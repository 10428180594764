
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionTablasCargando from "../../../components/animacionTablasCargando.vue";
// import registrarEstudio from "../HojaDeVida/Educacion/RegistrarEducacionPersona.vue";

@Component({
  components: {
    // registrarEstudio,
    AnimacionTablasCargando,
  },
})
export default class Login extends Vue {
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;

  docValido = false;
  estadoPeticion = false;
  estadoPeticionTabla = false;
  lista_capacitaciones = [];
  modalSubirCertificado = false;
  datosEducacion = {
    actualizado: "",
    certificado: "",
    creado: "",
    fecha_fin: "",
    fecha_inicio: "",
    id_educacion: "",
    institucion: "",
    persona_id: "",
    tipo_educacion: "",
    titulo_obtenido: "",
    pic_inscripcion_id: "",
  };

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "No pude ser negativo",
        // max: () => "La suma de los cupos no puede ser mayor a " + this.convocatoria.cupo_total,
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }

  mounted() {
    this.obtenerCapacitacionesEni();
  }

  obtenerCapacitacionesEni() {
    Axios()
      .get("listar_mis_capacitaciones/" + this.id_persona_logeada)
      .then((respuesta) => {
        this.lista_capacitaciones = respuesta.data;
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }

  //métodos registrar educación con certificado
  registrarEducacionPersona() {
    this.estadoPeticion = true;
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    //Creamos el formData
    var data = new FormData();
    //Añadimos la docinscrip seleccionada
    data.append("persona_id", this.id_persona_logeada);
    data.append("titulo_obtenido", this.datosEducacion.titulo_obtenido);
    data.append("institucion", this.datosEducacion.institucion);
    data.append("fecha_inicio", this.datosEducacion.fecha_inicio);
    data.append("fecha_fin", this.datosEducacion.fecha_fin);
    data.append("certificado", this.datosEducacion.certificado);
    data.append("tipo_educacion", this.datosEducacion.tipo_educacion);
    data.append("pic_inscripcion_id", this.datosEducacion.pic_inscripcion_id);

    Axios()
      .post("almacenar_educacion_persona", data, { headers: headers })
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Educación Guardada",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.modalSubirCertificado = false;
          this.obtenerCapacitacionesEni();
          // this.$router.push({ name: "GestionarCapacitacionENI" });
          // this.$router.back();
        } else {
          Swal.fire({
            title: "No se Guardo La Educación",
            icon: "error",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.registrarEducacionPersona();
      }
    });
  }

  obtenerDocumento(event) {
    if (event.target.files[0].size <= 5000000) {
      //5mb tope
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.datosEducacion.certificado = event.target.files[0];
        Swal.fire(
          "Certificado Cargado!",
          "Archivo cargado exitosamente!",
          "success"
        );
        this.docValido = true;
        // this.docResolucon = null;
      } else {
        this.docValido = false;
        this.datosEducacion.certificado = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.datosEducacion.certificado = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  }

  llenarDatosPredeterminados(id_pic_inscripcion) {
    // this.datosEducacion = {
    this.datosEducacion.fecha_fin = this.lista_capacitaciones[0].fecha_final;
    this.datosEducacion.fecha_inicio =
      this.lista_capacitaciones[0].fecha_inicio;
    this.datosEducacion.tipo_educacion = "1";
    this.datosEducacion.titulo_obtenido =
      this.lista_capacitaciones[0].convocatoria;
    this.datosEducacion.pic_inscripcion_id = id_pic_inscripcion;

    // };
  }
  //---------------------------------------------------------------------------
}
