
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../../components/animacionCargando.vue";
import AnimacionTablasCargando from "../../../../components/animacionTablasCargando.vue";

@Component({
  components: {
    AnimacionCargando,
    AnimacionTablasCargando,
  },
})
export default class sensibilizacion extends Vue {
  listaSensibilizacion = [];
  listaSensibilizacionFiltrado: any[] = [];
  paginate = ["listaSensibilizacionFiltrado"];
  filter = "";
  id_convenio = null;

  estadoPeticionTabla = false;

  sensibilizacion = {
    id_sensibilizacion_convenio: "",
    fecha_socilizacion: "",
    resultados: "",
    compromisos: "",
    prioridad_uno: "",
    prioridad_dos: "",
    url_acta_sensibilizacion: "",
    url_carta_intension: "",
    url_asistencia: "",
    cantidad_asistentes: "",
    convenio_id: "",
  };
  convenio = {
    convenio: "",
    id_convenio: "",
    regional_id: "",
    regional: "",
    id_centro_formacion: "",
    centro_formacion: "",
    fase_id: "",
    fase: "",
    sena_persona_id: "",
    numero_convenio: "",
    fecha_firma: "",
    fecha_inicio: "",
    fecha_final: "",
    sena_cargo: "",
    colegio_cargo: "",
    minuta: "",
  };

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        min: () => "Mínimo 8 caracteres",
      },
    };
    this.$validator.localize("es", dict);
  }

  mounted() {
    this.obtenerSensibilizacion();
    this.id_convenio = this.$route.params.id_convenio;
    this.obtenerConvenio(this.$route.params.id);
  }

  obtenerSensibilizacion() {
    Axios()
      .get("listar_sensibilizacion/"+this.$route.params.id_convenio)
      .then((respuesta) => {
        this.listaSensibilizacion = respuesta.data.results;
        this.listaSensibilizacionFiltrado = respuesta.data.results;
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }

  eliminarSensibilizacion(id, index) {
    Swal.fire({
      title: "Eliminar sensibilización ",
      text:
        this.listaSensibilizacionFiltrado[index].id_sensibilizacion_convenio +
        "?",
      icon: "warning",
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((respuesta) => {
      if (respuesta.isConfirmed) {
        Axios()
          .delete("eliminar_sensibilizacion/" + id)
          .then((respuesta) => {
            if (respuesta.data.status == "success") {
              Swal.fire({
                title: "Sensibilización Eliminada",
                icon: "success",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.listaSensibilizacionFiltrado.splice(index, 1);
            } else {
              Swal.fire({
                title: "No se pudo eliminar la sensibilización",
                text: respuesta.data.message,
                icon: "error",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#007bb8",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.$router.back();
            }
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }
  obtenerConvenio(id) {
    Axios()
      .get("ver_detalle_convenio/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          // this.convenio = respuesta.data.results;
        }
      });
  }
  sensibilizacionFiltrado() {
    let sensibilizacionFilter = [];
    // this.listaConvocatoriasFiltrado = []

    for (let sensibiliza of this.listaSensibilizacion) {
      let resultados = sensibiliza.resultados.toLowerCase();
      let compromisos = sensibiliza.compromisos.toLowerCase();
      let red_prioridad_dos = sensibiliza.red_prioridad_dos.toLowerCase();
      let red_prioridad_uno = sensibiliza.red_prioridad_uno.toLowerCase();

      if (
        resultados.indexOf(this.filter) >= 0 ||
        compromisos.indexOf(this.filter) >= 0 ||
        red_prioridad_dos.indexOf(this.filter) >= 0 ||
        red_prioridad_uno.indexOf(this.filter) >= 0
      ) {
        sensibilizacionFilter.push(sensibiliza);
      }
    }

    this.listaSensibilizacionFiltrado = sensibilizacionFilter;
    if (this.listaSensibilizacionFiltrado.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró la sensibilizacion, que coincida con la búsqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#238276",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filter = "";
      this.listaSensibilizacionFiltrado = this.listaSensibilizacion;
    }
  }

  cambiarFaseConvenio() {
    if (this.listaSensibilizacionFiltrado == null) {
      Swal.fire({
        title: "Informacion",
        text: "Para Sensibilizar debe tener almenos 1",
        icon: "warning",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#007bb8",
        customClass: {
          confirmButton: "btn btn-azul-sena",
        },
      });
    } else {
      Axios()
        .put("modificar_fase_convenio/" + this.id_convenio, {
          id_convenio: this.id_convenio,
          fase_id: 4,
          id_actualiza: JSON.parse(sessionStorage.getItem("usuario")),
        })
        .then(() => {
          Swal.fire({
            title: "Cambio de fase correcto!",
            text: "El Convenio pasó a fase colegios exitosamente!",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
            icon: "success",
          });
          this.$router.push("GestionarConvenio");
        })
        .catch(() => {
          Swal.fire({
            title: "ERROR",
            text: "No se puede cambiar de fase",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
            icon: "error",
          });
        });
    }
  }
}
