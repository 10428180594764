
import { Component, Vue } from "vue-property-decorator";
import Swal from "sweetalert2";
import Axios from "@/Axios";
import AnimacionTablasCargando from "../../../../components/animacionTablasCargando.vue";

@Component({
  components: { AnimacionTablasCargando },
})
export default class Index extends Vue {
  listarEntidadCapacitadora: any[] = [];
  estadoPeticionTabla = false;
  filtrarEntidad = "";
  filter = "";
  listarEntidad = [];

  entidadCapacitadora = {
    id_pic_entidad_capacitadora: "",
    entidad_capacitadora: "",
    nit: "",
    direccion: "",
    correo: "",
    numero_telefono: "",
    camara_comercio: "",
  };

  mounted() {
    this.obtenerEntidadCapacitadora();
  }
  obtenerEntidadCapacitadora() {
    Axios()
      .get("listar_entidad_capacitadora")
      .then((respuesta) => {
        this.listarEntidadCapacitadora = respuesta.data.results;
        this.listarEntidad = respuesta.data.results;
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }

  convocatoriaFiltrado() {
    let picFilter = [];
    // this.listaConvocatoriasFiltrado = []

    for (let entidad of this.listarEntidadCapacitadora) {
      let entidadC = entidad.entidad_capacitadora.toLowerCase();

      if (entidadC.indexOf(this.filter) >= 0) {
        picFilter.push(entidad);
      }
    }

    this.listarEntidad = picFilter;
    if (this.listarEntidad.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró la capacitación, que coincida con la búsqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#238276",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filter = "";
      this.listarEntidad = this.listarEntidadCapacitadora;
    }
  }

  eliminarEntidadCapacitadora(id, index) {
    Swal.fire({
      title: "Eliminar entidad capacitadora ",
      text: this.listarEntidadCapacitadora[index].entidad_capacitadora + "?",
      icon: "warning",
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((respuesta) => {
      if (respuesta.isConfirmed) {
        Axios()
          .delete(
            "eliminar_entidad_capacitadora/" +
              this.listarEntidadCapacitadora[index].id_pic_entidad_capacitadora
          )
          .then((respuesta) => {
            if (respuesta.data.status == "success") {
              Swal.fire({
                title: "Entidad capacitadora Eliminada",
                icon: "success",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#007bb8",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.listarEntidadCapacitadora.splice(index, 1);
            } else if (respuesta.data.status == "info"){
              Swal.fire({
                title: "Esta Entidad capacitadora es reservada",
                text: "no se puede eliminar esta entidad",
                icon: "info",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
            }
            else{
              Swal.fire({
                title: "No se pudo eliminar la Entidad capacitadora",
                text: respuesta.data.message,
                icon: "error",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
            }
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }
}
