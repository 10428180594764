var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.estadoPeticion)?_c('div',{staticClass:"mt-5 container-fluid"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body row"},[_c('AnimacionCargando')],1)])]):_vm._e(),(_vm.estadoPeticion)?_c('div',{staticClass:"mt-5 container-fluid"},[_c('div',{staticClass:"card"},[_vm._m(1),_c('div',{staticClass:"card-body row"},[_c('div',{staticClass:"col-12 col-md-8 mx-auto mt-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6"},[_vm._m(2),_c('div',{attrs:{"id":"contenido"}},[_c('p',{attrs:{"id":"texto"}},[_vm._v(_vm._s(_vm.persona.primer_nombre))])])]),_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6"},[_vm._m(3),_c('div',{attrs:{"id":"contenido"}},[_c('p',{attrs:{"id":"texto"}},[_vm._v(_vm._s(_vm.persona.segundo_nombre))])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6"},[_vm._m(4),_c('div',{attrs:{"id":"contenido"}},[_c('p',{attrs:{"id":"texto"}},[_vm._v(_vm._s(_vm.persona.primer_apellido))])])]),_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6"},[_vm._m(5),_c('div',{attrs:{"id":"contenido"}},[_c('p',{attrs:{"id":"texto"}},[_vm._v(_vm._s(_vm.persona.segundo_apellido))])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6"},[_vm._m(6),_c('div',{attrs:{"id":"contenido"}},[_c('p',{attrs:{"id":"texto"}},[_vm._v(_vm._s(_vm.persona.correo))])])]),_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6"},[_vm._m(7),_c('div',{attrs:{"id":"contenido"}},[_c('p',{attrs:{"id":"texto"}},[_vm._v(_vm._s(_vm.persona.identificacion))])])])])])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-4 col-sm-12 mt-1"},[_c('button',{staticClass:"btn btn-block bg-azul-sena text-white shadow-3 p-2",on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" Volver ")])])])])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"border-radius-lg bg-gradient-primary card-header p-0 position-relative mt-n4 mx-3 z-index-2 shadow-3 P-2"},[_c('div',{staticClass:"pt-4 pb-3"},[_c('h1',{staticClass:"text-white text-capitalize ps-3 ms-3 title-header"},[_vm._v(" USUARIO POSTULADO ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"border-radius-lg bg-gradient-primary card-header p-0 position-relative mt-n4 mx-3 z-index-2 shadow-3 P-2"},[_c('div',{staticClass:"pt-4 pb-3"},[_c('h1',{staticClass:"text-white text-capitalize ps-3 ms-3 title-header"},[_vm._v(" USUARIO POSTULADO ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v(" Primer Nombre:")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v(" Segundo Nombre:")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v(" Primer Apellido:")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v(" Segundo Apellido:")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v(" Correo:")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v(" Identificación:")])])
}]

export { render, staticRenderFns }