
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";


@Component({
  components: {
    AnimacionCargando,
  },
})
export default class Inscribir extends Vue {
  aprendicesInscritos: any [] = [];
  aprendicesInscritosFiltrado?:any[] = [];
  estadoDoc=false;
  nuevaCC = "";
  filtrado = "";
  nombre = "";
  correo = "";
  paginate = ["aprendicesInscritosFiltrado"];
  convocatoria = {
    centro_formacion_id: "",
    centro_formación: "",
    convocatoria: "",
    cupo_hombres_14_17: null,
    cupo_hombres_mayores: null,
    cupo_instructores: null,
    cupo_mujeres_14_17: null,
    cupo_mujeres_mayores: null,
    cupo_total: null,
    estimulo_mensual: "",
    fase_bienestar: "",
    fase_bienestar_id: "",
    fecha_final_convoca: "",
    fecha_inicio_convoca: "",
    id_convocatoria: "",
    ruta_documento: "",
    tipo_apoyo: "",
    tipo_apoyo_id: "",
    tipo_monitoria: "",
    tipo_monitoria_id: "",
  };
  modalInscribir = false;
  modalArchivo = false;
  listaCriterios?: [] = [];
  listaCriteriosApoyo?: any[] = [];
  idConvocatoria = null;
  docValido = false;
  docInscritos = null;
  modalEliminarAprendiz = false;
  estadoConsulta = false;
  estadoConsultaFicha = false;
  estadoEliminar = false;
cantidadInscritos = 0;
  nombreAprendizEliminar = "";
  cedulaAprendizEliminar = "";
  idpersonaAprendizEliminar = "";

  criteriosSeleccionados: [] = [];
  fichasAprendiz?: any[] = [];
  nombreObtenido?: any;
  correoObtenido?: any;
  fichaSeleccionada = null;
  btnVerFichas = false;

  constructor() {
    super();

    const dict = {
      messages: {
        required: () => "Este campo es requerido",
        min: () => "Mínimo 6 caracteres",
      },
    };
    this.$validator.localize("es", dict);
  }

  mounted() {
    this.obtenerConvocatoria(this.$route.params.id);
    this.idConvocatoria = this.$route.params.id;
    this.obtenerInscritosConvocatoria(this.$route.params.id);
    this.obtenerCriterios(this.$route.params.id);
  }

  //   updated() {
  //     if(this.modalInscribir ==  false){
  //   //      this.nombreObtenido=null;
  //   //         this.correoObtenido=null;
  //   //         this.fichaSeleccionada=null;
  //   //         this.listaCriterios=[];
  //   //         this.nuevaCC="";
  //         this.criteriosSeleccionados=[];

  //     }
  //  }

  ver() {
    setTimeout(() => {}, 1000);
  }

  async descargarFormato() {
    this.estadoDoc=true;
    await Axios()
      .get("convocatoria_csv/" + this.idConvocatoria, {
        responseType: "blob",
      })
      .then((respuesta) => {
        this.estadoDoc=false;
        
        var fileURL = window.URL.createObjectURL(
          new Blob([respuesta.data], { type: "text/csv" })
        );
        var fileLink = document.createElement("a");
        
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          this.idConvocatoria + "-InscripcionAprendices.csv"
        );
        document.body.appendChild(fileLink);

        fileLink.click();
        Swal.fire({
            icon: "success",
            text: "Archivo descargado con exito!",
            confirmButtonText: "Ok",
            confirmButtonColor: "#007bb8",
        })
      })
      .catch((error) => {
        this.estadoDoc=false;
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  obtenerFichasAprendiz(cedula) {
    this.estadoConsulta = true;
    this.modalInscribir = true;
    Axios()
      .get("buscar_fichas_aprendiz/" + cedula)
      .then((respuesta) => {
        this.estadoConsultaFicha = true;
        if (respuesta.data.status.toLowerCase() == "info") {
          this.btnVerFichas = false;
          this.estadoConsulta = false;

          Swal.fire({
            title: "ERROR!",
            icon: "error",
            text: respuesta.data.message,
            confirmButtonText: "Ok",
            confirmButtonColor: "#007bb8",
          });
        } else if (respuesta.data.status.toLowerCase() == "success") {
          this.fichasAprendiz = respuesta.data.results;
          let segundo_nombre = respuesta.data.results[0].segundo_nombre;
          let primer_apellido = respuesta.data.results[0].primer_apellido;
          let correo = respuesta.data.results[0].correo;
          if (segundo_nombre == null) {
            this.nombreObtenido =
              respuesta.data.results[0].primer_nombre + " " + primer_apellido;
          } else {
            this.nombreObtenido =
              respuesta.data.results[0].primer_nombre +
              " " +
              segundo_nombre +
              " " +
              primer_apellido;
          }
          this.correoObtenido = respuesta.data.results[0].correo;
        }
        this.estadoConsulta = false;

        // this.btnVerFichas = false
      })

      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
        this.estadoConsulta = false;
        this.estadoConsultaFicha = false;
      })
      .finally(() => {
        this.estadoConsultaFicha = false;
      });
  }

  limpiarFichasAprendiz() {
    // if(this.nuevaCC.length < 7){
    this.btnVerFichas = false;
    this.fichasAprendiz = [];
    this.fichaSeleccionada = null;
    this.nombreObtenido = null;
    this.correoObtenido = null;
    // this.obtenerFichasAprendiz(this.nuevaCC)
    // }
  }

  obtenerConvocatoria(id) {
    Axios()
      .get("listar_convocatoria/" + id)
      .then((respuesta) => {
        this.convocatoria = respuesta.data.results;
        this.obtenerCriteriosApoyo(this.convocatoria.tipo_apoyo_id);
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  async obtenerCriteriosApoyo(id_apoyo) {
    await Axios()
      .get("listar_criterios/" + id_apoyo)
      .then((respuesta) => {
        this.listaCriteriosApoyo = respuesta.data.results;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  async obtenerCriterios(id) {
    await Axios()
      .get("crear_inscripcion_convocatoria/" + id)
      .then((respuesta) => {
        return respuesta.data.results;
      })
      .then((res) => {
        this.listaCriterios = res;
        return true;
      })
      .then(() => {
        let checks = document.getElementsByTagName("input");
        for (let i = 0; i < checks.length; i++) {
          const element = checks[i];
          if (element.type == "checkbox") {
            element.checked = false;
          }
        }
      });
  }

  async obtenerInscritosConvocatoria(id) {
    await Axios()
      .get("listar_inscripciones_convocatoria/" + id)
      .then((respuesta) => {
        return respuesta.data.results;
      })
      .then((res) => {
        setTimeout(() => {
          if (res != null) {
            this.aprendicesInscritos = res;
            this.aprendicesInscritosFiltrado=res;
          }
        }, 100);
      });
  }

FiltrarInscritos(){
  let Filter =[];
  for (let inscrito of this.aprendicesInscritos) {
      let identificacion = inscrito.identificacion.toLowerCase();
      let primer_nombre = inscrito.primer_nombre.toLowerCase();
      let primer_apellido = inscrito.primer_apellido.toLowerCase();
      let segundo_nombre="";
      let segundo_apellido="";
      if(inscrito.segundo_nombre != null){
        segundo_nombre= inscrito.segundo_nombre.toLowerCase();
      }
      if(inscrito.segundo_apellido !=null){
        segundo_apellido = inscrito.segundo_apellido.toLowerCase();
      }
      if(
        identificacion.indexOf(this.filtrado.toLowerCase()) >= 0 ||
        primer_apellido.indexOf(this.filtrado.toLowerCase()) >= 0 ||
        segundo_nombre.indexOf(this.filtrado.toLowerCase()) >= 0||
        primer_nombre.indexOf(this.filtrado.toLowerCase()) >= 0 ||
        segundo_apellido.indexOf(this.filtrado.toLowerCase()) >= 0
      ){
        Filter.push(inscrito);
      }
  }
  this.aprendicesInscritosFiltrado= Filter;
  if(this.aprendicesInscritosFiltrado.length ==0){
     Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró la persona que coincida con la búsqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#007bb8",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filtrado ="";
      this.aprendicesInscritosFiltrado= this.aprendicesInscritos;
  }
}


  inscribirAprendizConvocatoria(nuevaCC, idConvocatoria) {
    this.estadoConsulta = true;
    Axios()
      .post("almacenar_inscripcion_convocatoria", {
        id_convocatoria: idConvocatoria,
        identificacion: nuevaCC,
        id_criterio_priorizacion: this.criteriosSeleccionados,
        id_ficha_caracterizacion: this.fichaSeleccionada,
      })
      .then((respuesta) => {
        return respuesta.data;
      })
      .then((res) => {
        if (res.status == "success") {
          this.estadoConsulta = false;
          Swal.fire(
            "Aprendiz Inscrito!".toString().toUpperCase(),
            "Se inscribió exitosamente el aprendiz",
            "success"
          );

          this.listaCriterios = [];
          this.listaCriteriosApoyo = [];
          this.criteriosSeleccionados = [];
          this.obtenerCriteriosApoyo(this.convocatoria.tipo_apoyo_id);
          this.obtenerInscritosConvocatoria(this.$route.params.id);
          this.modalInscribir = false;
          this.nombreObtenido = null;
          this.correoObtenido = null;
          this.fichaSeleccionada = null;
          this.listaCriterios = [];
          this.nuevaCC = "";
          this.criteriosSeleccionados = [];

          setTimeout(() => {
            this.obtenerCriterios(this.$route.params.id);
          }, 3000);
        } else {
          this.estadoConsulta = false;
          this.modalInscribir = false;
          this.nombreObtenido = null;
          this.correoObtenido = null;
          this.fichaSeleccionada = null;
          this.listaCriterios = [];
          this.nuevaCC = "";
          this.criteriosSeleccionados = [];
          Swal.fire(
            res.status.toString().toUpperCase(),
            res.message,
            res.status
          );
        }
      })
      .catch((err) => {
        this.estadoConsulta = false;
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
        this.estadoConsulta = false;
      });
  }

  mostrarModalEliminar(persona) {
    this.nombreAprendizEliminar =
      persona.primer_nombre + " " + persona.primer_apellido;
    this.cedulaAprendizEliminar = persona.identificacion;
    this.idpersonaAprendizEliminar = persona.id_persona;
    this.modalEliminarAprendiz = true;
  }
  eliminarInscrito(persona){
    
    Swal.fire({
      title:"Estas seguro de eliminar el Inscrito?",
      text:"Se elimará el Aprendiz Seleccionado",
      icon:"question",
      showCancelButton:true,
      confirmButtonText:"Eliminar",
      confirmButtonColor:"red", //revisar
      cancelButtonText:"Cancelar",
      cancelButtonColor:"#2d2d2d"
    })
    .then((respuesta)=>{
      if(respuesta.isConfirmed){
       Axios()
      .delete(
        "eliminar_inscripcion_convocatoria/" +
          this.$route.params.id +
          "/" +
          persona.id_persona
      )
      .then((respuesta) => {
        Swal.fire({
        title:"Inscrito Eliminado",
        text:"El inscrito se ha eliminado exitosamente!",
        icon:"success",
        confirmButtonText:"Aceptar",
        confirmButtonColor:"#007bb8",
        
        })
        this.aprendicesInscritos = [];
        this.modalEliminarAprendiz = false;
        this.obtenerInscritosConvocatoria(this.$route.params.id);
      })
      .then(() => {
        this.obtenerInscritosConvocatoria(this.$route.params.id);
      }) ;
      }
    })
  }

  // cambiarEstadoConvocatoria() {
  //   if (this.aprendicesInscritos.length == 0) {
  //     Swal.fire({
  //       title: "Informacion",
  //       text: "Para finalizar las inscripciones debe estar inscrito almenos un aprendiz",
  //       icon: "warning",
  //       confirmButtonText: "Aceptar",
  //       customClass: {
  //         confirmButton: "btn btn-azul-sena",
  //       },
  //     });
  //   } else {
  //     Axios()
  //       .put("actualizar_fase_convocatoria/" + this.idConvocatoria, {
  //         id_convocatoria: this.idConvocatoria,
  //         fase_bienestar_id: 3,
  //         id_actualiza: JSON.parse(sessionStorage.getItem("usuario"))
  //           .id_usuario,
  //       })
  //       .then((respuesta) => {
  //         if (respuesta.data.status == "success") {
  //           Swal.fire(
  //             "CAMBIO DE FASE CORRECTO",
  //             "Se ha cambiado la fase de la convocatoria exitosamente!",
  //             "success"
  //           );
  //           this.$router.push("GestionarConvocatoria");
  //         }
  //         return respuesta.data;
  //       });
  //   }
  // }
  cambiarEstadoConvocatoria(convocatoria){
    this.obtenerInscritos(convocatoria.id_convocatoria);
   Swal.fire({
      title: "Quieres cambiar el estado de la convocatoria?",
      text: "Tu fase actual es: " + " " + convocatoria.fase_bienestar,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
   }).then((respuesta) => {
      if (respuesta.isConfirmed) {
      if (this.cantidadInscritos < convocatoria.cupo_total){
          Swal.fire({
              title: "Error ",
              text: "No se pudo hacer el cambio de fase, porque no se cumple el cupo de inscritos",
              icon: "error",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
      }else{
           this.cambiar_fase(3, convocatoria.id_convocatoria);
      }
      }
   })
  }
  obtenerInscritos(id_convocatoria) {
    Axios()
      .get("buscar_numero_inscritos/" + id_convocatoria)
      .then((respuesta) => {
        this.cantidadInscritos = respuesta.data.results;
      });
  }
cambiar_fase(fase_id, id_convovatoria) {
    Axios()
      .put("actualizar_fase_convocatoria/" + id_convovatoria, {
        id_convocatoria: id_convovatoria,
        fase_bienestar_id: fase_id,
        id_actualiza: JSON.parse(sessionStorage.getItem("usuario")).id_usuario,
      })
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Cambio de fase correcto!",
            text: "Se ha cambiado la fase de la convocatoria exitosamente!",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.$router.push("GestionarConvocatoria");
        }
        return respuesta.data;
      });
  }
  obtenerDocumento(event) {
    if (event.target.files[0].size <= 5000000) {
      //5mb tope
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".csv") {
        this.docInscritos = event.target.files[0];
        Swal.fire(
          "Listado de aprendices cargado!",
          "Archivo cargado exitosamente!",
          "success"
        );
        this.docValido = true;
      } else {
        this.docValido = false;
        this.docInscritos = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un CVS!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.docInscritos = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  }

  subirDocumento() {
    this.estadoDoc=true;
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    //Creamos el formData
    var data = new FormData();
    //Añadimos la docinscrip seleccionada
    data.append("inscripciones", this.docInscritos);
    //Enviamos la petición
    Axios()
      .post("cargar_inscripciones/" + this.$route.params.id, data, {
        headers: headers,
      })
      .then((respuesta) => {
        this.docInscritos=null;
        this.estadoDoc=false;
        if ((respuesta.data.status = "success")) {
          this.modalArchivo = false;
          this.obtenerInscritosConvocatoria(this.$route.params.id);
          Swal.fire({
            title: "Se subió la resolución!",
            text: "Archivo cargado exitosamente!",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.docInscritos=null;
          this.obtenerInscritosConvocatoria(this.$route.params.id);


        } 
      }).catch((error)=>{
        this.estadoDoc=false;
          Swal.fire({
            title: "Error de servidor!",
            text: "Revise bien el archivo",
            icon: "error",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          }
           
          );
          this.docInscritos=null;
      })
  }

  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.inscribirAprendizConvocatoria(this.nuevaCC, this.idConvocatoria);
        this.nuevaCC = "";

        /* se quita la validacion por requeremiento de Johanna */

        // el formulario se validó y tiene los datos que corresponden por campo
        // if (this.criteriosSeleccionados.length <= 0) {
        //   Swal.fire({
        //     title: "Selección de criterios".toString().toUpperCase(),
        //     text: "Debe seleccionar al menos un criterio!",
        //     icon: "error",
        //     confirmButtonText: "Aceptar",
        //     confirmButtonColor: "#007bb8",
        //   });
        // } else {
        //   this.inscribirAprendizConvocatoria(this.nuevaCC, this.idConvocatoria);
        //   this.nuevaCC = "";
        // }
      }
    });
  }
}
