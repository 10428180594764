
import Component from "vue-class-component";
import { Prop, Vue } from "vue-property-decorator";
import { Tabla } from "./VerDetalleEvaluacionCapacitacion.vue";

@Component
export default class Formulario1 extends Vue {
  ver: any = true;
  crear: any = true;
  @Prop() contenidoCurso: Tabla;
  preguntas: Tabla = new Tabla();
  juntificacion = false;
  posPregunta = 0;
  respuesta = "";

  mounted() {
    if (
      this.$route.params.ver != undefined &&
      this.$route.params.crear != undefined
    ) {
      this.ver = this.$route.params.ver;
      this.crear = this.$route.params.crear;
    }
  }
  mostrar(cal, objeto) {
    objeto.calificacion = cal;
  }
  guardarJustificacion() {
    this.preguntas.pic_pregunta[
      this.posPregunta
    ].justificacion_respuesta = this.respuesta.trim();
    this.respuesta = "";
    this.juntificacion = false;
    this.guardarRespuestas();
  }
  guardarRespuestas() {
    this.$emit("respFormulario2", this.preguntas);
  }
}
