
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import AnimacionCargando from "../../../components/animacionCargando.vue";
import router from "@/router";
import Multiselect from "vue-multiselect";

@Component({
  components: {
    AnimacionCargando,
    Multiselect,
  },
})
export default class VerDetalleEvaluacionCpacitacion extends Vue {

  fecha_Inicio_Capacitacion  = true;
  fecha_inicio_convocatoria  = false;
  fecha_fin_convocatoria = true;
  fecha_Fin_Capacitación = true;
  estadoCargando = false;
  ver: any = false;
  mostar_aprendizaje: any = false;
  mostar_oferta: any = false;
  crear: any = true;
  errorRequisito: any = false;
  docResolucon = null;
  docValido = false;
  estadoPeticion = false;
  modalBuscarInstructor = false;
  infoInstructorResponsable = "";
  estadobusquedainstructor = false;

  tipo_inter_nacio = "";
  otro = false;

  estadoPeticionRegistrar = false;
  convocatoria = {
    convocatoria: "",
    fecha_inicio: "",
    fecha_final: "",
    lugar: "",
    perfiles_requisitos: "",
    para_funcionarios: "",
    pertenece_a_red: "SI",
    plan_institucional_id: "",
    id_pic: "",
    id_usuario_autenticado: JSON.parse(sessionStorage.getItem("usuario"))
      .id_usuario, //esta quemado, verificar
    id_municipio: "",
    financiacion: "",
    id_pic_tipo: "",
    tipo_financiacion: "",
    nombre: "",
    dirigido_a_id: "",
    linea_tecnologica: "",
    linea_tecnologica_id: "",
    entidad_capacitadora_id: "",
    entidad_capacitadora: "",
    resultado_aprendizaje: "",
    competencia_programa_oferta: "",
    fecha_fin_conv: "",
    fecha_inicio_conv: "",
    horas_capacitacion: "",
    identificacion_responsable: "",
    modalidad_id: "",
    tipos_funcionarios: [],
  };

  fechas_pic = [];

  lista_capacitaciones: any[] = [];
  modalidades: any[] = [];
  listarLineaTecnologica: any[] = [];
  listarEntidadCapacitadora: any[] = [];

  lineaTecnologica = {
    id_pic_linea_tecnologica: "",
    linea_tecnologica: "",
  };
  entidadCapacitadora = {
    id_pic_entidad_capacitadora: "",
    entidad_capacitadora: "",
  };

  requisito = "";
  aprendizaje = "";
  oferta = "";

  lista_perfiles_requisitos: any[] = ["documento de identidad","prueba de conocimiento"];
  lista_aprendizaje: any[] = [];
  lista_oferta: any[] = [];
  // anexos
  anexos_convocatoria: any[] = [];
  id_anexo = "";
  modalAnexo = false;
  rutadocumento = "";
  //departamentos
  lista_departamentos: any[] = [];
  id_pais = 57;
  lista_paises = null;
  //municipios
  lista_municipios = null;
  departamento_actual = null;

  lista_tipos_capacitaciones: any[] = [];
  picTipos: any[] = [];

  paraFuncionarios: any[] = [];
  paraFuncionariosEscogidos: any[] = [];

  fecha = "";

  async mounted() {
    await this.obtenerCapacitaciones();
    await this.ontenerPlanInstitucional();
    await this.obtenerDepartamento(57);
    await this.obtenerLineaTecnologica();
    await this.obtenerEntidadCapacitadora();
    await this.listarTiposPics();
    await this.listarCapacitacionesTipoAnio();
    await this.obtenerFuncionarios();
    this.estadoCargando = true;
  }

  //varios dirigido a
  // multiselect funcionarios
  obtenerFuncionarios() {
    Axios()
      .get("listar_pic_dirigida")
      .then((respuesta) => {
        this.paraFuncionarios = respuesta.data.results;
      });
  }

  fechasHabilitar ()
  {
    if (this.convocatoria.fecha_inicio_conv != '')
    {
    this.fecha_fin_convocatoria = false ;
    }else{
    this.fecha_fin_convocatoria = true;
    }
    if (this.convocatoria.fecha_fin_conv != "")
    {
    this.fecha_Inicio_Capacitacion = false ;
    }else{
    this.fecha_Inicio_Capacitacion = true;
    }
  //primeras fases funcionan perfectamente
    if (this.convocatoria.fecha_inicio != "")
    {
    this.fecha_Fin_Capacitación = false ;
    }else{
    this.fecha_Fin_Capacitación = true ;
    }
  }

  addTag(newTag) {
    const tag = {
      para_funcionarios: newTag,
      nombre: "",
      id_dirigido_a:
        newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
    };
    this.paraFuncionarios.push(tag);
    this.paraFuncionariosEscogidos.push(tag);
  }
  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        if (
          this.convocatoria.entidad_capacitadora_id == "1" &&
          this.infoInstructorResponsable == ""
        ) {
          Swal.fire({
            title: "Error no se encontro la identificación",
            icon: "error",
            confirmButtonColor: "#007bb8",
            confirmButtonText: "Aceptar",
            customClass: {
              confirmButton: "btn btn-azul-sena",
            },
          });
        } else if (
          this.infoInstructorResponsable != "" &&
          this.convocatoria.entidad_capacitadora_id == "1"
        ) {
          this.registrarConvocatoria();
        } else {
          this.registrarConvocatoria();
        }
      }
    });
  }
  registrarConvocatoria() {
    this.estadoPeticionRegistrar = true;

    for (const perfil of this.lista_perfiles_requisitos) {
      this.convocatoria.perfiles_requisitos =
        this.convocatoria.perfiles_requisitos + perfil + ",";
    }
    for (const aprend of this.lista_aprendizaje) {
      this.convocatoria.resultado_aprendizaje =
        this.convocatoria.resultado_aprendizaje + aprend + ",";
    }
    for (const ofert of this.lista_oferta) {
      this.convocatoria.competencia_programa_oferta =
        this.convocatoria.competencia_programa_oferta + ofert + ",";
    }

    Axios()
      .post("/almacenar_convocatoria_pic", this.convocatoria)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          // this.estadoPeticionRegistrar = false;
          // this.registrarDirigidoA(506);
          Swal.fire({
            title: "Convocatoria Creada",
            text: "Se guardó la convocatoria exitosamente",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
            customClass: {
              confirmButton: "btn btn-azul-sena",
            },
          });

          this.$router.push({ name: "GestionarConvocatoriaENI" });
        } else {
          Swal.fire({
            title: "Error convocatoria no creada",
            text: respuesta.data.message,
            icon: "error",
            confirmButtonText: "Aceptar",
            customClass: {
              confirmButton: "btn btn-azul-sena",
            },
          });
        }
      })
      .catch((err) => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
        Swal.fire({
          title: "Error de Servidor",
          icon: "error",
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "btn btn-azul-sena",
          },
        });
        this.$router.push({ name: "GestionarConvocatoriaENI" });
      })
      .finally(() => {
        this.estadoPeticionRegistrar = false;
      });
  }

  obtenerOtro() {
    if (this.convocatoria.linea_tecnologica == "Otro") {
      this.otro = true;
    } else {
      this.otro = false;
    }
  }

  obtenerCapacitaciones() {
    Axios()
      .get("/crear_convocatoria_pic")
      .then((respuesta) => {
        this.lista_capacitaciones = respuesta.data.results.pics;
        this.modalidades = respuesta.data.results.modalidades;
      });
  }
  listarCapacitacionesTipoAnio() {
    Axios()
      .post("listar_capacitaciones_anio_tipo", {
        pic_tipo_id: this.convocatoria.id_pic_tipo,
        plan_institucional_id: this.convocatoria.plan_institucional_id,
      })
      .then((respuesta) => {
        this.lista_tipos_capacitaciones = respuesta.data.results;
      });
  }
  async listarTiposPics() {
    await Axios()
      .get("/crear_pic")
      .then((respuesta) => {
        this.picTipos = respuesta.data.results.picTipos;
      });
  }
  //obtener municipios inicio
  obtenerMunicipio(id) {
    Axios()
      .get("listar_municipios/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_municipios = respuesta.data.results.municipios;
        } else {
          Swal.fire({
            title: respuesta.data.message,
          });
        }
      });
  }
  //obtener municipios fin

  //obtener departamentos inicio
  obtenerDepartamento(id_pais) {
    Axios()
      .get("listar_departamentos/" + id_pais)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_departamentos = respuesta.data.results;
        } else {
          Swal.fire({
            title: respuesta.data.message,
          });
        }
      });
  }
  //obtener departamentos fin
  updated() {
    // if (this.convocatoria.id_pic_tipo == "1") {
    //   this.convocatoria.identificacion_responsable = "";
    //   this.infoInstructorResponsable = "";
    // }
  }
  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "Valor no valido en este campo",
        // max: () => "La suma de los cupos no puede ser mayor a " + this.convocatoria.cupo_total,
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }
  ontenerPlanInstitucional() {
    Axios()
      .get("listar_plan_institucional")
      .then((respuesta) => {
        this.fechas_pic = respuesta.data.results;
      });
  }

  agregarEliminarRequisito(tipo, pos?) {
    if (tipo == "agregar") {
      this.lista_perfiles_requisitos.push(this.requisito);
      this.requisito = "";
    } else {
      this.lista_perfiles_requisitos.splice(pos, 1);
    }
  }

  agregarEliminarAprendizaje(tipo, pos?) {
    if (tipo == "agregar") {
      this.lista_aprendizaje.push(this.aprendizaje);
      this.aprendizaje = "";
    } else {
      this.lista_aprendizaje.splice(pos, 1);
    }
  }
  agregarEliminarOferta(tipo, pos?) {
    if (tipo == "agregar") {
      this.lista_oferta.push(this.oferta);
      this.oferta = "";
    } else {
      this.lista_oferta.splice(pos, 1);
    }
  }

  validarInstructorBuscado() {
    if (this.estadobusquedainstructor) {
      this.buscarInstructor();
      this.convocatoria.identificacion_responsable = "";
      this.estadobusquedainstructor = false;
    }
  }

  buscarInstructor() {
    if (this.convocatoria.identificacion_responsable != "") {
      Axios()
        .get("buscar_usuario/" + this.convocatoria.identificacion_responsable)
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            this.infoInstructorResponsable =
              respuesta.data.results.identificacion +
              " - " +
              respuesta.data.results.nombres +
              " " +
              respuesta.data.results.apellidos;
            this.modalBuscarInstructor = false;
            this.estadobusquedainstructor = true;
            Swal.fire({
              title: "Instructor encontrado",
              text:
                "Instructor resposable escogido: " +
                respuesta.data.results.nombres +
                " " +
                respuesta.data.results.apellidos,
              icon: respuesta.data.status,
              confirmButtonText: "Aceptar",
              customClass: {
                confirmButton: "btn btn-azul-sena",
              },
            });
          } else {
            this.convocatoria.identificacion_responsable = "";
            this.infoInstructorResponsable = "";
            Swal.fire({
              title: "Instructor no encontrado",
              text: respuesta.data.message,
              icon: "error",
              confirmButtonText: "Aceptar",
              customClass: {
                confirmButton: "btn btn-azul-sena",
              },
            });
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        });
    } else {
      //campo vacio
      Swal.fire({
        title: "Campo vacío",
        text: "Por favor ingresa una identificación",
        icon: "error",
        confirmButtonText: "Aceptar",
        customClass: {
          confirmButton: "btn btn-azul-sena",
        },
      });
    }
  }
  obtenerLineaTecnologica() {
    Axios()
      .get("/listar_lineas_tecnologica")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listarLineaTecnologica = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  registrarLineaTecnologica() {
    Axios()
      .post("almacenar_linea_tecnologica", this.lineaTecnologica)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Linea Tecnologica Guardada",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          //   this.estadoPeticion = false;
          this.$router.push({ name: "GestionarLineasTecnologicas" });
        } else {
          Swal.fire("ERROR!", "No Se Modifico La Linea Tecnologica", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  obtenerEntidadCapacitadora() {
    Axios()
      .get("listar_entidad_capacitadora")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listarEntidadCapacitadora = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  registrarEntidadCapacitadora() {
    Axios()
      .post("almacenar_entidad_capacitadora", this.entidadCapacitadora)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Entidad Capacitadora Guardada",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.$router.push({ name: "GestionarEntidadCapacitadora" });
        } else {
          Swal.fire("ERROR!", "No Se Modifico La Linea Tecnologica", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
}
