
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import AnimacionCargando from "../../../components/animacionCargando.vue";

@Component({
  components: {
    Multiselect,
 AnimacionCargando 

  },
})
export default class Idioma extends Vue {
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;
   
  estadoPeticionModificar = false;
  listaColegios: any[] = [];
  lista_regionales: any[] = [];
  listaCentroFormacion: any[] = [];
  listaConveniosColegios: any[] = [];

  docValido = false;
  estadoPeticion = false;
  infoSenaPersona = "";
  modalBuscarColegio = false;
  lista_municipios = null;
  departamento_actual = null;
  lista_departamentos: any[] = [];

  // Sena persona datos
  modalSenaPersona = false;
  // identificacionSenaPersona = "";
  buscarSenaPersona;
  colegio: any[] = [];
  colegioEscogido: any[] = [];
  convenio = {
    id_convenio: "",
    convenio: "",
    regional_id: "",
    regional: "",
    centro_formacion_id: "",
    sena_persona_id: "",
    numero_convenio: "",
    fecha_firma: "",
    fecha_inicio: "",
    fecha_final: "",
    sena_cargo: "",
    colegio_cargo: "",
    minuta: null,
    municipio_id: "",
    am_convenio_colegio: [],
    // usuarioPersona: "",
  };
  cedulabuscada = "";

  persona = {
    primer_nombre: "",
    segundo_nombre: "",
    primer_apellido: "",
    segundo_apellido: "",
    identificacion: "",
  };

  colegioConvenio = {
    id_colegio_convenio: null,
    convenio_id: "",
    colegio_id: "",
  };

  centroFormacion = {
    id_centro_formacion: "",
    municipio_id: "",
    regional_id: "",
    centro_formacion: "",
    direccion_centro_formacion: "",
    telefono_centro_formacion: "",
  };
  mounted() {
    this.convenio.id_convenio = this.$route.params.id;
    this.obtenerDepartamento();
    this.obtenerRegionalConvenio();
    this.obtenerCovenio(this.$route.params.id);
    this.llenarcolegiosEscogidos();
  }

  //-----------------------------------------------------------
  obtenerCovenio(id) {
    Axios()
      .get("ver_detalle_convenio/" + id)
      .then((respuesta) => {
        this.convenio = respuesta.data.results;

        this.colegioEscogido = respuesta.data.results.colegios;
        this.persona = respuesta.data.results.usuarioPersona;
        // this.convenio.sena_persona_id = this.infoSenaPersona;
        // MACHETAZO DE LO LINDO ;)
        this.convenio.sena_persona_id = this.persona.identificacion;
        this.infoSenaPersona =
          this.persona.primer_nombre +
          " " +
          this.persona.segundo_nombre +
          " " +
          this.persona.primer_apellido +
          " " +
          this.persona.segundo_apellido +
          " - " +
          this.persona.identificacion;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!obtener",
          "error"
        );
      });
  }

  //-----------------------------------------------------------
  obtenerMinuta(event) {
    if (event.target.files[0].size <= 5000000) {
      //5mb tope
     
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.convenio.minuta = event.target.files[0];
        Swal.fire(
          "Certificación Cargada!",
          "Archivo cargado exitosamente!",
          "success"
        );
        this.docValido = true;
        // this.docResolucon = null;
      } else {
        this.docValido = false;
        this.convenio.minuta = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.convenio.minuta = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  }
  //--------------------------------------------------------------------
  modificarConvenio() {
    this.estadoPeticion = true;
    this.estadoPeticionModificar = true;
    var data = new FormData();

    //Añadimos la docinscrip seleccionada
    data.append("convenio", this.convenio.convenio);
    data.append("regional_id", this.convenio.regional_id);
    data.append("centro_formacion_id", this.convenio.centro_formacion_id);
    data.append("sena_persona_id", this.convenio.sena_persona_id);
    data.append("numero_convenio", this.convenio.numero_convenio);
    data.append("fecha_firma", this.convenio.fecha_firma);
    data.append("fecha_inicio", this.convenio.fecha_inicio);
    data.append("fecha_final", this.convenio.fecha_final);
    data.append("colegio_cargo", this.convenio.colegio_cargo);
    data.append("sena_cargo", this.convenio.sena_cargo);
    data.append("minuta", this.convenio.minuta);

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    Axios()
      .post("modificar_convenio/" + this.convenio.id_convenio, data, {
        headers: headers,
      })
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire(
            "Se subió el convenio!",
            "Convenio Modificada",
            respuesta.data.status
          );
          this.estadoPeticion = false;
          // this.eliminarConvenioColegios();
          // this.modificarColegios(this.convenio.id_convenio);
          // this.eliminarColegiosEscogidos();
          this.$router.push({ name: "GestionarConvenio" });
        } else {
          Swal.fire("ERROR!", "No se Modifico el convenio", "error");
        }
        // this.estadoPeticion = false;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(()=>{
        this.estadoPeticionModificar = false;
      });
  }
  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.modificarConvenio();
      }
    });
  }

  obtenerMunicipio(id) {
    Axios()
      .get("listar_municipios/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_municipios = respuesta.data.results.municipios;
        } else {
          Swal.fire({
            title: respuesta.data.message,
          });
        }
      });
  }

  obtenerDepartamento() {
    Axios()
      .get("listar_departamentos/57")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_departamentos = respuesta.data.results;
        } else {
          Swal.fire({
            title: respuesta.data.message,
          });
        }
      });
  }

  obtenerColegios() {
    Axios()
      .get("listar_colegios_municipios/" + this.convenio.municipio_id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listaColegios = respuesta.data.results;
        } else {
          Swal.fire({
            title: respuesta.data.message,
          });
        }
      });
  }

  addTag(newTag) {
    const tag = {
      colegio: newTag,
      municipio_id: "",
      municipio: "",
      id_colegio: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
    };
    this.listaColegios.push(tag);
    this.colegioEscogido.push(tag);
  }
  llenarcolegiosEscogidos() {
    for (let list of this.convenio.am_convenio_colegio) {

      this.colegioEscogido.push(list.colegio);
    }
  }

  modificarColegios(id_convenio) {
    for (let lista of this.colegioEscogido) {
      Axios()
        .post("almacenar_convenio_colegio", {
          colegio_id: lista.id_colegio,
          convenio_id: id_convenio,
        })
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
          } else {
          }
        });
    }
  }
  eliminarConvenioColegios() {
    Axios()
      .delete(
        "eliminar_convenio_colegio_escogidas/" + this.convenio.id_convenio
      )
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
        } else {
        }
      });
  }

  obtenerRegionalConvenio() {
    Axios()
      .get("listar_regional_convenio")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_regionales = respuesta.data.results;
        }
      });
  }
  buscarUsuario() {
    Axios()
      .get("buscar_usuario_persona/" + this.cedulabuscada)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.buscarSenaPersona = respuesta.data.results;
          this.convenio.sena_persona_id = this.buscarSenaPersona.id_persona;
          this.infoSenaPersona =
            this.buscarSenaPersona.primer_nombre +
            " " +
            this.buscarSenaPersona.segundo_nombre +
            " " +
            this.buscarSenaPersona.primer_apellido +
            " " +
            this.buscarSenaPersona.segundo_apellido +
            " - " +
            this.buscarSenaPersona.identificacion;
          this.modalSenaPersona = false;
          Swal.fire({
            title: "Sena Persona encontrada",
            text:
              this.buscarSenaPersona.primer_nombre +
              " " +
              this.buscarSenaPersona.segundo_nombre +
              " " +
              this.buscarSenaPersona.primer_apellido +
              " " +
              this.buscarSenaPersona.segundo_apellido,
            icon: respuesta.data.status,
            confirmButtonText: "Aceptar",
            customClass: {
              confirmButton: "btn btn-azul-sena",
            },
          });
        } else {
          Swal.fire({
            title: "Sena Persona no existe",
            text: "Ingrese una identificación correcta",
            icon: "error",
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  obtenerCentroFormacion() {
    Axios()
      .get("listar_centro_formacion/" + this.convenio.municipio_id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listaCentroFormacion = respuesta.data.results;
        } else {
          Swal.fire({
            title: respuesta.data.message,
          });
        }
      });
  }
  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "El valor no puede ser menor a cero",
        email: () => "El correo no es válido",
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }
}
