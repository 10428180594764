
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import router from "@/router";
import AnimacionCargando from "../../../components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class VerDetalleEvaluacionCpacitacion extends Vue {
  errorRequisito: any = false;
  // docResolucon = null;
  docValido = false;
  estadoPeticion = false;
  estadoPeticionSubirDocumento = false;
  estadoPeticionRegistrar = false;

  // variables para capacitacion nacional e internacional
  tipo_inter_nacio = "";
  convocatoria = {
    convocatoria: "",
    fecha_inicio: "",
    fecha_final: "",
    lugar: "",
    perfiles_requisitos: "",
    financiacion: "",
    id_pic_convocatoria: "",
    para_funcionarios: "",
    pertenece_a_red: "SI", //dato quemado
    pic_id: "",
    plan_institucional_id: "",
    id_pic: "",
    id_pic_fase: null,
    id_usuario_autenticado: JSON.parse(sessionStorage.getItem("usuario"))
      .id_usuario, //esta quemado, verificar
    nacional: "",
    id_municipio: "",
    tipo_financiacion: "",
    linea_tecnologica: "",
    nombre: "",
    entidad_capacitadora: "",
    entidad_capacitacion: "",
    resultado_aprendizaje: "",
    competencia_programa_oferta: "",
    //nuevos
    fecha_fin_conv: "",
    fecha_inicio_conv: "",
    horas_capacitacion: "",
    identificacion_responsable: "",
  };

  fechas_pic = [];

  lista_capacitaciones: any[] = [];
  modalidades: any[] = [];
  docResolucon: any[] = null;
  docSubidosCont = 0;

  requisito = "";
  aprendizaje = "";
  oferta = "";
  mostar_aprendizaje: any = false;
  mostar_oferta: any = false;
  lista_perfiles_requisitos: any[] = [];
  lista_aprendizaje: any[] = [];
  lista_oferta: any[] = [];
  // anexos
  anexos_convocatoria: any[] = [];
  id_anexo = "";
  modalAnexo = false;
  rutadocumento = "";
  //departamentos
  lista_departamentos: any[] = [];
  id_pais = 57;
  lista_paises = null;
  //municipios
  lista_municipios = null;
  departamento_actual = null;
  mounted() {
    // Axios()
    //   .get("/crear_convocatoria_pic")
    //   .then((respuesta) => {
    //     this.lista_capacitaciones = respuesta.data.results.pics;
    //     this.modalidades = respuesta.data.results.modalidades;
    //   });

    this.convocatoria.id_pic_convocatoria = this.$route.params.id;

    this.obtenerPic(this.convocatoria.id_pic_convocatoria);
    this.obtenerAnexoConvocatoria();

    this.ontenerPlanInstitucional();
    this.obtenerDepartamento(57);
  }

  //obtener municipios inicio
  obtenerMunicipio(id) {
    Axios()
      .get("listar_municipios/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_municipios = respuesta.data.results.municipios;
        } else {
          Swal.fire({
            title: respuesta.data.message,
          });
        }
      });
  }
  //obtener municipios fin

  //obtener departamentos inicio
  obtenerDepartamento(id_pais) {
    Axios()
      .get("listar_departamentos/" + id_pais)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_departamentos = respuesta.data.results;
        } else {
          Swal.fire({
            title: respuesta.data.message,
          });
        }
      });
  }

  //obtener departamentos fin

  obtenerPic(id) {
    Axios()
      .get("editar_convocatoria_pic/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.convocatoria = respuesta.data.results;

          // this.convocatoria.pic_fase_id = respuesta.data.results.id_pic_fase; //se hace
          this.lista_perfiles_requisitos =
            this.convocatoria.perfiles_requisitos.split(",");
          this.lista_perfiles_requisitos.splice(
            this.lista_perfiles_requisitos.length - 1,
            1
          );

          this.lista_aprendizaje =
            this.convocatoria.resultado_aprendizaje.split(",");
          this.lista_aprendizaje.splice(this.lista_aprendizaje.length - 1, 1);

          this.lista_oferta =
            this.convocatoria.competencia_programa_oferta.split(",");
          this.lista_oferta.splice(this.lista_oferta.length - 1, 1);
          // this.convocatoria.id_pic = respuesta.data.results.pic_id;
        } else {
          Swal.fire({
            title: respuesta.data.message,
          }).then(() => {
            this.$router.push("GestionarConvocatoriaENI");
          });
        }
      });
  }
  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "No pude ser negativo",
        // max: () => "La suma de los cupos no puede ser mayor a " + this.convocatoria.cupo_total,
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }

  // anexos
  obtenerAnexoConvocatoria() {
    Axios()
      .get(
        "mostrar_anexos_pic_convocatoria/" +
          this.convocatoria.id_pic_convocatoria
      )
      .then((respuesta) => {
        this.anexos_convocatoria = respuesta.data.results;
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
    // .catch(() => {
    //   Swal.fire({
    //     title: 'ERROR anexo'
    //   })
    // })
  }
  mostrarDocumento(id) {
    this.rutadocumento =
      this.$urlAPI + "visualizar_anexo_pic_convocatoria/" +
      id;
  }

  ontenerPlanInstitucional() {
    Axios()
      .get("listar_plan_institucional")
      .then((respuesta) => {
        this.fechas_pic = respuesta.data.results;
      });
  }

  cambiarPostuacion() {
    const id_usuario = JSON.parse(sessionStorage.getItem("usuario"));
    this.estadoPeticionRegistrar = true;
    Axios()
      .put("/actualizar_fase/" + this.convocatoria.id_pic_convocatoria, {
        id_pic_convocatoria: this.convocatoria.id_pic_convocatoria,
        pic_fase_id: 1,
        id_usuario_actualiza: id_usuario.id_usuario,
      })
      .then(() => {
        this.estadoPeticionRegistrar = true;
        Swal.fire({
          title: "Convocatoria ",
          text: this.convocatoria.convocatoria + " pasó a fase de postulación",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#007bb8",
          icon: "success",
        });
        router.back();
      })
      .catch(() => {
        this.estadoPeticionRegistrar = false;

        Swal.fire({
          title: "ERROR",
        });
      });
  }
  async obtenerDocumento(event, pos, id_pic_convocatoria) {
    if (event.target.files[0].size <= 4000000) {
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.docResolucon = event.target.files;

        Swal.fire({
          title: "Subir los archivos",
          text: "Se Agregarán " + this.docResolucon.length + " Documento(s)",
          icon: "question",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#007bb8",
          cancelButtonText: "Cancelar",
          showCancelButton: true,
        }).then((resp) => {
          if (resp.isConfirmed) {
            this.subirDocumento(pos, id_pic_convocatoria);
          }
        });
        this.docValido = true;
        // this.$router.push({ name: "VerDetalleConvocatoriaENI" });
      } else {
        this.docValido = false;
        this.docResolucon = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.docResolucon = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 4 MegaBytes!",
        "error"
      );
    }
  }

  subirDocumento(pos, id_pic_convocatoria) {
    // this.estadoPeticionSubirDocumento = true;
    let timerInterval;
    Swal.fire({
      title: "Cargando...",
      timer: 10000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const b = Swal.getHtmlContainer().querySelector("b");
        timerInterval = setInterval(() => {}, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    });
    let id = JSON.parse(sessionStorage.getItem("usuario"));
    for (const documento of this.docResolucon) {
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      var data = new FormData();
      data.append("anexo", documento.name);
      data.append("documento", documento);
      data.append(
        "id_convocatoria",
        id_pic_convocatoria
        // this.listaConvocatoriasFiltrado[pos].id_pic_convocatoria
      );
      data.append("id_usuario_autenticado", id.id_usuario);
      Axios()
        .post(
          "almacenar_anexo_convocatoria_pic/" + id_pic_convocatoria,
          // this.listaConvocatoriasFiltrado[pos].id_pic_convocatoria,
          data,
          { headers: headers }
        )
        .then((respuesta) => {
          if ((respuesta.data.status = "success")) {
            this.docSubidosCont = this.docSubidosCont + 1;
            if (this.docResolucon.length == this.docSubidosCont) {
              Swal.fire({
                title: "Los Anexos fueron subidos exitosamente",
                icon: "success",
                text: "Se subieron " + this.docResolucon.length + " anexos",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#007bb8",
              });
              this.docSubidosCont = 0;
            }
          } else {
            Swal.fire({
              title: "Los Anexos No fueron subidos",
              icon: "error",
              text: respuesta.data.message,
              confirmButtonText: "Aceptar",
            });
          }
          // this.modalCargarAnexo = false;
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        })
        .finally(() => {
          this.estadoPeticionSubirDocumento = false;
          this.obtenerAnexoConvocatoria();
        });
    }
  }

  eliminarAnexoDocumento(id_pic_anexo_convocatoria, index) {
    Swal.fire({
      title: "Desea Eliminar?",
      icon: "warning",
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((respuesta) => {
      if (respuesta.isConfirmed) {
        Axios()
          .delete("eliminar_anexo_documento/" + id_pic_anexo_convocatoria)
          .then((respuesta) => {
            if (respuesta.data.status == "success") {
              Swal.fire({
                title: "Anexo Eliminado",
                icon: "success",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#007bb8",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.anexos_convocatoria.splice(index, 1);
            } else {
              Swal.fire({
                title: "No se pudo eliminar la convocatoria",
                text: respuesta.data.message,
                icon: "error",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.$router.back();
            }
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }
}
