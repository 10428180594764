
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class ModificarEducacionPersona extends Vue {
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;
  estado = false;
  estadoPeticionModificar = false;

  docValido = false;
  estadoPeticion = false;
  rutadocumento = null;
  verModal = false;
  docResolucon = "";
  docVista = null;

  pic_eje = {
    id_eje: "",
    eje: "",
  };
  mounted() {
    this.obtenerPicEje(this.$route.params.id);
  }

  // ------------------------------------------------------------------------
  obtenerPicEje(idEje) {
    Axios()
      .get("listar_detalle_eje/" + idEje)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.pic_eje = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .finally(() => {
        this.estado = true;
      });
  }

  //----------------------------------------------------------------

  modificarPicEje() {
      this.estadoPeticionModificar = true;
    Axios()
      .put("modificar_detalle_eje/" + this.pic_eje.id_eje, this.pic_eje)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire(
            "Se Modifico el pic eje",
            "Pic Eje Modificado",
            respuesta.data.status
          );
          //   this.estadoPeticion = false;
          this.$router.push({ name: "GestionarPICEje" });
        } else {
          Swal.fire("ERROR!", "No Se Modifico Pic Eje", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
       .finally(()=>{
        this.estadoPeticionModificar =false;
      });
  }
  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.modificarPicEje();
      }
    });
  }
}
