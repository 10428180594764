
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class ModificarPerfil extends Vue {
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_usuario;

  estadoPeticion = false;

  persona = {
    primer_nombre: "",
    segundo_nombre: "",
    primer_apellido: "",
    segundo_apellido: "",
    correo: "",
    identificacion: "",
  };

  // persona = null;
  constructor() {
    super();
  }

  mounted() {
    this.obtenerInscritos(this.$route.params.id);
  }

  obtenerInscritos(id) {
    Axios()
      .get("listar_persona/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.persona = respuesta.data.results;
          this.estadoPeticion = false;
        } else {
          Swal.fire({
            title: "Hay un problema",
            text: respuesta.data.message,
            icon: "error",
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
        // this.$router.push("/GestionarConvocatoriaENI");
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }
}
