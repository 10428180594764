
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class ModificarEducacionPersona extends Vue {
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;
  estado = false;
  estadoPeticionModificar = false;

  docValido = false;
  estadoPeticion = false;
  rutadocumento = null;
  verModal = false;
  docResolucon = "";
  docVista = null;

  Linea_Tecnologica = {
    id_pic_linea_tecnologica: "",
    linea_tecnologica: "",
  };
  mounted() {
    this.obtenerLineaTecnologica(this.$route.params.id);
  }

  // ------------------------------------------------------------------------
  obtenerLineaTecnologica(id_linea) {
    Axios()
      .get("listar_detalle_linea_tecnologica/" + id_linea)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.Linea_Tecnologica = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estado = true;
      });
  }

  //----------------------------------------------------------------

  modificarLineaTecnologica() {
    this.estadoPeticionModificar = true;
    Axios()
      .put(
        "modificar_linea_tecnologica/" +
          this.Linea_Tecnologica.id_pic_linea_tecnologica,
        this.Linea_Tecnologica
      )
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire(
            "Se subió la Línea Tecnologica!",
            "Línea Tecnologica Modificada",
            respuesta.data.status
          );
          //   this.estadoPeticion = false;
          this.$router.push({ name: "GestionarLineasTecnologicas" });
        } else {
          Swal.fire("ERROR!", "No Se Modifico La Linea Tecnologica", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionModificar = false;
      });
  }
  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.modificarLineaTecnologica();
      }
    });
  }
}
