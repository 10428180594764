
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../../components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class ExperienciaPersonal extends Vue {
  lista_departamentos = null;
  lista_municipios = null;
  departamentoActual = null;
  departamentoResidencia = null;
  departamentoNacimiento = null;

  anexos_experiencia: any[] = [];
  index_doc_ver = 0;
  vistapreviaDoc = null;
  docsRequisitosArray: any[] = [];

  docValido = false;
  estadoPeticion = false;
  rutadocumento = null;
  verModal = false;

  requisito = "";
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;

  experienciaPersona = {
    id_experiencia: "",
    persona_id: "",
    empresa: "",
    direccion_empresa: "",
    correo_empresa: "",
    salario: "",
    fecha_ingreso_empresa: "",
    fecha_retiro_empresa: "",
    cargo: "",
    area: "",
    tipo_contrato: "",
    jefe_inmediato: "",
    cargo_jefe: "",
    funciones: "",
    actividad_empresa: "",
    certificado: "",

    id_tipo_empresa: "",
    tipo_empresa: "",
    municipio: "",
    id_municipio: "",
    departamento: "",
    id_departamento: "",
    pais: "",
    id_pais: "",
  };

  mounted() {
    this.obtenerDepartamentos();
    this.obtenerPaises();
    this.verDetallleExperienciaPersona(this.$route.params.id);
    this.mostrarDocumento(this.experienciaPersona.id_experiencia);
  }

  // paises,departamentos y municipios datos personales #1
  // voy a intentar mostrar en boton el pdf
  mirarModal() {
    this.verModal = true;
  }
  // ------------------------------------------------------------------------
  obtenerDepartamentos() {
    Axios()
      .get("listar_departamentos/57")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_departamentos = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Hubo un problema en el servidor",
          icon: "error",
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "btn btn-azul-sena",
          },
        });
      });
  }

  obtenerMunicipios(iddepa) {
    Axios()
      .get("listar_municipios/" + iddepa)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_municipios = respuesta.data.results.municipios;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Hubo un problema en el servidor",
          icon: "error",
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "btn btn-azul-sena",
          },
        });
      });
  }

  obtenerPaises() {
    Axios()
      .get("/listar_paises")
      .then((respu) => {})
      .catch((err) => {
        Swal.fire({
          title: "Hubo un problema en el servidor",
          icon: "error",
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "btn btn-azul-sena",
          },
        });
      });
  }

  //-----------------------------------------------------------
  vistaPreviaRequisito(index) {
    //para la vista previa del documento se debe crear la url
    this.vistapreviaDoc = URL.createObjectURL(this.docsRequisitosArray[index]);
    this.index_doc_ver = -1;
  }
  //-----------------------------------------------------------

  obtenerDocumento(event) {
    if (event.target.files[0].size <= 5000000) {
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.experienciaPersona.certificado = event.target.files[0];
        Swal.fire(
          "Certificación Cargada!",
          "Archivo cargado exitosamente!",
          "success"
        );
        this.docValido = true;
        // this.docResolucon = null;
      } else {
        this.docValido = false;
        this.experienciaPersona.certificado = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.experienciaPersona.certificado = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  }

  mostrarDocumento(id_experiencia) {
    this.rutadocumento =
      this.$urlAPI + "ver_certificado_experiencia_persona/" +
      id_experiencia;
  }
  //-----------------------------------------------------------
  verDetallleExperienciaPersona(id) {
    Axios()
      .get("ver_detalle_experiencia_persona/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.experienciaPersona = respuesta.data.results;
          this.mostrarDocumento(id);
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }
}
