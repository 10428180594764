var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h1',{staticClass:"text-azul-sena"},[_vm._v("LISTA DE SEGUIMIENTOS")]),_c('h',[_vm._v(_vm._s(_vm.nombreActividad))])],1),_c('div',{staticClass:"card-body"},[(_vm.estadoPeticion)?_c('div',{staticClass:"text-center"},[_c('AnimacionCargando')],1):_vm._e(),_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Modificar Actividad Trazabilidad","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"to":{
          name: 'RegistrarSeguimientoActividad',
        }}},[_c('button',{staticClass:"btn btn-sm bg-oscuro-sena text-white"},[_vm._v(" Adicionar Seguimiento ")])])],1),_c('div',{staticClass:"table-responsive"},[(!_vm.estadoPeticion)?_c('table',{staticClass:"table table-hover table-inverse"},[_vm._m(0),(_vm.seguimientos != null)?_c('tbody',_vm._l((_vm.seguimientos),function(seguimiento,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(seguimiento.id_gestion_actividad))]),_c('td',{staticClass:"campos"},[_c('div',{staticClass:"seguimiento"},[_vm._v(" "+_vm._s(seguimiento.gestion_actividad)+" ")])]),_c('td',[_vm._v(_vm._s(seguimiento.actualizado.slice(0, -9)))]),_c('td',{staticClass:"campos"},[_c('div',{staticClass:"acciones"},[_c('span',[_c('a',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"tooltip":"Eliminar Seguimiento","tooltip-flow":"top"},on:{"click":function($event){return _vm.eliminarSeguimiento(
                          seguimiento.id_gestion_actividad,
                          key
                        )}}},[_c('i',{staticClass:"fas fa-trash"})])]),_c('span',[_c('a',{staticClass:"btn btn-sm bg-oscuro-sena text-white mr-1",attrs:{"tooltip":"Ver Detalle","tooltip-flow":"top"},on:{"click":function($event){return _vm.verSeguimiento(seguimiento.id_gestion_actividad)}}},[_c('i',{staticClass:"fas fa-eye"})])]),_c('span',[_c('a',{staticClass:"btn btn-sm bg-oscuro-sena text-white mr-1",attrs:{"tooltip":"Modificar","tooltip-flow":"top"},on:{"click":function($event){return _vm.modificarSeguimiento(seguimiento.id_gestion_actividad)}}},[_c('i',{staticClass:"fas fa-edit"})])])])])])}),0):_c('p',[_vm._v("No hay seguimientos registrados!")])]):_vm._e()])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-4 col-sm-12 mt-1"},[_c('router-link',{staticClass:"btn btn-block bg-azul-sena text-white",attrs:{"to":{
              name: 'GestionarActividadCentro',
            }}},[_vm._v(" Volver ")])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-gradient-azul-sena text-white",attrs:{"id":"listado"}},[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Descripción del seguimiento")]),_c('th',[_vm._v("Fecha de modificación")]),_c('th',[_vm._v("Acciones")])])])
}]

export { render, staticRenderFns }