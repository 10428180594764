
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";
import CrearActividad from "../../../components/ModuloEgresados/RegistrarActividad.vue";

@Component({
  components: {
    CrearActividad,
    AnimacionCargando,
  },
})
export default class ListarActividades extends Vue {
  listaActividades: any[] = []; //Array de tipo cualquieravalor inicial vacio

  mounted() {
    this.listarActividades();
  }

  eliminarActividad(id, index) {
    Swal.fire({
      title:
        "Eliminar Actividad " + this.listaActividades[index].actividad + "?",
      icon: "warning",
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((respuesta) => {
      if (respuesta.isConfirmed) {
        Axios()
          .delete("eliminar_actividad/" + id)
          .then((respuesta) => {
            if (respuesta.data.status == "success") {
              Swal.fire({
                title: "Actividad Eliminada",
                icon: "success",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.listaActividades.splice(index, 1);
            } else {
              Swal.fire({
                title: "No se pudo eliminar la actividad",
                text: respuesta.data.message,
                icon: "error",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.$router.back();
            }
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }

  listarActividades() {
    Axios()
      .get("listar_actividades")
      .then((respuesta) => {
        this.listaActividades = respuesta.data;
      })
      .catch((error) => {});
  }
}
