
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../../components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class VerDetalleEducacionPersona extends Vue {
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;

  docValido = false;
  rutadocumento = null;
  estadoPeticion = false;
  verModal = false;

  datosEducacion = {
    actualizado: "",
    certificado: "",
    creado: "",
    fecha_fin: "",
    fecha_inicio: "",
    id_educacion: "",
    institucion: "",
    persona_id: "",
    tipo_educacion: "",
    titulo_obtenido: "",
  };
  mounted() {
    this.obtenerEducacionPersona(this.$route.params.id);
    this.mostrarDocumento(this.datosEducacion.id_educacion);
  }
  // voy a intentar mostrar en boton el pdf

  mirarModal() {
    this.verModal = true;
  }
  // ------------------------------------------------------------------------

  obtenerEducacionPersona(id) {
    Axios()
      .get("ver_detalle_educacion_persona/" + id)
      .then((respuesta) => {
        this.datosEducacion = respuesta.data.results;
        this.mostrarDocumento(id);
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }

  obtenerDocumento(event) {
    if (event.target.files[0].size <= 5000000) {
      //5mb tope
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.datosEducacion.certificado = event.target.files[0];
        Swal.fire(
          "Resolución Cargada!",
          "Archivo cargado exitosamente!",
          "success"
        );
        this.docValido = true;
      } else {
        this.docValido = false;
        this.datosEducacion.certificado = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.datosEducacion.certificado = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  }
  mostrarDocumento(id_educacion) {
    this.rutadocumento =
      this.$urlAPI + "ver_certificado_educacion_persona/" +
      id_educacion;
  }
}
