var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-5 container-fluid"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[(!_vm.estadoPeticionTabla)?_c('div',{staticClass:"text-center"},[_c('AnimacionTablasCargando')],1):_vm._e(),(_vm.estadoPeticionTabla)?_c('div',[_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-md-3 col-lg-3 mt-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Buscar certificado"},domProps:{"value":(_vm.filter)},on:{"keyup":function($event){$event.preventDefault();return _vm.certificadoFiltrado()},"input":function($event){if($event.target.composing)return;_vm.filter=$event.target.value}}})]),_c('div',{staticClass:"col-md-9 col-lg-9 mt-4"},[_c('paginate-links',{staticClass:"pagination justify-content-end",attrs:{"for":"listaCertificadoFiltrado","limit":2,"hide-single-page":true,"show-step-links":true,"full-page":true,"classes":{
                ul: 'simple-links-container',
                li: 'simple-links-item',
                liActive: ['simple-links-item', 'active1'],
                'li.active': 'active1',
              }}})],1)]),_c('div',{staticClass:"table-responsive"},[_c('paginate',{ref:"paginator",attrs:{"name":"listaCertificadoFiltrado","list":_vm.listaCertificadoFiltrado,"per":10}},[_c('table',{staticClass:"table table-hover"},[_c('thead',{attrs:{"id":"listado"}},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Id Certificacion")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Convenio")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Colegio")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Seguimiento inicial")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Seguimiento final")]),_c('th',{staticClass:"col-sm-2",attrs:{"scope":"col"}},[_vm._v("Acciones")])])]),_c('tbody',[(
                    _vm.listaCertificadoFiltrado != null &&
                    _vm.listaCertificadoFiltrado.length != 0
                  )?_vm._l((_vm.paginated(
                      'listaCertificadoFiltrado'
                    )),function(Certificacion,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(Certificacion.id_certificacion))]),_c('td',[_vm._v(_vm._s(Certificacion.convenio))]),_c('td',[_vm._v(_vm._s(Certificacion.colegio))]),_c('td',[_vm._v(_vm._s(Certificacion.seguimiento_inicial))]),_c('td',[_vm._v(_vm._s(Certificacion.seguimiento_final))]),_c('td',{attrs:{"scope":"row"}},[_c('div',{staticClass:"btn-toolbar btn-responsive-table",attrs:{"role":"toolbar"}},[_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Ver Convenio","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"to":{
                              name: 'VerDetallecertificado',
                              params: {
                                id: _vm.certificado.id_certificado,
                              },
                            }}},[_c('i',{staticClass:"fas fa-eye"})])],1),_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Eliminar Convenio","tooltip-flow":"top"}},[_c('a',{staticClass:"btn btn-sm bg-oscuro-sena text-white",on:{"click":function($event){return _vm.eliminarConvenio(
                                _vm.certificado.id_certificado,
                                key
                              )}}},[_c('i',{staticClass:"fas fa-trash"})])])])])])}):_c('tr',[_vm._v(" No hay convenios ")])],2)])])],1),_c('paginate-links',{staticClass:"pagination justify-content-end",attrs:{"for":"listaCertificadoFiltrado","limit":2,"hide-single-page":true,"show-step-links":true,"full-page":true,"classes":{
            ul: 'simple-links-container',
            li: 'simple-links-item',
            liActive: ['simple-links-item', 'active1'],
            'li.active': 'active1',
          }}})],1):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-header"},[_c('h1',{staticClass:"text-azul-sena"},[_c('h1',{staticClass:"text-azul-sena"},[_vm._v("LISTA CERTIFICACIÓN")])])])
}]

export { render, staticRenderFns }