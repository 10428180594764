
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import AnimacionCargando from "../../../components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class VerDetalleColegio extends Vue {
  estadoPeticion = false;
  rutadocumento = null;
  verModal = false;

  colegio = {
    id_colegio: "",
    id_colegio_modalidad: "",
    colegio_modalidad: "",
    zona_id: "",
    zona: "",
    id_jornada: "",
    jornada: "",
    municipio_id: "",
    municipio: "",
    primer_nombre: "",
    coord_persona_id: "",
    rector_persona_id: "",
    // 'personas.id_persona',
    // 'personas.primer_nombre',
    // 'personas.id_persona',
    // 'rector.segundo_nombre',
    colegio: "",
    nit: "",
    direccion: "",
    telefono: "",
    cod_dane: "",
    licencia: "",
    sector_colegio: "",
    secretaria_id: "",
    estado: "",
    resguardo: "",
    correo: "",
    calendario: "",
    genero: "",
    sedes: "",
    jornadas: "",
    rector: "",
  };
  mounted() {
    this.obtenerColegio(this.$route.params.id);
  }

  obtenerColegio(id) {
    Axios()
      .get("ver_detalle_colegio/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.colegio = respuesta.data.results;
          this.mostrarDocumento(id);
        }
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }

  mirarModal() {
    this.verModal = true;
  }

  mostrarDocumento(id_colegio) {
    this.rutadocumento =
    this.$urlAPI + "ver_certificado_licencia/" +
      id_colegio;
  }
}
