
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import animationLoading from "@/components/animacionCargando.vue";
import Tabs from "@/components/Tabs/Tabs.vue";

@Component({
  components: {
    animationLoading,
    Tabs,
  },
})
export default class Gestor_Eni_Instructor extends Vue {
  statusPetition = false;
  existInstructor = false;
  existInstructor2 = false;
  modalExperience = false;
  modalEducation = false;
  statusEducation = false;
  statusIdioma = false;

  modalIdioma = false;
  definido: string = "D";
  rutadocumento = null;
  rutadocumento2 = null;
  rutadocumento3 = null;

  showModalExperience() {
    this.modalExperience = true;
  }
  showModalEducation() {
    this.modalEducation = true;
  }
  showModalIdioma() {
    this.modalIdioma = true;
  }

  idDetalleexperiecia?: any;
  identification = "";
  showInformationPerson: any = {
    correo: "",
    correo_aternativo: null,
    depto_expedicion: "",
    depto_nacimiento: null,
    depto_residencia: "",
    direccion: "",
    discapacidad: null,
    fecha_identificacion: "",
    fecha_nacimiento: "",
    genero: "",
    id_depto_expedicion: "",
    id_depto_nacimiento: null,
    id_depto_residencia: "",
    id_discapacidad: null,
    id_genero: "",
    id_municipio_expedicion: "",
    id_municipio_nacimiento: null,
    id_municipio_residencia: "",
    id_persona: "",
    id_tipo_identificacion: "",
    identificacion: "",
    localidad: null,
    municipio_expedicion: "",
    municipio_nacimiento: null,
    municipio_residencia: "",
    nacionalidad: null,
    pais_nacimiento: null,
    pais_residencia: "",
    porcentaje_discapacidad: null,
    primer_apellido: "",
    primer_nombre: "",
    segundo_apellido: "",
    segundo_nombre: "",
    telefono_fijo: "",
    telefono_movil: "",

    grupo_etnico: "",
    centro_formacion: "",
    regional: " ",
  };

  informationInstructor: any = {
    apellidos: "",
    area_ruta_id: null,
    cabeza_familia: null,
    centro_formacion_id: null,
    clasificacion_sisben: null,
    correo: "",
    correo_aternativo: null,
    direccion: "",
    discapacidad_id: null,
    estado_civil_id: "",
    estrato: "",
    fecha_identificacion: "",
    fecha_nacimiento: "",
    genero_id: "",
    grupo_etnico_id: null,
    grupo_etnico: null,
    hijos: null,
    id_persona: null,
    id_usuario: null,
    identificacion: "",
    localidad: null,
    municipio_identificacion_id: null,
    municipio_nacimiento_id: null,
    municipio_residencia_id: null,
    nombre_completo: null,
    nombres: "",
    numero_libreta_militar: null,
    objeto_guia: null,
    pais_nacionalidad_id: null,
    perfil_id: null,
    persona_id: null,
    primer_apellido: "",
    primer_nombre: "",
    procentaje_discapacidad: null,
    puntaje_icfes: "",
    recordar_token: null,
    recuperar_clave: null,
    ruta_foto: null,
    segundo_apellido: "",
    segundo_nombre: "",
    telefono_alternativo: "",
    telefono_fijo: "",
    telefono_movil: "",
    tipo_identificacion_id: "",
    tipo_libreta_militar_id: "",
    tipo_sangre: "",
    usuario: null,
    victima_conflicto: null,
    zona_id: null,
  };

  instructorEducationInformation: any = [];
  instructorIdiomasInformation: any = [];
  instructorExperienceInformation: any = [];
  instructorDataDetailEducationInformation: any = {
    id_educacion: "",
    persona_id: "",
    titulo_obtenido: "",
    institucion: "",
    fecha_inicio: "",
    fecha_fin: "",
    tipo_educacion: "",
    certificado: "",
  };
  instructorDataDetailIdiomaInformation: any = {
    id_idioma: "",
    persona_id: "",
    idioma_id: "",
    idioma: "",
    institucion: "",
    certificado: "",
  };
  instructorDataDetailExperienceInformation: any = {
    actividad_empresa: "",
    area: "",
    cargo: "",
    cargo_jefe: "",
    certificado: "",
    correo_empresa: "",
    departamento: "",
    direccion_empresa: "",
    empresa: "",
    fecha_ingreso_empresa: "",
    fecha_retiro_empresa: "",
    funciones: "",
    id_departamento: "",
    id_experiencia: "",
    id_municipio: "",
    id_pais: "",
    id_tipo_empresa: "",
    jefe_inmediato: "",
    municipio: "",
    pais: "",
    persona_id: "",
    salario: "",
    tipo_contrato: "",
    tipo_empresa: "",
  };
  educationInstructor = false;
  experienceInstructor = false;

  idiomaInstructor = false;

  mostrarDocumento(id_experiencia) {
    this.rutadocumento =
      this.$urlAPI + "ver_certificado_experiencia_persona/" +
      id_experiencia;
  }

  mostrarDocumento2(id_educacion) {
    this.rutadocumento2 =
      this.$urlAPI + "ver_certificado_educacion_persona/" +
      id_educacion;
  }
  mostrarDocumento3(id_idioma) {
    this.rutadocumento3 =
      this.$urlAPI + "visualizar_certificado_idioma_persona/" +
      id_idioma;
  }

  buscarInstructor(identification) {
    this.statusPetition = true;
    if (this.identification == "") {
      this.statusPetition = false;
      this.existInstructor = false;
      Swal.fire({
        title: "Información Importante",
        text: "El campo de búsquedas no puede quedar vació",
        icon: "warning",
        timer: 1250,
      });
    } else {
      this.informationInstructor = [];
      Axios()
        .get("buscar_usuario/" + identification)
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            this.informationInstructor = respuesta.data.results;
            this.statusPetition = false;
            this.existInstructor = true;
            this.showInformationInstructor();
          } else {
            this.statusPetition = false;
            Swal.fire({
              title: "Instructor no encontrado",
              text: respuesta.data.message,
              icon: respuesta.data.status,
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        })
        .finally(() => {
          this.statusPetition = false;
        });
    }
  }

  async showInformationInstructor() {
    this.showInformationPerson = [];
    Axios()
      .get("ver_detalle_persona/" + this.informationInstructor.id_persona)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.showInformationPerson = respuesta.data.results;
          this.existInstructor2 = true;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.statusPetition = false;
      });
  }

  getInstructorEducation() {
    this.statusEducation = true;
    Axios()
      .get("listar_educacion_persona/" + this.informationInstructor.id_persona)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.instructorEducationInformation = respuesta.data.results;
          this.statusEducation = false;
        } else {
          this.statusEducation = false;
          Swal.fire({
            title: "Error",
            text: respuesta.data.message,
            icon: "error",
            timer: 1350,
          });
        }
      })
      .catch(() => {
        this.statusEducation = false;
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido un error en el servidor",
          timer: 1250,
        });
      });
  }

  getInstructorIdiomas() {
    this.statusIdioma = true;
    Axios()
      .get("listar_idiomas_persona/" + this.informationInstructor.id_persona)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.instructorIdiomasInformation = respuesta.data.results;

          this.statusIdioma = false;
        } else {
          this.statusIdioma = false;
          Swal.fire({
            title: "Error",
            text: respuesta.data.message,
            icon: "error",
            timer: 1350,
          });
        }
      })
      .catch(() => {
        this.statusIdioma = false;
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido un error en el servidor",
          timer: 1250,
        });
      });
  }

  getInstructorExperience() {
    this.experienceInstructor = true;
    this.instructorExperienceInformation = [];
    this.instructorDataDetailExperienceInformation = [];
    Axios()
      .get(
        "listar_experiencia_persona/" + this.informationInstructor.id_persona
      )
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.instructorExperienceInformation = respuesta.data.results;
          this.experienceInstructor = false;
        } else {
          this.experienceInstructor = false;
          Swal.fire({
            title: "Error",
            text: respuesta.data.message,
            icon: "error",
            timer: 1350,
          });
        }
      })
      .catch(() => {
        this.experienceInstructor = false;
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error en el servidor",
          timer: 1250,
        });
      });
  }

  seeDetailIdiomaInstructor(idIdioma) {
    Axios()
      .get("listar_item_idiomas_persona/" + idIdioma)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.instructorDataDetailIdiomaInformation = respuesta.data.results;
          this.mostrarDocumento3(idIdioma);
          console.log("dataDetail", this.instructorDataDetailIdiomaInformation);
        } else {
          Swal.fire({
            title: "Error",
            text: respuesta.data.message,
            icon: "error",
            timer: 1350,
          });
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error en el servidor",
          timer: 1250,
        });
      });
  }

  seeDetailEducationInstructor(idEducation) {
    Axios()
      .get("ver_detalle_educacion_persona/" + idEducation)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.instructorDataDetailEducationInformation =
            respuesta.data.results;
          this.mostrarDocumento2(idEducation);
          console.log(
            "dataDetail",
            this.instructorDataDetailEducationInformation
          );
        } else {
          Swal.fire({
            title: "Error",
            text: respuesta.data.message,
            icon: "error",
            timer: 1350,
          });
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error en el servidor",
          timer: 1250,
        });
      });
  }

  seeDetailExperienceInstructor(idExperience) {
    Axios()
      .get("ver_detalle_experiencia_persona/" + idExperience)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.instructorDataDetailExperienceInformation =
            respuesta.data.results;
          this.mostrarDocumento(idExperience);
          console.log(
            "dataDetail",
            this.instructorDataDetailExperienceInformation
          );
        } else {
          Swal.fire({
            title: "Error",
            text: respuesta.data.message,
            icon: "error",
            timer: 1350,
          });
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error en el servidor",
          timer: 1250,
        });
      });
  }
  mounted() {
    this.mostrarDocumento(
      this.instructorDataDetailExperienceInformation.id_experiencia
    );
    this.mostrarDocumento2(
      this.instructorDataDetailEducationInformation.id_educacion
    );
    this.mostrarDocumento3(
      this.instructorDataDetailIdiomaInformation.id_idioma
    );
  }
}
