import { render, staticRenderFns } from "./ValidarRecuperacionContrasena.vue?vue&type=template&id=0cfc6c79&"
import script from "./ValidarRecuperacionContrasena.vue?vue&type=script&lang=ts&"
export * from "./ValidarRecuperacionContrasena.vue?vue&type=script&lang=ts&"
import style0 from "./ValidarRecuperacionContrasena.vue?vue&type=style&index=0&id=0cfc6c79&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports