
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class VerTrazabilidad extends Vue {
  estadoPeticion = true;
  //idConvocatoria = null;

  trazabilidad = {
    eje: "",
    id_eje: "",
    id_trazabilidad: "",
    trazabilidad: "",
  };

  mounted() {
    // this.idConvocatoria = this.$route.params.id;
    this.verDetalleTrazabilidad(this.$route.params.id);
  }

  verDetalleTrazabilidad(id) {
    Axios()
      .get("ver_detalle_pane_trazabilidad/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.trazabilidad = respuesta.data.results;
        }
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }

  //   async verDetalleTrazabilidad(id) {
  //     Axios()
  //       .get("ver_detalle_pane_trazabilidad/" + id)
  //       .then((respuesta) => {
  //         if (respuesta.data.status == "success") {
  //           this.trazabilidad = respuesta.data.results;
  //         } else {
  //           Swal.fire({
  //             title: "Error obteniendo la razabilidad",
  //             text: respuesta.data.message,
  //             icon: "error",
  //             confirmButtonText: "Aceptar",
  //             customClass: {
  //               confirmButton: "btn btn-azul-sena",
  //             },
  //           });
  //         }
  //       })
  //       .catch(() => {
  //         Swal.fire(
  //           "ERROR!",
  //           "Se ha presentado un error en el servidor!",
  //           "error"
  //         );
  //       })
  //       .finally(() => {
  //         this.estadoPeticion = false;
  //       });
  //   }
}
