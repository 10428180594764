
  import { Component, Vue } from "vue-property-decorator";
  import Axios from "@/Axios";
  import Swal from "sweetalert2";
  import AnimacionCargando from "../../../components/animacionCargando.vue";
  import AnimacionTablasCargando from "../../../components/animacionTablasCargando.vue";
  import registrarEstudio from "../HojaDeVida/Educacion/RegistrarEducacionPersona.vue";
  import Card from "@/components/Card.vue";

  @Component({
    components: {
      AnimacionCargando,
      AnimacionTablasCargando,
      registrarEstudio,
      Card,
    },
  })
  export default class Login extends Vue {
    otro = false;
    estadoPeticion = true;

    listaInscripciones = {
      id_pic_inscripcion: "",
      pic_convocatoria_id: "",
      persona_id: "",
      pic_estado_inscripcion_id: "",
      observaciones: "",
      creado: "",
      actualizado: "",
      borrado_el: "",
      id_usuario_actualiza: "",
      id_pic_estado_inscripcion: "",
      pic_estado_inscripcion: "",
      id_persona: "",
      tipo_identificacion_id: "",
      identificacion: "",
      municipio_identificacion_id: "",
      genero_id: "",
      zona_id: "",
      grupo_etnico_id: "",
      discapacidad_id: "",
      estado_civil_id: "",
      municipio_residencia_id: "",
      tipo_libreta_militar_id: "",
      fecha_identificacion: "",
      primer_nombre: "",
      segundo_nombre: "",
      primer_apellido: "",
      segundo_apellido: "",
      nombre_completo: "",
      fecha_nacimiento: "",
      estrato: "",
      hijos: "",
      clasificacion_sisben: "",
      direccion: "",
      puntaje_icfes: "",
      tipo_sangre: "",
      victima_conflicto: "",
      cabeza_familia: "",
      correo: "",
      correo_aternativo: "",
      telefono_fijo: "",
      telefono_movil: "",
      telefono_alternativo: "",
      numero_libreta_militar: "",
      id_crea: "",
      id_actualiza: "",
      contrasena: "",
      municipio_nacimiento_id: "",
      procentaje_discapacidad: "",
      localidad: "",
      pais_nacionalidad_id: "",
    };
    listaInscripcionesFiltrado = [];
    paginate = ["listaInscripcionesFiltrado"];
    filter = "";
    fecha_pic = "2021";
    fechas_pic = [];
    listaAdjudicados: any = [];
    listaInscripciones2: any = [];
    id_persona = null;
    adjudicar = false;
    nombre = "";
    observacion = null;
    convocatoria = {
      convocatoria: "",
      fecha_inicio: "",
      fecha_final: "",
      lugar: "",
      perfiles_requisitos: "",
      financiacion: "",
      id_pic_convocatoria: "",
      para_funcionarios: "",
      pertenece_a_red: "SI", //dato quemado
      pic_id: "",
      plan_institucional_id: "",
      id_pic: "",
      pic_fase_id: 0,
      id_usuario_autenticado: "",
      nacional: "",
      id_municipio: "",
    };

    idPersonaActual = null;
    prueba = "";
    idInscripcionActual = null;
    estadoPeticionTabla = false;

    requisitosInstructor = null;
    rutadocumento = null;
    modalNoAprobar = false;

    inscritoNoAprobar= {
       id_pic_inscripcion: "",
      pic_convocatoria_id: "",
      persona_id: "",
      pic_estado_inscripcion_id: "",
      observaciones: "",
      creado: "",
      actualizado: "",
      borrado_el: "",
      id_usuario_actualiza: "",
      id_pic_estado_inscripcion: "",
      pic_estado_inscripcion: "",
      id_persona: "",
      tipo_identificacion_id: "",
      identificacion: "",
      municipio_identificacion_id: "",
      genero_id: "",
      zona_id: "",
      grupo_etnico_id: "",
      discapacidad_id: "",
      estado_civil_id: "",
      municipio_residencia_id: "",
      tipo_libreta_militar_id: "",
      fecha_identificacion: "",
      primer_nombre: "",
      segundo_nombre: "",
      primer_apellido: "",
      segundo_apellido: "",
      nombre_completo: "",
      fecha_nacimiento: "",
      estrato: "",
      hijos: "",
      clasificacion_sisben: "",
      direccion: "",
      puntaje_icfes: "",
      tipo_sangre: "",
      victima_conflicto: "",
      cabeza_familia: "",
      correo: "",
      correo_aternativo: "",
      telefono_fijo: "",
      telefono_movil: "",
      telefono_alternativo: "",
      numero_libreta_militar: "",
      id_crea: "",
      id_actualiza: "",
      contrasena: "",
      municipio_nacimiento_id: "",
      procentaje_discapacidad: "",
      localidad: "",
      pais_nacionalidad_id: "",
    };
    persona = JSON.parse(sessionStorage.getItem("usuario")).id_persona;
    constructor() {
      super();
      const dict = {
        messages: {
          required: () => "Campo obligatorio",
          min: () => "Mínimo 8 caracteres",
          // email: () => "Correo invalido",
        },
      };
      this.$validator.localize("es", dict);
    }

    async mounted() {
      this.obtenerPic(this.$route.params.id);
      this.obtenerInscritos();
    }

    noAprobar(inscrito){
      this.inscritoNoAprobar = inscrito;
      
    }

    mostrarDocumento(id) {
      this.rutadocumento =
        this.$urlAPI + "visualizar_requisito_pic/" + id;
    }
    // Solo obtiene los datos de los inscritos
    async obtenerInscritos() {
      let idIncritos = this.$route.params.id;
      await Axios()
        .get("listar_inscripciones_pic_adjudicados_finalizados/" + idIncritos)
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            this.listaInscripciones = respuesta.data.results;
            this.listaInscripcionesFiltrado = respuesta.data.results;


            this.estadoPeticion = false;
          } else {
            Swal.fire({
              title: "Hay un problema",
              text: respuesta.data.message,
              icon: "error",
            });
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
          this.$router.push("/GestionarConvocatoriaENI");
        })
        .finally(() => {
          this.estadoPeticionTabla = true;
        });
    }

    async obtenerPic(id) {
      await Axios()
        .get("editar_convocatoria_pic/" + id)
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            this.convocatoria = respuesta.data.results;
          }
        });
    }

    cambiarEstadoConvocatoria() {
      const id_usuario = JSON.parse(sessionStorage.getItem("usuario"));
      Axios()
        .put("actualizar_fase/" + this.$route.params.id, {
          id_pic_convocatoria: this.$route.params.id,
          pic_fase_id: 4,
          id_usuario_actualiza: id_usuario.id_usuario,
        })
        .then(() => {
          Swal.fire({
            title: "Convocatoria ",
            text: this.convocatoria.convocatoria + " pasó a fase de Terminación",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
            icon: "success",
          });
          this.observacion = null;
          this.$router.push({ name: "GestionarConvocatoriaENI" });
        })
        .catch(() => {
          Swal.fire({
            title: "ERROR",
          });
        });
    }
    obtenerOtro() {
      if (this.observacion == "Otro") {
        this.otro = true;
      } else {
        this.otro = false;
      }
    }

    // cambia de aprobado y no aprobado
    actualizarEstadoInscritos(estado_inscripcion, id_persona) {
      // alert(this.idPersonaActual + " - " + estado_inscripcion);
      Swal.fire({
        title: 'Aprobación capacitación ',
        text: this.convocatoria.convocatoria + " Esta acción libera al funcionario para participar en otras capacitaciones",
        icon: "warning",

        confirmButtonText: "Finalizar",
        confirmButtonColor: "#007bb8",
        cancelButtonColor: "#2d2d2d",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
      }).then((respuesta) => {
        if (respuesta.isConfirmed) {
          //aqui debo actualizar metodo para mandar la observación en caso de no haber aprobado la capacitación
          Axios()
            .post("actualizar_estado_inscripto_pic/" + this.convocatoria.id_pic_convocatoria, {
              id_pic_estado_inscripcion: estado_inscripcion,
              id_usuario_actualiza: JSON.parse(sessionStorage.getItem("usuario")).id_usuario,
              id_persona: id_persona,
              observaciones: this.observacion,
            })
            .then((respuesta) => {
              if (respuesta.data.status == "success") {
                if (estado_inscripcion == 4) {
                  //aprobado y listo para subir certificado
                  Swal.fire({
                    title: "El instructor aprobó la capacitación",
                    text:
                      "Ahora se debe subir sus certificados de la capacitación",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                    confirmButtonColor: "#007bb8",
                    customClass: {
                      confirmButton: "btn btn-azul-sena",
                    },
                  });
                } else {
                  //no aprobado la capacitación
                  Swal.fire({
                    title: "El instructor No aprobó la capacitación",
                    text:
                      "Capacitación terminada sin aprobación del curso por parte del instructor",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                    confirmButtonColor: "#007bb8",
                    customClass: {
                      confirmButton: "btn btn-azul-sena",
                    },
                  });
                }
              } else {
                Swal.fire({
                  title: "No se pudo realizar la acción, intentalo nuevamente",
                  icon: "error",
                  confirmButtonText: "Aceptar",
                  customClass: {
                    confirmButton: "btn btn-azul-sena",
                  },
                });
              }
              this.modalNoAprobar = false;
              this.observacion = null;
              // this.obtenerInscritos()
            })
            .catch(() => {
              Swal.fire(
                "ERROR!",
                "Se ha presentado un error en el servidor!",
                "error"
              );
            })
            .finally(() => {
              this.obtenerInscritos();
            });
        }
      });
    }

  }
