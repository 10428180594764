
import { Component, Vue } from "vue-property-decorator";
import Swal from "sweetalert2";
import Axios from "@/Axios";
import AnimacionTablasCargando from "../../../../components/animacionTablasCargando.vue";

@Component({
  components: { AnimacionTablasCargando },
})
export default class Index extends Vue {
  estadoPeticionTabla = false;
  listaFuncionarios: any[] = [];

  filter = "";
  listafuncionario = [];

  Funcionario = {
    id_dirigido_a: "",
    nombre: "",
  };

  mounted() {
    this.obtenerFuncionarios();
  }

  obtenerFuncionarios() {
    Axios()
      .get("/listar_pic_dirigida")
      .then((respuesta) => {
        this.listaFuncionarios = respuesta.data.results;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }
  eliminarFuncionario(id, index) {
    Swal.fire({
      title: "Eliminar Funcionario ",
      text: this.listaFuncionarios[index].nombre + "?",
      icon: "warning",
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((respuesta) => {
      if (respuesta.isConfirmed) {
        Axios()
          .delete(
            "eliminar_pic_dirigida/" +
              this.listaFuncionarios[index].id_dirigido_a
          )
          .then((respuesta) => {
            if (respuesta.data.status == "success") {
              Swal.fire({
                title: "Funcionario Eliminado",
                icon: "success",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#007bb8",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.listaFuncionarios.splice(index, 1);
            } else {
              Swal.fire({
                title: "No se pudo eliminar el funcionario",
                text: respuesta.data.message,
                icon: "error",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
            }
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }
  FuncionarioFiltrado() {
    let picFilter = [];
    // this.listaConvocatoriasFiltrado = []

    for (let func of this.listaFuncionarios) {
      let funcionario = func.nombre.toLowerCase();

      if (funcionario.indexOf(this.filter) >= 0) {
        picFilter.push(func);
      }
    }

    this.listafuncionario = picFilter;
    if (this.listafuncionario.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró el Funcionario, que coincida con la búsqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#007bb8",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filter = "";
      this.listafuncionario = this.listaFuncionarios;
    }
  }
}
