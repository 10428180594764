var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-5 container-fluid"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',[_c('p',{staticClass:"text-azul-sena font-weight-bold col"},[_vm._v(" Indicador: "),_c('small',[_vm._v(_vm._s(_vm.evidenciaIndicador.nombre_indicador))])])]),_c('div',[_c('p',{staticClass:"text-azul-sena font-weight-bold col"},[_vm._v(" Fecha Inicio: "),_c('small',[_vm._v(_vm._s(_vm.evidenciaIndicador.fecha_inicio))])])]),_c('div',[_c('p',{staticClass:"text-azul-sena font-weight-bold col"},[_vm._v(" Fecha Fin: "),_c('small',[_vm._v(_vm._s(_vm.evidenciaIndicador.fecha_fin))])])]),_c('div',{staticClass:"btn-group ml-2 sm",staticStyle:{"width":"2%","height":"10%"},attrs:{"tooltip":"Ver Documento","tooltip-flow":"top"}},[_c('router-link',{staticClass:"text-white btn btn-sm bg-gradient-primary",attrs:{"to":{
              name: 'VerDetalleDocumento',
              params: {
                id: _vm.evidenciaIndicador.documento_id,
              },
            }}},[_c('i',{staticClass:"fas fa-file-pdf"})])],1),_c('paginate-links',{staticClass:"pagination justify-content-end col",attrs:{"for":"listaHistorialEvidencias","limit":2,"hide-single-page":true,"show-step-links":true,"full-page":true,"classes":{
            ul: 'simple-links-container',
            li: 'simple-links-item',
            liActive: ['simple-links-item', 'active1'],
            'li.active': 'active1',
          }}})],1),(_vm.estadoPeticion)?_c('div',{staticClass:"text-center"},[_c('AnimacionCargando')],1):_vm._e(),(!_vm.estadoPeticion)?_c('div',{staticClass:"table-responsive"},[_c('paginate',{ref:"paginator",attrs:{"name":"listaHistorialEvidencias","list":_vm.listaHistorialEvidencias,"per":8}},[(!_vm.estadoPeticion)?_c('table',{staticClass:"table table-hover"},[_c('thead',{staticClass:"bg-gradient-primary",attrs:{"id":"listado"}},[_c('tr',[_c('th',{staticClass:"text-nowrap"},[_vm._v("Nombre Indicador")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("OBJETIVO DEL INDICADOR")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("ACTIVIDADES DESARROLLADAS")]),_c('th',{staticClass:"text-center"},[_vm._v("Responsable Operativo")]),_c('th',{staticClass:"text-center"},[_vm._v("Estado")]),_c('th',[_vm._v("Acciones")])])]),_c('tbody',[(_vm.listaHistorialEvidencias != null)?_vm._l((_vm.paginated(
                    'listaHistorialEvidencias'
                  )),function(historialevidencias,key){return _c('tr',{key:key},[_c('td',[_vm._v(" "+_vm._s(historialevidencias.nombre_indicador)+" ")]),_c('td',[_vm._v(" "+_vm._s(historialevidencias.objetivo_indicador)+" ")]),_c('td',[_vm._v(" "+_vm._s(historialevidencias.actividades_desarrolladas)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(historialevidencias.primer_nombre)+" "+_vm._s(historialevidencias.segundo_nombre)+" ")]),(historialevidencias.estado == 'Por Aprobar')?_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(historialevidencias.estado)+" ")]):_vm._e(),(historialevidencias.estado == 'Aprobado')?_c('td',{staticClass:"text-green text-center"},[_vm._v(" "+_vm._s(historialevidencias.estado)+" ")]):_vm._e(),(historialevidencias.estado == 'Rechazado')?_c('td',{staticClass:"text-red text-center"},[_vm._v(" "+_vm._s(historialevidencias.estado)+" ")]):_vm._e(),_c('td',{attrs:{"scope":"row"}},[_c('div',{staticClass:"btn-toolbar btn-responsive-table",attrs:{"role":"toolbar"}},[(historialevidencias.estado == 'Rechazado')?_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Aprobar","tooltip-flow":"top","id":"cursor-pointer"}},[_c('a',{staticClass:"btn btn-sm bg-oscuro-sena text-white",on:{"click":function($event){return _vm.cambiarEstadoAprobado(
                              historialevidencias.id_historial_evidencia,
                              _vm.evidenciaIndicador.id_evidencia
                            )}}},[_c('i',{staticClass:"fas fa-thumbs-up"})])]):_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Rechazar","tooltip-flow":"top"}},[_c('a',{staticClass:"btn btn-sm bg-oscuro-sena text-white",on:{"click":function($event){return _vm.cambiarEstadoRechazado(
                              historialevidencias.id_historial_evidencia,
                              _vm.evidenciaIndicador.id_evidencia
                            )}}},[_c('i',{staticClass:"fas fa-thumbs-down"})])]),_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Ver documento","tooltip-flow":"top"}},[_c('a',{staticClass:"btn btn-sm bg-oscuro-sena text-white",on:{"click":function($event){(_vm.modalRequisito = true),
                              _vm.mostrarDocumento(
                                historialevidencias.id_documento
                              )}}},[_c('i',{staticClass:"fas fa-file-pdf"})])]),_c('div',{staticClass:"btn-group btn-spc",attrs:{"div":"","tooltip":"Ver Detalle","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"to":{
                            name: 'VerDetalleRetroalimentacionEvidencia',
                            params: {
                              id_historial_evidencia:
                                historialevidencias.id_historial_evidencia,
                            },
                          }}},[_c('i',{staticClass:"fas fa-eye"})])],1)]),_c('modal',{attrs:{"show":_vm.modalRequisito,"body-classes":"p-0","modal-classes":"modal-dialog-centered modal-sm modal-lg"},on:{"update:show":function($event){_vm.modalRequisito=$event}}},[_c('template',{slot:"header"},[_c('h1',{staticClass:"text-azul-sena modal-title",attrs:{"id":"modalNovedadesAprendiz"}},[_vm._v(" Evidencia ")])]),_c('embed',{attrs:{"src":_vm.rutadocumento,"type":"application/pdf","width":"100%","height":"600px"}}),_c('template',{slot:"footer"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"dfd"},[(historialevidencias.estado == 'Rechazado')?_c('div',{staticClass:"btn-group btn-spc dsd",attrs:{"tooltip":"Aprobar","tooltip-flow":"top","id":"cursor-pointer"}},[_c('a',{staticClass:"btn btn-sm bg-oscuro-sena text-white",on:{"click":function($event){return _vm.cambiarEstadoAprobado(
                                    historialevidencias.id_historial_evidencia,
                                    _vm.evidenciaIndicador.id_evidencia
                                  )}}},[_c('i',{staticClass:"fas fa-thumbs-up"},[_c('span',{staticClass:"p-2"},[_vm._v("Aprobar")])])])]):_c('div',{staticClass:"btn-group btn-spc dsd",attrs:{"tooltip":"Rechazar","tooltip-flow":"top","id":"cursor-pointer"}},[_c('a',{staticClass:"btn btn-sm bg-oscuro-sena text-white",on:{"click":function($event){return _vm.cambiarEstadoRechazado(
                                    historialevidencias.id_historial_evidencia,
                                    _vm.evidenciaIndicador.id_evidencia
                                  )}}},[_c('i',{staticClass:"fas fa-thumbs-down"},[_c('span',{staticClass:"p-2"},[_vm._v("Rachazar")])])])])])])])],2)],1)])}):_c('tr',[_vm._v(" No hay historial de evidencias ")])],2)]):_vm._e()])],1):_vm._e(),_c('paginate-links',{staticClass:"pagination justify-content-end mt-3",attrs:{"for":"listaHistorialEvidencias","limit":2,"hide-single-page":true,"show-step-links":true,"full-page":true,"classes":{
          ul: 'simple-links-container',
          li: 'simple-links-item',
          liActive: ['simple-links-item', 'active1'],
          'li.active': 'active1',
        }}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"border-radius-lg bg-gradient-primary card-header p-0 position-relative mt-n4 mx-3 z-index-2 shadow-3"},[_c('div',{staticClass:"pt-4 pb-3"},[_c('h1',{staticClass:"text-white text-capitalize ps-3 ms-3 title-header"},[_vm._v(" HISTORIAL DE EVIDENCIAS ")])])])
}]

export { render, staticRenderFns }