<template>
  <!-- loader conteidos -->
  <div>
    <transition name="fade">
      <div id="loader" class="wrapper">
        <div class="row_loader">
          <div class="table-responsive col-sm-12">
            <div class="float-right almacenar"></div>
            <div class="row_loader_search"></div>
            <table style="width: 100%">
              <thead class="row_loader_title">
                <tr>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio2">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td scope="row">
                    <div class="btn-toolbar" role="toolbar">
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio2">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td scope="row">
                    <div class="btn-toolbar" role="toolbar">
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio2">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td scope="row">
                    <div class="btn-toolbar" role="toolbar">
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio2">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td scope="row">
                    <div class="btn-toolbar" role="toolbar">
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio2">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td scope="row">
                    <div class="btn-toolbar" role="toolbar">
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio2">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td scope="row">
                    <div class="btn-toolbar" role="toolbar">
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio2">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td scope="row">
                    <div class="btn-toolbar" role="toolbar">
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio2">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td scope="row">
                    <div class="btn-toolbar" role="toolbar">
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio2">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td scope="row">
                    <div class="btn-toolbar" role="toolbar">
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio2">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td scope="row">
                    <div class="btn-toolbar" role="toolbar">
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio2">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td scope="row">
                    <div class="btn-toolbar" role="toolbar">
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio1">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td>
                    <div class="card search espacio2">
                      <div class="title placeholder"></div>
                    </div>
                  </td>
                  <td scope="row">
                    <div class="btn-toolbar" role="toolbar">
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                      <div class="btn-group btn-spc">
                        <div class="almacenar"></div>
                      </div>
                    </div>
                  </td>
                </tr>               
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <!-- fin contenido jqyuery -->
</template>
<script>
export default {
  name: "AnimacionTablasCargando",
};
</script>