
import Component from "vue-class-component";
import { Prop, Vue } from "vue-property-decorator";
import { Pregunta, Tabla } from "./VerDetalleEvaluacionCapacitacion.vue";

@Component
export default class Formulario3 extends Vue {
  ver: any = true;
  crear: any = true;
  @Prop() contenidoCurso: Tabla;
  @Prop() contenidoCurso2: Tabla;
  preguntas: Tabla = new Tabla();
  preguntas2: Tabla = new Tabla();
  juntificacion = false;
  posPregunta = 0;
  respuesta = "";

  mounted() {
    if (
      this.$route.params.ver != undefined &&
      this.$route.params.crear != undefined
    ) {
      this.ver = this.$route.params.ver;
      this.crear = this.$route.params.crear;
    }
  }
  mostrar(cal, objeto) {
    objeto.calificacion = cal;
  }
  guardarJustificacion() {
    this.preguntas.pic_pregunta[
      this.posPregunta
    ].justificacion_respuesta = this.respuesta.trim();
    this.respuesta = "";
    this.juntificacion = false;
    this.guardarRespuestas();
  }
  guardarRespuestas() {
    this.$emit("respFormulario3", this.preguntas);
    this.$emit("respFormulario4", this.preguntas2);
  }
  preguntaCheck(respuesta: Pregunta) {
    if (respuesta.calificacion == 1) {
      respuesta.calificacion = 0;
    } else {
      respuesta.calificacion = 1;
    }
  }
}
