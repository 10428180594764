
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";


@Component({
  components: {
    AnimacionCargando,
  },
})
export default class ModificarFuncionario extends Vue {
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;
  estado = false;
estadoPeticionModificar= false;
  docValido = false;
  estadoPeticion = false;
  rutadocumento = null;
  verModal = false;
  docResolucon = "";
  docVista = null;

  Funcionario = {
    id_dirigido_a: "",
    nombre: "",
  };
  mounted() {
    this.obtenerFuncionario(this.$route.params.id);
  }

  // ------------------------------------------------------------------------
  obtenerFuncionario(id_dirigido_a) {
    Axios()
      .get("mostrar_pic_dirigida/" + id_dirigido_a)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.Funcionario = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        )
      })
          .finally(() => {
        this.estado = true;
      });
  }

  //----------------------------------------------------------------

  modificarFuncionario() {
    this.estadoPeticionModificar = true;
    Axios()
      .put(
        "actualizar_pic_dirigida/" +
          this.Funcionario.id_dirigido_a,
        this.Funcionario
      )
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire(
            "Se subió el Funcionario!",
            "Funcionario Modificado",
            respuesta.data.status
          );
          //   this.estadoPeticion = false;
          this.$router.push({ name: "GestionarFuncionarios" });
        } else {
          Swal.fire("ERROR!", "No Se Modifico el Funcionario", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(()=>{
        this.estadoPeticionModificar = false;
      });
  }
  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.modificarFuncionario();
      }
    });
  }
}
