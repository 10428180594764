<template>
  <div class="container-fluid">
    <div class="card my-4">
      <div
        class="border-radius-lg bg-gradient-primary card-header p-0 position-relative mt-n4 mx-3 z-index-2 shadow-3"
      >
        <div class="pt-4 pb-3">
          <h1 class="text-white text-capitalize ps-3 ms-3">
            ACTIVIDADES CENTRO DE FORMACIÓN
          </h1>
        </div>
      </div>
      <div class="card-body">
        <div class=" " id="contenedor_todo">
          <!--------------------------- BUSQUEDA DE ACTIVIDAD -------------------------->
          <div class="col-md-6 col-lg-5 col-xl-3 mt-2 pb-3" id="uno">
            <input
              action="search/"
              type="text"
              class="form-control"
              placeholder="Buscar Actividad"
              v-model="filtro"
              @keyup.prevent="filtrarActividad()"
            />
          </div>
          <!-------------------------------- FILTRO DE EJES ----------------------------->
          <div class="col-md-6 col-lg-5 col-xl-3 mt-2 pb-3" id="uno">
            <select
              class="form-control p-2"
              v-model="filtroEje"
              @change="filtrarEjes()"
            >
              <option value="" Disabled>Buscar por Eje</option>
              <option value="">Todas</option>
              <option v-for="(eje, key) in lista_Eje" :key="key">
                {{ eje.eje }}
              </option>
            </select>
          </div>
          <!------------------------------------- PAGINACIÓN --------------------------------->
          <paginate-links
            class="pagination mt-2 justify-content-end col-md-6"
            for="actividades"
            id="dos"
            :limit="2"
            :hide-single-page="true"
            :show-step-links="true"
            :full-page="true"
            :classes="{
              ul: 'simple-links-container',
              li: 'simple-links-item',
              liActive: ['simple-links-item', 'active1'],
              'li.active': 'active1',
            }"
          ></paginate-links>
        </div>
      </div>
      <!------------------------------------- ANIMACIÓN --------------------------------->
      <div class="text-center" v-if="estadoPeticion">
        <AnimacionCargando></AnimacionCargando>
      </div>
      <!-------------------------------- SELECCIONAR ACTIVIDAD CENTRO ----------------------------->
      <p class="font-weight-bold container-fluid">Selecione las actividades:</p>
      <template v-if="actividades != ''">
        <div class="table-responsive container-fluid">
          <paginate
            ref="paginator"
            name="actividades"
            :list="actividades"
            :per="10"
          >
            <table
              class="table table-hover table-inverse"
              v-if="!estadoPeticion"
            >
              <thead class="bg-gradient-azul-sena text-white" id="listado">
                <tr>
                  <th></th>
                  <th>EJE</th>
                  <th>ACTIVIDAD</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(actividad, key) in paginated('actividades')"
                  :key="key"
                >
                  <td>
                    <input
                      type="checkbox"
                      :id="actividad.id_actividad"
                      v-bind:value="actividad.id_actividad"
                      v-model="lista_id_actividad"
                    />
                  </td>
                  <td class="campos">
                    <div class="primera-seccion">{{ actividad.eje }}</div>
                  </td>
                  <td class="campos">
                    <div class="segunda-seccion">{{ actividad.actividad }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </paginate>
        </div>
      </template>
      <p v-else class="container-fluid">No hay actividades para seleccionar!</p>
      <paginate-links
      class="pagination mt-2 justify-content-end col-md-12"
      for="actividades"
      :limit="2"
      :hide-single-page="true"
      :show-step-links="true"
      :full-page="true"
      :classes="{
        ul: 'simple-links-container',
        li: 'simple-links-item',
        liActive: ['simple-links-item', 'active1'],
        'li.active': 'active1',
      }"
    ></paginate-links>
    </div>
    
    <div class="card-footer">
      <div class="row justify-content-center">
        <div class="col-lg-4 col-sm-12 mt-1" v-if="actividades != ''">
          <button
            type="azul-sena"
            @click.prevent="validarActividades()"
            class="btn btn-block bg-gradient-primary text-white shadow-3 p-2"
          >
            Seleccionar Actividad
          </button>
        </div>

        <div class="col-lg-4 col-sm-12 mt-1">
          <router-link
            class="btn btn-block bg-gradient-primary text-white shadow-3 p-2"
            :to="{
              name: 'GestionarActividadCentro',
            }"
          >
            Volver
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";

export default {
  components: {
    AnimacionCargando,
  },
  mounted() {
    this.cargarActividades();
    this.listaEjes();
  },

  data() {
    return {
      estadoPeticion: true,
      actividades: [],
      id_usuario: JSON.parse(sessionStorage.getItem("usuario")).id_usuario,
      lista_Eje: [],
      id_centro_formacion: JSON.parse(sessionStorage.getItem("usuario"))
        .id_centro_formacion,
      lista_id_actividad: [],
      paginate: ["actividades"],
      /* Filtro Actividades */
      /* 1 */ filtroActividad: [],
      /* 2 */ filtro: "",
      /* Filtro eje */
      /* 1 */ filtradoEje: [],
      /* 2 */ filtroEje: "",
    };
  },

  methods: {
    cargarActividades() {
      Axios()
        .get("crear_actividad_centro/" + this.id_usuario)
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            this.actividades = respuesta.data.results;
            this.activida = respuesta.data.results;
            this.estadoPeticion = false;
          }
          if (respuesta.data.status == "info") {
            Swal.fire({
              title: "Informacion",
              text: respuesta.data.message,
              icon: "warning",
              confirmButtonText: "Aceptar",
              customClass: {
                confirmButton: "btn btn-azul-sena",
              },
            });
            this.estadoPeticion = false;
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        });
    },
    almacenarActividad() {
      for (let i = 0; i < this.lista_id_actividad.length; i++) {
        const id_actividad = this.lista_id_actividad[i];
        Axios()
          .post("almacenar_actividad_centro", {
            id_actividad: id_actividad,
            id_centro_formacion: JSON.parse(sessionStorage.getItem("usuario"))
              .centro_formacion_id,
            id_usuario: JSON.parse(sessionStorage.getItem("usuario"))
              .id_usuario,
          })
          .then((respuesta) => {})
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
      }
      Swal.fire(
        "Actividades Registradas!",
        "Se ha registrado exitosamente",
        "success"
      );
      this.$router.push("GestionarActividadCentro");
    },

    validarActividades() {
      if (this.lista_id_actividad != "") {
        this.almacenarActividad();
      } else {
        Swal.fire({
          title: "Información",
          text: "Por favor seleccione una actividad",
          icon: "warning",
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "btn btn-azul-sena",
          },
        });
      }
    },

    filtrarActividad() {
      let filtroActividad = [];
      for (let activi of this.activida) {
        let nombre = activi.actividad.toLowerCase();
        if (nombre.indexOf(this.filtro) >= 0) {
          filtroActividad.push(activi);
        }
      }
      this.actividades = filtroActividad;
      if (this.actividades.length == 0) {
        //busco no encontro
        Swal.fire({
          title: "No hay coincidencias",
          position: "center",
          timer: 2000,
          text: "No se encontró convocatorias que coincidan con las busqueda",
          showConfirmButton: false,
          backdrop: "rgba(0,0,0,0)",
          background: "#eeeeee",
        });
        this.filtro = "";
        this.actividades = this.activida;
      }
    },

    filtrarEjes() {
      let filtradoEje = [];

      for (let nombreejes of this.activida) {
        let nombre = nombreejes.eje;
        if (nombre.indexOf(this.filtroEje) >= 0) {
          filtradoEje.push(nombreejes);
        }
      }
      this.actividades = filtradoEje;

      if (this.actividades.length == 0) {
        //busco no encontro
        Swal.fire({
          title: "No hay coincidencias",
          position: "center",
          timer: 1000,
          text: "No se encontró convocatorias que coincidan con las busqueda",
          showConfirmButton: false,
          backdrop: "rgba(0,0,0,0)",
          background: "#eeeeee",
        });
        this.filtroEje = "";
        this.actividades = this.activida;
      }
    },

    listaEjes() {
      Axios()
        .get("/listaEje")
        .then((respuesta) => {
          this.lista_Eje = respuesta.data.results;
        });
    },
  },
};
</script>

<style scoped>
#contenedor_todo {
  display: flex;
  flex-wrap: wrap;
}
.campos .primera-seccion {
  margin: 0%;
  width: 400px;
}
.campos .segunda-seccion {
  width: 450px;
}
</style>
