
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";
import axios from "axios";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class TrazabilidadActividad extends Vue {
  estadoPeticion = false;
  estado_eje = "";
  actividad = "";
  id_trazabilidad;
  estadoDeshabilitado = false;
  listaActividadEje: any[] = [];
  listaTrazabilidadActividad = [];
  trazabilidad = {
    trazabilidad_id: 0,
    asistencia: 0,
  };
  estrategia = "";

  mounted() {
    this.estado_eje = this.$route.params.id_eje;
    this.actividad = this.$route.params.id_actividad;
    this.verActividadTrazabilidad();
    this.obtenerTrazabilidadActividad();
    this.guardarEstrategia();
  }

  aumentar(posicion) {
    // alert("aaaa")
    this.listaActividadEje[posicion].asistencia =
      this.listaActividadEje[posicion].asistencia + 1;
  }

  verActividadTrazabilidad() {
    Axios()
      .get("ver_trazabilidad_eje/" + this.estado_eje)
      .then((respuesta) => {
        this.listaActividadEje = respuesta.data.results;
        // this.listaActividadEje[index].asistencia = "";

        // this.id_trazabilidad = respuesta.data.results[0].id_trazabilidad;
        for (let i = 0; i < this.listaActividadEje.length; i++) {
          this.listaActividadEje[i].asistencia = 0;
        }
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }
  //
  registrarTrazabilidad() {
    for (let i = 0; i < this.listaActividadEje.length; i++) {
      this.trazabilidad.asistencia = this.listaActividadEje[i].asistencia;
      this.trazabilidad.trazabilidad_id =
        this.listaActividadEje[i].id_trazabilidad;
      Axios()
        .post("almacenar_trazabilidad_actividad", {
          actividad_id: this.$route.params.id_actividad,
          trazabilidad_id: this.trazabilidad.trazabilidad_id,
          asistencia: this.trazabilidad.asistencia,
        })
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            Swal.fire({
              title: "Trazabilidad Guardada",
              text: "Se guardo la Trazabilidad",
              icon: "success",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
            this.$router.push({ name: "GestionarActividadCentro" });
          } else {
            Swal.fire("ERROR!", "No se Guardo la Trazabilidad", "error");
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        });
    }
  }
  guardarEstrategia() {
    Axios()
      .post("estrategia_centro", {
        id_actividad: this.$route.params.id_actividad,
        estrategia_implementada: this.estrategia,
      })
      .then((respuesta) => {
        // respuesta.data.results;
        console.log("Estrategia", respuesta);
      });
  }

  obtenerTrazabilidadActividad() {
    Axios()
      .get("listar_trazabilidad_actividad/" + this.actividad)
      .then((respuesta) => {
        this.listaTrazabilidadActividad = respuesta.data.results;
        if (this.listaTrazabilidadActividad == null) {
          this.estadoDeshabilitado = false;
        } else {
          this.estadoDeshabilitado = true;
          Swal.fire({
            title: "Trazabilidad Ya Guardada",
            text: "No se puede volver a guardar",
            icon: "info",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.$router.push({ name: "GestionarActividadCentro" });
        }
      });
  }

  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        // el formulario se validó y tiene los datos que corresponden por campo
        this.registrarTrazabilidad();
        this.guardarEstrategia();
      }
    });
  }
}
