
  import { Vue } from "vue-property-decorator";
  import Component from "vue-class-component";
  import Axios from "@/Axios";
  import Swal from "sweetalert2";
  import AnimacionCargando from "@/components/animacionCargando.vue";

  @Component({
    components: {
      AnimacionCargando,
    },
  })
  export default class VerDetalleFuncionario extends Vue {
    estado = false;

    funcionario = {
      id_dirigido_a: "",
      nombre: "",
    };

    mounted() {
      this.obtenerFuncionario(this.$route.params.id);
    }
    obtenerFuncionario(id) {
      Axios()
        .get("mostrar_pic_dirigida/" + id)
        .then((respuesta) => {

          this.funcionario = respuesta.data.results;

        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        })
        .finally(() => {
          this.estado = true;
        });
    }
  }
