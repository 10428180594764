
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import AnimacionCargando from "../../../components/animacionCargando.vue";
import AnimacionTablasCargando from "../../../components/animacionTablasCargando.vue";

@Component({
  components: {
    AnimacionCargando,
    AnimacionTablasCargando,
  },
})
export default class listaProgramasProyectados2 extends Vue {
  listaProgramasProyectados = [];
  listaProgramasProyectadosFiltrado: any[] = [];
  listaEstadosProgramas: any[] = [];
  programaColegio: any[] = [];
  programaColegioFiltrado: any[] = [];

  estadoPeticion = false;
  idConvenio = null;
  filter = "";
  paginate = ["listaProgramasProyectadosFiltrado", "programaColegioFiltrado"];

  estadoPeticionTabla = false;
  id_convenio = null;
  estadoPrograma = null;

  Programa = {
    id_colegio_programa: "",
    colegio_id: "",
    programa_formacion_id: "",
    convenio_id: "",
  };

  programasProyectados = {
    centro_formacion_id: "",
    colegio_cargo: "",
    colegio_id: "",
    convenio_id: "",
    fase_id: "",
    fecha_final: "",
    fecha_firma: "",
    fecha_inicio: "",
    id_convenio_programa_proyectado: "",
    numero_convenio: "",
    programa_formacion: "",
    programa_formacion_id: "",
    regional_id: "",
    sena_cargo: "",
    sena_persona_id: "",
  };

  convenio = {
    convenio: "",
    id_convenio: "",
    regional_id: "",
    regional: "",
    id_centro_formacion: "",
    centro_formacion: "",
    fase_id: "",
    fase: "",
    sena_persona_id: "",
    numero_convenio: "",
    fecha_firma: "",
    fecha_inicio: "",
    fecha_final: "",
    sena_cargo: "",
    colegio_cargo: "",
    minuta: "",
  };

  mounted() {
    this.obtenerConvenio(this.$route.params.id_convenio);
    this.obtenerProgramas();
    this.id_convenio = this.$route.params.id_convenio;
    this.obtenerColegioPrograma();
  }
  ProgramaFiltrado() {
    let picFilter = [];
    // this.listaConvocatoriasFiltrado = []

    for (let programa of this.listaProgramasProyectados) {
      let programaP = programa.programa_formacion.toLowerCase();

      if (programaP.indexOf(this.filter) >= 0) {
        picFilter.push(programa);
      }
    }

    this.listaProgramasProyectadosFiltrado = picFilter;
    if (this.listaProgramasProyectadosFiltrado.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró la capacitación, que coincida con la búsqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#238276",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filter = "";
      this.listaProgramasProyectadosFiltrado = this.listaProgramasProyectados;
    }
  }
  obtenerProgramas() {
    Axios()
      .get("listar_programa_aprobado/" + this.$route.params.id_convenio)
      .then((respuesta) => {
        this.programasProyectados = respuesta.data.results;
        this.listaProgramasProyectados = respuesta.data.results;
        this.listaProgramasProyectadosFiltrado = respuesta.data.results;
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }
  obtenerConvenio(id) {
    Axios()
      .get("ver_detalle_convenio/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.convenio = respuesta.data.results;
        }
      });
  }

  cambiarFaseConvenio() {
    if (this.listaProgramasProyectadosFiltrado == null) {
      Swal.fire({
        title: "Informacion",
        text: "Para Sensibilizar debe tener almenos 1",
        icon: "warning",
        confirmButtonText: "Aceptar",
        customClass: {
          confirmButton: "btn btn-azul-sena",
        },
      });
    } else {
      Axios()
        .put("modificar_fase_convenio/" + this.id_convenio, {
          id_convenio: this.id_convenio,
          fase_id: 5,
          id_actualiza: JSON.parse(sessionStorage.getItem("usuario")),
        })
        .then(() => {
          Swal.fire({
            title: "Cambio de fase correcto!",
            text: "El Convenio pasó a viabilidad exitosamente!",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
            icon: "success",
          });
          this.$router.push("GestionarConvenio");
        })
        .catch(() => {
          Swal.fire({
            title: "ERROR",
            text: "No se puede cambiar de fase",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
            icon: "error",
          });
        });
    }
  }
  eliminarColegioPrograma(id, index) {
    Swal.fire({
      title: "Eliminar Programa ",
      text:
        this.programaColegioFiltrado[index].programa_formacion
          .programa_formacion + "?",
      icon: "warning",
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((respuesta) => {
      if (respuesta.isConfirmed) {
        Axios()
          .delete("eliminar_colegio_programa/" + id)
          .then((respuesta) => {
            if (respuesta.data.status == "success") {
              Swal.fire({
                title: "Programa Eliminado",
                icon: "success",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#007bb8",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.programaColegioFiltrado.splice(index, 1);
            } else {
              Swal.fire({
                title: "No se pudo eliminar el Programa Proyectado",
                text: respuesta.data.message,
                icon: "error",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.$router.back();
            }
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }

  cambiarEstadoPrograma(id_convenio_programa_proyectado, estado) {
    // alert(id_convenio_programa_proyectado)
    Axios()
      .post("modificar_estado_aprobado", {
        id_convenio_programa_proyectado: id_convenio_programa_proyectado,
        estado_programa_proyectado_id: estado,
        // id_actualiza: JSON.parse(sessionStorage.getItem("usuario")),
      })
      .then(() => {
        Swal.fire({
          title: "Cambio de fase correcto!",
          text: "El Convenio pasó a Sensibilizar programa exitosamente!",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#007bb8",
          icon: "success",
        });
        this.$router.push({ name: "GestionarProgramasAprobados" });
        this.obtenerProgramas();
      })
      .catch(() => {
        Swal.fire({
          title: "ERROR",
          text: "No se puede cambiar de fase",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#007bb8",
          icon: "error",
        });
      });
  }

  registrarColegioProgramaConvenio(id_programa_formacion, programa_formacion) {
    Axios()
      .post("almacenar_colegio_programa", {
        colegio_id: this.$route.params.id_colegio,
        convenio_id: this.$route.params.id_convenio,
        programa_formacion_id: id_programa_formacion,
      })
      .then((respuesta) => {
        //200->exitosas
        if (respuesta.data.status.toLowerCase() == "success") {
          Swal.fire({
            title: "Se vinculo el programa!",
            text:
              "El programa " +
              programa_formacion +
              " se vinculo exitosamente al colegio",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
            icon: "success",
          });
        } else {
          Swal.fire({
            title: "No se vinculo el programa",
            text: respuesta.data.message,
            icon: "error",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
            customClass: {
              confirmButton: "btn btn-azul-sena",
            },
          });
        }
      })
      .catch(() => {
        //500->error deservidor
        //400->error del cliente
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  obtenerColegioPrograma() {
    Axios()
      .get("/listar_colegio_programa/" + this.id_convenio)
      .then((respuesta) => {
        this.programaColegio = respuesta.data.results;
        this.programaColegioFiltrado = respuesta.data.results;

        if (respuesta.data.status == "success") {
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {});
  }
}
