
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import ReportesPICConvocatoria from "@/components/ReportesEni/ReportesPICConvocatoria.vue";
import ReportesInstructores from "@/components/ReportesEni/ReportesInstructores.vue";
import ReportesPresupuesto from "@/components/ReportesEni/ReportesPresupuesto.vue";

@Component({
  components: {
    ReportesPICConvocatoria,
    ReportesInstructores,
    ReportesPresupuesto,
  },
})
export default class IndexReportes extends Vue {
  esReporteConvocatoria = false;
  esReporteInstructores = false;
  esReportePresupuesto = false;

  constructor() {
    super();
  }

  cambiarEstadosComponentes() {
    this.esReporteConvocatoria = false;
    this.esReporteInstructores = false;
    this.esReportePresupuesto = false;
  }
}
