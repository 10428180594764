var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-5 container-fluid"},[_c('div',{staticClass:"card"},[_vm._m(0),(!_vm.estado)?_c('div',[_c('AnimacionCargando')],1):_vm._e(),(_vm.estado)?_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"col-lg-8 col-md-10 col-sm-12 offset-lg-2 offset-md-1 mt-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6"},[_vm._m(1),_c('div',{attrs:{"id":"contenido"}},[_c('p',{attrs:{"id":"texto"}},[_vm._v(" "+_vm._s(_vm.Entidad_Capacitadora.id_pic_entidad_capacitadora)+" ")])])]),_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6"},[_vm._m(2),_c('div',{attrs:{"id":"contenido"}},[_c('p',{attrs:{"id":"texto"}},[_vm._v(" "+_vm._s(_vm.Entidad_Capacitadora.entidad_capacitadora)+" ")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6"},[_vm._m(3),_c('div',{attrs:{"id":"contenido"}},[_c('p',{attrs:{"id":"texto"}},[_vm._v(" "+_vm._s(_vm.Entidad_Capacitadora.nit)+" ")])])]),_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6"},[_vm._m(4),_c('div',{attrs:{"id":"contenido"}},[_c('p',{attrs:{"id":"texto"}},[_vm._v(" "+_vm._s(_vm.Entidad_Capacitadora.direccion)+" ")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6"},[_vm._m(5),_c('div',{attrs:{"id":"contenido"}},[_c('p',{attrs:{"id":"texto"}},[_vm._v(" "+_vm._s(_vm.Entidad_Capacitadora.correo)+" ")])])]),_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6"},[_vm._m(6),_c('div',{attrs:{"id":"contenido"}},[_c('p',{attrs:{"id":"texto"}},[_vm._v(" "+_vm._s(_vm.Entidad_Capacitadora.numero_telefono)+" ")])])])])])]):_vm._e(),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-4 col-sm-12 mt-1"},[_c('router-link',{staticClass:"btn btn-block bg-azul-sena text-white shadow-3 p-2",attrs:{"to":{
              name: 'GestionarEntidadCapacitadora',
            }}},[_vm._v(" Volver ")])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"border-radius-lg bg-gradient-primary card-header p-0 position-relative mt-n4 mx-3 z-index-2 shadow-3 P-2"},[_c('div',{staticClass:"pt-4 pb-3"},[_c('h1',{staticClass:"text-white text-capitalize ps-3 ms-3 title-header"},[_vm._v(" ENTIDAD CAPACITADORA ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v("Id Entidad Capacitadora :")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v("Entidad Capacitadora:")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v("Nit:")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v("Dirección:")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v("Correo:")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v("Número teléfono:")])])
}]

export { render, staticRenderFns }