
  import { Vue } from "vue-property-decorator";
  import Component from "vue-class-component";
  import Axios from "@/Axios";
  import Swal from "sweetalert2";

  @Component({
    components: {
    },
  })
  export default class Sensibilizacion extends Vue {
    id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;
    id_convenio = null;

    estadoPeticion = false;
    lista_prioridad_uno: any[] = [];
    lista_convenio: any[] = [];

    sensibilizacion = {
      id_sensibilizacion_convenio: "",
      fecha_socilizacion: "",
      resultados: "",
      compromisos: "",
      prioridad_uno: "",
      prioridad_dos: "",
      cantidad_asistentes: "",
      convenio_id: "",
    };

    idSensibilizacion = "";

    mounted() {
      this.sensibilizacion.convenio_id = this.$route.params.id_convenio;
      this.obtenerConvenio();
      this.obtenerRedConocimiento();
    }
    obtenerConvenio() {
      Axios()
        .get("listar_convenio")
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            this.lista_convenio = respuesta.data.results;
          }
        });
    }

    obtenerRedConocimiento() {
      Axios()
        .get("listar_red_conocimiento")
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            this.lista_prioridad_uno = respuesta.data.results;
          }
        });
    }

    registrarSensibilizacion() {
      this.estadoPeticion = true;
      Axios()
        .post("almacenar_sensibilizacion", this.sensibilizacion)
        .then((respuesta) => {
          if ((respuesta.data.status = "success")) {            
            // this.modalBuscarColegio = false;
            Swal.fire({
              title: "Sensibilizacion Agregada",
              text: "Se Agrego la Viabilidad",
              icon: "success",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });

            this.idSensibilizacion = respuesta.data.results.id_sensibilizacion_convenio;

            this.$emit('envioSensibilizacion', this.idSensibilizacion);
            
            // this.$router.push({ name: "GestionarViabilidad" });
          } else {
            Swal.fire("ERROR!", "No se Agrego La Sensibilizacion", "error");
          }
          // this.estadoPeticion = false;
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        });
    }
    
    constructor() {
      super();
      const dict = {
        messages: {
          required: () => "Campo obligatorio",
          date_format: () => "Campo obligatorio",
          decimal: () => "Campo obligatorio",
          min_value: () => "El valor no puede ser menor a cero",
          email: () => "El correo no es válido",
          alpha_spaces: () => "No permite numeros ni caracteres especiales",
          alpha_num: () => "No permite caracteres especiales",
        },
      };
      this.$validator.localize("es", dict);
    }

    validarFormulario() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          // el formulario se validó y tiene los datos que corresponden por campo
          this.registrarSensibilizacion();
        }
      });
    }
  }
