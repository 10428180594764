
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";
import CrearIndicador from "../../../components/ModuloEgresados/RegistrarIndicador.vue";

@Component({
  components: {
    CrearIndicador,
    AnimacionCargando,
  },
})
export default class ListarActividades extends Vue {
 



 
}
