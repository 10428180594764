
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../../components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class Idioma extends Vue {
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;

  listarIdiomas: any[] = [];
  listarIdiomasString: any[] = [];

  lista_idiomas: any[] = [];

  docValido = false;
  estadoPeticion = false;
  rutadocumento = null;
  verModal = false;

  inactivo = false;

  contIdiomaGuardada = 0;

  idiomas = {
    id_idioma_persona: "",
    persona_id: "",
    idioma_id: "",
    idioma: "",
    institucion: "",
    nivel: "",
    certificado: null,
  };

  mounted() {
    this.obtenerIdiomas();
    this.obtenerIdiomaPersona(this.$route.params.id);
  }
  // voy a intentar mostrar en boton el pdf
  mirarModal() {
    this.verModal = true;
  }
  // ------------------------------------------------------------------------
  //-----------------------------------------------------------
  obtenerDocumento(event) {
    if (event.target.files[0].size <= 5000000) {
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.idiomas.certificado = event.target.files[0];
        Swal.fire(
          "Certificación Cargada!",
          "Archivo cargado exitosamente!",
          "success"
        );
        this.docValido = true;
      } else {
        this.docValido = false;
        this.idiomas.certificado = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.idiomas.certificado = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  }
  //--------------------------------------------------------------------
  // Roa y edier eliminar
  //--------------------------------------------------------------------
  obtenerIdiomas() {
    Axios()
      .get("crear_idiomas_persona")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_idiomas = respuesta.data.results.idiomas;
        } else {
          Swal.fire("ERROR!", "No se han encontrado idiomas", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  //--------------------------------------------------------------------
  obtenerIdiomaPersona(id) {
    Axios()
      .get("listar_item_idiomas_persona/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.idiomas = respuesta.data.results;
          this.mostrarDocumento(id);
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Hubo un problema en el servidor",
          icon: "error",
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "#238276",
          },
        });
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }
  //--------------------------------------------------------------------
  mostrarDocumento(id_idioma_persona) {
    this.rutadocumento =
      this.$urlAPI + "visualizar_certificado_idioma_persona/" +
      id_idioma_persona;
  }

  //--------------------------------------------------------------------

  //--------------------------------------------------------------------

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "El valor no puede ser menor a cero",
        email: () => "El correo no es válido",
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }
}
