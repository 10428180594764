var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-5 container-fluid"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[(!_vm.estadoPeticionTabla)?_c('div',{staticClass:"text-center"},[_c('AnimacionTablasCargando')],1):_vm._e(),(_vm.estadoPeticionTabla)?_c('div',{staticClass:"table-responsive"},[_c('div',{staticClass:"col-9 mb-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"form-control col-lg-3 col-sm-10",attrs:{"type":"text","placeholder":"Buscar"},domProps:{"value":(_vm.filter)},on:{"keyup":function($event){$event.preventDefault();return _vm.trazabilidadFiltrado()},"input":function($event){if($event.target.composing)return;_vm.filter=$event.target.value}}}),_c('br')]),_c('table',{staticClass:"table table table-hover table-inverse"},[_vm._m(1),_c('tbody',_vm._l((_vm.listaTrazabilidad),function(trazabilidad,key){return _c('tr',{key:key},[_c('td',[_vm._v(" "+_vm._s(trazabilidad.trazabilidad.charAt(0).toUpperCase() + trazabilidad.trazabilidad.slice(1))+" ")]),_c('td',[_vm._v(" "+_vm._s(trazabilidad.eje)+" ")]),_c('td',{staticClass:"campos"},[_c('span',{attrs:{"tooltip":"Ver detalle Trazabilidad","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"to":{
                      name: 'VerDetalleTrazabilidades',
                      params: {
                        id: trazabilidad.id_trazabilidad,
                      },
                    }}},[_c('i',{staticClass:"fas fa-eye"})])],1),_c('span',{attrs:{"tooltip":"Modificar Trazabilidad","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"to":{
                      name: 'ModificarTrazabilidad',
                      params: {
                        id: trazabilidad.id_trazabilidad,
                      },
                    }}},[_c('i',{staticClass:"fas fa-edit"})])],1),_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Eliminar Eje","tooltip-flow":"top"}},[_c('button',{staticClass:"btn btn-sm bg-oscuro-sena text-white",on:{"click":function($event){return _vm.eliminarTrazabilidad(trazabilidad.id_trazabilidad, key)}}},[_c('i',{staticClass:"fas fa-trash"})])])])])}),0)])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-header"},[_c('h1',{staticClass:"text-azul-sena"},[_vm._v("LISTA TRAZABILIDAD EGRESADOS")]),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('thead',{staticClass:"bg-gradient-naranja-sena text-white",attrs:{"id":"listado"}},[_c('tr',[_c('th',{staticClass:"text-nowrap"},[_vm._v("Trazabilidad")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Eje")]),_c('th',[_vm._v("Acciones")])])])
}]

export { render, staticRenderFns }