
  import { Vue } from "vue-property-decorator";
  import Component from "vue-class-component";
  import Axios from "@/Axios";
  import Swal from "sweetalert2";
  import Docs from "@/components/Documentos/Documento.vue";

  @Component({
    components: {
      Docs,
    },
  })
  export default class Certificacion extends Vue {
    inactivo = false;
    estadoPeticion = false;
    docsPadre: any[] = [];
    paginaFormulario: number = 0;
    viabilidadPadre = "";

    docValido = false;
    docCertificacion = {
      convenio_id: "",
      colegio_id: "",
      seguimiento_inicial: "",
      seguimiento_final: "",
    };
    docCertificacionDocumentos = {
      certificacion_id: "",
      documento_id: "",
    };

    validarDocCertificado = "";
    id_certificacion = "";

    moverPantalla() {
      window.scrollTo(0, 0);
    }

    envioDocsMetodo(data) {
      this.docsPadre = data;
    }
    envioCertificacionMetodo(data) {
      this.viabilidadPadre = data;

    }
    mounted() {
      this.docCertificacion.convenio_id = this.$route.params.id_convenio;
      this.docCertificacion.colegio_id = this.$route.params.id_colegio;
    }


    obtenerSeguimientoInicial(event) {
      if (event.target.files[0].size <= 5000000) {
        let extDoc = event.target.files[0].name
          .toString()
          .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
        if (extDoc == ".pdf") {
          this.docCertificacion.seguimiento_inicial = event.target.files[0];
          Swal.fire(
            "Certificación Cargada!",
            "Archivo cargado exitosamente!",
            "success"
          );
          this.docValido = true;
          // this.docResolucon = null;
        } else {
          this.docValido = false;
          this.docCertificacion.seguimiento_inicial = null;
          Swal.fire(
            "Algo salió mal!",
            "El archivo cargado no es un PDF!",
            "error"
          );
        }
      } else {
        this.docValido = false;
        this.docCertificacion.seguimiento_inicial = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado pesa más de 5 MegaBytes!",
          "error"
        );
      }
    }
    obtenerSeguimientoFinal(event) {
      if (event.target.files[0].size <= 5000000) {
        let extDoc = event.target.files[0].name
          .toString()
          .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
        if (extDoc == ".pdf") {
          this.docCertificacion.seguimiento_final = event.target.files[0];
          Swal.fire(
            "Certificación Cargada!",
            "Archivo cargado exitosamente!",
            "success"
          );
          this.docValido = true;
        } else {
          this.docValido = false;
          this.docCertificacion.seguimiento_final = null;
          Swal.fire(
            "Algo salió mal!",
            "El archivo cargado no es un PDF!",
            "error"
          );
        }
      } else {
        this.docValido = false;
        this.docCertificacion.seguimiento_final = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado pesa más de 5 MegaBytes!",
          "error"
        );
      }
    }
    registrarCertificacion() {
      this.estadoPeticion = true;
      var data = new FormData();
      data.append("colegio_id", this.docCertificacion.colegio_id);
      data.append("convenio_id", this.docCertificacion.convenio_id);
      data.append(
        "seguimiento_inicial",
        this.docCertificacion.seguimiento_inicial
      );
      data.append("seguimiento_final", this.docCertificacion.seguimiento_final);

      const headers = {
        "Content-Type": "multipart/form-data",
      };
      Axios()
        .post("almacenar_certificacion", data, {
          headers: headers,
        })
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            Swal.fire({
              title: "Programa Proyectado Guardado",
              icon: "success",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
            this.validarDocCertificado = respuesta.data.results;
            this.id_certificacion = respuesta.data.results.id_certificacion;
            //   this.estadoPeticion = false;
          } else {
            Swal.fire({
              title: "Programa proyectado no guardado",
              text: respuesta.data.message,
              icon: respuesta.data.status.toLowerCase(),
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        });
    }

    validarFormulario() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          // el formulario se validó y tiene los datos que corresponden por campo
          this.registrarCertificacion();
        }
      });
    }
    registrarCertificacionDocumenos() {
      for (let i = 0; i < this.docsPadre.length; i++) {
        this.docCertificacionDocumentos.certificacion_id = this.id_certificacion;
        this.docCertificacionDocumentos.documento_id = this.docsPadre[i].id_documento;

        Axios()
          .post("almacenar_certificacion_documento", this.docCertificacionDocumentos)
          .then((respuesta) => {
            if (this.docCertificacionDocumentos.documento_id != "") {
              if ((respuesta.data.status = "success")) {
                // this.modalBuscarColegio = false;
                Swal.fire({
                  title: "Viabilidad Guardada",
                  text: "Se guardo la Viabilidad",
                  icon: "success",
                  confirmButtonText: "Aceptar",
                  confirmButtonColor: "#007bb8",
                });
                // this.$router.push({ name: "GestionarViabilidad" });
                this.$router.push({ name: "GestionarConvenio" });
              } else {
                Swal.fire("ERROR!", "No se Guardo la Certificacion", "error");
              }
            } else {
            }
            // this.estadoPeticion = false;
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
      }
    }
    moverFormulario() {
      this.moverPantalla();
      if (this.validarDocCertificado == "") {
        this.paginaFormulario == 0;
        Swal.fire({
          title: "Faltan Datos",
          icon: "warning",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#007bb8",
        });
      } else {
        this.$validator.validate().then((valid) => {
          if (valid) {
            // el formulario se validó y tiene los datos que corresponden por campo
            this.registrarCertificacion();
          }
        });
        this.paginaFormulario += 1;
      }
    }
  }
