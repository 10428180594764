
import Axios from "@/Axios";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import Swal from "sweetalert2";
import XLSX from "xlsx";
import BarChart from "@/components/Charts/BarChart";
import Chart from "chart.js";

@Component({
  components: {
      BarChart,
  },
})
export default class listaRepresentantes extends Vue {
  canvas = document.getElementsByTagName("canvas");
  verGraficas = false;
  listaCentros = [];
  listaRegionales = [];
  representantes: any[] = [];
  representanteFiltrado: any = [];
  paginate = ["representanteFiltrado"];
  filterRegional = null;
  filterCentro = null;
  filterFase = "";
  contHombres = 0;
  contMujeres = 0;
  regional = null;
 

  id_regional = null;

  mounted() {
    this.obtenerRegionales();
    this.obtenerRepresentantes();
  }
  obtenerRegionales() {
    Axios()
      .get("listar_regionales")

      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listaRegionales = respuesta.data.results;
        } else {
          Swal.fire({
            title: "Hubo un error obteniendo los Representantes",
            icon: respuesta.data.status,
            text: respuesta.data.message,
            confirmButtonText: "Aceptar",
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
obtenerRepresentantes() {
    Axios()
      .get("reporte_representante")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.representantes = respuesta.data.results;
          this.representanteFiltrado = respuesta.data.results;
          let arregloFiltrado = [];

          for (let rep of this.representantes) {
            let regional = rep.regional;
            if (
              regional.indexOf(
                JSON.parse(sessionStorage.getItem("usuario")).regional
              ) >= 0
            ) {
              arregloFiltrado.push(rep);
            }
            this.representantes = arregloFiltrado;
            this.representanteFiltrado = arregloFiltrado;
          }
          if (this.representanteFiltrado.length == 0) {
            //busco no encontro
            Swal.fire({
              title: "No hay coincidencias",
              position: "center",
              timer: 1000,
              text: "No se encontró Representantes que coincidan con las busqueda",
              showConfirmButton: false,
              // confirmButtonText: "Aceptar",
              // confirmButtonColor: "#238276",
              backdrop: "rgba(0,0,0,0)",
              background: "#eeeeee",
            });
            this.regional = null;
            this.representanteFiltrado = this.representantes;
          }
          this.generarContadoresPorFase();
        } else {
          Swal.fire({
            title: "Hubo un error obteniendo los Representantes",
            icon: respuesta.data.status,
            text: respuesta.data.message,
            confirmButtonText: "Aceptar",
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  buscarCentrosSegunRegional(id_regional) {
    Axios()
      .get("listar_centro_formacion_regional/ " + id_regional)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listaCentros = respuesta.data.results;
        } else {
          Swal.fire({
            title: "Hubo un error obteniendo los Representantes",
            icon: respuesta.data.status,
            text: respuesta.data.message,
            confirmButtonText: "Aceptar",
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  picFiltrado() {
    let arregloFiltrado = [];
  
    for (let adj of this.representantes) {
      let regional = adj.regional;

      if (regional.indexOf(this.filterRegional.regional) >= 0) {
        arregloFiltrado.push(adj);
      }
    }

    this.representanteFiltrado = arregloFiltrado;
    if (this.representanteFiltrado.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró Representantes que coincidan con las busqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#238276",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      // this.filter = "";
      this.representanteFiltrado = this.representantes;
    }
  }
  filtroCentro() {
    let arregloFiltrado = [];

    for (let rep of this.representantes) {
      let centro = rep.centro_formacion;
      if (centro.indexOf(this.filterCentro) >= 0) {
        arregloFiltrado.push(rep);
      }
      this.representanteFiltrado = arregloFiltrado;
    }
    if (this.representanteFiltrado.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró Representantes que coincidan con las busqueda",
        showConfirmButton: false,
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#007bb8",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filterCentro = null;
      this.representanteFiltrado = this.representantes;
    }
  }

  exportExcel() {
    let data = XLSX.utils.json_to_sheet(this.representanteFiltrado, {
      header: [
        "regional",
        "centro formacion",
      
      ],
    });
      (data["A1"].v = "regional"),
      (data["B1"].v = "centro formacion");
     
     
    const workbook = XLSX.utils.book_new();
    const filename =
      "Reporte Representante " +
      new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1);
    XLSX.utils.book_append_sheet(workbook, data, filename);
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  }
    generarContadoresPorFase() {
    for (let i of this.representantes) {
      if (i.genero_id == "M") {
        this.contHombres = this.contHombres + 1;
      }
      if (i.genero_id == "F") {
        this.contMujeres = this.contMujeres + 1;
      }
    }
}
}
