
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import Multiselect from "vue-multiselect";
import AnimacionCargando from "../../../components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
    // Multiselect,
  },
})
export default class ResgitrarTrazabilidad extends Vue {
  // id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;

  trazabilidad = {
    eje: "",
    id_eje: "",
    id_trazabilidad: "",
    trazabilidad: "",
  };

  estadoPeticion = false;

  //   picEje = {
  //     id_eje: "",
  //     eje: "",
  //   };

  //   listaEjes: any[] = [];

  async mounted() {
    this.obtenerListaTrazabilidad(this.$route.params.id);
  }

  async obtenerListaTrazabilidad(id_trazabilidad) {
    Axios()
      .get("ver_detalle_pane_trazabilidad/" + id_trazabilidad)
      .then((respuesta) => {
        this.trazabilidad = respuesta.data.results;
        this.estadoPeticion = false;
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }

  async modificarTrazabilidad() {
    Axios()
      .put(
        "actualizar_pane_trazabilidad/" + this.trazabilidad.id_trazabilidad,
        { trazabilidad: this.trazabilidad.trazabilidad }
      )
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Trazabilidad modificada",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          //   this.estadoPeticion = false;
          this.$router.push({ name: "GestionarTrazabilidades" });
        } else {
          Swal.fire("ERROR!", "No se modifico trazabilidad", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.modificarTrazabilidad();
      }
    });
  }
}
