
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class Login extends Vue {
  esAdjudicado = false;
  noAprobado = false;
  btnIniciarValidaciones = true;
  idPersonaModal = "";
  estadoPeticion = true;
  listaInscripciones = [];
  paginate = ["listaInscripciones"];
  filter = "";
  fecha_pic = "2021";
  fechas_pic = [];
  listaAdjudicados: any = [];
  id_persona = null;
  adjudicar = false;
  nombre = "";
  observacion = null;
  convocatoria = {
    convocatoria: "",
    fecha_inicio: "",
    fecha_final: "",
    lugar: "",
    perfiles_requisitos: "",
    financiacion: "",
    id_pic_convocatoria: "",
    para_funcionarios: "",
    pertenece_a_red: "SI", //dato quemado
    pic_id: "",
    plan_institucional_id: "",
    id_pic: "",
    pic_fase_id: 0,
    id_usuario_autenticado: "",
    nacional: "",
    id_municipio: "",
  };
  modalAdjudicar = false;

  verDetalle = null;

  idPersonaActual = null;
  idInscripcionActual = null;

  requisitosInstructor = null;
  rutadocumento = null;
  modalRequisito = false;
  inscritoPersonaActual = {
    correo: "",
    id_persona: null,
    identificacion: null,
    primer_apellido: "",
    primer_nombre: "",
    segundo_apellido: "",
    segundo_nombre: "",
  };

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        min: () => "Mínimo 8 caracteres",
        // email: () => "Correo invalido",
      },
    };
    this.$validator.localize("es", dict);
  }

  mounted() {
    this.obtenerPic(this.$route.params.id);
    this.obtenerInscritos();
  }

  mostrarDocumento(id) {
    this.rutadocumento =
      this.$urlAPI + "visualizar_requisito_pic/" + id;
  }

  obtenerRequisitos(idPersona, idInscripcion, inscrito) {
    // alert(idPersona + " " + idInscripcion);
    Axios()
      .post("obtener_rutas_requisitos", {
        id_persona: idPersona,
        id_pic_inscripcion: idInscripcion,
      })
      .then((respuesta) => {
        this.requisitosInstructor = respuesta.data.results;
        // recorrer pdf de in idPersona
        // this.mostrarDocumento(this.requisitosInstructor[0].id_requisito);

        for (let i = 0; i < this.requisitosInstructor.length; i++) {
          this.mostrarDocumento(this.requisitosInstructor[i].id_requisito);
        }
        this.inscritoPersonaActual = inscrito.persona;
      })
      .catch((err) => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  obtenerInscritos() {
    let id = this.$route.params.id;
    Axios()
      .get("listar_inscripciones_pic/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listaInscripciones = respuesta.data.results;
          console.log("data");
          console.log(this.listaInscripciones);

          this.estadoPeticion = false;
        } else {
          Swal.fire({
            title: "Hay un problema",
            text: respuesta.data.message,
            icon: "error",
          });
        }
      })
      .catch(() => {
        this.$router.push("/GestionarConvocatoriaENI");
      });
  }

  // verDatos() {
  //   this.nombre = this.$route.params.persona;
  // }

  obtenerPic(id) {
    Axios()
      .get("editar_convocatoria_pic/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.convocatoria = respuesta.data.results;
        }
        // else {
        //   Swal.fire({
        //     title: "Hay un problema",
        //     text: respuesta.data.message,
        //     icon: "error"
        //   });
        //   this.$router.push("GestionarConvocatoriaENI");
        // }
      });
  }

  adjudicarInstructorConvocatoria(id, esAprobado) {
    // alert(id);
    Axios()
      .post("adjudicar_persona_pic", {
        id_pic_convocatoria: this.$route.params.id,
        id_persona: id,
        id_usuario_autenticado: JSON.parse(sessionStorage.getItem("usuario"))
          .id_usuario,
        //observaciones pedir servicio aquí
        aprobado: esAprobado,
        observaciones: this.observacion,
      })
      .then((respuesta) => {
        if (
          respuesta.data.message != "El aprendiz ya no se encuentra adjudicado"
        ) {
          if (this.esAdjudicado) {
            Swal.fire({
              title: "Usuario " + this.nombre + " adjudicado",
              icon: "success",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
          }
          if (!this.esAdjudicado) {
            Swal.fire({
              title: "Usuario " + this.nombre + " desadjudicado",
              icon: "success",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
          }
          if (this.noAprobado) {
            Swal.fire({
              title: "Usuario " + this.nombre + " no aprobado",
              icon: "success",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
            this.noAprobado = false;
          }

          this.adjudicar = false;
        }
      })
      .then(() => {
        this.obtenerInscritos();
      })
      .catch((err) => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
    this.observacion = null;
    this.modalAdjudicar = false;
  }
  cambiarEstadoConvocatoria() {
    this.btnIniciarValidaciones = false;
    const id_usuario = JSON.parse(sessionStorage.getItem("usuario"));
    Axios()
      .put("/actualizar_fase/" + this.$route.params.id, {
        id_pic_convocatoria: this.$route.params.id,
        pic_fase_id: 3,
        id_usuario_actualiza: id_usuario.id_usuario,
      })
      .then(() => {
        Swal.fire({
          title: "Convocatoria ",
          text: " pasó a fase de Terminación",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#007bb8",
          icon: "success",
        });
        this.$router.back();
      })
      .catch(() => {
        Swal.fire({
          title: "ERROR",
        });
      })
      .finally(() => {
        this.btnIniciarValidaciones = true;
      });
  }

  filtrar(lista: any[]) {
    let resp;
    if (this.filter != "") {
      resp = lista.filter(
        (item) =>
          item.capacitacion
            .substring(0, this.filter.length)
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "") === this.filter.toLowerCase()
      );
    } else {
      resp = lista;
    }
    return resp;
  }
  ordenar(n: number, type: string) {
    let rows,
      switching,
      i,
      x,
      y,
      shouldSwitch,
      dir,
      switchcount = 0;

    const table: any = document.getElementById("listado");
    switching = true;
    dir = "asc";

    while (switching) {
      switching = false;
      rows = table.rows;
      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];
        if (dir == "asc") {
          if (
            (type == "str" &&
              x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) ||
            (type == "int" && parseFloat(x.innerHTML) > parseFloat(y.innerHTML))
          ) {
            shouldSwitch = true;
            break;
          }
        } else if (dir == "desc") {
          if (
            (type == "str" &&
              x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) ||
            (type == "int" && parseFloat(x.innerHTML) < parseFloat(y.innerHTML))
          ) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount == 0 && dir == "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }
}
