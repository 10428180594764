
import Component from "vue-class-component";
import AnimacionCargando from "@/components/animacionCargando.vue";
import { Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class VoceroVerEditar extends Vue {
  cargarDatos = false;
  modificar = false;
  usuario = "";
  tipovocero = [];
  tiposdevoceros: any[] = [];
  vocero = {
    id_vocero: null,
    id_persona: null,
    primer_nombre: "",
    segundo_nombre: "",
    primer_apellido: "",
    segundo_apellido: "",
    tipo_documento: "",
    identificacion: null,
    modalidad: "",
    jornada: "",
    programa_formacion: "",
    id_ficha_caracterizacion: null,
    id_tipo_vocero: [],
  };
  mounted() {
    this.vocero.id_persona = this.$route.params.id;
    this.obtenerPersona(this.vocero.id_persona);
    this.obtenerTiposVoceros();
  }
  obtenerPersona(id) {
    this.cargarDatos = true;
    Axios()
      .post("buscar_vocero_por_iden", { identificacion: id })
      .then((respuesta) => {
        this.cargarDatos = false;
        if (respuesta.data.status == "success") {
          this.vocero = respuesta.data.results;
        } else {
          Swal.fire({
            title: respuesta.data.message,
          })
            .then(() => {
              this.$router.back();
            })
            .finally(() => {
              this.cargarDatos = false;
            });
        }
      });
  }
  datosVacios(dato) {
    if (dato == null || dato == "" || dato == " ") {
      return "No hay datos registrados";
    } else {
      return dato;
    }
  }

  obtenerTiposVoceros() {
    Axios()
      .get("listar_tipos_voceros")
      .then((respuesta) => {
        this.tiposdevoceros = respuesta.data.results;
      });
  }
   constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
      },
    };
    this.$validator.localize("es", dict);
  }
  validarFormulario(){
    this.$validator.validate().then((valid)=>{
      if(valid){
       this.editarVocero(this.vocero);
        
      }else{
         Swal.fire({
            title: "Vocero no registrado!",
            icon: "error",
            text: "Debe escoger el tipo de vocero",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
      }
    })
  }
  editarVocero(id) {
    this.modificar = true;
    Axios()
      .post("actualizar_vocero/" + id.id_vocero, {
        id_vocero: this.vocero.id_vocero,
        persona_id: this.vocero.id_persona,
        ficha_caracterizacion_id: this.vocero.id_ficha_caracterizacion,
        tipo_vocero_id:this.tiposdevoceros.id_tipo_vocero,
      })
      .then((resp) => {
        this.modificar = false;
        if (resp.data.status == "success") {
          Swal.fire({
            title: "Vocero Actualizado!",
            text: "Se ha actualizado el vocero exitosamente",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.$router.back();
        } else {
          Swal.fire({
            title: "Vocero No actualizado!",
            icon: resp.data.status,
            text: "Seleccionar un tipo de vocero diferenta al actual",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          }).catch(()=>{
              Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
          }).finally(() => {
            this.modificar = false;
          });
        }
      });
  }
}
