
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class ModificarEducacionPersona extends Vue {
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;
  estado = false;
  estadoPeticionRegistrar = false;

  docValido = false;
  estadoPeticion = false;
  rutadocumento = null;
  verModal = false;
  docResolucon = "";
  docVista = null;

  pic_eje = {
    id_eje: "",
    eje: "",
  };
  updated()
  {
    //metho de prueba
  }

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "El valor no puede ser menor a cero",
        email: () => "El correo no es válido",
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }

  mounted() {}
  registrarPicEje() {
    this.estadoPeticionRegistrar = true;
    this.pic_eje.eje = this.pic_eje.eje.toUpperCase();
    Axios()
      .post("almacenar_eje", this.pic_eje )
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.estado=true;
          Swal.fire({
            title: "Pic Eje Guardado",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          //   this.estadoPeticion = false;
          this.$router.push({ name: "GestionarPICEje" });
        } else {
          Swal.fire("ERROR!", "No Se registro el Pic Eje", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(()=>{
        this.estadoPeticionRegistrar =false;
      });
  }
  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.registrarPicEje();
      }
    });
  }
}
