
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class registrarColegio extends Vue {
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;

  estadoPeticionModificar = false;
  listarIdiomas: any[] = [];
  listarIdiomasString: any[] = [];
  lista_modalidad: any[] = [];
  lista_zona: any[] = [];
  lista_jornada: any[] = [];
  lista_municipio: any[] = [];
  lista_departamentos: any[] = [];
  departamento_actual = null;

  infoSenaPersona = "";
  modalSenaPersona = false;
  buscarSenaPersona;

  lista_municipios = null;

  lista_idiomas: any[] = [];

  docValido = false;
  estadoPeticion = false;

  inactivo = false;

  contIdiomaGuardada = 0;

  colegio = {
    id_colegio: "",
    colegio_modalidad_id: "",
    colegio_modalidad: "",
    zona_id: "",
    zona: "",
    jornada_id: "",
    jornada: "",
    municipio_id: "",
    municipio: "",
    coord_persona_id: "",
    rector_persona_id: "",
    colegio: "",
    nit: "",
    direccion: "",
    telefono: "",
    cod_dane: "",
    licencia: null,
    sector_colegio: "",
    secretaria_id: "",
    estado: "",
    resguardo: "",
    correo: "",
    calendario: "",
    genero: "",
    sedes: "",
    jornadas: "",
    rector: "",
  };

  mounted() {
    this.obtenerModalidadColegio();
    this.obtenerZonaColegio();
    this.obtenerJornadaColegio();
    this.obtenerDepartamento();
    this.obtenerColegio(this.$route.params.id);
  }

  obtenerColegio(id) {
    Axios()
      .get("ver_detalle_colegio/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.colegio = respuesta.data.results;
          //   this.mostrarDocumento(id);
        }
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }

  //--------------------------------------------------------------------
  modificarColegio(id) {
    this.estadoPeticion = true;
    this.estadoPeticionModificar = true;
    // var data = new FormData();

    //Añadimos la docinscrip seleccionada
    //   data.append("colegio_modalidad_id", this.colegio.colegio_modalidad_id);
    // data.append("zona_id", this.colegio.zona_id);
    // //   data.append("jornada_id", this.colegio.jornada_id);
    // data.append("municipio_id", this.colegio.municipio_id);
    // //   data.append("coord_persona_id", this.colegio.coord_persona_id);
    // //   data.append("rector_persona_id", this.colegio.rector_persona_id);
    // data.append("colegio", this.colegio.colegio);
    // //data.append("nit", this.colegio.nit);
    // data.append("direccion", this.colegio.direccion);
    // data.append("telefono", this.colegio.telefono);
    // data.append("cod_dane", this.colegio.cod_dane);
    // //data.append("licencia", this.colegio.licencia);
    // data.append("sector_colegio", this.colegio.sector_colegio);
    // data.append("secretaria_id", this.colegio.secretaria_id);
    // data.append("estado", this.colegio.estado);
    // data.append("resguardo", this.colegio.resguardo);
    // data.append("correo", this.colegio.correo);
    // data.append("calendario", this.colegio.calendario);
    // data.append("genero", this.colegio.genero);
    // data.append("sedes", this.colegio.sedes);
    // data.append("jornadas", this.colegio.jornadas);
    // data.append("rector", this.colegio.rector);

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    Axios()
      .post("modificar_colegios/" + id, this.colegio)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire(
            "Se subió el colegio!",
            "Colegio Modificado",
            respuesta.data.status
          );
          this.estadoPeticion = false;
          //   this.eliminarConvenioColegios();
          //   this.modificarColegios(this.convenio.id_convenio);
          // this.eliminarColegiosEscogidos();
          this.$router.push({ name: "GestionarColegio" });
        } else {
          Swal.fire("ERROR!", "No se Modifico el colegio", "error");
        }
        // this.estadoPeticion = false;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!modficar",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionModificar = false;
      });
  }

  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.modificarColegio(this.$route.params.id);
      }
    });
  }

  //-----------------------------------------------------------
  obtenerLicencia(event) {
    if (event.target.files[0].size <= 5000000) {
      //5mb tope
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.colegio.licencia = event.target.files[0];
        Swal.fire(
          "Certificación Cargada!",
          "Archivo cargado exitosamente!",
          "success"
        );
        this.docValido = true;
        // this.docResolucon = null;
      } else {
        this.docValido = false;
        this.colegio.licencia = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.colegio.licencia = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  }

  //--------------------------------------------------------------------
  //   registrarColegio() {
  //     this.estadoPeticion = true;
  //     var data = new FormData();

  //     //Añadimos la docinscrip seleccionada
  //     data.append("colegio_modalidad_id", this.colegio.colegio_modalidad_id);
  //     data.append("zona_id", this.colegio.zona_id);
  //     data.append("jornada_id", this.colegio.jornada_id);
  //     data.append("municipio_id", this.colegio.municipio_id);
  //     data.append("coord_persona_id", this.colegio.coord_persona_id);
  //     data.append("rector_persona_id", this.colegio.rector_persona_id);
  //     data.append("colegio", this.colegio.colegio);
  //     data.append("nit", this.colegio.nit);
  //     data.append("direccion", this.colegio.direccion);
  //     data.append("telefono", this.colegio.telefono);
  //     data.append("cod_dane", this.colegio.cod_dane);
  //     data.append("licencia", this.colegio.licencia);
  //     data.append("sector_colegio", this.colegio.sector_colegio);

  //     const headers = {
  //       "Content-Type": "multipart/form-data",
  //     };
  //     Axios()
  //       .post("almacenar_colegios", data, {
  //         headers: headers,
  //       })
  //       .then((respuesta) => {
  //         if ((respuesta.data.status = "success")) {
  //           // this.modalBuscarColegio = false;
  //           Swal.fire({
  //             title: "colegio Guardado",
  //             text: "Se guardo el colegio",
  //             icon: "success",
  //             confirmButtonText: "Aceptar",
  //             confirmButtonColor: "#238276",
  //           });
  //           //   this.$router.push({ name: "GestionarHojaDeVidaENI" });
  //         } else {
  //           Swal.fire("ERROR!", "No se Guardo El colegio", "error");
  //         }
  //         // this.estadoPeticion = false;
  //       })
  //       .catch(() => {
  //         Swal.fire(
  //           "ERROR!",
  //           "Se ha presentado un error en el servidor!",
  //           "error"
  //         );
  //       });
  //   }

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "El valor no puede ser menor a cero",
        email: () => "El correo no es válido",
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }

  obtenerModalidadColegio() {
    Axios()
      .get("listar_colegio_modalidad")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_modalidad = respuesta.data.results;
        }
      });
  }

  obtenerZonaColegio() {
    Axios()
      .get("listar_zona_colegio")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_zona = respuesta.data.results;
        }
      });
  }

  obtenerJornadaColegio() {
    Axios()
      .get("listar_jornada_colegio")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_jornada = respuesta.data.results;
        }
      });
  }

  obtenerDepartamento() {
    Axios()
      .get("listar_departamentos/57")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_departamentos = respuesta.data.results;
        } else {
          Swal.fire({
            title: respuesta.data.message,
          });
        }
      });
  }

  obtenerMunicipio(id) {
    Axios()
      .get("listar_municipios/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_municipios = respuesta.data.results.municipios;
        } else {
          Swal.fire({
            title: respuesta.data.message,
          });
        }
      });
  }

  buscarUsuario() {
    Axios()
      .post("buscar_usuario", {
        identificacion: this.colegio.coord_persona_id,
      })
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.buscarSenaPersona = respuesta.data.results;
          this.infoSenaPersona =
            this.buscarSenaPersona.primer_nombre +
            " " +
            this.buscarSenaPersona.segundo_nombre +
            " " +
            this.buscarSenaPersona.primer_apellido +
            " " +
            this.buscarSenaPersona.segundo_apellido +
            " - " +
            this.buscarSenaPersona.identificacion;
          this.modalSenaPersona = false;
          Swal.fire({
            title: "Sena Persona encontrada",
            text:
              this.buscarSenaPersona.primer_nombre +
              " " +
              this.buscarSenaPersona.segundo_nombre +
              " " +
              this.buscarSenaPersona.primer_apellido +
              " " +
              this.buscarSenaPersona.segundo_apellido,
            icon: respuesta.data.status,
            confirmButtonText: "Aceptar",
            customClass: {
              confirmButton: "btn btn-azul-sena",
            },
          });
        } else {
          Swal.fire({
            title: "Sena Persona no existe",
            text: "Ingrese una identificación correcta",
            icon: "error",
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
}
