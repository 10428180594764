
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";

@Component ({
   components: {
    AnimacionCargando,
  },
})

export default class ModificarPerfil extends Vue {
  estadoConsulta=false;
  filter= "";
  aprendicesInscritos :any =[];
  aprendicesInscritosFilter:any= [];
  cantidadAdjudicados:0;
  aprendicesAdjudicados: any = [];
  nuevaCC;
  idConvocatoria = null;
  adjudicar = false;
  fecha_inicio = "";
  fecha_fin = "";
  porc_estimulo_entero = null;
  porc_estimulo_decimal = null;

  id_persona = null;
  nombre = "";

  paginate = ["aprendicesInscritosFilter"];
  //array:any [] = [];
  array = "";
  convocatoria = {
    centro_formacion_id: "",
    centro_formación: "",
    convocatoria: "",
    cupo_hombres_14_17: null,
    cupo_hombres_mayores: null,
    cupo_instructores: null,
    cupo_mujeres_14_17: null,
    cupo_mujeres_mayores: null,
    cupo_total: null,
    estimulo_mensual: "",
    fase_bienestar: "",
    fase_bienestar_id: "",
    fecha_final_convoca: "",
    fecha_inicio_convoca: "",
    id_convocatoria: "",
    ruta_documento: "",
    tipo_apoyo: "",
    tipo_apoyo_id: "",
    tipo_monitoria: "",
    tipo_monitoria_id: "",
  };

  constructor() {
    super();

    const dict = {
      messages: {
        required: () => "Campo Obligatorio",
        min: () => "Mínimo 6 caracteres",
      },
    };
    this.$validator.localize("es", dict);
  }
  comparar(id) {
    return (
      this.aprendicesAdjudicados.filter((item) => item.identificacion == id)
        .length != 0
    );
  }
  mounted() {
    this.idConvocatoria = this.$route.params.id;
    this.obtenerConvocatoria(this.$route.params.id);
    this.obtenerInscritosConvocatoria(this.$route.params.id);
    this.obtenerAdjudicadosConvocatoria(this.$route.params.id);
    this.verAnio();
  }
  //fechas
  verAnio() {
    var fecha = new Date();
    var anio = fecha.getFullYear();
    // alert("El año actual es: " + anio);
    this.fecha_inicio = "";
    this.fecha_fin = "";
  }
  //fechas

  obtenerConvocatoria(id) {
    Axios()
      .get("listar_convocatoria/" + id)
      .then((respuesta) => {
        this.convocatoria = respuesta.data.results;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  async obtenerInscritosConvocatoria(id) {
    // SE LISTAN LOS APRENDICES INSCRITOS CON EL PUNTAJE TOTAL
    await Axios()
      .get("listar_adjudicaciones_convocatoria/" + id)
      .then((respuesta) => {
        return respuesta.data.results;
      })
      .then((res) => {
        setTimeout(() => {
          if (res != null) {
            this.aprendicesInscritos = res;
            this.aprendicesInscritosFilter= res;
          }
          // this.usuario = respuesta.data.results;
        }, 100);
      });
  }
  async obtenerAdjudicadosConvocatoria(id) {
    await Axios()
      .get("listar_adjudicados/" + id)
      .then((respuesta) => {
        return respuesta.data.results;
      })
      .then((res) => {
        setTimeout(() => {
          if (res != null) {
            this.aprendicesAdjudicados = res;

            let checks = document.getElementsByTagName("input");
            for (let i = 0; i < checks.length; i++) {
              const element = checks[i];
              element.checked = false;
            }
            for (let i = 0; i < checks.length; i++) {
              const element = checks[i];

              this.aprendicesAdjudicados.forEach((aprendiz) => {
                if (element.id == aprendiz.identificacion) {
                  element.checked = true;
                }
              });
            }
          }
        }, 100);
      });
  }

  adjudicarAprendizConvocatoria(id) {
  
    Axios()
      .post("adjudicarInscrito", {
        id_convocatoria: this.$route.params.id,
        id_persona: id,
        fecha_inicio_apoyo: this.fecha_inicio,
        fecha_fin_apoyo: this.fecha_fin,
        porc_estimulo_mensual:
          this.porc_estimulo_entero + "," + this.porc_estimulo_decimal,
        id_usuario_autenticado: JSON.parse(sessionStorage.getItem("usuario"))
          .id_usuario,
      })
      .then((respuesta) => {
        this.estadoConsulta=false;
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Usuario " + this.nombre + " adjudicado",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.obtenerAdjudicadosConvocatoria(this.$route.params.id);

          this.fecha_inicio = "";
          this.fecha_fin = "";
          this.porc_estimulo_entero = "";
          this.porc_estimulo_decimal = "";
          //this.obtenerAdjudicadosConvocatoria(this.$route.params.id);
          this.adjudicar = false;
        } else {
          Swal.fire({
            title: "INFORMACIÓN",
            icon: "warning",
            text: respuesta.data.message,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        }
        this.obtenerAdjudicadosConvocatoria(this.$route.params.id);
        this.adjudicar = false;
        // }
      })
      .catch((err) => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
        this.estadoConsulta=false;
      });
  }

  quitarAdjudicacion(id) {
    Axios()
      .post("quitar_adjudicacion", {
        id_persona: id,
      })
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Se ha quitado la adjudicación con exito",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.obtenerAdjudicadosConvocatoria(this.$route.params.id);
          this.adjudicar = false;
        }

        if (respuesta.data.status == "info") {
          Swal.fire({
            title: "INFORMACIÓN",
            icon: "warning",
            text:
              "El aprendiz " +
              this.nombre +
              " se encuentra adjudicado en otra convocatoria",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.obtenerAdjudicadosConvocatoria(this.$route.params.id);
          this.adjudicar = false;
        }
      })
      .catch((err) => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  // cambiarEstadoConvocatoria() {
  //   if (this.aprendicesAdjudicados.length == 0) {
  //     Swal.fire({
  //       title: "Informacion",
  //       text: "Para finalizar la adjudicación debe estar adjudicado almenos un aprendiz",
  //       icon: "warning",
  //       confirmButtonText: "Aceptar",
  //       confirmButtonColor: "#007bb8",
  //       customClass: {
  //         confirmButton: "btn btn-azul-sena",
  //       },
  //     });
  //   } else {
  //     Axios()
  //       .put("actualizar_fase_convocatoria/" + this.idConvocatoria, {
  //         id_convocatoria: this.idConvocatoria,
  //         fase_bienestar_id: 4,
  //         id_actualiza: JSON.parse(sessionStorage.getItem("usuario"))
  //           .id_usuario,
  //       })
  //       .then((respuesta) => {
  //         if (
  //           this.aprendicesAdjudicados.length <= this.convocatoria.cupo_total
  //         ) {
  //           if (respuesta.data.status == "success") {
  //             Swal.fire(
  //               "CAMBIO DE FASE CORRECTO",
  //               "Se ha cambiado la fase de la convocatoria exitosamente!",
  //               "success"
  //             );
  //             this.$router.push("GestionarConvocatoria");
  //           }
  //           return respuesta.data;
  //         } else {
  //           Swal.fire({
  //             title: "Información",
  //             text:
  //               "Solo hay " +
  //               this.convocatoria.cupo_total +
  //               " cupos disponibles para esta convocatoria, verifique el numero de adjudicados.",
  //             icon: "warning",
  //             confirmButtonText: "Aceptar",
  //             confirmButtonColor: "#007bb8",
  //             customClass: {
  //               confirmButton: "btn btn-azul-sena",
  //             },
  //           });
  //         }
  //       });
  //   }
  // }
  validarFormulario(check) {
  
    if (check == true) {
      this.quitarAdjudicacion(this.id_persona);
    } else {
      this.$validator.validate().then((valid) => {
        if (valid) {
          if (this.fecha_inicio != null && this.fecha_fin != null) {
            // el formulario se validó y tiene los datos que corresponden por campo
            this.estadoConsulta=true;
            this.adjudicarAprendizConvocatoria(this.id_persona);
          } else {
          }
        }
      });
    }
  }
    cambiarEstadoConvocatoria(convocatoria) {
this.obtenerAdjudicados(convocatoria.id_convocatoria);
  Swal.fire({
      title: "Quieres cambiar el estado de la convocatoria?",
      text: "Tu fase actual es: " + " " + convocatoria.fase_bienestar,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
    }).then((resp)=>{
       if (resp.isConfirmed) {
            if (this.cantidadAdjudicados != convocatoria.cupo_total) {
            Swal.fire({
              title: "Error ",
              text: "No se pudo hacer el cambio de fase, porque no se asignaron los cupos de los adjudicados",
              icon: "error",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
          } else {
            console.log("yes");
            this.cambiar_fase(4, convocatoria.id_convocatoria);
          }
       }
      
    })
  }
  cambiar_fase(fase_id, id_convovatoria) {
    Axios()
      .put("actualizar_fase_convocatoria/" + id_convovatoria, {
        id_convocatoria: id_convovatoria,
        fase_bienestar_id: fase_id,
        id_actualiza: JSON.parse(sessionStorage.getItem("usuario")).id_usuario,
      })
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Cambio de fase correcto!",
            text: "Se ha cambiado la fase de la convocatoria exitosamente!",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.$router.push("GestionarConvocatoria");
        }
        return respuesta.data;
      });
  }
   obtenerAdjudicados(id_convocatoria) {
    Axios()
      .get("obtener_adjudicados/" + id_convocatoria)
      .then((resp) => {
        this.cantidadAdjudicados = resp.data.results;
      });
  }
  AdjudicadosFiltrado(){
    let Filter=[];
    for(let inscrito of this.aprendicesInscritos){
     let identificacion = inscrito.identificacion.toLowerCase();
     let primer_nombre = inscrito.primer_nombre.toLowerCase();
     let primer_apellido =inscrito.primer_apellido.toLowerCase();
     let segundo_nombre ="";
     let segundo_apellido="";
     if(inscrito.segundo_nombre != null){
      segundo_nombre = inscrito.segundo_nombre.toLowerCase();
     }
     if(inscrito.segundo_apellido != null){
      segundo_apellido = inscrito.segundo_apellido.toLowerCase(); 
     }
     if(
      identificacion.indexOf(this.filter.toLowerCase()) >= 0 ||
      primer_nombre.indexOf(this.filter.toLowerCase()) >=0 ||
      segundo_nombre.indexOf(this.filter.toLowerCase()) >=0 ||
      primer_apellido.indexOf(this.filter.toLowerCase()) >=0 ||
      segundo_apellido.indexOf(this.filter.toLowerCase()) >= 0
     ){
      Filter.push(inscrito);
     }
    }
    this.aprendicesInscritosFilter = Filter;
    if(this.aprendicesInscritosFilter.length==0){
       Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró la persona que coincida con la búsqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#007bb8",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filter="";
      this.aprendicesInscritosFilter=this.aprendicesInscritos;
    }
  }
}
