
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import Multiselect from "vue-multiselect";
import AnimacionCargando from "../../../components/animacionCargando.vue";

@Component({
  components: {
    BaseCheckbox,
    Multiselect,
    AnimacionCargando,
  },
})
export default class ActualizarIndicador extends Vue {
  estadoPeticionModificar = false;
  cargarDatos = false;
  indicador = {
    id_indicador: null,
    nombre_indicador: "",
    meta_indicador: "",
    objetivo_indicador: "",
    descripcion_indicador: "",
    estado_indicador: "Activo",
    gi_responsable_id: null,
    frecuencia_id: null,
  };

  frecuencias: any[] = [];
  giresponsables: any[] = [];

  constructor() {
    super();

    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "No pude ser negativo",
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }

  mounted() {
    this.obtenerIndicador(this.$route.params.id);
  }




  obtenerIndicador(id) {
    Axios()
      .get("listar_indicador/" + id)
      .then((respuesta) => {
        this.indicador = respuesta.data.results;
        this.frecuencias = respuesta.data.results.frecuencias;
        this.giresponsables = respuesta.data.results.gi_responsables;
    
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

 


  ActualizarIndicador() {
    this.estadoPeticionModificar = true;
    Axios()
      .put("actualizar_indicador/" + this.$route.params.id,
      this.indicador)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Indicador Actualizado!",
            text: "Se ha Actualizado el Indicador exitosamente",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        } else {
          Swal.fire({
            title: "Indicador No  Actualizado!",
            icon: respuesta.data.status,
            text: respuesta.data.message,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        }
        this.$router.push("GestionarSigeaIndicador");

      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionModificar = false;
      });
  }

  validarFormulario(){
    this.$validator.validate().then((valid)=>{
      if(valid){
       this.ActualizarIndicador();
      }else{
         Swal.fire({
            title: "Indicador no actualizado!",
            icon: "error",
            text: "Los campos son Obligatorios",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
      }
    })
  }
}
