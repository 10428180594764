var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-5 container-fluid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h1',{staticClass:"text-azul-sena"},[_vm._v("REGISTRAR PROGRAMA PROYECTADO")]),_c('hr'),(!_vm.estadoPeticionTabla)?_c('div',{staticClass:"text-center"},[_c('AnimacionTablasCargando')],1):_vm._e(),(_vm.estadoPeticionTabla)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-lg-6"},[_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-md-6 col-lg-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Buscar Programa Formación"},domProps:{"value":(_vm.filter)},on:{"keyup":function($event){$event.preventDefault();return _vm.ProgramaFiltrado()},"input":function($event){if($event.target.composing)return;_vm.filter=$event.target.value}}})]),_c('div',{staticClass:"col-md-6 col-lg-6"},[_c('paginate-links',{staticClass:"pagination justify-content-end",attrs:{"for":"listaProgramasFiltrado","limit":2,"hide-single-page":true,"show-step-links":true,"full-page":true,"classes":{
                    ul: 'simple-links-container',
                    li: 'simple-links-item',
                    liActive: ['simple-links-item', 'active1'],
                    'li.active': 'active1',
                  }}})],1)]),_c('div',[_c('paginate',{ref:"paginator",attrs:{"name":"listaProgramasFiltrado","list":_vm.listaProgramasFiltrado,"per":7}},[_c('table',{staticClass:"table table-hover table-responsive"},[_c('thead',{attrs:{"id":"listado"}},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Id Pogramas Formación")]),_c('th',{staticClass:"col-sm-12",attrs:{"scope":"col"}},[_vm._v(" Programas Formación ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Accion")])])]),_c('tbody',_vm._l((_vm.paginated(
                        'listaProgramasFiltrado'
                      )),function(ProgramasFormacion,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(ProgramasFormacion.id_programa_formacion)+" ")]),_c('td',[_vm._v(_vm._s(ProgramasFormacion.programa_formacion))]),_c('td',{attrs:{"scope":"row"}},[_c('div',{staticClass:"btn-toolbar btn-responsive-table",attrs:{"role":"toolbar"}},[_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Agregar Programa","tooltip-flow":"top"}},[_c('base-button',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"type":"azul-sena"},on:{"click":function($event){return _vm.agregarProgramaLista(ProgramasFormacion)}}},[_c('i',{staticClass:"fas fa-plus size"})])],1)])])])}),0)])])],1),_c('paginate-links',{staticClass:"pagination justify-content-end",attrs:{"for":"listaProgramasFiltrado","limit":2,"hide-single-page":true,"show-step-links":true,"full-page":true,"classes":{
                ul: 'simple-links-container',
                li: 'simple-links-item',
                liActive: ['simple-links-item', 'active1'],
                'li.active': 'active1',
              }}})],1),_c('div',{staticClass:"col-md-6 col-lg-6"},[_c('div',{staticClass:"row my-3"},[_vm._m(0),_c('div',{staticClass:"col-md-6 col-lg-6"},[_c('paginate-links',{staticClass:"pagination justify-content-end",attrs:{"for":"programasProyectTemp","limit":2,"hide-single-page":true,"show-step-links":true,"full-page":true,"classes":{
                    ul: 'simple-links-container',
                    li: 'simple-links-item',
                    liActive: ['simple-links-item', 'active1'],
                    'li.active': 'active1',
                  }}})],1)]),_c('div',{staticClass:"table-responsive"},[_c('paginate',{ref:"paginator",attrs:{"name":"programasProyectTemp","list":_vm.programasProyectTemp,"per":7}},[_c('table',{staticClass:"table table-hover"},[_c('thead',{attrs:{"id":"listado"}},[_c('tr',[_c('th',[_vm._v("Programa de formación")]),_c('th',[_vm._v("Acción")])])]),_c('tbody',_vm._l((_vm.paginated(
                        'programasProyectTemp'
                      )),function(ProgramasFormacion,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(ProgramasFormacion.programa_formacion))]),_c('td',{attrs:{"scope":"row"}},[_c('div',{staticClass:"btn-toolbar btn-responsive-table",attrs:{"role":"toolbar"}},[_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Eliminar Programa","tooltip-flow":"top"}},[_c('base-button',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"type":"azul-sena"},on:{"click":function($event){return _vm.eliminar(_vm.programasProyectTemp)}}},[_c('i',{staticClass:"fas fa-trash"})])],1)])])])}),0)])])],1),_c('paginate-links',{staticClass:"pagination justify-content-end",attrs:{"for":"programasProyectTemp","limit":2,"hide-single-page":true,"show-step-links":true,"full-page":true,"classes":{
                ul: 'simple-links-container',
                li: 'simple-links-item',
                liActive: ['simple-links-item', 'active1'],
                'li.active': 'active1',
              }}})],1)]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-4 col-sm-12 mt-1"},[_c('base-button',{staticClass:"btn btn-block bg-azul-sena text-white",attrs:{"type":"azul-sena"},on:{"click":function($event){return _vm.guardarListaProgramasProyectados()}}},[_vm._v("Guardar")])],1),_c('div',{staticClass:"col-lg-4 col-sm-12 mt-1"},[_c('a',{staticClass:"btn btn-block bg-azul-sena text-white",on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" Volver ")])])])])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-md-6 col-lg-6"},[_c('strong',{staticClass:"text-azul-sena"},[_vm._v("Programas seleccionados")])])
}]

export { render, staticRenderFns }