
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";

/* --------------------------------------------- COMPONENTS-------------------------------------------------*/

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class SeleccionarIndicadores extends Vue {
  /* ------------------------------------------------ DATA -------------------------------------------------*/
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_usuario;
  estadoPeticion = true;
  listaIndicadores: any = [];
  lista_id_indicador = [];
  paginate = ["listaIndicadores"];
  frecuencias = [];
  giresponsables = [];

  /* FILTRO DE INDICADOR */
  /* 1 */ indicadores = [];
  /* 2 */ filtroIndicador = "";
  /* 3 */ filtroIndi = [];
  /* FILTRO DE GRUPO RESPONSABLE */
  /* 1 */ filtroGrupoResponsable = [];
  /* 2 */ filtroResponsable = "";
  /* FILTRO DE FRECUENCIA */
  /* 1 */ filtroFrecuencia = [];
  /* 2 */ filtroFrecuen = "";
  /* --------------------------------------------- MOUNTED -------------------------------------------------*/
  mounted() {
    this.cargarIndicadores();
    this.ObtenerDatosParaFiltros();
  }

  /* --------------------------------------------- METODOS -------------------------------------------------*/
  cargarIndicadores() {
    Axios()
      .get("/mostrar_indicadores/"+ this.id_persona_logeada)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listaIndicadores = respuesta.data.results;
          this.indicadores = respuesta.data.results;
          this.estadoPeticion = false;
        }
        if (respuesta.data.status == "info") {
          Swal.fire({
            title: "Informacion",
            text: respuesta.data.message,
            icon: "warning",
            confirmButtonText: "Aceptar",
            customClass: {
              confirmButton: "btn btn-azul-sena",
            },
          });
          this.estadoPeticion = false;
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  ObtenerDatosParaFiltros() {
    Axios()
      .get("crear_sigea_indicador")
      .then((respuesta) => {
        this.frecuencias = respuesta.data.results.frecuencias;
        this.giresponsables = respuesta.data.results.gi_responsables;
      });
  }

  filtroIndicadores() {
    let filtroIndi = [];
    for (let indi of this.indicadores) {
      let nombre = indi.nombre_indicador.toLowerCase();
      if (nombre.indexOf(this.filtroIndicador) >= 0) {
        filtroIndi.push(indi);
      }
    }
    this.listaIndicadores = filtroIndi;
    if (this.listaIndicadores.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 2000,
        text: "No se encontró convocatorias que coincidan con las busqueda",
        showConfirmButton: false,
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filtroIndicador = "";
      this.listaIndicadores = this.indicadores;
    }
  }

  filtrarResponsable() {
    let filtroGrupoResponsable = [];
    for (let responsable of this.indicadores) {
      let respon = responsable.nombre_gi_responsable;
      if (respon.indexOf(this.filtroResponsable) >= 0) {
        filtroGrupoResponsable.push(responsable);
      }
    }
    this.listaIndicadores = filtroGrupoResponsable;
    if (this.listaIndicadores.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró convocatorias que coincidan con las busqueda",
        showConfirmButton: false,
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filtroResponsable = "";
      this.listaIndicadores = this.indicadores;
    }
  }

  filtrarFrecuencia() {
    let filtroFrecuencia = [];
    for (let responsable of this.indicadores) {
      let respon = responsable.nombre_frecuencia;
      if (respon.indexOf(this.filtroFrecuen) >= 0) {
        filtroFrecuencia.push(responsable);
      }
    }
    this.listaIndicadores = filtroFrecuencia;
    
    if (this.listaIndicadores.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró convocatorias que coincidan con las busqueda",
        showConfirmButton: false,
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filtroFrecuen = "";
      this.listaIndicadores = this.indicadores;
    }
  }
}
