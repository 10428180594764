
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class TrazabilidadActividad extends Vue {
  estadoPeticion = false;
  actividad = "";
  listaTrazabilidadActividad: any[] = [];
  trazabilidad = {
    id_trazabilidad_actividad: 0,
    asistencia: 0,
  };
  actividad_estrategia?: any = null;

  
  mounted() {
    this.actividad = this.$route.params.id_actividad;
    this.obtenerTrazabilidadActividad();
    this.obtenerEstrategiaImplementadaCentro()
  }

  obtenerTrazabilidadActividad() {
    Axios()
      .get("listar_trazabilidad_actividad/" + this.actividad)
      .then((respuesta) => {
        this.listaTrazabilidadActividad = respuesta.data.results;
        if (this.listaTrazabilidadActividad == null) {
          Swal.fire({
            title: "Falta registrar trazabilidad",
            text: "No se ha registrado ninguna trazabilidad",
            icon: "info",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.$router.push({ name: "GestionarActividadCentro" });
        } else {
        }
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }

  async obtenerEstrategiaImplementadaCentro() {
    await Axios()
      .get("ver_actividad/" + this.actividad)
      .then((respuesta) => {
        this.actividad_estrategia = respuesta.data.results.actividad;
        
      });
  }

  modificarTrazabilidad() {
    for (let i = 0; i < this.listaTrazabilidadActividad.length; i++) {
      this.trazabilidad.id_trazabilidad_actividad =
        this.listaTrazabilidadActividad[i].id_trazabilidad_actividad;
      this.trazabilidad.asistencia =
        this.listaTrazabilidadActividad[i].asistencia;
      Axios()
        .put(
          "editar_trazabilidad_actividad/" +
            this.trazabilidad.id_trazabilidad_actividad,
          {
            asistencia: this.trazabilidad.asistencia,
          }
        )
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            Swal.fire({
              title: "Trazabilidad Modificada",
              text: "Se Modifico la Trazabilidad",
              icon: "success",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
            this.$router.push({ name: "GestionarActividadCentro" });
          } else {
            Swal.fire("ERROR!", "No se Modifico la Trazabilidad", "error");
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        });
    }
  }

  modificarEstrategiaImplementadaCentro(){
    Axios()
    .post("estrategia_centro",{
      id_actividad:this.actividad,
      estrategia_implementada:this.actividad_estrategia.estrategia_implementada
    })
    .then((respuesta)=>{
      respuesta
      console.log(respuesta);
      
    })
  }

  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        // el formulario se validó y tiene los datos que corresponden por campo
        this.modificarTrazabilidad();
        this.modificarEstrategiaImplementadaCentro()
      }
    });
  }
}
