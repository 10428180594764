
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import router from "@/router";
import AnimacionCargando from "../../../components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class VerDetalleRetroalimentacionEvidencia extends Vue {
  estadoPeticion = false;

  retroalimentacion_evidencia = {
    ////////////////////////HISTORIAL EVIDENCIA////////////////////////
    id_historial_evidencia: "",
    actividades_desarrolladas: "",
    avance_indicador: "",
    fuente_informacion: "",
    logros_indicador: "",
    hitos_alcanzar_meta: "",
    identificacion_riesgos: "",
    descripcion_evidencia: "",
    concluciones: "",
    estado: "",
    ////////////////////////EVIDENCIA////////////////////////
    fecha_inicio: "",
    fecha_fin: "",
    ////////////////////////PERSONA////////////////////////
    primer_nombre: "",
    segundo_nombre: "",
    ////////////////////////INDICADOR////////////////////////

    nombre_indicador: "",
    meta_indicador: "",
    objetivo_indicador: "",
    descripcion_indicador: "",
    ////////////////////////FRECUENCIA////////////////////////
    nombre_frecuencia: "",
    ////////////////////////PERIODICIDADES////////////////////////
    nombre: "",
    ////////////////////////GRUPO INTERNO RESPONSABLE////////////////////////
    nombre_gi_responsable: "",
    nombre_proceso: "",
  };

  mounted() {
    this.obtenerRetroalimentacion_Evidencia(
      this.$route.params.id_historial_evidencia
    );
  }

  //obtener municipios inicio
  obtenerRetroalimentacion_Evidencia(id) {
    Axios()
      .get("mostrar_evidencia_historial/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.retroalimentacion_evidencia = respuesta.data.results;
          this.estadoPeticion = false;
        } else {
          Swal.fire({
            title: "Hay un problema",
            text: respuesta.data.message,
            icon: "error",
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
        // this.$router.push("/GestionarConvocatoriaENI");
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }
}
