
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class VerTrazabilidadActividad extends Vue {
  listaTrazabilidadActividad: any[] = [];
  id_actividad = "";
  estadoPeticion = false;
  actividad?: any = null;

  mounted() {
    this.id_actividad = this.$route.params.id_actividad;
    this.obtenerTrazabilidadActividad();
  }

  async obtenerTrazabilidadActividad() {
    await Axios()
      .get("listar_trazabilidad_actividad/" + this.id_actividad)
      .then((respuesta) => {
        this.listaTrazabilidadActividad = respuesta.data.results;
        if (this.listaTrazabilidadActividad == null) {
          Swal.fire({
            title: "Falta registrar trazabilidad",
            text: "No se ha registrado ninguna trazabilidad",
            icon: "info",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.$router.push({ name: "GestionarActividadCentro" });
        } else {
        }
      })
      .finally(() => {
        this.obtenerEstrategiaImplementadaCentro();

        this.estadoPeticion = true;
      });
  }
  async obtenerEstrategiaImplementadaCentro() {
    await Axios()
      .get("ver_actividad/" + this.id_actividad)
      .then((respuesta) => {
        this.actividad = respuesta.data.results.actividad;
      });
  }

  data() {
    return {
      estadoPeticion: true,
      actividades: [],
      paginate: ["actividades"],
      verModal: false,
      fechas_actividad: {
        fecha_inicio: "",
        fecha_fin: "",

        id_actividad: "",
      },
    };
  }
}
