
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";
import AnimacionTablasCargando from "../../../components/animacionTablasCargando.vue";
import Multiselect from "vue-multiselect";
@Component({
  components: {
    AnimacionCargando,
    AnimacionTablasCargando,
    Multiselect,
  },
})
export default class Login extends Vue {
  estadoPeticion = true;
  listaIndicadores: any[] = [];
  indicadores = [];
  frecuencias: any[] = [];
  giresponsables: any[] = [];

  estadoPeticionTabla = false;
  filter = "";
  paginate = ["listaIndicadores"];

  /* Variables para los filtros */

  filtroIndicador = "";
  filtroIndi = [];

  filtroGIResponsable = "";
  filtroGrupoInternoResponsable = [];

  filtroPorFrecuencia = [];
  filtroFrecuencia = "";

  mounted() {
    this.obtenerIndicadores();
    this.ObtenerDatosParaFiltrosIndicadores();
  }

  obtenerIndicadores() {
    Axios()
      .get("listar_indicadores")
      .then((respuesta) => {
        this.listaIndicadores = respuesta.data.results;
        this.indicadores = respuesta.data.results;
        this.estadoPeticion = false;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }
  eliminarIndicador(id) {
    Swal.fire({
      title: "Eliminar Indicador",
      text: "¿Desea eliminar al indicador?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios()
          .delete("eliminar_indicadorsigea/" + id)
          .then((respuesta) => {
            if(respuesta.data.status == "success"){
              Swal.fire({
                title: "Indicador eliminado!",
                text: "El Indicador ha sido eliminado exitosamente!",
                icon: "success",
                cancelButtonText: "Aceptar",
                confirmButtonColor: "btn btn-azul-sena",
              });
            }else{
              Swal.fire({
                title: "INFO",
                text: "No se puede eliminar - Hay evidencias subidas!",
                icon: "warning",
                cancelButtonText: "Aceptar",
                confirmButtonColor: "btn btn-azul-sena",
              });
            }
          })
          .catch((error) => {
            Swal.fire(
              "ERROR!",
              "Se ha detectado un error en el servidor!",
              "error"
            );
          })
          .finally(()=>{
            this.obtenerIndicadores();
          })
      }
    });
  }

  cambiarEstadoActivo(id) {
    Swal.fire({
      title: "Activo",
      text: "¿Desea cambiar el estado de este Indicador a Activo?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Activar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios()
          .put("cambiar_estado_activo/" + id)
          .then((respuesta) => {
            this.obtenerIndicadores();
            Swal.fire({
              title: "Se cambio el Estado!",
              text: "El Estado ha sido actualizado exitosamente!",
              icon: "success",
              cancelButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
          })
          .catch((error) => {
            Swal.fire(
              "ERROR!",
              "Se ha detectado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }

  cambiarEstadoInactivo(id) {
    Swal.fire({
      title: "Inactivo",
      text: "¿Desea cambiar el estado de este Indicador a Inactivo?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Inactivar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios()
          .put("cambiar_estado_inactivo/" + id)
          .then((respuesta) => {
            this.obtenerIndicadores();
            Swal.fire({
              title: "Se cambio el Estado!",
              text: "El Estado ha sido actualizado exitosamente!",
              icon: "success",
              cancelButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
          })
          .catch((error) => {
            Swal.fire(
              "ERROR!",
              "Se ha detectado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }

  /*           FILTROS INDICADORES         */

  ObtenerDatosParaFiltrosIndicadores() {
    Axios()
      .get("crear_sigea_indicador")
      .then((respuesta) => {
        this.frecuencias = respuesta.data.results.frecuencias;
        this.giresponsables = respuesta.data.results.gi_responsables;
      });
  }

  filtrarPorIndicadores() {
    let filtroIndi = [];
    for (let indicador of this.indicadores) {
      let nombre = indicador.nombre_indicador.toLowerCase();

      if (nombre.indexOf(this.filtroIndicador) >= 0) {
        filtroIndi.push(indicador);
      }
    }
    this.listaIndicadores = filtroIndi;

    if (this.listaIndicadores.length == 0) {
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 2000,
        text: "No se encontró indicadores que coincidan con las busqueda",
        showConfirmButton: false,
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filtroIndicador = "";
      this.listaIndicadores = this.indicadores;
    }
  }

  filtrarPorGIResponsable() {
    let filtroGrupoInternoResponsable = [];
    for (let giresponsable of this.indicadores) {
      let responsable = giresponsable.nombre_gi_responsable;
      if (responsable.indexOf(this.filtroGIResponsable) >= 0) {
        filtroGrupoInternoResponsable.push(giresponsable);
      }
    }
    this.listaIndicadores = filtroGrupoInternoResponsable;
    if (this.listaIndicadores.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró convocatorias que coincidan con las busqueda",
        showConfirmButton: false,
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filtroGIResponsable = "";
      this.listaIndicadores = this.indicadores;
    }
  }

  filtrarPorFrecuencia() {
    let filtroPorFrecuencia = [];
    for (let frecuencia of this.indicadores) {
      let frecuen = frecuencia.nombre_frecuencia;
      if (frecuen.indexOf(this.filtroFrecuencia) >= 0) {
        filtroPorFrecuencia.push(frecuencia);
      }
    }
    this.listaIndicadores = filtroPorFrecuencia;

    if (this.listaIndicadores.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró convocatorias que coincidan con las busqueda",
        showConfirmButton: false,
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filtroFrecuencia = "";
      this.listaIndicadores = this.indicadores;
    }
  }

  alertDesarrollo() {
    Swal.fire({
      title: "En Desarrollo!",
      text: "Este apartado aun se encuentra en desarrollo",
      icon: "error",
      confirmButtonText: "Aceptar",
      confirmButtonColor: "#007bb8",
    });
  }
}
