
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import Multiselect from "vue-multiselect";
import AnimacionCargando from "../../../components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
    Multiselect,
  },
})
export default class ResgitrarTrazabilidad extends Vue {
  trazabilidad = {
    eje: "",
    id_eje: "",
    id_trazabilidad: "",
    trazabilidad: "",
  };

  estadoPeticion = false;

  picEje = {
    id_eje: "",
    eje: "",
  };

  listaEjes: any[] = [];

  async mounted() {
    this.obtenerPicEje();
  }

  obtenerPicEje() {
    Axios()
      .get("/crear_ejes")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listaEjes = respuesta.data.results.Ejes;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        alpha_spaces: () => "No permite números ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }

  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.registrarTrazabilidad();
      }
    });
  }

  registrarTrazabilidad() {
    Axios()
      .post("almacenar_pane_trazabilidad", this.trazabilidad)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Pic Eje Guardado",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          //   this.estadoPeticion = false;
          this.$router.push({ name: "GestionarTrazabilidades" });
        } else {
          Swal.fire("ERROR!", "No Se registro el Pic Eje", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
}
