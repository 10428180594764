
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";

/* --------------------------------------------- COMPONENTS-------------------------------------------------*/
@Component({
  components: {
    AnimacionCargando,
  },
})
export default class RegistrarEvidencias extends Vue {
  /* --------------------------------------------- DATA -------------------------------------------------*/
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;
  estadoPeticionRegister = false;
  historial = {
    persona_id: this.id_persona_logeada,
    ruta: "",
    documento: "",
    id_evidencia: "",
    periodicidad_id: "",
    actividades_desarrolladas: "",
    avance_indicador: "",
    fuente_informacion: "",
    logros_indicador: "",
    hitos_alcanzar_meta: "",
    identificacion_riesgos: "",
    concluciones: "",
  };
  docResolucion = null;
  docValido = false;
  periodo_reportado: any[] = [];
  idevidencia = "";

  /* --------------------------------------------- MOUNTED-------------------------------------------------*/
  mounted() {
    this.ObtenerDatosParaFiltros();
  }
  /* --------------------------------------------- METODOS -------------------------------------------------*/

  obtenerEvidencia(event) {
    if (event.target.files[0].size <= 5000000) {
      //5mb tope
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.historial.ruta = event.target.files[0];
        Swal.fire({
          title: "Evidencia Cargada!",
          text: "Archivo cargado exitosamente!",
          icon: "success",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#007bb8",
        });
        this.docValido = true;
      } else {
        this.docValido = false;
        this.docResolucion = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.docResolucion = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  }

  ObtenerDatosParaFiltros() {
    Axios()
      .get("/crear_historial_evidencias/" + this.$route.params.id_indicador)
      .then((respuesta) => {
        this.periodo_reportado = respuesta.data.results;
        this.idevidencia = respuesta.data.results[0].id_evidencia;
      });
  }

  subirEvidencia() {
    this.estadoPeticionRegister = true;
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    var data = new FormData();
    data.append("ruta", this.historial.ruta); /*  */
    data.append("documento", this.historial.documento); /*  */
    data.append("persona_id", this.id_persona_logeada); /*  */
    data.append("id_evidencia", this.idevidencia);
    data.append("periodicidad_id", this.historial.periodicidad_id); /*  */
    data.append(
      "actividades_desarrolladas",
      this.historial.actividades_desarrolladas
    ); /*  */
    data.append("avance_indicador", this.historial.avance_indicador); /*  */
    data.append("fuente_informacion", this.historial.fuente_informacion); /*  */
    data.append("logros_indicador", this.historial.logros_indicador); /*  */
    data.append(
      "hitos_alcanzar_meta",
      this.historial.hitos_alcanzar_meta
    ); /*  */
    data.append(
      "identificacion_riesgos",
      this.historial.identificacion_riesgos
    ); /*  */
    data.append("concluciones", this.historial.concluciones); /*  */

    Axios()
      .post("almacenar_indicador_evidencia", data, {
        headers: headers,
      })
      .then((respuesta) => {
        if ((respuesta.data.status = "success")) {
          Swal.fire({
            title: "Se subió la Evidencia!",
            text: "Archivo cargado exitosamente!",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        } else {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(()=>{
        this.estadoPeticionRegister = false;
        this.$router.push({
            name: "MisIndicadores",
          });
      })
  }

  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        Axios()
          .get("verificacion_historial_evidencia/" + this.$route.params.id_indicador)
          .then((respuesta) => {
            if (respuesta.data.results != null) {
              this.subirEvidencia();
            } else {
              Swal.fire({
                title: "Evidencia No Creada",
                text: respuesta.data.message,
                icon: "warning",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#007bb8",
              });
            }
          })
          .finally(() => {
            this.estadoPeticionRegister = false;
            this.$router.push({
              name: "MisIndicadores",
            });
          });
      }
    });
  }
}
