<template>
  <div class="container-fluid">
    <div class="card my-4">
      <div
        class="border-radius-lg bg-gradient-primary card-header p-0 position-relative mt-n4 mx-3 z-index-2 shadow"
      >
        <div class="pt-4 pb-3">
          <h1 class="text-white text-capitalize ps-3 ms-3">GESTIONAR PANE</h1>
        </div>
      </div>
      <div class="card-body">
        <!-- ---------------------------------------- PAGINACION ------------------------------->
        <paginate-links
          class="pagination justify-content-end"
          for="lineamientos"
          :limit="3"
          :hide-single-page="true"
          :show-step-links="true"
          :full-page="true"
          :async="true"
          :classes="{
            ul: 'simple-links-container',
            li: 'simple-links-item',
            liActive: ['simple-links-item', 'active1'],
            'li.active': 'active1',
          }"
        ></paginate-links>
        <!-- ---------------------------FILTROS DE BUSQUEDAS ------------------------------->
        <div class=" " id="contenedor_todo">
          <!------------------- BUSQUEDA DE LINEAMIENTO Y RESULTADO ESPERADO ----------------->
          <div class="col-md-6 col-lg-3 col-xl-3 mt-2 pb-3">
            <input
              action="search/"
              type="text"
              class="form-control"
              placeholder="Buscar Lineamiento y Resultado Esperado "
              v-model="filtro"
              @keyup.prevent="filtrarLiniamientos()"
            />
          </div>
          <!------------------------ FILTRO DE TIPO PROCESO --------------------------------->
          <div class="col-md-4 col-lg-3 col-sm-12 mt-2 pb-3">
            <select
              class="form-control p-2"
              v-model="filtrotipoproceso"
              @change="filtrarTipoProceso()"
            >
              <option value="" Disabled>Tipo Proceso</option>
              <option value="">Todas</option>
              <option>Proceso Estrategico</option>
              <option>Proceso de Soporte</option>
              <option>Procesos Misionales</option>
            </select>
          </div>
          <!----------------------------- FILTRO DEPENDENCIA ------------------------------------>
          <div class="col-md-4 col-lg-3 col-sm-12 mt-2 pb-3">
            <select
              class="form-control p-2"
              v-model="filtroDependencia"
              @change="filtrarDependencia()"
            >
              <option value="" Disabled>Dependencia</option>
              <option value="">Todas</option>
              <option>Dirección Administrativa Y Financiera</option>
              <option>Dirección De Empleo Y Trabajo</option>
              <option>Dirección De Formación Profesional</option>
              <option>Dirección De Promoción Y Relaciones Corporativas</option>
              <option>
                Dirección Del Sistema Nacional De Formación Para El Trabajo
              </option>
              <option>Dirección Jurídica</option>
              <option>Oficina De Comunicaciones</option>
              <option>Oficina De Control Interno</option>
              <option>Oficina De Control Interno Disciplinario</option>
              <option>Oficina De Sistemas</option>
              <option>Secretaría General</option>
            </select>
          </div>
          <!------------------------------ FILTRO PERIODICIDAD 1 ------------------------------->
          <div class="col-md-4 col-lg-3 col-sm-12 mt-2 pb-3">
            <select
              class="form-control p-2"
              v-model="filtroPeriodicidad"
              @change="filtrarPeriocidad()"
            >
              <option value="" Disabled>Periodicidad</option>
              <option value="">Todas</option>
              <option>Semanal</option>
              <option>Quincenal</option>
              <option>Mensual</option>
              <option>Bimestral</option>
              <option>Trimestral</option>
              <option>Cuatrimestral</option>
              <option>Semestral</option>
              <option>Anual</option>
            </select>
          </div>
        </div>
        <!-- ------------------------------- ANIMACION ------------------------------------------->
        <div class="text-center" v-if="estadoPeticion">
          <AnimacionCargando></AnimacionCargando>
        </div>
        <!-- ------------------------------- TABLA ------------------------------------------->
        <div class="table-responsive">
          <paginate
            ref="paginator"
            name="lineamientos"
            :list="lineamientos"
            :per="4"
          >
            <table
              class="table table-hover table-inverse"
              v-if="!estadoPeticion"
            >
              <thead class="bg-gradient-azul-sena text-white" id="listado">
                <tr>
                  <th>EJE</th>
                  <th>ACCIÓN</th>
                  <th>PROCESO NIVEL 1</th>
                  <th>PROCESO NIVEL 2</th>
                  <th>DEPENDENCIA</th>
                  <th>GRUPO</th>
                  <th>PERSPECTIVA</th>
                  <th>OBJETIVO</th>
                  <th>INDICADOR</th>
                  <th>PACTO</th>
                  <th>LINEA</th>
                  <th>OBJETIVO</th>
                  <th>ESTRATEGIA</th>
                  <th>DIMENSIONES</th>
                  <th>POLITICAS</th>
                  <th>PLANES</th>
                  <th>TEMA</th>
                  <th>LINEAMIENTO</th>
                  <th>RESULTADO ESPERADO</th>
                  <th>EVIDENCIA DE SEGUIMIENTO 1</th>
                  <th>DESCRIPCIÓN DE ACTIVIDAD 1</th>
                  <th>PERIODICIDAD 1</th>
                  <th>EVIDENCIA DE SEGUIMIENTO 2</th>
                  <th>DESCRIPCIÓN DE ACTIVIDAD 2</th>
                  <th>PERIODICIDAD 2</th>
                  <th>RESPONSABLE DIRECCIÓN GENERAL</th>
                  <th>RESPONSABLE DESPACHO REGIONAL</th>
                  <th>RESPONSABLE CENTRO DE FORMACIÓN</th>
                  <th>CUPOS PLANEADOS</th>
                  <th>ANEXO</th>
                  <th>GLOSARIO</th>
                  <th>ACCIONES</th>
                </tr>
              </thead>
              <div v-if="lineamientos.length <= 0">
                <p>No hay datos aún.</p>
              </div>
              <tbody v-if="lineamientos.length > 0">
                <tr
                  v-for="(lineamiento, key) in paginated('lineamientos')"
                  :key="key"
                >
                  <td class="campos">
                    <div class="primera-seccion">{{ lineamiento.eje }}</div>
                  </td>
                  <td class="campos">
                    <div class="primera-seccion">{{ lineamiento.actividad }}</div>
                  </td>
                  <td class="campos">
                    <div  class="tercera-seccion">{{ lineamiento.tipo_proceso }}</div>
                  </td>

                  <td class="campos">
                    <div class="primera-seccion">{{ lineamiento.proceso }}</div>
                  </td>

                  <td class="campos">
                    <div class="tercera-seccion">{{ lineamiento.dependencia }}</div>
                  </td>

                  <td class="campos">
                    <div class="tercera-seccion">{{ lineamiento.grupo }}</div>
                  </td>
                  <td>
                    <div>{{ lineamiento.perspectiva }}</div>
                  </td>

                  <td class="campos">
                    <div class="primera-seccion">{{ lineamiento.objetivo_estrategico }}</div>
                  </td>

                  <td class="campos">
                    <div class="tercera-seccion">{{ lineamiento.indicador }}</div>
                  </td>
                  <td class="campos">
                    <div class="primera-seccion">{{ lineamiento.pacto }}</div>
                  </td>
                  <td class="campos">
                    <div class="primera-seccion">{{ lineamiento.linea }}</div>
                  </td>

                  <td class="campos">
                    <div class="segunda-seccion">
                      {{ lineamiento.pes_objetivo }}
                    </div>
                  </td>
                  <td class="campos">
                    <div class="segunda-seccion">
                      {{ lineamiento.pes_estrategia }}
                    </div>
                  </td>

                  <td class="campos">
                    <div class="tercera-seccion">
                      {{ lineamiento.mipg_dimenciones }}
                    </div>
                  </td>
                  <td class="campos">
                    <div class="tercera-seccion">
                      {{ lineamiento.mipg_politicas }}
                    </div>
                  </td>
                  <td class="campos">
                    <div class="tercera-seccion">
                      {{ lineamiento.mipg_planes }}
                    </div>
                  </td>
                  <td class="campos">
                    <div class="primera-seccion">
                      {{ lineamiento.linea_tema }}
                    </div>
                  </td>

                  <td class="campos">
                    <div class="primera-seccion">
                      {{ lineamiento.lineamiento }}
                    </div>
                  </td>
                  <td class="campos">
                    <div class="primera-seccion">
                      {{ lineamiento.resultado_esperado }}
                    </div>
                  </td>
                  <td>{{ lineamiento.herramienta_seguimiento_1 }}</td>
                  <td class="campos">
                    <div class="primera-seccion">
                      {{ lineamiento.observacion1 }}
                    </div>
                  </td>
                  <td>{{ lineamiento.periodicidad_1 }}</td>
                  <td>{{ lineamiento.herramienta_seguimiento_2 }}</td>
                  <td class="campos">
                    <div class="tercera-seccion">
                      {{ lineamiento.observacion2 }}
                    </div>
                  </td>
                  <td>{{ lineamiento.periodicidad_2 }}</td>
                  <td>{{ lineamiento.rol_direccion_general }}</td>
                  <td>{{ lineamiento.rol_despacho_regional }}</td>
                  <td>{{ lineamiento.rol_centro_formacion }}</td>
                  <td>{{ lineamiento.cupos_planeados }}</td>

                  <td class="campos">
                    <div class="tercera-seccion">{{ lineamiento.anexo }}</div>
                  </td>

                  <td class="campos">
                    <div class="tercera-seccion">
                      {{ lineamiento.glosario }}
                    </div>
                  </td>
                  <td>
                    <div
                      class="btn-group btn-spc"
                      tooltip="Eliminar Actividad"
                      tooltip-flow="top"
                    >
                      <a
                        @click="
                          eliminarActividad(
                            lineamiento.id_actividad,
                            lineamiento.actividad
                          )
                        "
                        class="btn btn-sm bg-oscuro-sena text-white"
                      >
                        <i class="fas fa-trash"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </paginate>
        </div>
        <!-- ----------------------------------- PAGINACION ------------------------------->
        <paginate-links
          class="pagination justify-content-end mt-3"
          for="lineamientos"
          :limit="3"
          :hide-single-page="true"
          :show-step-links="true"
          :full-page="true"
          :async="true"
          :classes="{
            ul: 'simple-links-container',
            li: 'simple-links-item',
            liActive: ['simple-links-item', 'active1'],
            'li.active': 'active1',
          }"
        ></paginate-links>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";

export default {
  components: {
    AnimacionCargando,
  },

  mounted() {
    this.cargarData();
  },

  data() {
    return {
      estadoPeticion: true,
      lineamientos: [],
      paginate: ["lineamientos"],
      /* Para Busqueda de Lineamientos Y Resultado Esperado */
      /* 1 */ filtro: "",
      /* 2 */ lineamientoFiltrado: [],
      /* Filtro Tipo de Proceso */
      /* 1 */ filtrotipoproceso: "",
      /* 2 */ tipoProcesoFiltrado: [],
      /* Filtro Responsable*/
      /* 1 */ filtroDependencia: "",
      /* 2 */ dependenciaFiltrado: [],
      /* Filtro Periodicidad 1 */
      /* 1 */ filtroPeriodicidad: "",
      /* 2 */ periocidadFiltrado: [],
    };
  },

  methods: {
    cargarData() {
      Axios()
        .get("listar_data")
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            this.lineamientos = respuesta.data.results;
            this.lineaOriginal = respuesta.data.results;
            this.estadoPeticion = false;
            console.log(respuesta);
          }
          if (respuesta.data.status == "info") {
            Swal.fire({
              title: "Informacion",
              text: respuesta.data.message,
              icon: "warning",
              confirmButtonText: "Aceptar",
              customClass: {
                confirmButton: "btn btn-azul-sena",
              },
            });
            this.estadoPeticion = false;
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        });
    },
    eliminarActividad(id, nombreActividad) {
      Swal.fire({
        title: "Eliminar Actividad",
        title: "Eliminar Actividad " + nombreActividad + "?",
        icon: "warning",
        confirmButtonText: "Eliminar",
        confirmButtonColor: "#007bb8",
        cancelButtonColor: "#2d2d2d",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
      }).then((respuesta) => {
        if (respuesta.isConfirmed) {
          Axios()
            .delete("eliminar_actividad/" + id)
            .then((respuesta) => {
              if (respuesta.data.status == "success") {
                Swal.fire({
                  title: "Actividad Eliminada",
                  icon: "success",
                  confirmButtonText: "Aceptar",
                  customClass: {
                    confirmButton: "btn btn-azul-sena",
                  },
                });
                // this.lineamientos.splice(index, 1);
                // this.cargarData()
              } else {
                Swal.fire({
                  title: "INFO",
                  text: respuesta.data.message,
                  icon: "warning",
                  confirmButtonText: "Aceptar",
                  customClass: {
                    confirmButton: "btn btn-azul-sena",
                  },
                });
              }
            })
            .catch(() => {
              Swal.fire(
                "ERROR!",
                "Se ha presentado un error en el servidor!",
                "error"
              );
            })
            .finally(() => {
              this.cargarData();
            });
        }
      });
    },
    filtrarLiniamientos() {
      let lineamientoFiltrado = [];
      // this.listaConvocatoriasFiltrado = []

      for (let linea of this.lineaOriginal) {
        let lineamiento = linea.lineamiento.toLowerCase();
        if (lineamiento.indexOf(this.filtro) >= 0) {
          lineamientoFiltrado.push(linea);
        }
      }
      this.lineamientos = lineamientoFiltrado;
      if (this.lineamientos.length == 0) {
        //busco no encontro
        Swal.fire({
          title: "No hay coincidencias",
          position: "center",
          timer: 2000,
          text: "No se encontró convocatorias que coincidan con las busqueda",
          showConfirmButton: false,
          backdrop: "rgba(0,0,0,0)",
          background: "#eeeeee",
        });
        this.filtro = "";
        this.lineamientos = this.lineaOriginal;
      }
    },
    filtrarTipoProceso() {
      let tipoProcesoFiltrado = [];

      for (let proceso of this.lineaOriginal) {
        let tipo = proceso.tipo_proceso;
        if (tipo.indexOf(this.filtrotipoproceso) >= 0) {
          tipoProcesoFiltrado.push(proceso);
        }
      }
      this.lineamientos = tipoProcesoFiltrado;
      if (this.lineamientos.length == 0) {
        //busco no encontro
        Swal.fire({
          title: "No hay coincidencias",
          position: "center",
          timer: 1000,
          text: "No se encontró convocatorias que coincidan con las busqueda",
          showConfirmButton: false,
          backdrop: "rgba(0,0,0,0)",
          background: "#eeeeee",
        });
        this.filtrotipoproceso = "";
        this.lineamientos = this.lineaOriginal;
      }
      // let localist = this.lineamientos;
    },
    filtrarDependencia() {
      let dependenciaFiltrado = [];
      for (let dependencia of this.lineaOriginal) {
        let depende = dependencia.dependencia;
        if (depende.indexOf(this.filtroDependencia) >= 0) {
          dependenciaFiltrado.push(dependencia);
        }
      }
      this.lineamientos = dependenciaFiltrado;

      if (this.lineamientos.length == 0) {
        //busco no encontro
        Swal.fire({
          title: "No hay coincidencias",
          position: "center",
          timer: 1000,
          text: "No se encontró convocatorias que coincidan con las busqueda",
          showConfirmButton: false,
          backdrop: "rgba(0,0,0,0)",
          background: "#eeeeee",
        });
        this.filtroDependencia = "";
        this.lineamientos = this.lineaOriginal;
      }
    },
    filtrarPeriocidad() {
      let periocidadFiltrado = [];
      for (let periodicidad of this.lineaOriginal) {
        let periodo = periodicidad.periodicidad_1;
        if (periodo.indexOf(this.filtroPeriodicidad) >= 0) {
          periocidadFiltrado.push(periodicidad);
        }
      }
      this.lineamientos = periocidadFiltrado;

      if (this.lineamientos.length == 0) {
        //busco no encontro
        Swal.fire({
          title: "No hay coincidencias",
          position: "center",
          timer: 1000,
          text: "No se encontró convocatorias que coincidan con las busqueda",
          showConfirmButton: false,
          backdrop: "rgba(0,0,0,0)",
          background: "#eeeeee",
        });
        this.filtroPeriocidad = "";
        this.lineamientos = this.lineaOriginal;
      }
    },
  },
};
</script>
<style scoped>
.campos .primera-seccion {
  margin: 0%;
  width: 400px;
}
.campos .segunda-seccion {
  width: 1000px;
}
.campos .tercera-seccion {
  width: 200px;
}
.campos {
  margin: 0%;
  width: 100%;
}
.scroll-vertical {
  overflow: scroll;
  max-height: 400px;
}
#contenedor_todo {
  /* border: 1px seagreen solid; */
  display: flex;
  flex-wrap: wrap;
}
</style>
