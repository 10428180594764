
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class ModificarPerfil extends Vue {
  tiposdeapoyo = [];
  tiposdemonitoria = [];
  tiposMonitoria: any[] = [];
  tipoAlimentacion: any[] = [];
  tipoTransporte: any[] = [];
  subtipoTransporte: any[] = [];

  estadoPeticion = false;
  estadoInscripcion=false;
  idConvocatoria = null;

  convocatoria = {
    centro_formacion_id: "",
    centro_formación: "",
    convocatoria: "",
    cupo_hombres_14_17: null,
    cupo_hombres_mayores: null,
    cupo_instructores: null,
    cupo_mujeres_14_17: null,
    cupo_mujeres_mayores: null,
    cupo_total: null,
    estimulo_mensual: "",
    fase_bienestar: "",
    fase_bienestar_id: "",
    fecha_final_convoca: "",
    fecha_inicio_convoca: "",
    id_convocatoria: "",
    ruta_documento: "",
    tipo_apoyo: "",
    tipo_alimentacion_id: "",
    tipo_transporte_id: "",
    subtipo_transporte_id: "",
    tipo_apoyo_id: "",
    tipo_monitoria: "",
    tipo_monitoria_id: "",
  };

  constructor() {
    super();

    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        // min: () => "Mínimo 8 caracteres",
        // email: () => "Correo invalido",
      },
    };
    this.$validator.localize("es", dict);
  }

  mounted() {
    this.idConvocatoria = this.$route.params.id;
    this.obtenerConvocatoria(this.$route.params.id);
    this.obtenerTiposMonitoria(this.$route.params.id);
  }

  obtenerTiposMonitoria(id) {
    Axios()
      .get("listar_convocatoria_tipos_monitorias/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.tiposMonitoria = respuesta.data.results;
        }
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }

  obtenerConvocatoria(id) {
    Axios()
      .get("listar_convocatoria/" + id)
      .then((respuesta) => {
        this.convocatoria = respuesta.data.results;
        this.tipoAlimentacion = respuesta.data.results.tipo_alimentacion;
        this.tipoTransporte = respuesta.data.results.tipo_transporte;
        this.subtipoTransporte = respuesta.data.results.subtipo_transporte;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }

  iniciarInscripcion() {
    Axios()
      .put("actualizar_fase_convocatoria/" + this.idConvocatoria, {
        id_convocatoria: this.idConvocatoria,
        fase_bienestar_id: 2,
        id_actualiza: JSON.parse(sessionStorage.getItem("usuario")).id_usuario,
      })
      .then((respuesta) => {
       this.estadoInscripcion=true;
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Cambio de fase correcto!",
            text: "Se ha cambiado la fase de la convocatoria exitosamente!",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.$router.push("GestionarConvocatoria");
        }
        return respuesta.data;
       
      });
  }
}
