
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";
import AnimacionTablasCargando from "../../../components/animacionTablasCargando.vue";
import router from "@/router";

@Component({
  components: {
    AnimacionCargando,
    AnimacionTablasCargando,
  },
})
export default class Login extends Vue {
  //variable para colocar estados
  obten = "" ;
  estadoPeticionRegistrar = true ;
  estadoFases = true;
  estadoConsultAnno = true;
  //fin  variable para colocarestados
  estadoPeticion = true;
  listaConvocatorias = [];
  filter = "";
  filterPicTipo = "";
  fecha_pic ;
  id_convocatoria_pic = 0;
  fechas_pic = [];
  docSubidosCont = 0;
  estadoPeticionTabla = false;
  paginate = ["listaDataTableFilter"];
  listGlobal = [];

  //listas para fiter global
    picTipoFiltradResult = [] ;
    filtrarPorFasesResut = [] ;
  //fin val

  existe_tipo_tecnico = false;
  existe_tipo_pedagogico = false;
  existe_tipo_clave = false;
  estadopeticionAnio = false;
  listaConvocatoriasFiltrado = [];
  // variablespara fases
  listadefases = [];
  filterPicfases = ""
  tablellenaOnull = true ;
  // fin variables para fases

  //val data table
    listaDataTable = [];
    listaDataTableFilter = [];

  //finlistada

  tipoPicFilter = "";

  capacitacion = {
    area_ruta: "",
    capacitacion: "",
    componentes: "",
    id_area_ruta: "",
    id_pic: "",
    plan_institucional_id: "",
    red_conocimiento_id: "",
    tipo_pic: "",
    eje: "",
  };

  docResolucon: any[] = null;
  docValido = false;
  idConvocatoriaActual = null;

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        min: () => "Mínimo 8 caracteres",
      },
    };
    this.$validator.localize("es", dict);
  }
  
  async mounted() {
    // await this.obtenerConvocatorias();
    this.obtenYearNow();
    await this.ontenerPlanInstitucional();
    await this.obtenerDataFormaulario();
    await this.obtenerPicFases();
  }
  async updated() {
    await this.listarTiposPic();
  }
  obtenYearNow ()
  {
    this.fecha_pic = new  Date().getFullYear().toString();
    // console.log(this.fecha_pic);
  }
  cambiarFase(conv)
  {
    this.obten = conv.id_pic_convocatoria;
    const id_usuario = JSON.parse(sessionStorage.getItem("usuario"));
    this.estadoPeticionRegistrar = false;
    Axios()
      .put("/actualizar_fase/" + conv.id_pic_convocatoria, {
        id_pic_convocatoria: conv.id_pic_convocatoria,
        pic_fase_id: conv.id_pic_fase,
        id_usuario_actualiza: id_usuario.id_usuario,
      })
      .then((res) => {
        let result = res.data.status;
        if (result == "error")
        {
          this.estadoPeticionRegistrar = true;
          Swal.fire({
          title: "Accion invalida " ,
          text: "No existen profesores inscritos",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#007bb8",
          icon: "error",
        });
        }else if(result == "info") {
        this.estadoPeticionRegistrar = true;
        Swal.fire({
          title: "Info" ,
          text: "No se envio email No hay intructores registrados en esta convocatoria" ,
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#007bb8",
          icon: "info",
        });
          this.fecha_pic = conv.plan_institucional_id;
          this.obtenerDataFormaulario();
        }else{
          this.estadoPeticionRegistrar = true;
        Swal.fire({
          title: "Convocatoria " + conv.id_pic_fase,
          text: conv.convocatoria +  " pasó a fase de postulación",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#007bb8",
          icon: "success",
        });
          this.fecha_pic = conv.plan_institucional_id;
          this.obtenerDataFormaulario();
        // router.back();
        }

      })
      .catch(() => {
        this.estadoPeticionRegistrar = false;

        Swal.fire({
          title: "ERROR",
        });
      })
      .finally(()=>
      {
        this.estadoPeticion = true;
      });
  }
  async obtenerConvocatorias() {
    this.filter = "";
    this.filterPicTipo = "";
    this.estadoPeticionTabla = false;
    await Axios()
      .get("listPuntual/" + this.fecha_pic)
      .then((respuesta) => {
        this.listaConvocatorias = respuesta.data.results;
        this.listaConvocatoriasFiltrado = respuesta.data.results;
        this.estadopeticionAnio = false;
        // console.log(this.listaConvocatorias);

        if (respuesta.data.results == null) {
          this.listaConvocatorias = [];
          this.listaConvocatoriasFiltrado = [];
        }
        this.estadoPeticion = false;
        // this.estadoPeticionTabla = false;
      })
      .then(() => {
        this.listarTiposPic();
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }
  ontenerPlanInstitucional() {
    Axios()
      .get("listar_plan_institucional")
      .then((respuesta) => {
        this.fechas_pic = respuesta.data.results;
        this.estadoPeticionTabla = false;
        // console.log("aqui laso la consulta de plan institucional ");
        // this.fecha_pic = respuesta.data.results[0].plan_institucional_id;
        //se retira esta linea para que obtenga el año de forma auntomatica y no pasarle el año de forma quemada
        // console.log(this.fecha_pic);
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }
  obtenerDataFormaulario(){
    this.estadopeticionAnio = true;
    Axios()
    .get("listar_convocatoria_pics/" + this.fecha_pic)
    .then((reponse)=>{
      this.listaDataTable = reponse.data.results;
      this.listaDataTableFilter = reponse.data.results;
      // console.log(this.listaDataTable);
    })
    .catch(()=>{

    Swal.fire({
                title: "No se pudo finalizar la capacitación",
                icon: "error",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
    })
    .finally(()=>{
    this.estadopeticionAnio = false;
    });
  }

  actualizarEstadoInscritos(id, index) {
    //NO UTILIZADO
    Swal.fire({
      title:
        'Finalizar ejecución de la capacitación "' +
        this.listaDataTableFilter[index].convocatoria +
        '"?',
      text: "Esta acción libera a los funcionarios que participaron en la capacitación",
      icon: "warning",

      confirmButtonText: "Finalizar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((respuesta) => {
      if (respuesta.isConfirmed) {
        Axios()
          .post("actualizar_estado_inscripcion_pic/" + id, {
            id_pic_estado_inscripcion: 4,
            id_usuario_actualiza: JSON.parse(sessionStorage.getItem("usuario"))
              .id_usuario,
          })
          .then((respuesta) => {
            if (respuesta.data.status == "success") {
              Swal.fire({
                title: "Capacitación terminada",
                icon: "success",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
            } else {
              Swal.fire({
                title: "No se pudo finalizar la capacitación",
                icon: "error",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.$router.back();
            }
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }

  eliminarConvocatoria(id, index) {
    Swal.fire({
      title: "Eliminar Convocatoria ",
      text: this.listaDataTableFilter[index].convocatoria + "?",
      icon: "warning",
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((respuesta) => {
      if (respuesta.isConfirmed) {
        Axios()
          .delete("eliminar_convocatoria_pic/" + id)
          .then((respuesta) => {
            if (respuesta.data.status == "success") {
              Swal.fire({
                title: "Convocatoria Eliminada",
                icon: "success",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#007bb8",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.listaDataTableFilter.splice(index, 1);
            } else {
              Swal.fire({
                title: "No se pudo eliminar la convocatoria",
                text: respuesta.data.message,
                icon: "error",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.$router.back();
            }
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }
  obtenerDocumento(event, pos, id_pic_convocatoria) {
    if (event.target.files[0].size <= 4000000) {
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.docResolucon = event.target.files;
        Swal.fire({
          title: "Subir los archivos",
          text:
            "(" +
            this.nombres() +
            ") de la Convocatoria " +
            this.listaDataTableFilter[pos].convocatoria +
            "?",
          icon: "question",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#007bb8",
          cancelButtonText: "Cancelar",
          showCancelButton: true,
        }).then((resp) => {
          if (resp.isConfirmed) {
            this.subirDocumentos(pos, id_pic_convocatoria);
          }
        });
        this.docValido = true;
      } else {
        this.docValido = false;
        this.docResolucon = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.docResolucon = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 4 MegaBytes!",
        "error"
      );
    }
  }
  nombres(): string {
    let nombre = "";
    for (const archivo of this.docResolucon) {
      nombre += archivo.name + ", ";
    }
    nombre = nombre.slice(0, -1);
    return nombre;
  }
  subirDocumentos(pos, id_pic_convocatoria) {
    this.estadoPeticion = true;
    let timerInterval;
    Swal.fire({
      title: "Cargando...",
      timer: 10000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const b = Swal.getHtmlContainer().querySelector("b");
        timerInterval = setInterval(() => {
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    });

    let id = JSON.parse(sessionStorage.getItem("usuario"));
    for (const documento of this.docResolucon) {
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      var data = new FormData();
      data.append("anexo", documento.name);
      data.append("documento", documento);
      data.append(
        "id_convocatoria",
        id_pic_convocatoria
        // this.listaConvocatoriasFiltrado[pos].id_pic_convocatoria
      );
      data.append("id_usuario_autenticado", id.id_usuario);
      Axios()
        .post(
          "almacenar_anexo_convocatoria_pic/" + id_pic_convocatoria,
          // this.listaConvocatoriasFiltrado[pos].id_pic_convocatoria,
          data,
          { headers: headers }
        )
        .then((respuesta) => {
          if ((respuesta.data.status = "success")) {
            this.docSubidosCont = this.docSubidosCont + 1;
            if (this.docResolucon.length == this.docSubidosCont) {
              Swal.fire({
                title: "Los Anexos fueron subidos exitosamente",
                icon: "success",
                text: "Se subieron " + this.docResolucon.length + " anexos",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#007bb8",
              });
              this.docSubidosCont = 0;
            }
          } else {
            Swal.fire({
              title: "Los Anexos No fueron subidos",
              icon: "error",
              text: respuesta.data.message,
              confirmButtonText: "Aceptar",
            });
          }
          // this.modalCargarAnexo = false;
          this.estadoPeticion = false;
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        });
    }
  }

  filtrarTipoPic(lista: any[])
  {
    let listaRetornar = [];

    if (this.tipoPicFilter != "") {
      for (let i = 0; i < lista.length; i++) {
        if (lista[i].pic_tipo_id == this.tipoPicFilter) {
          listaRetornar.push(lista[i]);
        }
      }
      return listaRetornar;
    } else {
      return lista;
    }
  }

  picFiltrado()
  {
    let arregloFiltrado = [];
    // this.listaConvocatoriasFiltrado = []

    for (let conv of this.listaDataTable) {
      let convocatoria = conv.convocatoria.toLowerCase();
      let tipo = conv.pic_tipo.toLowerCase();
      let codigo = conv.codigo_conv.toLowerCase();

      if (
        convocatoria.indexOf(this.filter) >= 0 ||
        tipo.indexOf(this.filter) >= 0 ||
        codigo.indexOf(this.filter) >= 0 ||
        this.filter == ""
      ) {
        arregloFiltrado.push(conv);
      }
    }

    this.listaDataTableFilter = arregloFiltrado;
    if (this.listaDataTableFilter.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró convocatorias que coincidan con las busqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#238276",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filter = "";
      this.listaDataTableFilter = this.listaDataTable;
      // this.paginate = ['listaConvocatoriasFiltrado']
    }
  }

  filtrarPorFases(){
    let convocatoriasFiltrado = [];
    //permitir que la lista este vasia
  //  this.listaDataTableFilter = this.listaDataTable;
    // console.log(this.listaDataTableFilter);
    for (let conv of this.listaDataTable) {

      let pic_fase = conv.id_pic_fase + "";

      if (pic_fase.indexOf(this.filterPicfases) >= 0
      || this.filterPicfases == "0")
      {
        convocatoriasFiltrado.push(conv);
      }
        this.listaDataTableFilter = convocatoriasFiltrado;
    }

    if (this.listaDataTableFilter.length == 0 ) {
        // alert("entre al else");
        Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró convocatorias que coincidan con las busqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#238276",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      // copyArray = this.listaConvocatorias;
      let arraynull = [];
      this.filter = "";
      // this.listaDataTableFilter = arraynull;
      this.listaDataTableFilter = this.listaDataTable
      }
  }

  async filterGlobal ()
  {
      for(let count of this.picTipoFiltradResult)
      {
        // count =
      }
    }

  async picTipoFiltrado() {
    this.listaDataTableFilter = [];
    this.listarTiposPic();
    for (let conv of this.listaDataTable) {
      let picTipo = conv.pic_tipo;
      if (this.filterPicTipo != "") {
        if (picTipo.indexOf(this.filterPicTipo) >= 0) {
          this.listaDataTableFilter.push(conv);
        } else {
          // no hay coincidencias
        }
      } else {
        this.listaDataTableFilter = this.listaDataTable;
      }
    }
      let locallista = this.listaDataTableFilter ;
      this.picTipoFiltradResult = locallista;
      // console.log(this.picTipoFiltradResults);
      // this.filterGlobal();
  }

  listarTiposPic() {
    for (let conv of this.listaDataTable) {
      if (conv.pic_tipo == "Técnico") {
        this.existe_tipo_tecnico = true;
      } else if (conv.pic_tipo == "Pedagógico") {
        this.existe_tipo_pedagogico = true;
      } else if (conv.pic_tipo == "Clave y Transversal") {
        this.existe_tipo_clave = true;
      }
    }
  }

  obtenerPicFases(){
    Axios()
    .get("listar_fases_pic")
    .then((respuesta)=>{

      this.listadefases = respuesta.data.results;
      // console.log(this.listadefases);

    })
    .catch((err)=>{
      Swal.fire(
          "Algo salió mal!",
          "Error de server",
          "error"
        );
    })
    .finally(() => {
      // console.log("animacion de carga");
    });
  }

  // updated(){
  //   this.obtenerConvocatorias()
  // }
}
