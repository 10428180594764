
import Axios from "@/Axios";
import { Component, Vue } from "vue-property-decorator";
import CrearGrupo from "../../../components/ModuloEgresados/RegistrarGrupo.vue";

@Component({
  components: {
    CrearGrupo,
  },
})
export default class ListarGrupos extends Vue {
  listaGrupos: any[] = [];

  //  mounted() {
  //     this.listarGrupos();
  //   }

  //  listarGrupos() {
  //   Axios()
  //     .get("listar_grupos/"+1)
  //     .then((respuesta) => {
  //         this.listaGrupos = respuesta.data.results;

  //     })
  //     .catch((error) => {
  //     });
  // }
}
