
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";
import AnimacionTablasCargando from "../../../components/animacionTablasCargando.vue";
import listaInscritos from "../../../components/ReportesBienestar/Regional/ReporteInscritosRegional.vue";
import Multiselect from "vue-multiselect";
@Component({
  components: {
    AnimacionCargando,
    AnimacionTablasCargando,
    Multiselect,
  },
})
export default class Login extends Vue {
  estadoPeticion = true;
  areasEscogidas: any[] = [];
  areas: any[] = [];
  faseEscogidas: any[] = [];
  fases: any[] = [];
  filterFase? = "";
  listaConvocatorias: any[] = [];
  estadoPeticionTabla = false;
  filter = "";
  paginate = ["listaConvocatoriasB"];
  listaConvocatoriasB = [];
  idConvocatoria = null;
  cantidadInscritos = 0;
  cantidadAdjudicados = 0;
  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        min: () => "Mínimo 8 caracteres",
        // email: () => "Correo invalido",
      },
    };
    this.$validator.localize("es", dict);
  }

  mounted() {
    this.obtenerConvocatorias();
    // this.obtenerFases();
  }

  // obtenerFases() {
  //   Axios()
  //     .get("crear_convocatoria")
  //     .then((res) => {
  //       if (res.data.status == "success") {
  //         this.fases = res.data.results.fases_bienestar;
  //       } else {
  //         Swal.fire({
  //           title: "ERROR!",
  //           text: "No hay fases de bienestar",
  //           icon: "error",
  //           confirmButtonText: "Aceptar",
  //           confirmButtonColor: "#007bb8",
  //         });
  //       }
  //     });
  // }

  obtenerConvocatorias() {
    Axios()
      .get(
        "listar_convocatorias/" +
          JSON.parse(sessionStorage.getItem("usuario")).id_usuario
      )
      .then((respuesta) => {
        this.listaConvocatorias = respuesta.data.results;
        this.listaConvocatoriasB = respuesta.data.results;
        this.estadoPeticion = false;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }

  eliminarConvocatoria(id: number, nombre: string) {
    this.eliminarConvenioColegios(id);
    Swal.fire({
      title: "Eliminar Convocatoria ",
      text: nombre + " ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
    }).then((resp) => {
      if (resp.isConfirmed) {
        Axios()
          .delete("eliminar_convocatoria/" + id)
          .then((respuesta) => {
            Swal.fire({
              title: "Convocatoria Eliminada",
              text: "La convocatoria se ha eliminado exitosamente!",
              icon: "success",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
            this.obtenerConvocatorias();
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }

  eliminarConvenioColegios(id) {
    Axios()
      .delete("eliminar_convocatoria_tipo_monitoria/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
        } else {
        }
      });
  }

  convocatoriaFiltrado() {
    let picFilter = [];
    // this.listaConvocatoriasFiltrado = []

    for (let convo of this.listaConvocatorias) {
      let convocatoria = convo.convocatoria.toLowerCase();
      let fase = convo.fase_bienestar.toLowerCase();
      let fecha_inicio = convo.fecha_inicio_convoca.toLowerCase();
      let fecha_fin = convo.fecha_final_convoca.toLowerCase();

      if (
        convocatoria.indexOf(this.filter.toLowerCase()) >= 0 ||
        fase.indexOf(this.filter.toLowerCase()) >= 0 ||
        fecha_inicio.indexOf(this.filter.toLowerCase()) >= 0 ||
        fecha_fin.indexOf(this.filter.toLowerCase()) >= 0
      ) {
        picFilter.push(convo);
      }
    }

    this.listaConvocatoriasB = picFilter;
    if (this.listaConvocatoriasB.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró la capacitación, que coincida con la búsqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#007bb8",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filter = "";
      this.listaConvocatoriasB = this.listaConvocatorias;
    }
  }
  cambiarFase(convocatoria) {
    // let cantInscritos = this.obtenerInscritos(convocatoria.id_convocatoria);
    this.obtenerInscritos(convocatoria.id_convocatoria);
    this.obtenerAdjudicados(convocatoria.id_convocatoria);

    Swal.fire({
      title: "Quieres cambiar el estado de la convocatoria?",
      text: "Tu fase actual es: " + " " + convocatoria.fase_bienestar,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
    }).then((respuesta) => {
      if (respuesta.isConfirmed) {
        if (convocatoria.fase_bienestar == "Creación") {
          this.cambiar_fase(2, convocatoria.id_convocatoria);
          // Swal.fire({
          //   title: "La convocatoria paso a Inscripcion ",
          //   text: cantInscritos+"",
          //   icon: "success",
          //   confirmButtonText: "Aceptar",
          //   confirmButtonColor: "#007bb8",
          // });
        } else if (convocatoria.fase_bienestar == "Inscripción") {
          console.log(
            this.cantidadInscritos + "    -    " + convocatoria.cupo_total
          );

          if (this.cantidadInscritos < convocatoria.cupo_total) {
            Swal.fire({
              title: "Error ",
              text: "No se pudo hacer el cambio de fase, porque no se cumple el cupo de inscritos",
              icon: "error",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
          } else {
            this.cambiar_fase(3, convocatoria.id_convocatoria);
          }
        } else if (convocatoria.fase_bienestar == "Adjudicación") {
          if (this.cantidadAdjudicados != convocatoria.cupo_total) {
            Swal.fire({
              title: "Error ",
              text: "No se pudo hacer el cambio de fase, porque no se asignaron los cupos de los adjudicados",
              icon: "error",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
          } else {
            console.log("yes");
            this.cambiar_fase(4, convocatoria.id_convocatoria);
          }
        } else if (convocatoria.fase_bienestar == "Ejecución") {
          this.cambiar_fase(7, convocatoria.id_convocatoria);
        } else {
          Swal.fire({
            title: "La convocatoria ya esta en su ultima fase",
            text: "Has realizado con exito todas las fases",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        }
      }
    });
  }

  cambiar_fase(fase_id, id_convovatoria) {
    Axios()
      .put("actualizar_fase_convocatoria/" + id_convovatoria, {
        id_convocatoria: id_convovatoria,
        fase_bienestar_id: fase_id,
        id_actualiza: JSON.parse(sessionStorage.getItem("usuario")).id_usuario,
      })
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Cambio de fase correcto!",
            text: "Se ha cambiado la fase de la convocatoria exitosamente!",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.obtenerConvocatorias();
          this.$router.push("GestionarConvocatoria");
        }
        return respuesta.data;
      });
  }
  obtenerInscritos(id_convocatoria) {
    Axios()
      .get("buscar_numero_inscritos/" + id_convocatoria)
      .then((respuesta) => {
        this.cantidadInscritos = respuesta.data.results;
      });
  }
  obtenerAdjudicados(id_convocatoria) {
    Axios()
      .get("obtener_adjudicados/" + id_convocatoria)
      .then((resp) => {
        this.cantidadAdjudicados = resp.data.results;
      });
  }
  FiltrarFase() {
    let Filtrado = [];
    for (let conv of this.listaConvocatorias) {
      let fase = conv.fase_bienestar.toLowerCase();

      if (fase.indexOf(this.filterFase.toLowerCase()) >= 0) {
        Filtrado.push(conv);
      }
    }
    this.listaConvocatoriasB = Filtrado;
    if (this.listaConvocatoriasB.length == 0) {
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró la fase que coincida con la búsqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#007bb8",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filterFase = "";
      this.listaConvocatoriasB = this.listaConvocatorias;
    }
  }
  // addTag(newTag) {
  //   const tag = {
  //     fase_bienestar: "",
  //     // id_fase_bienestar: null,
  //     id_fase_bienestar:
  //       newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
  //   };
  //   this.fases.push(tag);
  //   this.faseEscogidas.push(tag);

  // }
}
