
import { Component, Vue } from "vue-property-decorator";
import Swal from "sweetalert2";
import Axios from "@/Axios";
import AnimacionCargando from "../../../components/animacionCargando.vue";
import FotoPerfilComponente from "@/components/FotoPerfilComponente.vue";

@Component({
  components: {
    AnimacionCargando,
    FotoPerfilComponente,
  },
})
export default class Index extends Vue {
  estadoPeticion = false;
  filterExp = "";
  filterEdu = "";
  filterIdioma = "";

  fotoPerfil = null;
  fotoPerfilTemp = null;

  usuario = null;

  modalPasosAsignacionRed = false;

  areaRuta = {
    id_usuario: null,
    persona_id: null,
    id_area_ruta: null,
    area_ruta: "",
    red_conocimiento: "",
    id_red_conocimiento: null,
  };

  // Servicios
  listarIdiomasPersona: any[] = [];
  listarIdiomasFiltrado: any[] = [];

  listaEducacionPersona: any[] = [];
  listaEducacionFiltrado: any[] = [];

  listaExperienciaPersona: any[] = [];
  listaExperienciaFiltrado: any[] = [];

  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;
  id_usuario_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_usuario;

  datosPersonales = {
    correo: "",
    correo_aternativo: "",
    depto_expedicion: "",
    depto_nacimiento: "",
    depto_residencia: "",
    direccion: "",
    discapacidad: "",
    id_discapacidad: "",
    fecha_identificacion: "",
    fecha_nacimiento: "",
    genero: "",
    id_depto_expedicion: "",
    id_depto_nacimiento: "",
    id_depto_residencia: "",
    id_genero: "",
    id_municipio_expedicion: "",
    id_municipio_nacimiento: "",
    id_municipio_residencia: "",
    id_persona: "",
    id_tipo_identificacion: "",
    identificacion: "",
    localidad: "",
    municipio_expedicion: "",
    municipio_nacimiento: "",
    municipio_residencia: "",
    nacionalidad: "",
    pais_nacimiento: "",
    pais_residencia: "",
    porcentaje_discapacidad: "",
    primer_apellido: "",
    primer_nombre: "",
    segundo_apellido: "",

    segundo_nombre: "",
    telefono_fijo: "",
    telefono_movil: "",
    grupo_etnico:"",
  };

  experienciaPersona = {
    id_experiencia: "",
    persona_id: "",
    empresa: "",
    direccion_empresa: "",
    correo_empresa: "",
    salario: "",
    fecha_ingreso_empresa: "",
    fecha_retiro_empresa: "",
    cargo: "",
    area: "",
    tipo_contrato: "",
    jefe_inmediato: "",
    cargo_jefe: "",
    funciones: "",
    actividad_empresa: "",
    certificado: "",
    id_tipo_empresa: "",
    tipo_empresa: "",
    municipio: "",
    id_municipio: "",
    departamento: "",
    id_departamento: "",
    pais: "",
    id_pais: "",
  };

  datosEducacion = {
    actualizado: "",
    certificado: "",
    creado: "",
    fecha_fin: "",
    fecha_inicio: "",
    id_educacion: "",
    institucion: "",
    persona_id: "",
    tipo_educacion: "",
    titulo_obtenido: "",
  };

  idiomas = {
    id_idioma_persona: "",
    persona_id: "",
    idioma_id: "",
    idioma: "",
    institucion: "",
    nivel: "",
    certificado: null,
  };

  mounted() {
    this.mostrarDatosPersonales(this.id_persona_logeada);
    this.obtenerEducacionesPersona(this.id_persona_logeada);
    this.obtenerExperienciaPersona(this.id_persona_logeada);
    this.obtenerUsuario(this.id_usuario_logeada);
    this.obtenerIdiomasPersonales(this.id_persona_logeada);
    this.verFoto(this.id_usuario_logeada);
    console.log( this.id_persona_logeada)
  }

  obtenerAreaRuta(idUsuario) {
    Axios()
      .get("ver_area_red_usuario/" + idUsuario)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.areaRuta = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  obtenerUsuario(idUsuario) {
    Axios()
      .get("listar_usuario/" + idUsuario)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.usuario = respuesta.data.results;
          if (this.usuario.area_ruta_id != null) {
            this.obtenerAreaRuta(idUsuario);
          }
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  obtenerExperienciaPersona(id_xp) {
    Axios()
      .get("listar_experiencia_persona/" + id_xp)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listaExperienciaPersona = respuesta.data.results;
          this.listaExperienciaFiltrado = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  verDetallleExperienciaPersona(id_xe) {
    Axios()
      .get("ver_detalle_experiencia_persona/" + id_xe)
      .then((respuesta) => {})
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  mostrarDatosPersonales(id) {
    Axios()
      .get("ver_detalle_persona/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.datosPersonales = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })

      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }

  eliminarExperienciaPersona(id, index) {
    Swal.fire({
      title: "Eliminar Experiencia ",
      text: this.listaExperienciaPersona[index].empresa + "?",
      icon: "warning",
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((respuesta) => {
      if (respuesta.isConfirmed) {
        Axios()
          .delete(
            "eliminar_experiencia_persona/" +
              this.listaExperienciaPersona[index].id_experiencia
          )
          .then((respuesta) => {
            if (respuesta.data.status == "success") {
              Swal.fire({
                title: "Experiencia Eliminada",
                icon: "success",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.listaExperienciaPersona.splice(index, 1);
            } else {
              Swal.fire({
                title: "No se pudo eliminar la experiencia",
                text: respuesta.data.message,
                icon: "error",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
            }
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }

  //Idiomas
  obtenerIdiomasPersonales(idPersona) {
    Axios()
      .get("listar_idiomas_persona/" + idPersona)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listarIdiomasPersona = respuesta.data.results;
          this.listarIdiomasFiltrado = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  eliminarIdioma(id, index) {
    Swal.fire({
      title: "Eliminar Idioma ",
      text: this.listarIdiomasPersona[index].idioma + "?",
      icon: "warning",
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((respuesta) => {
      if (respuesta.isConfirmed) {
        Axios()
          .delete(
            "eliminar_idioma_persona/" +
              this.listarIdiomasPersona[index].id_idioma_persona
          )
          .then((respuesta) => {
            if (respuesta.data.status == "success") {
              Swal.fire({
                title: "Idioma Eliminado",
                icon: "success",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.listarIdiomasPersona.splice(index, 1);
            } else {
              Swal.fire({
                title: "No se pudo eliminar el idioma",
                text: respuesta.data.message,
                icon: "error",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
            }
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }
  //Fin de idiomas

  // Animacion despliegue
  datosDesplegables() {
    var contenido = document.getElementById("contenido");
    var button = document.getElementById("show-more");

    button.onclick = function () {
      if (contenido.className == "open") {
        contenido.className = "";
        button.innerHTML = "Ver más";
      } else {
        contenido.className = "open";
        button.innerHTML = "Ver menos";
      }
    };
  }
  obtenerEducacionesPersona(id_p) {
    Axios()
      .get("listar_educacion_persona/" + id_p)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listaEducacionPersona = respuesta.data.results;
          this.listaEducacionFiltrado = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  verDetalleEducacionPersona(id_e) {
    Axios()
      .get("ver_detalle_educacion_persona/" + id_e)
      .then((respuesta) => {})
      .finally(() => {
        this.estadoPeticion = true;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  //------------------------------------------------------------------

  lineaExperienciaFiltrado() {
    let picFilter = [];
    // this.listaConvocatoriasFiltrado = []

    for (let experiencia of this.listaExperienciaPersona) {
      let empresa = experiencia.empresa.toLowerCase();
      let actividad = experiencia.actividad_empresa.toLowerCase();

      if (
        empresa.indexOf(this.filterExp) >= 0 ||
        actividad.indexOf(this.filterExp) >= 0
      ) {
        picFilter.push(experiencia);
      }
    }

    this.listaExperienciaFiltrado = picFilter;
    if (this.listaExperienciaFiltrado.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró la experiencia, que coincida con la búsqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#238276",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filterExp = "";
      this.listaExperienciaFiltrado = this.listaEducacionPersona;
    }
  }
  //------------------------------------------------------------------
  lineaEducacionFiltrado() {
    let picFilter = [];

    for (let educacion of this.listaEducacionPersona) {
      let institucion = educacion.institucion.toLowerCase();
      let titulo = educacion.titulo_obtenido.toLowerCase();

      if (
        institucion.indexOf(this.filterEdu) >= 0 ||
        titulo.indexOf(this.filterEdu) >= 0
      ) {
        picFilter.push(educacion);
      }
    }

    this.listaEducacionFiltrado = picFilter;
    if (this.listaEducacionFiltrado.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró la educación, que coincida con la búsqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#238276",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filterEdu = "";
      this.listaEducacionFiltrado = this.listaEducacionPersona;
    }
  }

  //------------------------------------------------------------------
  //------------------------------------------------------------------
  lineaIdiomasFiltrado() {
    let picFilter = [];

    for (let idiomas of this.listarIdiomasPersona) {
      let institucion = idiomas.institucion.toLowerCase();
      let idioma = idiomas.idioma.toLowerCase();

      if (
        institucion.indexOf(this.filterIdioma) >= 0 ||
        idioma.indexOf(this.filterIdioma) >= 0
      ) {
        picFilter.push(idiomas);
      }
    }

    this.listarIdiomasFiltrado = picFilter;
    if (this.listarIdiomasFiltrado.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró el idioma, que coincida con la búsqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#238276",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filterIdioma = "";
      this.listarIdiomasFiltrado = this.listarIdiomasPersona;
    }
  }
  //------------------------------------------------------------------
  eliminarEducacion(id, index) {
    Swal.fire({
      title: "Eliminar Educación ",
      text: this.listaEducacionPersona[index].titulo_obtenido + "?",
      icon: "warning",
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((respuesta) => {
      if (respuesta.isConfirmed) {
        Axios()
          .delete(
            "eliminar_educacion_persona/" +
              this.listaEducacionPersona[index].id_educacion
          )
          .then((respuesta) => {
            if (respuesta.data.status == "success") {
              Swal.fire({
                title: "Educación Eliminada",
                icon: "success",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.listaEducacionPersona.splice(index, 1);
            } else {
              Swal.fire({
                title: "No se pudo eliminar la educación",
                text: respuesta.data.message,
                icon: "error",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
            }
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }
  //---------------------------------------------------------------

  verFoto(id_usuario) {
    this.fotoPerfil =
      this.$urlAPI + "ver_foto/" + id_usuario;
  }
  //---------------------------------------------------------------
  subirFoto() {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    //Creamos el formData
    var data = new FormData();
    //Añadimos la docinscrip seleccionada
    data.append("imagen", this.fotoPerfilTemp);
    data.append("id_usuario_autenticado", this.id_usuario_logeada);

    //Enviamos la petición
    Axios()
      .post("almacenar_foto", data, { headers: headers })
      .then((respuesta) => {
        if ((respuesta.data.status = "success")) {
          Swal.fire({
            title: "Se subió la Foto!",
            text: "Foto de Perfil cargado exitosamente!",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.verFoto(this.id_usuario_logeada);
        } else {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        }
        this.$router.push({ name: "GestionarHojaDeVidaENI" });
      });
  }
  //---------------------------------------------------------------
  obtenerFoto(event) {
    if (event.target.files[0].size <= 2000000) {
      //2mb tope
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".jpeg" || extDoc == ".jpg" || extDoc == ".png") {
        this.fotoPerfilTemp = event.target.files[0];
        Swal.fire({
          title: "Foto De Perfil Cargada!",
          text: "La foto a sido cargado exitosamente, presiona el botón subir foto para que sea guardada!",
          icon: "success",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#007bb8",
        });
      } else {
        this.fotoPerfilTemp = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es una foto!",
          "error"
        );
      }
    } else {
      this.fotoPerfilTemp = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 2 MegaBytes!",
        "error"
      );
    }
  }
}
