
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";


@Component({
  components: {
    AnimacionCargando,
  },
})
export default class VoceroVer extends Vue {
  estado = false;
  cargarDatos= false;
  usuario= ""
  tipovocero= [];
 vocero = {
    id_persona: null,
    primer_nombre: "",
    segundo_nombre: "",
    primer_apellido: "",
    segundo_apellido: "",
    tipo_documento: "",
    identificacion: null,
    modalidad: "",
    jornada: "",
    programa_formacion: "",
    id_ficha_caracterizacion: null,
    id_tipo_vocero: [],
  };
  mounted() {
    this.vocero.id_persona = this.$route.params.id;
    this.obtenerPersona(this.vocero.id_persona);
}
 obtenerPersona(id) {
  this.cargarDatos=true;
    Axios()
      .post("buscar_vocero_por_iden", { identificacion: id })
      .then((respuesta) => {
        this.cargarDatos=false;
        if (respuesta.data.status == "success") {
          this.vocero = respuesta.data.results;
        } else {
          Swal.fire({
            title:  respuesta.data.message,
          }).then(() => {
            this.$router.back();
          })
           .finally(() => {
            this.cargarDatos=false;
      });
        }
      });
  }
 datosVacios(dato) {
    if (dato == null || dato == "" || dato == " ") {
      return "No hay datos registrados";
    } else {
      return dato;
    }
 }
  }
