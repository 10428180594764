
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import AnimacionCargando from "@/components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class VerDetallePicEje extends Vue {
  estadoPeticion = false;

  ver: any = false;
  areas: any[] = [];
  picTipos: any[] = [];
  redesConocimiento: any[] = [];
  estado = false;

  pic_eje = {
    id_eje: "",
    eje: "",
  };

  mounted() {
    this.obtenerPicEje(this.$route.params.id);
  }

  obtenerPicEje(id_eje) {
    Axios()
      .get("listar_detalle_eje/" + id_eje)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.pic_eje = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .finally(() => {
        this.estado = true;
      });
  }
  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "Fecha mayor o igual a 2020",
        // max: () => "La suma de los cupos no puede ser mayor a " + this.convocatoria.cupo_total,
        alpha_spaces: () => "No permite números ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }
}
