
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";


@Component({
  components: {
    AnimacionCargando,
  },
})
export default class ModificarEntidadCapacitadora extends Vue {
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;
  estadoPeticionModificar = false;
  estado = false;
  docValido = false;
  estadoPeticion = false;
  rutadocumento = null;
  verModal = false;
  docResolucon = "";
  docVista = null;
  docPdf = null;

  Entidad_Capacitadora = {
    id_pic_entidad_capacitadora: "",
    entidad_capacitadora: "",
    nit: "",
    direccion: "",
    correo: "",
    numero_telefono: "",
    camara_comercio: null,
    url_pdf : null,
    name_doc : null,
  };
  mounted() {
    this.obtenerEntidadCapacitadora(this.$route.params.id);
  }
  mirarModal()
  {
    this.verModal = true;

    if (this.Entidad_Capacitadora.url_pdf != null ||this.Entidad_Capacitadora.url_pdf == 'null') {
      this.docVista = this.Entidad_Capacitadora.url_pdf;
    }else{
      this.docVista = URL.createObjectURL(this.Entidad_Capacitadora.camara_comercio);
      this.Entidad_Capacitadora.url_pdf = this.docVista;
    }
  }
//----------------------------------------------------------------------------
  // ------------------------------------------------------------------------
  obtenerEntidadCapacitadora(id_entidad) {
    Axios()
      .get("ver_entidad_capacitadora/" + id_entidad)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.Entidad_Capacitadora = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
        .finally(() => {
        this.estado = true;
      });
  }
  eliminarpdf()
  {

    if (this.Entidad_Capacitadora.url_pdf == null ) {
      this.docVista  = null;
    }else
    {
      Axios()
    .delete("eliminar_pdf_entidadCap/" + this.$route.params.id)
    .then((response) =>
    {
      if(response.data.status == 'success')
      {
        Swal.fire("success", "Documento Eliminado con exito", "success");
      }
    })
    .catch((res)=>
    {
      Swal.fire("ERROR", "No elimino el documento", "error");

    }).finally(()=>
    {
      this.obtenerEntidadCapacitadora(this.$route.params.id);
    });
    }
  this.obtenerEntidadCapacitadora(this.$route.params.id);

  }
  //----------------------------------------------------------------
  obtenerMinuta(event) {
    if (event.target.files[0].size <= 5000000) {
      //5mb tope
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.Entidad_Capacitadora.camara_comercio = event.target.files[0];
        this.Entidad_Capacitadora.name_doc = event.target.files[0].name;
        Swal.fire(
          "Certificación Cargada!",
          "Archivo cargado exitosamente!",
          "success"
        );
        this.docValido = true;
        // this.docResolucon = null;
      } else {
        this.docValido = false;
        this.Entidad_Capacitadora.camara_comercio = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.Entidad_Capacitadora.camara_comercio = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  }
  //----------------------------------------------------------------

  modificarEntidadCapacitador() {
    this.estadoPeticionModificar = true;
    this.estadoPeticion = true;
    if (this.Entidad_Capacitadora.url_pdf == null) {
      this.Entidad_Capacitadora.url_pdf = URL.createObjectURL(this.Entidad_Capacitadora.camara_comercio);
    }
    var data = new FormData();

    //Añadimos la docinscrip seleccionada
    data.append("nit", this.Entidad_Capacitadora.id_pic_entidad_capacitadora);
    data.append(
      "entidad_capacitadora",
      this.Entidad_Capacitadora.entidad_capacitadora
    );
    data.append("nit", this.Entidad_Capacitadora.nit);
    data.append("direccion", this.Entidad_Capacitadora.direccion);
    data.append("correo", this.Entidad_Capacitadora.correo);
    data.append("numero_telefono", this.Entidad_Capacitadora.numero_telefono);
    data.append("camara_comercio", this.Entidad_Capacitadora.camara_comercio);
    data.append("url_pdf", this.Entidad_Capacitadora.url_pdf);



    const headers = {
      "Content-Type": "multipart/form-data",
    };
    Axios()
      .post(
        "actualizar_entidad_capacitadora/" +
          this.Entidad_Capacitadora.id_pic_entidad_capacitadora,
        data,
        {
          headers: headers,
        }
      )
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire(
            "Se subió la Entidad Capacitadora!",
            "Educación Modificada",
            respuesta.data.status
          );
          //   this.estadoPeticion = false;
          this.$router.push({ name: "GestionarEntidadCapacitadora" });
        } else {
          Swal.fire(
            "ERROR!",
            "No Se Modifico La Entidad Capacitadora",
            "error"
          );
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(()=>{
        this.estadoPeticionModificar = false;
      });
  }
  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        if (this.Entidad_Capacitadora.name_doc == null ) {
          Swal.fire(
            "INFO!",
            "Es obligatorio el documento de la camara de comercio",
            "info"
          );
        }else
        {
        this.modificarEntidadCapacitador();
        }
      }
    });
  }
}
