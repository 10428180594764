
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class VerDetalleEducacionPersona extends Vue {
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;
  estado = false;

  Linea_Tecnologica = {
    id_pic_linea_tecnologica: "",
    linea_tecnologica: "",
  };
  mounted() {
    this.obtenerLineaTecnologica(this.$route.params.id);
  }
  obtenerLineaTecnologica(id_linea) {
    Axios()
      .get("listar_detalle_linea_tecnologica/" + id_linea)
      .then((respuesta) => {

        if (respuesta.data.status == "success") {
          this.Linea_Tecnologica = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
       .finally(() => {
        this.estado = true;
      });
  }
}
