
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import AnimacionCargando from "../../../components/animacionCargando.vue";
import Documento from "@/components/Documentos/Documento.vue";

@Component({
  components: {
    AnimacionCargando,
    Documento,
  },
})
export default class VerDetalleConvenio extends Vue {
  estadoPeticion = false;
  docValido = false;
  rutadocumento = null;
  verModal = false;
  colegiosConvenio: any[] = [];

  convenio = {
    convenio: "",
    id_convenio: "",
    regional_id: "",
    regional: "",
    id_centro_formacion: "",
    centro_formacion: "",
    fase_id: "",
    fase: "",
    sena_persona_id: "",
    numero_convenio: "",
    fecha_firma: "",
    fecha_inicio: "",
    fecha_final: "",
    sena_cargo: "",
    colegio_cargo: "",
    minuta: "",
  };
  mounted() {
    this.obtenerConvenio(this.$route.params.id);
    this.obtenerColegiosConvenio(this.$route.params.id);
  }
  obtenerColegiosConvenio(id) {
    Axios()
      .get("ver_detalle_convenio_colegio/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.colegiosConvenio = respuesta.data.results;
        }
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }
  obtenerConvenio(id) {
    Axios()
      .get("ver_detalle_convenio/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.convenio = respuesta.data.results;
          this.mostrarDocumento(id);
        }
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }
  mirarModal() {
    this.verModal = true;
  }
  obtenerDocumento(event) {
    if (event.target.files[0].size <= 5000000) {
      //5mb tope
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.convenio.minuta = event.target.files[0];
        Swal.fire(
          "Certificación Cargada!",
          "Archivo cargado exitosamente!",
          "success"
        );
        this.docValido = true;
        // this.docResolucon = null;
      } else {
        this.docValido = false;
        this.convenio.minuta = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.convenio.minuta = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  }
  mostrarDocumento(id_convenio) {
    this.rutadocumento =
      this.$urlAPI + "ver_certificado_minuta/" +
      id_convenio;
  }

  cambiarFaseConvenio() {
    Axios()
      .put("modificar_fase_convenio/" + this.convenio.id_convenio, {
        id_convenio: this.convenio.id_convenio,
        fase_id: 2,
        id_actualiza: JSON.parse(sessionStorage.getItem("usuario")),
      })
      .then(() => {
        Swal.fire({
          title: "Cambio de fase correcto!",
          text: "El Convenio pasó a Proyectar programa exitosamente!",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#007bb8",
          icon: "success",
        });
        this.$router.push("GestionarConvenio");
      })
      .catch(() => {
        Swal.fire({
          title: "ERROR",
          text: "No se puede cambiar de fase",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#007bb8",
          icon: "error",
        });
      });
  }
}
