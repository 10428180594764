var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-5 container-fluid"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[(!_vm.estadoPeticionTabla)?_c('div',{staticClass:"text-center"},[_c('AnimacionTablasCargando')],1):_vm._e(),(_vm.estadoPeticionTabla)?_c('div',[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-5 col-lg-4 mt-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"form-control shadow p-2",attrs:{"type":"text","placeholder":"Buscar por Nombre"},domProps:{"value":(_vm.filter)},on:{"keyup":function($event){$event.preventDefault();return _vm.representantesFiltrado()},"input":function($event){if($event.target.composing)return;_vm.filter=$event.target.value}}})]),_c('div',{staticClass:"justify-content-lg-end col-md-5 col-lg-8 mt-2"},[_c('paginate-links',{staticClass:"pagination justify-content-end",attrs:{"for":"listaRepresentantesFiltrado","limit":2,"hide-single-page":true,"show-step-links":true,"full-page":true,"classes":{
                ul: 'simple-links-container',
                li: 'simple-links-item',
                liActive: ['simple-links-item', 'active1'],
                'li.active': 'active1',
              }}})],1)]),_c('div',{staticClass:"table-responsive"},[_c('paginate',{ref:"paginator",attrs:{"name":"listaRepresentantesFiltrado","list":_vm.listaRepresentantesFiltrado,"per":10}},[_c('table',{staticClass:"table table-hover"},[_c('thead',{attrs:{"id":"listado"}},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Identificación")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Nombres")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("# Ficha")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Regional")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Centro de formación")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Acciones")])])]),_c('tbody',[(!_vm.hayDatos)?_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"4"}},[_vm._v(" No hay datos para mostrar ")])]):_vm._l((_vm.paginated(
                    'listaRepresentantesFiltrado'
                  )),function(usuario,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(usuario.identificacion))]),_c('td',[_vm._v(" "+_vm._s(usuario.primer_nombre)+" "+_vm._s(usuario.segundo_nombre)+" "),_c('br'),_vm._v(" "+_vm._s(usuario.primer_apellido)+" "+_vm._s(usuario.segundo_apellido)+" ")]),_c('td',[_vm._v(_vm._s(usuario.id_ficha_caracterizacion))]),_c('td',[_vm._v(_vm._s(usuario.regional))]),_c('td',[_vm._v(_vm._s(usuario.centro_formacion))]),_c('td',{attrs:{"scope":"row"}},[_c('div',{staticClass:"btn-toolbar btn-responsive-table",attrs:{"role":"toolbar"}},[_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Ver Respresentante","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"to":{
                            name: 'VerRepresentanteBienestar',
                            params: {
                              id: usuario.identificacion,
                            },
                          }}},[_c('i',{staticClass:"fas fa-eye"})])],1),_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Eliminar Respresentante","tooltip-flow":"top"}},[_c('a',{staticClass:"btn btn-sm bg-oscuro-sena text-white",on:{"click":function($event){return _vm.eliminarRepresentante(
                              usuario.id_persona,
                              key,
                              usuario.primer_nombre +
                                ' ' +
                                usuario.primer_apellido
                            )}}},[_c('i',{staticClass:"fas fa-trash-alt"})])])])])])})],2)])])],1)]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"border-radius-lg bg-gradient-primary card-header p-0 position-relative mt-n4 mx-3 z-index-2 shadow-3"},[_c('div',{staticClass:"pt-4 pb-3"},[_c('h1',{staticClass:"text-white text-capitalize ps-3 ms-3 title-header"},[_vm._v(" REPRESENTANTES ")])])])
}]

export { render, staticRenderFns }