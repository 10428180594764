
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";

/* --------------------------------------------- COMPONENTS-------------------------------------------------*/
@Component({
  components: {
    AnimacionCargando,
  },
})
export default class VerHistorialEvidenciaIndicador extends Vue {
  /* ------------------------------------------------ DATA -------------------------------------------------*/
  historialEvidenciaIndicador: any[] = [];
  documentoEvidencia: any[] = [];
  estadoPeticion = true;
  nombreIndicador: any[] = [];
  fechaInicio: any[] = [];
  fechaFinal: any[] = [];
  documento: any[] = [];
  primer_nombre: any[] = [];
  segundo_nombre: any[] = [];
  primer_apellido: any[] = [];
  rutadocumento = null;
  modalRequisito = false;
  paginate = ["historialEvidenciaIndicador"];
  id_indicador: any = [];

  /* FILTRO DE ESTADO */
  /* 1 */ historialEstado = [];
  /* 2 */ filtroEstado = [];
  /* 3 */ filtroStatus = "";
  /* --------------------------------------------- MOUNTED -------------------------------------------------*/
  mounted() {
    this.cargarHistorialEvidenciaIndicador();
    this.cargarDatosIndicador();
  }

  /* --------------------------------------------- METODOS -------------------------------------------------*/

  mostrarDocumento(id) {
    this.rutadocumento = this.$urlAPI + "visualizarDucumentoEvidencia/" + id;
  }

  cargarHistorialEvidenciaIndicador() {
    Axios()
      .get(
        "verificacion_historial_evidencia/" + this.$route.params.id_indicador
      )
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Axios()
            .get(
              "/mostrar_historial_indicador/" + this.$route.params.id_indicador
            )
            .then((respuesta) => {
              if (respuesta.data.status == "success") {
                this.historialEvidenciaIndicador = respuesta.data.results;
                this.historialEstado = respuesta.data.results;
                this.estadoPeticion = false;
              }
              if (respuesta.data.status == "info") {
                Swal.fire({
                  title: "Información",
                  text: respuesta.data.message,
                  icon: "warning",
                  confirmButtonText: "Aceptar",
                  customClass: {
                    confirmButton: "btn btn-azul-sena",
                  },
                });
                this.estadoPeticion = false;
              }
            });
        } else {
          Swal.fire({
            title: "Información",
            text: respuesta.data.message,
            icon: "warning",
            confirmButtonText: "Aceptar",
            customClass: {
              confirmButton: "btn btn-azul-sena",
            },
          });
          this.$router.push({
            name: "MisIndicadores",
          });
        }
      });
  }

  async cargarDatosIndicador() {
    await Axios()
      .get("/mostrar_indicador_historial/" + this.$route.params.id_indicador)
      .then((respuesta) => {
        this.nombreIndicador = respuesta.data.results[0].nombre_indicador;
        this.fechaInicio = respuesta.data.results[0].fecha_inicio;
        this.fechaFinal = respuesta.data.results[0].fecha_fin;
        this.documento = respuesta.data.results[0].documento_id;
        this.primer_nombre =respuesta.data.results[0].primer_nombre;
        this.segundo_nombre =respuesta.data.results[0].segundo_nombre;
        this.primer_apellido =respuesta.data.results[0].primer_apellido;
      });
  }

  eliminarEvidencia(id_historial_evidencia) {
    Swal.fire({
      title: "Eliminar Indicador",
      text: "¿Eliminar Evidencia?",
      icon: "warning",
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((respuesta) => {
      if (respuesta.isConfirmed) {
        this.estadoPeticion = true;
        Axios()
          .delete("/eliminar_evidencia_historial/" + id_historial_evidencia)
          .then((respuesta) => {
            if (respuesta.data.status == "success") {
              Swal.fire({
                title: "Evidencia Eliminada",
                icon: "success",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
            } else {
              Swal.fire({
                title: "Información",
                text: respuesta.data.message,
                icon: "warning",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "bg-gradient-primary",
                },
              });
            }
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha detectado un error en el servidor!",
              "error"
            );
          })
          .finally(() => {
            this.$router.push({
              name: "MisIndicadores",
            });
            this.estadoPeticion = false;
          });
      }
    });
  }

  /* filtrarEstado(){
    let filtroEstado = [];
    for(let estados of this.historialEstado){
      let status = estados.estado;
      if(status.indexOf(this.filtroStatus)>=0){
        filtroEstado.push(estados);
      }
    }
    this.historialEvidenciaIndicador = filtroEstado;
    console.log(this.historialEvidenciaIndicador);
    if (this.historialEvidenciaIndicador.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró convocatorias que coincidan con las busqueda",
        showConfirmButton: false,
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filtroStatus = "";
      this.historialEvidenciaIndicador = this.historialEstado;
    }
  } */

  verificacionActualizar(id_historial_evidencias) {
    Axios()
      .get(
        "verificacion_estados/" +
          this.$route.params.id_indicador +
          "/" +
          id_historial_evidencias
      )
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.id_indicador = respuesta.data.results.id_indicador;
          this.$router.push({
            name: "ModificarHistorialEvidencia",
            params: {
              id_historial_evidencia: id_historial_evidencias,
              id_indicador: this.id_indicador,
            },
          });
        }
        if (respuesta.data.status == "info1") {
          Swal.fire({
            title: "Información",
            text: respuesta.data.message,
            icon: "warning",
            confirmButtonText: "Aceptar",
            customClass: {
              confirmButton: "btn btn-azul-sena",
            },
          });
          this.cargarHistorialEvidenciaIndicador();
        }
        if (respuesta.data.status == "info2") {
          Swal.fire({
            title: "Información",
            text: respuesta.data.message,
            icon: "warning",
            confirmButtonText: "Aceptar",
            customClass: {
              confirmButton: "btn btn-azul-sena",
            },
          });
          this.estadoPeticion = false;
          this.$router.push({
            name: "MisIndicadores",
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
}
