
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import AnimacionCargando from "../../../components/animacionCargando.vue";
import AnimacionGuardando from "../../../components/animacionGuardando.vue";

@Component({
  components: {
    AnimacionCargando,
    AnimacionGuardando
  },
})
export default class VerDetalleCapacitacionENI extends Vue {
  usuarioLogueado = JSON.parse(sessionStorage.getItem("usuario"));
  estadoPeticion = false;
  estadoPeticionPostularme = false;
  vistapreviaDoc = null;
  idInscripcion = "";
  contadorsubidos = 0;
  postulacionExitosa = false;
  requisitossubidos = false;
  modal_ver_requisito = false;
  modal_ver_requisito_Vista = false;
  modal_datos_acta = false;
  index_doc_ver = 0;
  ArrayUrlsRequisitos: any[] = [];

  estado_descargando = false;

  acta_compormiso_bool = false
  bool_tipo_profesor = false;
  dirigido_a_profesores_planta = ""
  dirigido_a_profesores_contratistas = ""

  convocatoria = {
    capacitacion: "",
    componentes: null,
    convocatoria: "",
    fecha_final: "",
    fecha_inicio: "",
    financiacion: "",
    id_pic_convocatoria: "",
    id_pic_fase: "",
    id_usuario_actualiza: "",
    lugar: "",
    municipio_id: "",
    para_funcionarios: "",
    perfiles_requisitos: "",
    pertenece_a_red: "",
    pic_fase_convocatoria: "",
    pic_fase_id: "",
    pic_id: "",
    plan_institucional_id: "",
  };
  lista_capacitaciones: any[] = [];

  requisito = "";
  lista_perfiles_requisitos: any[] = [];
  docRequisito = null;
  //array de docrequisitos
  docsRequisitosArray: any[] = [];
  docValido = false;
  docValidoActa = false;
  numeroRequisitos = this.lista_perfiles_requisitos.length;
  //acta de compromiso
  documentoActa = null;
  actaCompromisoSubida = false;
  vistaPreviaActa = null;
  vistaPreviaDocRequisito = null;
  //-------------------------------------------
  datosActaCompromiso = {
    nombre_instructor: "",
    identificacion: "",
    numero_telefono: "",
    correo: "",
    red_conocimiento: "",
    cargo: "",
    area: "",
    nombre_evento: "",
    fecha_inicio_evento: "",
    fecha_fin_evento: "",
    sede: "",
    aplicacion_conocimiento: "",
    regional: "",
    centro: "",
    ip_sena: "",
    nombre_subdirector: "",
    identificacion_subdirector: "",
  };
  //-------------------------------------------
  datosPersonales = {
    correo: "",
    correo_aternativo: "",
    depto_expedicion: "",
    depto_nacimiento: "",
    depto_residencia: "",
    direccion: "",
    discapacidad: "",
    id_discapacidad: "",
    fecha_identificacion: "",
    fecha_nacimiento: "",
    genero: "",
    id_depto_expedicion: "",
    id_depto_nacimiento: "",
    id_depto_residencia: "",
    id_genero: "",
    id_municipio_expedicion: "",
    id_municipio_nacimiento: "",
    id_municipio_residencia: "",
    id_persona: "",
    id_tipo_identificacion: "",
    identificacion: "",
    localidad: "",
    municipio_expedicion: "",
    municipio_nacimiento: "",
    municipio_residencia: "",
    nacionalidad: "",
    pais_nacimiento: "",
    pais_residencia: "",
    porcentaje_discapacidad: "",
    primer_apellido: "",
    primer_nombre: "",
    segundo_apellido: "",
    segundo_nombre: "",
    telefono_fijo: "",
    telefono_movil: "",
    ip_sena: "",
    nombre_subdirector: "",
    identificacion_subdirector: "",
  };
  areaRuta = {
    id_usuario: null,
    persona_id: null,
    id_area_ruta: null,
    area_ruta: "",
    red_conocimiento: "",
    id_red_conocimiento: null,
  };

  mounted() {
    this.obtenerPic(this.$route.params.id);
    this.mostrarDatosPersonales(this.usuarioLogueado.id_persona);
    this.obtenerAreaRuta();
  }

  updated() {
    //  this.obtenerDocumento();
  }

  generarActaCompromiso() {
    const headers = {
      responseType: "blob",
      // "Content-Disposition": "attachment;filename=report.pdf",
      "Content-Type": "application/octet-stream",
    };
    this.datosActaCompromiso.nombre_instructor =
      this.datosPersonales.primer_nombre +
      " " +
      this.datosPersonales.segundo_nombre +
      " " +
      this.datosPersonales.primer_apellido +
      " " +
      this.datosPersonales.segundo_apellido;
    //------------------------
    this.datosActaCompromiso.identificacion = this.datosPersonales.identificacion;

    this.datosActaCompromiso.nombre_subdirector = this.datosPersonales.nombre_subdirector;

    this.datosActaCompromiso.identificacion_subdirector = this.datosPersonales.identificacion_subdirector;


    this.datosActaCompromiso.ip_sena = this.datosPersonales.ip_sena;

    //------------------------
    this.datosActaCompromiso.correo = this.datosPersonales.correo;
    //------------------------
    this.datosActaCompromiso.numero_telefono =
      this.datosPersonales.telefono_movil
    //------------------------
    this.datosActaCompromiso.red_conocimiento = this.areaRuta.red_conocimiento;
    this.datosActaCompromiso.cargo = this.usuarioLogueado.perfil;
    this.datosActaCompromiso.area = this.areaRuta.area_ruta;
    //------------------------
    this.datosActaCompromiso.nombre_evento = this.convocatoria.capacitacion;
    //------------------------
    this.datosActaCompromiso.fecha_inicio_evento = this.convocatoria.fecha_inicio;
    this.datosActaCompromiso.fecha_fin_evento = this.convocatoria.fecha_final;
    this.datosActaCompromiso.sede = this.convocatoria.lugar;
    this.datosActaCompromiso.regional = this.usuarioLogueado.regional;
    this.datosActaCompromiso.centro = this.usuarioLogueado.centro_formacion;
    this.estado_descargando = true;
    Axios()
      .post("descargar_acta", this.datosActaCompromiso, {
        responseType: "blob",
      })
      .then((response) => {
        const type = response.headers["content-type"];
        const blob = new Blob([response.data], {
          type: type,
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download =
          this.datosActaCompromiso.nombre_instructor +
          " GTH_F_136_V03_FORMATO_ACTA_DE_COMPROMISO_CAPACITACIÓN_INSTRUCTORES.pdf";
        link.click();
        this.modal_datos_acta = false;
        this.estado_descargando = false;

      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
    // axios({
    //     url: 'https://develdfp.cloudsenactpi.net/api/descargar_acta',
    //     method: 'POST',
    //     responseType: 'blob', // important
    // }).then((response) => {
    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', 'file.pdf');
    //     document.body.appendChild(link);
    //     link.click();
    // });
  }
  vistaPreviaRequisito(docRequisitoMostrar) {
    this.vistaPreviaDocRequisito = URL.createObjectURL(docRequisitoMostrar);
    // this.index_doc_ver = -1;
  }
  vistaPreviaActaC(docMostrar) {
    this.vistaPreviaActa = URL.createObjectURL(docMostrar);
  }
  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        // el formulario se validó y tiene los datos que corresponden por campo
        //   this.RegistrarActividad();
        this.generarActaCompromiso();
      }
    });
  }
  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "No pude ser negativo",
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }

  profes_planta() {
    if (this.dirigido_a_profesores_planta == "yes") {
      this.bool_tipo_profesor = true
      this.acta_compormiso_bool = true
      this.dirigido_a_profesores_contratistas = "no"
    } else {
      this.bool_tipo_profesor = false
      this.acta_compormiso_bool = false
    }
  }

  profes_Contratistas() {
    if (this.dirigido_a_profesores_contratistas == "yes") {
      this.dirigido_a_profesores_planta = "no"
      this.bool_tipo_profesor = true
      this.acta_compormiso_bool = false
    } else {
      this.bool_tipo_profesor = false
      this.acta_compormiso_bool = false
    }
  }

  async postularme() {
    if (
      this.lista_perfiles_requisitos.length ==
      this.docsRequisitosArray.length
    ) {
      const usuario = JSON.parse(sessionStorage.getItem("usuario"));
      this.estadoPeticion = true;
      await Axios()
        .post("almacenar_inscripcion_pic", {
          id_pic_convocatoria: this.convocatoria.id_pic_convocatoria,
          id_persona: usuario.id_persona,
          id_pic_estado_inscripcion: 0,
          observaciones: "sin observaciones",
        })
        .then((respuesta) => {
          if (respuesta.data.status.toLowerCase() == "info") {
            Swal.fire({
              title: "Error al postularse",
              text: respuesta.data.message,
              icon: "error",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            }).then((results) => {
              if (results.isConfirmed) {
                this.estadoPeticionPostularme = false;
              }
            })
          }
          if (respuesta.data.status.toLowerCase() == "success") {
            this.estadoPeticionPostularme = true;
            Swal.fire({
              title: "Postulación Exitosa",
              icon: "success",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
            this.postulacionExitosa = true;

            this.idInscripcion = respuesta.data.results;
            //ciclo para subir cada requisito de la convocatoria
            for (let i = 0; i < this.docsRequisitosArray.length; i++) {
              const archivo = this.docsRequisitosArray[i];
              this.subirDocumento(archivo, i, this.idInscripcion);
            }
            this.subirActaCompromiso(this.documentoActa, this.idInscripcion);

            this.$router.push({
              name: "GestionarConvocatoriaActivasENI",
            });
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        }).finally(() => {
          this.estadoPeticionPostularme = true;
        })
    } else {
      // alerta

      Swal.fire({
        title: "Un momento!",
        icon: "info",
        text: "Si deseas postularte carga todos los requisitos!",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#007bb8",
      });
    }
  }

  obtenerAreaRuta() {
    Axios()
      .get("ver_area_red_usuario/" + this.usuarioLogueado.id_usuario)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.areaRuta = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado este", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  mostrarDatosPersonales(id) {
    Axios()
      .get("ver_detalle_persona/" + id)
      .then((respuesta) => {
        let val = respuesta
        console.log(val);
        if (respuesta.data.status == "success") {
          this.datosPersonales = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .finally(() => {
        this.estadoPeticion = true;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  obtenerPic(id) {
    Axios()
      .get("editar_convocatoria_pic/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.convocatoria = respuesta.data.results;
          this.convocatoria.pic_id = respuesta.data.results.pic_id;
          this.lista_perfiles_requisitos = this.convocatoria.perfiles_requisitos.split(
            ","
          );
          this.lista_perfiles_requisitos.splice(
            this.lista_perfiles_requisitos.length - 1,
            1
          );
          this.convocatoria.pic_id = respuesta.data.results.pic_id;
        } else {
          Swal.fire({
            title: respuesta.data.message,
          }).then(() => {
            this.$router.back();
          });
        }
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }

  obtenerDocumento(event, index) {
    if (event.target.files[0].size <= 5000000) {
      //5mb tope
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.docRequisito = event.target.files[0];

        //agregamos a un array de documentos
        this.docsRequisitosArray.splice(index, 1, this.docRequisito);

        Swal.fire({
          title: "Requisito Cargado exitosamente!",
          text: "Nombre documento cargado: " + this.docRequisito.name,
          icon: "success",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#007bb8",
        });

        this.docValido = true;
      } else {
        this.docValido = false;
        this.docRequisito = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.docRequisito = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
    //Asignamos la docinscrip a  nuestra data
  }

  obtenerActaCompromiso(event) {
    if (event.target.files[0].size <= 5000000) {
      //5mb tope
      let extDoc = event.target.files[0].name //nombre del archivo
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.documentoActa = event.target.files[0];
        Swal.fire({
          title: "Acta de compromiso cargada exitosamente!",
          text: "Nombre documento cargado: " + this.documentoActa.name,
          icon: "success",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#007bb8",
        });

        this.docValidoActa = true;
      } else {
        this.docValidoActa = false;
        this.documentoActa = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValidoActa = false;
      this.documentoActa = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
    //Asignamos la docinscrip a  nuestra data
  }

  async subirDocumento(archivo, i, idIns) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    //Creamos el formData
    var data = new FormData();
    //Añadimos la docinscrip seleccionada
    data.append("documento", archivo);
    data.append("nombre_anexo", this.lista_perfiles_requisitos[i]);
    data.append("id_pic_inscripcion", idIns);
    data.append(
      "id_usuario_autenticado",
      JSON.parse(sessionStorage.getItem("usuario")).id_persona
    );
    //Enviamos la petición
    // Axios().post('almacenar_resolucion_convocatoria/' + this.$route.params.id,{ id_convocatoria: this.$route.params.id, documento_resolucion : data})
    await Axios()
      .post("almacenar_requisito_inscripcion_pic", data, {
        headers: headers,
      })
      .then((respuesta) => {
        if ((respuesta.data.status = "success")) {
          // Swal.fire(
          //     "Se subió la resolución!",
          //     "Archivo cargado exitosamente!",
          //     respuesta.data.status
          // );
          this.contadorsubidos = this.contadorsubidos + 1;
        } else {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        }
        // this.estadoPeticion= false;
        if (this.contadorsubidos == this.lista_perfiles_requisitos.length) {
          //se subieron todos los archivos de los requisitos, ahora falta poner un sweet alert informando que se hizo todo bien
          this.requisitossubidos = true;
        }
      });
  }

  async subirActaCompromiso(archivo, idIns) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    //Creamos el formData
    var data = new FormData();
    //Añadimos la docinscrip seleccionada
    data.append("documento", archivo);
    data.append("nombre_anexo", "Acta De Compromiso");
    data.append("id_pic_inscripcion", idIns);
    data.append(
      "id_usuario_autenticado",
      JSON.parse(sessionStorage.getItem("usuario")).id_persona
    );
    //Enviamos la petición
    // Axios().post('almacenar_resolucion_convocatoria/' + this.$route.params.id,{ id_convocatoria: this.$route.params.id, documento_resolucion : data})
    await Axios()
      .post("almacenar_requisito_inscripcion_pic", data, {
        headers: headers,
      })
      .then((respuesta) => {
        if ((respuesta.data.status = "success")) {
          // Swal.fire(
          //     "Se subió la resolución!",
          //     "Archivo cargado exitosamente!",
          //     respuesta.data.status
          // );
          this.actaCompromisoSubida = true;
        } else {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        }
        //se subió todo y comparo las banderas
        if (
          this.requisitossubidos &&
          this.postulacionExitosa &&
          this.actaCompromisoSubida
        ) {
          //estan llegando falsos algunos, verificar
          Swal.fire({
            title: "Postulación Exitosa",
            text:
              "Estas inscrito a la convocatoria " +
              this.convocatoria.convocatoria,
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.estadoPeticion = false;
          this.$router.push({
            name: "GestionarConvocatoriaActivasENI",
          });
        }
      });
  }
}
