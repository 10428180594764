
import Axios from "@/Axios";
import router from "@/router";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import AnimacionCargando from "@/components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class VerDetalleEntidadCapacitadora extends Vue {
  redesConocimiento: any[] = [];
  estado = false;

  Entidad_Capacitadora = {
    id_pic_entidad_capacitadora: "",
    entidad_capacitadora: "",
    nit: "",
    direccion: "",
    correo: "",
    numero_telefono: "",
    camara_comercio: "",
  };

  mounted() {
    this.obtenerEntidadCapacitadora(this.$route.params.id);
  }

  obtenerEntidadCapacitadora(id_entidad) {
    Axios()
      .get("ver_entidad_capacitadora/" + id_entidad)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.Entidad_Capacitadora = respuesta.data.results;
        } else {
          Swal.fire({
            title: "No se obtuvo Capacitación",
            text: respuesta.data.message,
            icon: respuesta.data.status,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          }).then(() => {
            this.$router.back();
          });
        }
      })
      .finally(() => {
        this.estado = true;
      });
  }
}
