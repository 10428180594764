
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../components/animacionCargando.vue";
import AnimacionTablasCargando from "../../components/animacionTablasCargando.vue";

@Component({
  components: {
    AnimacionCargando,
    AnimacionTablasCargando,
  },
})
export default class Login extends Vue {
  estadoanimation = true;
  estadoPeticion = true;
  listaUsuarios = [];
  perfiles: any[] = [];
  mostrarLista = [];
  filter = "";
  colorSpinner = "azul-sena";
  modificarRolModal = false;
  usuarioModificarRol: any = null;
  estadoPeticionTabla = false;
  paginaActual = 1;
  totalUsuarios = 0;
  porPagina = 0;
  usuariobusqueda;
  identificacionbuscar = "";
  // PAGINACIÓN

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        min: () => "Mínimo 8 caracteres",
      },
    };
    this.$validator.localize("es", dict);
  }

  filtrar() {
    if (this.identificacionbuscar == "") {
      this.mostrarLista = this.listaUsuarios;
      return this.mostrarLista;
    } else {
      return this.mostrarLista;
    }
  }

  modificarRol(usuario) {
    this.usuarioModificarRol = usuario;
    this.obtenerPerfiles();
  }
  obtenerPerfiles() {
    Axios()
      .get("listar_perfiles")
      .then((respuesta) => {
        this.perfiles = respuesta.data.results;

        // this.$router.push({name: "dashboard"})
      })
      .catch((respuesta) => {
        //  Swal.fire((respuesta.data.status+"!").toString().toUpperCase(), respuesta.data.message, respuesta.data.status);
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor al cargar perfiles!",
          "error"
        );
      });
  }

  cambiarRol() {
    let perfil = this.perfiles.filter(
      (item) =>
        item.perfil.toLowerCase() ===
        this.usuarioModificarRol.perfil.toLowerCase()
    );
    Axios()
      .post("cambiar_perfil_usuario", {
        id_persona: this.usuarioModificarRol.id_persona,
        id_perfil: perfil[0].id_perfil,
      })
      .then((respuesta) => {
        this.modificarRolModal = false;
        Swal.fire({
          title: "Rol cambiado exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#007bb8",
        });
      });
  }

  cambiarPagina(pagina) {
    this.paginaActual = pagina;
    this.listarUsuarios();
  }

  mounted() {
    this.paginaActual = 1;
    this.listarUsuarios();
  }

  async buscarUsuario() {
    this.estadoanimation = false ;
    await Axios()
      .post("buscar_usuario", { identificacion: this.identificacionbuscar })
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.usuariobusqueda = respuesta.data.results;
          this.mostrarLista = [];
          this.mostrarLista.push(this.usuariobusqueda);
          // this.listaUsuarios = []
          // this.listaUsuarios.push(respuesta.data.results.data)

          // this.estadoPeticion = false;
        } else {
          Swal.fire({
            title: "perfil no existe",
            icon: "error",
          });
        }
      })
      .catch(() => {})
      .finally(()=>
      {
        this.estadoanimation = true;
      });
  }

  async listarUsuarios() {
    await Axios()
      .get("listar_usuarios?page=" + this.paginaActual)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listaUsuarios = respuesta.data.results.data;
          this.mostrarLista = this.listaUsuarios;
          const dataPaginacion = respuesta.data.results.paginacion;
          this.totalUsuarios = dataPaginacion.total_usuarios;
          this.porPagina = dataPaginacion.cantidad_por_pagina;
          this.paginaActual = dataPaginacion.pagina_actual;
          // dataPaginacion.siguiente_pagina;
          // dataPaginacion.anterior_pagina;
          this.estadoPeticion = false;
        } else {
          Swal.fire(
            (respuesta.data.status + "!").toString().toUpperCase(),
            respuesta.data.message,
            respuesta.data.status
          );
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }
  editar(key: number) {
    this.$router.push({
      name: "ModificarUSuario",
      params: this.listaUsuarios[key],
    });
  }
  ver(key: number) {
    this.$router.push({
      name: "VerDetalle",
      params: this.listaUsuarios[key],
    });
  }
  ordenar(n: number, type: string) {
    let rows,
      switching,
      i,
      x,
      y,
      shouldSwitch,
      dir,
      switchcount = 0;

    const table: any = document.getElementById("listado");
    switching = true;
    dir = "asc";

    while (switching) {
      switching = false;
      rows = table.rows;
      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];
        if (dir == "asc") {
          if (
            (type == "str" &&
              x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) ||
            (type == "int" && parseFloat(x.innerHTML) > parseFloat(y.innerHTML))
          ) {
            shouldSwitch = true;
            break;
          }
        } else if (dir == "desc") {
          if (
            (type == "str" &&
              x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) ||
            (type == "int" && parseFloat(x.innerHTML) < parseFloat(y.innerHTML))
          ) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount == 0 && dir == "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }
}
