
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";

/* --------------------------------------------- COMPONENTS-------------------------------------------------*/
@Component({
  components: {
    AnimacionCargando,
  },
})
export default class MisIndicadores extends Vue {
  /* ------------------------------------------------ DATA -------------------------------------------------*/
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_usuario;
  misIndicadores = [];
  indicadores = [];
  frecuencias = [];
  giresponsables = [];
  estadoPeticion = true;
  paginate = ["misIndicadores"];

  /* FILTRO DE INDICADOR */
  /* 2 */ filtroIndicador = "";
  /* 3 */ filtroIndi = [];
  /* FILTRO DE GRUPO RESPONSABLE */
  /* 1 */ filtroGrupoResponsable = [];
  /* 2 */ filtroResponsable = "";
  /* FILTRO DE FRECUENCIA */
  /* 1 */ filtroFrecuencia = [];
  /* 2 */ filtroFrecuen = "";
  /* --------------------------------------------- MOUNTED -------------------------------------------------*/
  mounted() {
    this.cargarMisIndicadores();
    this.ObtenerDatosParaFiltros();
  }
  /* --------------------------------------------- METODOS -------------------------------------------------*/
  cargarMisIndicadores() {
    Axios()
      .get("/mis_indicadores/" + this.id_persona_logeada)
      .then((respuesta) => {
        this.misIndicadores = respuesta.data.results;
        this.indicadores = respuesta.data.results;
        this.estadoPeticion = false;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  eliminarIndicador(id_indicador, nombre_indicador) {
    Swal.fire({
      title: "Eliminar Indicador",
      text: nombre_indicador + "?",
      icon: "warning",
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((respuesta) => {
      if (respuesta.isConfirmed) {
        Axios()
          .delete("eliminar_MisIndicadores/" + id_indicador)
          .then((respuesta) => {
            if (respuesta.data.status === "success") {
              Swal.fire({
                title: "Indicador Eliminado",
                icon: "success",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
            } else {
              Swal.fire({
                title: "Evidencia No Eliminada",
                text: respuesta.data.message,
                icon: "warning",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              
            }
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha detectado un error en el servidor!",
              "error"
            );
          })
          .finally(() => {
            this.cargarMisIndicadores();
          });
      }
    });
  }

  ObtenerDatosParaFiltros() {
    Axios()
      .get("crear_sigea_indicador")
      .then((respuesta) => {
        this.frecuencias = respuesta.data.results.frecuencias;
        this.giresponsables = respuesta.data.results.gi_responsables;
      });
  }

  filtroIndicadores() {
    let filtroIndi = [];
    for (let indi of this.indicadores) {
      let nombre = indi.nombre_indicador.toLowerCase();
      if (nombre.indexOf(this.filtroIndicador) >= 0) {
        filtroIndi.push(indi);
      }
    }
    this.misIndicadores = filtroIndi;
    if (this.misIndicadores.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 2000,
        text: "No se encontró convocatorias que coincidan con las busqueda",
        showConfirmButton: false,
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filtroIndicador = "";
      this.misIndicadores = this.indicadores;
    }
  }

  filtrarResponsable() {
    let filtroGrupoResponsable = [];
    for (let responsable of this.indicadores) {
      let respon = responsable.nombre_gi_responsable;
      if (respon.indexOf(this.filtroResponsable) >= 0) {
        filtroGrupoResponsable.push(responsable);
      }
    }
    this.misIndicadores = filtroGrupoResponsable;
    if (this.misIndicadores.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró convocatorias que coincidan con las busqueda",
        showConfirmButton: false,
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filtroResponsable = "";
      this.misIndicadores = this.indicadores;
    }
  }

  filtrarFrecuencia() {
    let filtroFrecuencia = [];
    for (let responsable of this.indicadores) {
      let respon = responsable.nombre_frecuencia;
      if (respon.indexOf(this.filtroFrecuen) >= 0) {
        filtroFrecuencia.push(responsable);
      }
    }
    this.misIndicadores = filtroFrecuencia;

    if (this.misIndicadores.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró convocatorias que coincidan con las busqueda",
        showConfirmButton: false,
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filtroFrecuen = "";
      this.misIndicadores = this.indicadores;
    }
  }
}
