import { render, staticRenderFns } from "./ModificarPICEje.vue?vue&type=template&id=b5ef8748&"
import script from "./ModificarPICEje.vue?vue&type=script&lang=ts&"
export * from "./ModificarPICEje.vue?vue&type=script&lang=ts&"
import style0 from "./ModificarPICEje.vue?vue&type=style&index=0&id=b5ef8748&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports