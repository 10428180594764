
  import { Vue } from "vue-property-decorator";
  import Component from "vue-class-component";
  import Axios from "@/Axios";
  import Swal from "sweetalert2";
  import Docs from "@/components/Documentos/Documento.vue"

  @Component({
    components: {
      Docs
    },
  })
  export default class Ejecucion extends Vue {
    docsPadre: any[] = [];
    inactivo = false;

    docEjecucion = {
      id_ejecucion: "",
      documento_id: "",
      convenio_id: "",
      colegio_id: "",
    }

    mounted() {
      this.docEjecucion.convenio_id = this.$route.params.id_convenio;
      this.docEjecucion.colegio_id = this.$route.params.id_colegio;
    }

    envioDocsMetodo(data) {
      this.docsPadre = data;
    }

    recorreDocs() {
      for (let i = 0; i < this.docsPadre.length; i++) {
      }
    }

    registrarEjecucion() {
      for (let i = 0; i < this.docsPadre.length; i++) {
        this.docEjecucion.documento_id = this.docsPadre[i].id_documento;

        Axios()
          .post("almacenar_ejecucion", this.docEjecucion)
          .then((respuesta) => {
            if (this.docEjecucion.documento_id != '') {
              if ((respuesta.data.status = "success")) {
                Swal.fire({
                  title: "Ejecución	Guardada",
                  text: "Se guardo la Ejecución",
                  icon: "success",
                  confirmButtonText: "Aceptar",
                  confirmButtonColor: "#007bb8",
                });
                this.$router.push({ name: "GestionarEjecucionColegios" });
              } else {
                Swal.fire("ERROR!", "No se Guardo la Ejecución", "error");
              }
            } else {
            }
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
      }
    }
  }
