var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-5 container-fluid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_vm._m(0),_c('br'),_c('h3',{staticClass:"mt-2 font-weight-bold"},[_vm._v(" Cantidad de Actividades : "+_vm._s(_vm.cantidadActividades)+" ")]),_c('br'),_c('paginate-links',{staticClass:"pagination justify-content-end",attrs:{"for":"listaActividades","limit":2,"hide-single-page":true,"show-step-links":true,"full-page":true,"classes":{
            ul: 'simple-links-container',
            li: 'simple-links-item',
            liActive: ['simple-links-item', 'active1'],
            'li.active': 'active1',
          }}})],1),(_vm.estadoPeticion)?_c('div',{staticClass:"text-center"},[_c('AnimacionCargando')],1):_vm._e(),(!_vm.estadoPeticion)?_c('div',{staticClass:"table-responsive"},[_c('paginate',{ref:"paginator",attrs:{"name":"listaActividades","list":_vm.listaActividades,"per":4}},[(!_vm.estadoPeticion)?_c('table',{staticClass:"table table-hover"},[_c('thead',{attrs:{"id":"listado"}},[_c('tr',[_c('th',{staticClass:"text-nowrap"},[_vm._v("#")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Nombre Actividad")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Nombre Responsable")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Correo")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Proceso")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Rol Centro")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Rol Despacho")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Rol Dirección")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Rol Estratégico")])])]),_c('tbody',[(_vm.listaActividades != null)?_vm._l((_vm.paginated(
                      'listaActividades'
                    )),function(actividad,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(key+1))]),_c('td',[_vm._v(_vm._s(actividad.actividad))]),_c('td',[_vm._v(_vm._s(actividad.apellidos)+" "+_vm._s(actividad.nombres))]),_c('td',[_vm._v(_vm._s(actividad.correo))]),_c('td',[_vm._v(_vm._s(actividad.proceso))]),_c('td',[_vm._v(_vm._s(actividad.rol_centro_formacion))]),_c('td',[_vm._v(_vm._s(actividad.rol_despacho_regional))]),_c('td',[_vm._v(_vm._s(actividad.rol_direccion_general))]),_c('td',[_vm._v(_vm._s(actividad.rol_estrategico))])])}):_c('tr',[_vm._v(" No hay Actividades ")])],2)]):_vm._e()])],1):_vm._e(),_c('paginate-links',{staticClass:"pagination justify-content-end",attrs:{"for":"listaActividades","limit":2,"hide-single-page":true,"show-step-links":true,"full-page":true,"classes":{
            ul: 'simple-links-container',
            li: 'simple-links-item',
            liActive: ['simple-links-item', 'active1'],
            'li.active': 'active1',
          }}}),_c('br'),_c('h2',{staticClass:"text-azul-sena"},[_vm._v("Herramientas de Seguimiento")]),_c('canvas')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-header"},[_c('h1',{staticClass:"text-azul-sena"},[_c('h1',{staticClass:"text-azul-sena"},[_vm._v("DATOS RELEVANTES DE EGRESADOS")])])])
}]

export { render, staticRenderFns }