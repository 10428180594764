
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";
import XLSX from "xlsx";
import Chart from "chart.js";
import BarChart from "@/components/Charts/BarChart";

/* --------------------------------------------- COMPONENTS-------------------------------------------------*/
@Component({
  components: {
    AnimacionCargando,
    BarChart,
  },
})
export default class ReportesIndicadores extends Vue {
  /* ------------------------------------------------ DATA -------------------------------------------------*/
  listadoIndicadores: any = [];
  buscarIndicadores: any = [];
  estadoPeticion = true;
  paginate = ["listadoIndicadores"];
  frecuencias = [];
  /* OBTENER GRAFICAS */
  /* 1 */ verGraficas = false;
  /* 2 */ canvas = document.getElementsByTagName("canvas");
  /* 3 */ contEstadoActivo = 0;
  /* 4 */ contEstadoInactivo = 0;
  /* 5 */ contFrecuen1 = 0;
  /* 6 */ contFrecuen2 = 0;
  /* 7 */ contFrecuen3 = 0;
  /* 8 */ contFrecuen4 = 0;
  /* 9 */ contFrecuen5 = 0;

  /* FILTRO DE FRECUENCIA */
  /* 1 */ filtroFrecuencia = [];
  /* 2 */ filtroFrecuen = "";
  /* FILTRO DE FRECUENCIA */
  /* 1 */ filtroEstado = [];
  /* 2 */ filtroStatus = "";

  /* FECHA */
  /* 1 */ fechayear = "";
  /* 2 */ fecha = [];
  /* 3 */

  /* --------------------------------------------- MOUNTED -------------------------------------------------*/
  async mounted() {
    await this.obtenerIndicadores();
    this.ObtenerDatosParaFiltros();
    this.obtenerCreate();
  }

  /* --------------------------------------------- METODOS -------------------------------------------------*/
  async obtenerIndicadores() {
    await Axios()
      .get("/listar_indicadores")
      .then((respuesta) => {
        this.listadoIndicadores = respuesta.data.results;
        this.buscarIndicadores = respuesta.data.results;
        this.estadoPeticion = false;
        this.generarActivoInactivo();
      });
  }

  ObtenerDatosParaFiltros() {
    Axios()
      .get("crear_sigea_indicador")
      .then((respuesta) => {
        this.frecuencias = respuesta.data.results.frecuencias;
        console.log(this.frecuencias);
      });
  }

  filtroEstadoIndicador() {
    let filtroEstado = [];
    for (let estado of this.buscarIndicadores) {
      let status = estado.estado_indicador;
      if (status.indexOf(this.filtroStatus) >= 0) {
        filtroEstado.push(estado);
      }
    }
    this.listadoIndicadores = filtroEstado;

    if (this.listadoIndicadores.length == 0) {
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró convocatorias que coincidan con las busqueda",
        showConfirmButton: false,
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filtroStatus = "";
      this.listadoIndicadores = this.buscarIndicadores;
    }
  }

  filtroFrecuenciaIndicador() {
    let filtroFrecuencia = [];
    for (let responsable of this.buscarIndicadores) {
      let respon = responsable.nombre_frecuencia;
      if (respon.indexOf(this.filtroFrecuen) >= 0) {
        filtroFrecuencia.push(responsable);
      }
    }
    this.listadoIndicadores = filtroFrecuencia;

    if (this.listadoIndicadores.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró convocatorias que coincidan con las busqueda",
        showConfirmButton: false,
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filtroFrecuen = "";
      this.listadoIndicadores = this.buscarIndicadores;
    }
  }

  generarReporte() {
    let data = XLSX.utils.json_to_sheet(this.listadoIndicadores, {
      header: [
        "nombre_indicador",
        "descripcion_indicador",
        "meta_indicador",
        "nombre_gi_responsable",
        "nombre_frecuencia",
        "estado_indicador",
        "cant_historial_evidencias",
      ],
    });
    (data["A1"].v = "NOMBRE DEL INDICADOR"),
      (data["B1"].v = "DESCRIPCION DEL INDICADOR"),
      (data["C1"].v = "META DEL INDICADOR"),
      (data["D1"].v = "GRUPO RESPONSABLE"),
      (data["E1"].v = "FRECUENCIA"),
      (data["F1"].v = "ESTADO DEL INDICADOR"),
      (data["G1"].v = "N° DE EVIDENCIAS");
    const workbook = XLSX.utils.book_new();
    const filename =
      "Reporte de Indicadores" +
      new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1);
    XLSX.utils.book_append_sheet(workbook, data, filename);
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  }

  generarActivoInactivo() {
    for (let i of this.listadoIndicadores) {
      if (i.estado_indicador == "Activo") {
        this.contEstadoActivo = this.contEstadoActivo + 1;
      } else if (i.estado_indicador == "Inactivo") {
        this.contEstadoInactivo = this.contEstadoInactivo + 1;
      }
    }
    /* FRECUENCIA */
    for (let i of this.listadoIndicadores) {
      if (i.nombre_frecuencia == "Mensual") {
        this.contFrecuen1 = this.contFrecuen1 + 1;
      } else if (i.nombre_frecuencia == "Bimensual") {
        this.contFrecuen2 = this.contFrecuen2 + 1;
      } else if (i.nombre_frecuencia == "Trimestral") {
        this.contFrecuen3 = this.contFrecuen3 + 1;
      } else if (i.nombre_frecuencia == "Cuatrimestral") {
        this.contFrecuen4 = this.contFrecuen4 + 1;
      } else if (i.nombre_frecuencia == "Semestral") {
        this.contFrecuen5 = this.contFrecuen5 + 1;
      }
    }
    this.obtenerGraficas();
  }

  obtenerGraficas() {
    new Chart(this.canvas[0], {
      type: "pie",
      data: {
        labels: ["Indicadores Activos", "Indicadores Inactivos"],
        datasets: [
          {
            label: "Estado del Indicador",
            data: [this.contEstadoActivo, this.contEstadoInactivo],
            backgroundColor: ["#F2A633", "#33DDF2"],
            borderColor: ["rgb(255, 99, 132)", "#247EF5"],
            borderWidth: 1,
          },
        ],
      },
    });
    new Chart(this.canvas[1], {
      type: "doughnut",
      data: {
        labels: [
          "Mensual",
          "Bimensual",
          "Trimestral",
          "Cuatrimestral",
          "Semestral",
        ],
        datasets: [
          {
            label: "Frecuencia del Indicador",
            data: [
              this.contFrecuen1,
              this.contFrecuen2,
              this.contFrecuen3,
              this.contFrecuen4,
              this.contFrecuen5,
            ],
            backgroundColor: [
              "#F2A633",
              "#33DDF2",
              "#E99AE9",
              "#7DDB39",
              "#E9783C",
            ],
            borderColor: [
              "#F2A633",
              "#33DDF2",
              "#E99AE9",
              "#7DDB39",
              "#E9783C",
            ],
            borderWidth: 1,
          },
        ],
      },
    });
  }

  obtenerCreate() {
    let create = [];
    let splitxd = [];
    let arrayReal = [];
    let contador = 1;
    let listaDefinitive = [];
    this.listadoIndicadores;
    console.log(this.listadoIndicadores);
    for (let array of this.listadoIndicadores) {
      create.push(array.creado);
    }
    for (let iterator in create) {
      splitxd.push(create[iterator].split("-"));
    }
    for (let index in splitxd) {
      arrayReal.push(splitxd[index][0]);
    }

    for (let data of arrayReal) {
      if (contador === 0) {
        listaDefinitive.push(data);
      } else {
        if (listaDefinitive[0] != data) {
          listaDefinitive.push(data);
        }
      }
      contador++;
    }

    // console.log(listaDefinitive);
    this.fecha = listaDefinitive;
  }

  filtroAnio() {
    let filtradoAnio = [];
    this.buscarIndicadores.map((data) => {
      if (data.creado.indexOf(this.fechayear) >= 0) {
        filtradoAnio.push(data);
      }
    });
    this.listadoIndicadores = filtradoAnio;
    console.log(filtradoAnio);
    if (this.listadoIndicadores.length == 0) {
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró convocatorias que coincidan con las busqueda",
        showConfirmButton: false,
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.fechayear = "";
      this.listadoIndicadores = this.buscarIndicadores;
    }
  }
}
