

import { Component, Vue } from "vue-property-decorator";

import AnimacionCargando from "../../../components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class CrearActaCompromiso extends Vue {

  // variables acta de compromiso
  acta_compromiso_data = {
    subdirector : 'Matias Lopez rodriguez',
    instructor : 'valentina fernandez gimenez',
    cargo :'ingeniero de software',
    identificacion : '565632563',
    ip_sena : '78278372',
    red_conocimiento : 'informatica y desarrollo  de software',
    area : 'programacion web',
    correo : 'valentina17@misena.edu.co',
    numero_telefono : '3145782314',
    regional : 'Cauca',
    centro : 'centro de teleinformatica y produccion industrial',
    nombre_evento : 'evento para la capacitacion de nuevas tecnologias',
    sede : 'valle del cauca ',
    fecha_inicio_evento : '12/12/2020',
    fecha_fin_evento : '01/13/2021',
  }
  

}

