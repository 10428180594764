var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-5 container-fluid"},[_c('div',{staticClass:"card"},[_vm._m(0),(!_vm.estadoPeticion)?_c('div',[_c('div',{staticClass:"col-lg-8 col-md-10 col-sm-12 offset-lg-2 offset-md-1"},[_c('AnimacionCargando')],1)]):_vm._e(),_c('div',{staticClass:"card-body"},[(_vm.estadoPeticion)?_c('div',[_vm._l((_vm.listaTrazabilidadActividad),function(trazabilidad,key){return _c('div',{key:key,staticClass:"mt-4 col-lg-8 col-md-10 col-sm-12 offset-lg-2 offset-md-1 center"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-4 col-lg-4"},[_vm._m(1,true),_c('div',{attrs:{"id":"contenido"}},[_c('p',{attrs:{"id":"texto"}},[_vm._v(_vm._s(trazabilidad.trazabilidad))])])]),_c('div',{staticClass:"col-sm-12 col-md-4 col-lg-4"},[_vm._m(2,true),_c('div',{attrs:{"id":"contenido"}},[_c('p',{attrs:{"id":"texto"}},[_vm._v(_vm._s(trazabilidad.asistencia))])])])])])}),_c('div',{},[_vm._m(3),_c('div',[_c('div',{staticClass:"pb-5 col-lg-4 mt-4 center"},[_vm._v(" "+_vm._s(_vm.actividad.estrategia_implementada)+" ")])])])],2):_vm._e(),_c('div',{staticClass:"col-lg-5 col-md-10 col-sm-12 offset-lg-2 offset-md-1 center pb-5"},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"to":{
            name: 'ModificarActividadTrazabilidad',
          }}},[_c('button',{staticClass:"btn btn-sm bg-oscuro-sena text-white"},[_vm._v(" Modificar Trazabilidad ")])])],1)]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-4 col-sm-12 mt-2 mb-4"},[_c('router-link',{staticClass:"btn btn-block bg-azul-sena text-white shadow-3 p-2",attrs:{"to":{
              name: 'GestionarActividadCentro',
            }}},[_vm._v(" Volver ")])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"border-radius-lg bg-gradient-primary card-header p-0 position-relative mt-n4 mx-3 z-index-2 shadow-3"},[_c('div',{staticClass:"pt-4 pb-3"},[_c('h1',{staticClass:"text-white text-capitalize ps-3 ms-3 title-header"},[_vm._v(" VER ACTIVIDAD TRAZABILIDAD ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v("Nombre de la Trazabilidad:")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v("Numero de Asistentes:")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{},[_c('div',{staticClass:"col-lg-4 mt-4 center"},[_c('strong',[_vm._v("ESTRATEGIA IMPLEMENTADA POR EL CENTRO DE FORMACIÓN PARA EL CUMPLIMIENTO DEL EJE:")])])])
}]

export { render, staticRenderFns }