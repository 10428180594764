
import { Component, Vue } from "vue-property-decorator";
import Swal from "sweetalert2";
import Axios from "@/Axios";
import AnimacionTablasCargando from "../../../../components/animacionTablasCargando.vue";

@Component({
  components: { AnimacionTablasCargando },
})
export default class Index extends Vue {
  filterLinea = "";
  listarLineaTecnologica: any[] = [];
  estadoPeticionTabla = false;

  filter = "";
  listaLineaTecnologica = [];

  Linea_Tecnologica = {
    id_pic_linea_tecnologica: "",
    linea_tecnologica: "",
  };

  mounted() {
    this.obtenerLineaTecnologica();
  }

  obtenerLineaTecnologica() {
    Axios()
      .get("/listar_lineas_tecnologica")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listarLineaTecnologica = respuesta.data.results;
          this.listaLineaTecnologica = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }

  lineaTecnologicaFiltrado() {
    let picFilter = [];
    // this.listaConvocatoriasFiltrado = []

    for (let linea of this.listarLineaTecnologica) {
      let tecnologica = linea.linea_tecnologica.toLowerCase();

      if (tecnologica.indexOf(this.filter) >= 0) {
        picFilter.push(linea);
      }
    }

    this.listaLineaTecnologica = picFilter;
    if (this.listaLineaTecnologica.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró la línea tecnologica, que coincida con la búsqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#238276",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filter = "";
      this.listaLineaTecnologica = this.listarLineaTecnologica;
    }
  }

  eliminarLineaTecnologica(id, index) {
    Swal.fire({
      title: "Eliminar Línea Tecnológica ",
      text: this.listarLineaTecnologica[index].linea_tecnologica + "?",
      icon: "warning",
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((respuesta) => {
      if (respuesta.isConfirmed) {
        Axios()
          .delete(
            "eliminar_linea_tecnologica/" +
              this.listarLineaTecnologica[index].id_pic_linea_tecnologica
          )
          .then((respuesta) => {
            if (respuesta.data.status == "success") {
              Swal.fire({
                title: "Línea Teconológica Eliminada",
                icon: "success",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#007bb8",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.listarLineaTecnologica.splice(index, 1);
            } else {
              Swal.fire({
                title: "No se pudo eliminar la línea teconológica",
                text: respuesta.data.message,
                icon: "error",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
            }
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }
}
