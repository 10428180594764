var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-5 container-fluid"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[(!_vm.estadoPeticionTabla)?_c('div',{staticClass:"text-center"},[_c('AnimacionTablasCargando')],1):_vm._e(),(_vm.estadoPeticionTabla)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-lg-6"},[_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-md-3 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"form-control shadow p-2",attrs:{"type":"text","placeholder":"Buscar Programa Formación"},domProps:{"value":(_vm.filter)},on:{"keyup":function($event){$event.preventDefault();return _vm.ProgramaFiltrado()},"input":function($event){if($event.target.composing)return;_vm.filter=$event.target.value}}})]),_c('div',{staticClass:"col-lg-4 col-sm-12 mt-1"},[_c('button',{staticClass:"btn btn-block bg-azul-sena text-white shadow-3 p-2",attrs:{"type":"azul-sena"},on:{"click":function($event){return _vm.cambiarFaseConvenio()}}},[_vm._v(" Fase viabilidad ")])])]),_c('div',{staticClass:"table-responsive"},[_c('paginate',{ref:"paginator",attrs:{"name":"listaProgramasProyectadosFiltrado","list":_vm.listaProgramasProyectadosFiltrado,"per":7}},[_c('table',{staticClass:"table table-hover"},[_c('thead',{attrs:{"id":"listado"}},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("id")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Programa Formación")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Estado")]),_c('th',{staticClass:"col-sm-2",attrs:{"scope":"col"}},[_vm._v("Acciones")])])]),_vm._l((_vm.paginated(
                      'listaProgramasProyectadosFiltrado'
                    )),function(programa,key){return _c('tbody',{key:key},[_c('td',[_vm._v(_vm._s(programa.programa_formacion_id))]),_c('td',[_vm._v(_vm._s(programa.programa_formacion))]),_c('td',[_vm._v(_vm._s(programa.estado))]),_c('td',{attrs:{"scope":"row"}},[_c('div',{staticClass:"btn-toolbar btn-responsive-table",attrs:{"role":"toolbar"}},[_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Ver Programa","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"to":{
                              name: 'VerDetalleProgramasAprobados',
                              params: {
                                id: programa.id_convenio_programa_proyectado,
                              },
                            }}},[_c('i',{staticClass:"fas fa-eye"})])],1),_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Aprobar programa","tooltip-flow":"top"}},[_c('b-button',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"type":"azul-sena"},on:{"click":function($event){return _vm.registrarColegioProgramaConvenio(
                                programa.programa_formacion_id,
                                programa.programa_formacion
                              )}}},[_c('i',{staticClass:"fas fa-plus"})])],1)])])])})],2)])],1),_c('paginate-links',{staticClass:"pagination justify-content-end",attrs:{"for":"listaProgramasProyectadosFiltrado","limit":2,"hide-single-page":true,"show-step-links":true,"full-page":true,"classes":{
                ul: 'simple-links-container',
                li: 'simple-links-item',
                liActive: ['simple-links-item', 'active1'],
                'li.active': 'active1',
              }}})],1),_c('div',{staticClass:"col-md-6 col-lg-6"},[_c('div',{staticClass:"row my-3"},[_vm._m(1),_c('div',{staticClass:"col-md-6 col-lg-6"},[_c('paginate-links',{staticClass:"pagination justify-content-end",attrs:{"for":"programasProyectTemp","limit":2,"hide-single-page":true,"show-step-links":true,"full-page":true,"classes":{
                    ul: 'simple-links-container',
                    li: 'simple-links-item',
                    liActive: ['simple-links-item', 'active1'],
                    'li.active': 'active1',
                  }}})],1)]),_c('div',{staticClass:"table-responsive"},[_c('paginate',{ref:"paginator",attrs:{"name":"programaColegioFiltrado","list":_vm.programaColegioFiltrado,"per":7}},[_c('table',{staticClass:"table table-hover"},[_c('thead',{attrs:{"id":"listado"}},[_c('tr',[_c('th',{staticClass:"col-sm-4",attrs:{"scope":"col"}},[_vm._v("Convenio")]),_c('th',{staticClass:"col-sm-4",attrs:{"scope":"col"}},[_vm._v("Colegio")]),_c('th',{staticClass:"col-sm-4",attrs:{"scope":"col"}},[_vm._v(" Programas Formación ")]),_c('th',{staticClass:"col-sm-2",attrs:{"scope":"col"}},[_vm._v("Acciones")])])]),_c('tbody',_vm._l((_vm.paginated(
                        'programaColegioFiltrado'
                      )),function(Programa,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(Programa.am_convenio.convenio))]),_c('td',[_vm._v(_vm._s(Programa.am_colegio.colegio))]),_c('td',[_vm._v(" "+_vm._s(Programa.programa_formacion.programa_formacion)+" ")]),_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Eliminar programa","tooltip-flow":"top"}},[_c('button',{staticClass:"btn btn-sm bg-oscuro-sena text-white",on:{"click":function($event){return _vm.eliminarColegioPrograma(
                              Programa.id_colegio_programa,
                              key
                            )}}},[_c('i',{staticClass:"fas fa-trash"})])])])}),0)])])],1),_c('paginate-links',{staticClass:"pagination justify-content-end",attrs:{"for":"programaColegioFiltrado","limit":2,"hide-single-page":true,"show-step-links":true,"full-page":true,"classes":{
                ul: 'simple-links-container',
                li: 'simple-links-item',
                liActive: ['simple-links-item', 'active1'],
                'li.active': 'active1',
              }}})],1)])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"border-radius-lg bg-gradient-primary card-header p-0 position-relative mt-n4 mx-3 z-index-2 shadow-3"},[_c('div',{staticClass:"pt-4 pb-3"},[_c('h1',{staticClass:"text-white text-capitalize ps-3 ms-3 title-header"},[_vm._v(" PROGRAMAS APROBADOS ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-md-6 col-lg-6"},[_c('strong',{staticClass:"text-azul-sena"},[_vm._v("Programas colegio")])])
}]

export { render, staticRenderFns }