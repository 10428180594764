
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import AnimacionCargando from "../../../components/animacionCargando.vue";

@Component({
  components: {
    Multiselect,
    AnimacionCargando,
  },
})
export default class ModificarPerfil extends Vue {
  usuariocurrent = JSON.parse(sessionStorage.getItem("usuario"));
  estadoPeticionModificar = false;
  estadoPeticion = false;
  modalCircular = false;
  docResolucon = null;
  docValido = false;
  rutadocumento: string = "";
  convocatoria = {
    id_convocatoria: "",
    centro_formacion_id: this.usuariocurrent.id_centro_formacion,
    convocatoria: "",
    cupo_hombres_14_17: 0,
    cupo_hombres_mayores: 0,
    cupo_instructores: 0,
    cupo_mujeres_14_17: 0,
    cupo_mujeres_mayores: 0,
    cupo_total: null,
    estimulo_mensual: "",
    // fase_bienestar: "Creación",
    fase_bienestar_id: 1,
    fecha_final_convoca: "",
    fecha_inicio_convoca: "",
    // id_convocatoria: null,
    ruta_documento: null,
    // tipo_apoyo: null,
    tipo_apoyo_id: null,

    tipo_alimentacion_id: "",
    tipo_transporte_id: "",
    subtipo_transporte_id: "",
    // tipo_monitoria: "",
    tipo_monitoria_id: null,
    id_medio_divulgacion: [],
    id_crea: this.usuariocurrent.id_usuario,
    medios_divulgacion: [],
    tipo_monitoria: [],
  };

  tiposdeapoyo = [];

  tiposdealimentacion: any[] = [];
  tiposdetransporte: any[] = [];
  subtiposdetransporte: any[] = [];
  tiposdemonitoria = [];
  mediosdivulgacion = [];
  monitoriaEscogido: any[] = [];
  monitoria: any[] = [];
  // tipoAlimentacion: any[] = [];
  // tipoTransporte: any[] = [];
  // subtipoTransporte: any[] = [];
  tipoAlimentacion = {
    id_tipo_alimentacion: null,
    tipo_alimentacion: "",
  };
  tipoTransporte = {
    id_tipo_transporte: null,
    tipo_transporte: "",
  };
  subtipoTransporte = {
    id_subtipo_transporte: null,
    tipo_transporte_personal: "",
  };

  constructor() {
    super();
  }

  mounted() {
    this.obtenerDatosParaFormulario();
    this.obtenerConvocatoria(this.$route.params.id);
    this.convocatoria.centro_formacion_id =
      this.usuariocurrent.id_centro_formacion;
    this.convocatoria.id_crea = this.usuariocurrent.id_usuario;
    this.llenarTiposMonitoria();
    this.obtenerTiposAlimentacion();
    this.obtenerTiposTransporte();
    this.obtenerSubtiposTransporte();
    this.mostrarDocumento(this.$route.params.id);
    // this.obtenerTiposMonitoria();
  }

  obtenerConvocatoria(id) {
    Axios()
      .get("listar_convocatoria/" + id)
      .then((respuesta) => {
        this.convocatoria = respuesta.data.results;
        this.tipoAlimentacion = respuesta.data.results.tipo_alimentacion;
        this.tipoTransporte = respuesta.data.results.tipo_transporte;
        this.subtipoTransporte = respuesta.data.results.subtipo_transporte;
        this.convocatoria.id_medio_divulgacion = [];
        for (const medio of this.convocatoria.medios_divulgacion) {
          this.convocatoria.id_medio_divulgacion.push(
            medio.id_medio_divulgacion
          );
        }
        const dict = {
          messages: {
            required: () => "Campo obligatorio",
            date_format: () => "Campo obligatorio",
            decimal: () => "Campo obligatorio",
            min_value: () => "No pude ser negativo",
            max: () =>
              "La suma de los cupos no puede ser mayor a " +
              this.convocatoria.cupo_total,
            alpha_spaces: () => "No permite numeros ni caracteres especiales",
            alpha_num: () => "No permite caracteres especiales",
          },
        };
        this.$validator.localize("es", dict);
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  obtenerTiposAlimentacion() {
    Axios()
      .get("listar_tipo_alimentacion")
      .then((respuesta) => {
        this.tiposdealimentacion = respuesta.data.results;
      });
  }
  obtenerTiposTransporte() {
    Axios()
      .get("listar_tipo_transporte")
      .then((respuesta) => {
        this.tiposdetransporte = respuesta.data.results;
      });
  }
  obtenerSubtiposTransporte() {
    Axios()
      .get("listar_subtipo_transporte")
      .then((respuesta) => {
        this.subtiposdetransporte = respuesta.data.results;
      });
  }
  obtenerDatosParaFormulario() {
    Axios()
      .get("crear_convocatoria")
      .then((respuesta) => {
        this.tiposdeapoyo = respuesta.data.results.tipos_apoyo;
        this.tiposdemonitoria = respuesta.data.results.tipos_monitoria;
        this.mediosdivulgacion = respuesta.data.results.medios_divulgacion;
      });
  }

  ModificarConvocatoria() {
    this.estadoPeticionModificar = true;
    this.convocatoria.medios_divulgacion = [1];
    // this.convocatoria.tipo_alimentacion_id = this.convocatoria.tipo_alimentacion.id_tipo_alimentacion,

    // this.convocatoria.tipo_transporte_id = this.convocatoria.tipo_transporte.id_tipo_transporte,
    //   this.convocatoria.subtipo_transporte_id = this.convocatoria.subtipo_transporte.id_subtipo_transporte,
    Axios()
      .put(
        "actualizar_convocatoria/" + this.$route.params.id,
        this.convocatoria
      )
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Convocatoria Modificada!",
            text: "Se ha modificado la convocatoria exitosamente",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.eliminarConvenioColegios();
          this.modificarTiposMonitoria(this.convocatoria.id_convocatoria);
          this.$router.push("GestionarConvocatoria");
        } else {
          Swal.fire({
            title: "Convocatoria no modificada!",
            icon: respuesta.data.status,
            text: respuesta.data.message,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionModificar = false;
      });
  }

  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        // el formulario se validó y tiene los datos que corresponden por campo
        if (
          this.convocatoria.fecha_inicio_convoca <
          this.convocatoria.fecha_final_convoca
        ) {
          if (this.convocatoria.tipo_apoyo_id == 3) {
            if (
              this.convocatoria.cupo_hombres_mayores +
                this.convocatoria.cupo_mujeres_14_17 +
                this.convocatoria.cupo_hombres_14_17 +
                this.convocatoria.cupo_mujeres_mayores !=
              this.convocatoria.cupo_total
            ) {
              Swal.fire({
                title: "Convocatoria no registrada!",
                icon: "error",
                text: "El número de cupos totales no está cubierto",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#007bb8",
              });
            } else {
              this.ModificarConvocatoria();
            }
          } else {
            this.ModificarConvocatoria();
          }
        } else {
          Swal.fire({
            title: "Convocatoria no modificada!",
            icon: "error",
            text: "No puede finalizar una convocatoria antes de iniciarla",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        }
      }
    });
  }

  // obtenerTiposMonitoria() {
  //   Axios()
  //     .get(
  //       "listar_tipo_monitoria_convocatoria/" +
  //         this.convocatoria.id_convocatoria
  //     )
  //     .then((respuesta) => {
  //       if (respuesta.data.status == "success") {
  //         this.tiposdemonitoria = respuesta.data.results.tipo_monitoria;
  //       } else {
  //         Swal.fire({
  //           title: respuesta.data.message,
  //         });
  //       }
  //     });
  // }

  addTag(newTag) {
    const tag = {
      monitoria: newTag,
      tipo_monitoria: "",
      id_tipo_monitoria:
        newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
    };
    this.tiposdemonitoria.push(tag);
    this.convocatoria.tipo_monitoria.push(tag);
  }

  modificarTiposMonitoria(id_convocatoria) {
    for (let lista of this.convocatoria.tipo_monitoria) {
      Axios()
        .post("almacenar_tipos_monitorias", {
          tipo_monitoria_id: lista.id_tipo_monitoria,
          convocatoria_id: id_convocatoria,
        })
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
          } else {
          }
        });
    }
  }

  llenarTiposMonitoria() {
    for (let list of this.convocatoria.tipo_monitoria) {
      this.convocatoria.tipo_monitoria.push(list.tipo_monitoria);
    }
  }

  eliminarConvenioColegios() {
    Axios()
      .delete(
        "eliminar_convocatoria_tipo_monitoria/" +
          this.convocatoria.id_convocatoria
      )
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
        } else {
        }
      });
  }
  obtenerDocumento(event) {
    if (event.target.files[0].size <= 5000000) {
      //5mb tope
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.docResolucon = event.target.files[0];
        Swal.fire({
          title: "Subir los Archivos",
          text: "Se Agregarán" + this.docResolucon.length + "Documento(s)",
          icon: "question",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#007bb8",
          cancelButtonText: "Cancelar",
          showCancelButton: true,
        }).then((resp) => {
          if (resp.isConfirmed) {
            this.subirDocumento();
          }
        });
        this.docValido = true;
      } else {
        this.docValido = false;
        this.docResolucon = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.docResolucon = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  }
  subirDocumento() {
    this.estadoPeticion = true;
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    //Creamos el formData
    var data = new FormData();
    //Añadimos la docinscrip seleccionada
    data.append("documento_resolucion", this.docResolucon);
    data.append("id_convocatoria", this.$route.params.id);
    //Enviamos la petición
    Axios()
      .post(
        "almacenar_resolucion_convocatoria/" + this.$route.params.id,
        data,
        { headers: headers }
      )
      .then((respuesta) => {
        if ((respuesta.data.status = "success")) {
          Swal.fire({
            title: "Se subió la resolución!",
            text: "Archivo cargado exitosamente!",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        } else {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        }
        this.estadoPeticion = false;
        this.$router.push({ name: "GestionarConvocatoria" });
      });
  }
  mostrarDocumento(id) {
    this.rutadocumento =
      this.$urlAPI + "mostrar_resolucion_convocatoria/" +
      id;
  }
  eliminarCircular(conv) {
    Swal.fire({
      title: "Desea Eliminar?",
      icon: "warning",
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((resp) => {
      if (resp.isConfirmed) {
        Axios()
          .put("eliminar_circular/" + conv.id_convocatoria)
          .then((respuesta) => {
            if (respuesta.data.status == "success") {
              Swal.fire({
                title: "Circular Eliminada",
                icon: "success",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#007bb8",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.obtenerConvocatoria(this.$route.params.id);
            }
          });
      }
    });
  }
}
class Convocatoria {
  centro_formacion_id: number;
  // centro_formación: this.usuariocurrent.centro_formacion,
  convocatoria: number;
  cupo_hombres_14_17: number;
  cupo_hombres_mayores: number;
  cupo_instructores: number;
  cupo_mujeres_14_17: number;
  cupo_mujeres_mayores: number;
  cupo_total: number;
  estimulo_mensual: number;
  // fase_bienestar: "Creación",
  fase_bienestar_id: number;
  fecha_final_convoca: string;
  fecha_inicio_convoca: string;
  // id_convocatoria: null,
  ruta_documento;
  // tipo_apoyo: null,
  tipo_apoyo_id: number;
  // tipo_monitoria: "",
  tipo_monitoria_id: number;
  id_medio_divulgacion: number[];
  id_crea: number;
  medios_divulgacion: any[];
}
