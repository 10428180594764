
import Axios from "@/Axios";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import Swal from "sweetalert2";
import XLSX from 'xlsx';

@Component({
  components: {},
})
export default class listaAdjudicados extends Vue {
  adjudicados: any[] = [];
  adjudicadosFiltrado: any[] = [];
  convocatoria: any[] = [];
  paginate = ["aprendicesInscritosFiltrado", "adjudicadosFiltrado"];
  aprendicesInscritos: any[] = [];
  aprendicesInscritosFiltrado: any[] = [];
  filtroA = "";
  filtroI = "";
  filterEstado= "";

  mounted() {
    this.obtenerAdjudicados(this.$route.params.id);
    this.obtenerInscritos(this.$route.params.id);
    this.obtenerConvocatoria(this.$route.params.id);
  }

  obtenerAdjudicados(id) {
    Axios()
      .get("listar_adjudicados/" + this.$route.params.id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.adjudicados = respuesta.data.results;
          this.adjudicadosFiltrado = respuesta.data.results;
        }
      });
  }
  obtenerInscritos(id) {
    Axios()
      .get("listar_inscripciones_convocatoria/" + this.$route.params.id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.aprendicesInscritos = respuesta.data.results;
          this.aprendicesInscritosFiltrado = respuesta.data.results;
        }
      });
  }
  obtenerConvocatoria(id) {
    Axios()
      .get("listar_convocatoria/" + id)
      .then((respuesta) => {
        this.convocatoria = respuesta.data.results;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  FiltrarAjudicados() {
    let Filtro = [];
    for (let adjudicados of this.adjudicados) {
      let identificacion = adjudicados.identificacion.toLowerCase();
      let primer_nombre = adjudicados.primer_nombre.toLowerCase();
      let primer_apellido = adjudicados.primer_apellido.toLowerCase();
      let segundo_nombre = "";
      let segundo_apellido = "";
      if(adjudicados.segundo_nombre != null){
           segundo_nombre = adjudicados.segundo_nombre.toLowerCase();
      }
      if(adjudicados.segundo_apellido != null){
        segundo_apellido = adjudicados.segundo_apellido.toLowerCase();
      }
      if (
        identificacion.indexOf(this.filtroA.toLowerCase()) >= 0 ||
        primer_nombre.indexOf(this.filtroA.toLowerCase()) >= 0 ||
        segundo_nombre.indexOf(this.filtroA.toLowerCase()) >= 0||
        primer_apellido.indexOf(this.filtroA.toLowerCase()) >= 0||
        segundo_apellido.indexOf(this.filtroA.toLowerCase()) >= 0
      ) {
        Filtro.push(adjudicados);
      }
    }
    this.adjudicadosFiltrado = Filtro;
    if (this.adjudicadosFiltrado.length == 0) {
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró la persona que coincida con la búsqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#007bb8",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filtroA = "";
      this.adjudicadosFiltrado = this.adjudicados;
    }
  }
  exportExcel(){
    let data = XLSX.utils.json_to_sheet(this.adjudicadosFiltrado,{
      header:[
      "identificacion",
        "primer_nombre",
        "segundo_nombre",
        "primer_apellido",
        "segundo_apellido",
      ]
    });
    (data["A1"].v = "identificacion"),
    (data["B1"].v = "primer_nombre"),
    (data["C1"].v = "segundo_nombre"),
    (data["D1"].v = "primer_apellido"),
    (data["E1"].v = "segundo_apellido");
    const workbook = XLSX.utils.book_new();
    const filename =
      "Reporte Adjudicados" +
      new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1);
    XLSX.utils.book_append_sheet(workbook, data, filename);
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  }
  FiltrarInscritos() {
    let arrayFiltrado = [];
    for (let inscrito of this.aprendicesInscritos) {
      let identificacion = inscrito.identificacion;
      let primer_nombre = inscrito.primer_nombre.toLowerCase();
      let primer_apellido = inscrito.primer_apellido.toLowerCase();
      let segundo_nombre = "";
      let segundo_apellido = "";
      if (inscrito.segundo_nombre != null) {
        segundo_nombre = inscrito.segundo_nombre.toLowerCase();
      }
      if (inscrito.segundo_apellido != null) {
        segundo_apellido = inscrito.segundo_apellido.toLowerCase();
      }
      console.log(inscrito);

      //agregar segundo nombre y segundo apellido
      if (
        identificacion.indexOf(this.filtroI) >= 0 ||
        primer_nombre.indexOf(this.filtroI.toLowerCase()) >= 0 ||
        segundo_nombre.indexOf(this.filtroI.toLowerCase()) >= 0 ||
        primer_apellido.indexOf(this.filtroI.toLowerCase()) >= 0 ||
        segundo_apellido.indexOf(this.filtroI.toLowerCase()) >= 0
        // ||
        // segundo_nombre.indexOf(this.filtroI.toLowerCase()) >= 0 ||
        // segundo_apellido.indexOf(this.filtroI.toLowerCase()) >= 0
      ) {
        arrayFiltrado.push(inscrito);
      }
    }
    this.aprendicesInscritosFiltrado = arrayFiltrado;
    if (this.aprendicesInscritosFiltrado.length == 0) {
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró la persona que coincida con la búsqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#007bb8",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filtroI = "";
      this.aprendicesInscritosFiltrado = this.aprendicesInscritos;
    }
  }
  FiltrarEstado(){
  let filter= [];
  for (let adjudicado of this.adjudicados){
  let tipo = adjudicado.estado.toLowerCase();

  if(tipo.indexOf(this.filterEstado.toLowerCase()) >= 0){
    filter.push(adjudicado);
  }
  }
  this.adjudicadosFiltrado= filter;
  if(this.adjudicadosFiltrado.length ==0){
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró la fase que coincida con la búsqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#007bb8",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filterEstado="";
       this.adjudicadosFiltrado = this.adjudicados;
  }
  }
}
