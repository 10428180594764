
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import Multiselect from "vue-multiselect";
import AnimacionCargando from "../../../components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
    Multiselect,
  },
})
export default class ModificarPIC extends Vue {
  list_table : any[] = [];
  lista_redes = [];
  estadoPeticion = false;
  areas: any[] = [];
  picTipos = null;
  redesConocimiento: any[] = [];
  estadoPeticionModificar = false;
  listarPicEje: any[] = [];
  capacitacion = {
    id_pic: null,
    capacitacion: "",
    pertenece_a_red: "",
    plan_institucional_id: "",
    id_area_ruta: "",
    id_pic_tipo: "",
    pic_tipo_id: "",
    tipo_pic: "",
    pic_tipo: "",
    eje: "",
    eje_id: "",
    pic_rel_capacitacion_red: [],
    redesconocimiento :[],
  };
  picEje = {
    id_eje: "",
    eje: "",
  };
  requisito = "";
  pertenece_a_red = "";
  // nuevo
  areasEscogidas: any[] = [];
  async mounted() {
    this.capacitacion.id_pic = this.$route.params.id;
    await this.ObtenerDatosPIC();
    await this.obtenerAreasConocimiento();
    await this.obtenerPic(this.capacitacion.id_pic);
    await this.obtenerPicEje();
    this.llenarAreasEscogidasPic();
    this.capacitacion.id_pic_tipo = this.capacitacion.pic_tipo_id;
    this.estadoPeticion = true;
    this.filtroBase(this.areasEscogidas , this.redesConocimiento)
    // console.log(this.capacitacion.id_pic_tipo);
  }
  async obtenerPic(id) {
    await Axios()
      .get("detalle_pic/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.capacitacion = respuesta.data.results;
          this.lista_redes = respuesta.data.results.redesconocimiento;
          // console.log(this.redesConocimiento);
          this.filtroBase(this.areasEscogidas , this.redesConocimiento)
        } else {
          Swal.fire({
            title: "No se obtuvo Capacitación",
            text: respuesta.data.message,
            icon: respuesta.data.status,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          }).then(() => {
            this.$router.back();
          });
        }
      });
  }
  async obtenerAreasConocimiento() {
    await Axios()
      .get("/crear_pic")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.areas = respuesta.data.results.areas;
          this.picTipos = respuesta.data.results.picTipos;
          this.redesConocimiento = respuesta.data.results.redesConocimiento;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      });
  }

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "Fecha mayor o igual a 2020",
        // max: () => "La suma de los cupos no puede ser mayor a " + this.convocatoria.cupo_total,
        alpha_spaces: () => "No permite números ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }
  llenarAreasEscogidasPic() {
    for (let area of this.capacitacion.pic_rel_capacitacion_red) {
      this.areasEscogidas.push(area.area_ruta);
    }
  }
  modificarAreas(id_pic) {
    for (let area of this.areasEscogidas) {
      Axios()
        .post("almacenar_red", { id_area: area.id_area_ruta, id_pic: id_pic })
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
          } else {
          }
        });
    }
  }

  eliminarAreasEscogidasPic() {
    Axios()
      .delete(
        "eliminar_areas_asociadas_capacitacion/" + this.capacitacion.id_pic
      )
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
        } else {
        }
      });
  }

  modificarPIC() {
    this.capacitacion.id_pic_tipo = this.capacitacion.pic_tipo_id;
    this.estadoPeticionModificar = true;
    Axios()
      .put("modificar_pic/" + this.capacitacion.id_pic, this.capacitacion)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Capacitación modificada",
            text:
              "La capacitación " +
              this.capacitacion.capacitacion +
              " fue modificada exitosamente.",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.eliminarAreasEscogidasPic();
          this.modificarAreas(this.capacitacion.id_pic);
          this.estadoPeticionModificar = true;
          this.$router.back();
        } else {
          Swal.fire({
            title: "Error no se pudo modificar",
            text:
              "La capacitación de nombre " +
              this.capacitacion.capacitacion +
              " no se puede modificar.",
            icon: "error",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionModificar = false;
      });
  }

  async ObtenerDatosPIC() {
    await Axios()
      .get("/crear_pic")
      .then((respuesta) => {
        this.areas = respuesta.data.results.areas;
        this.picTipos = respuesta.data.results.picTipos;
        this.redesConocimiento = respuesta.data.results.redesConocimiento;
      });
  }
  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        // el formulario se validó y tiene los datos que corresponden por campo
        this.modificarPIC();
      }
    });
  }
  async obtenerPicEje() {
    await Axios()
      .get("/listar_eje")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listarPicEje = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  async obtenerAreasPorRed() {
    await Axios()
      .get("listar_areas_filtrado/" + this.capacitacion.pertenece_a_red)
      .then((respuesta) => {
        this.areas = respuesta.data.results;
      });
  }

  addTag(newTag) {
    const tag = {
      area_ruta: newTag,
      red_conocimiento_id: "",
      componentes: "",
      id_area_ruta:
        newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
    };
    this.areas.push(tag);
    this.areasEscogidas.push(tag);
}
  filtroBase(arra1 ,arra2 ) {
    this.list_table = []
    let contador = 0

    for (let areas_escogiadas of arra1 ) {
    let red_id = areas_escogiadas.red_conocimiento_id

    for(let redesconocimiento of arra2 ){

      let id_red_redes = redesconocimiento.id_red_conocimiento
      let red_name = redesconocimiento.red_conocimiento

    if (red_id == id_red_redes) {
      if (this.list_table.length == 0){
          this.list_table.push(redesconocimiento.red_conocimiento)
        }else{
          for(let table of this.list_table){
          if (table == red_name) {
            contador ++
          }
        }
        if (contador == 0) {
          this.list_table.push(redesconocimiento.red_conocimiento)
        }
        contador = 0
        }
    }
  }
}
// console.log(this.list_table);
  }

}
