
  import { Vue } from "vue-property-decorator";
  import Component from "vue-class-component";
  import Axios from "@/Axios";
  import Swal from "sweetalert2";
  import Multiselect from "vue-multiselect";

  @Component({
    components: {
      Multiselect
    },
  })
  export default class Viabilidad extends Vue {

    id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;

    listaColegios: any[] = [];
    lista_regionales: any[] = [];
    listaCentroFormacion: any[] = [];
    lista_convenio: any[] = [];
    varray: any[] = [];
    apto = true;
    docValido = false;
    estadoPeticion = false;

    modalBuscarColegio = false;
    lista_municipios = null;
    departamento_actual = null;
    lista_departamentos: any[] = [];
    // Sena persona datos
    infoSenaPersona = "";
    modalSenaPersona = false;
    // identificacionSenaPersona = "";
    buscarSenaPersona;

    colegioPrograma = {};

    convenio = {
      id_convenio: null,
      convenio: "",
      regional_id: "",
      regional: "",
      centro_formacion_id: "",
      sena_persona_id: "",
      numero_convenio: "",
      fecha_firma: "",
      fecha_inicio: "",
      fecha_final: "",
      sena_cargo: "",
      colegio_cargo: "",
      minuta: null,
      municipio_id: "",
    };
    viabilidad = {
      id_viabilidad: "",
      convenio_id: "",
      convenio: "",
      colegio_id: "",
      colegio: "",
      apto: 1,
      fecha: "",
    };

    colegio: any[] = [];
    colegioEscogido: any[] = [];
    colegiosConvenio: any[] = [];

    idViabilidad = "";

    mounted() {
      this.viabilidad.convenio_id = this.$route.params.id_convenio;
      this.obtenerDepartamento();
      // this.obtenerColegios();
      this.obtenerConvenio();
      this.obtenerColegiosCovenio(this.$route.params.id_convenio);
      this.docsEmmit(event);
    }

    docsEmmit($event) {
      // alert($event);
    }
    obtenerColegiosCovenio(id_convenio) {
      Axios()
        .get("listarColegiosConvenio/" + id_convenio)
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            this.colegiosConvenio = respuesta.data.results;
          }
        });
    }

    preguntaCheck() {
      this.viabilidad.apto = this.viabilidad.apto == 0 ? 1 : 0;
    }
    //--------------------------------------------------------------------
    registrarViabilidad() {
      this.estadoPeticion = true;
      Axios()
        .post("almacenar_viabilidad", this.viabilidad)
        .then((respuesta) => {
          if ((respuesta.data.status = "success")) {
            // this.modalBuscarColegio = false;
            Swal.fire({
              title: "Viabilidad Agregada",
              text: "Se Agrego la Viabilidad",
              icon: "success",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });

            this.idViabilidad = respuesta.data.results.id_viabilidad;

            this.$emit('envioViabilidad', this.idViabilidad);
            // this.$router.push({ name: "GestionarViabilidad" });
          } else {
            Swal.fire("ERROR!", "No se Agrego La viabilidad", "error");
          }
          // this.estadoPeticion = false;
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        });
    }

    obtenerMunicipio(id) {
      Axios()
        .get("listar_municipios/" + id)
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            this.lista_municipios = respuesta.data.results.municipios;
          } else {
            Swal.fire({
              title: respuesta.data.message,
            });
          }
        });
    }

    obtenerDepartamento() {
      Axios()
        .get("listar_departamentos/57")
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            this.lista_departamentos = respuesta.data.results;
          } else {
            Swal.fire({
              title: respuesta.data.message,
            });
          }
        });
    }

    obtenerConvenio() {
      Axios()
        .get("listar_convenio")
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            this.lista_convenio = respuesta.data.results;
          }
        });
    }

    obtenerColegios() {
      Axios()
        .get("listar_colegios_municipios/" + this.convenio.municipio_id)
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            this.listaColegios = respuesta.data.results;
          } else {
            Swal.fire({
              title: respuesta.data.message,
            });
          }
        });
    }

    constructor() {
      super();
      const dict = {
        messages: {
          required: () => "Campo obligatorio",
          date_format: () => "Campo obligatorio",
          decimal: () => "Campo obligatorio",
          min_value: () => "El valor no puede ser menor a cero",
          email: () => "El correo no es válido",
          alpha_spaces: () => "No permite numeros ni caracteres especiales",
          alpha_num: () => "No permite caracteres especiales",
        },
      };
      this.$validator.localize("es", dict);
    }

    validarFormulario() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          // el formulario se validó y tiene los datos que corresponden por campo
          this.registrarViabilidad();
        }
      });
    }
  }
