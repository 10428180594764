
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import Multiselect from "vue-multiselect";
import { Vue } from "vue-property-decorator";
import AnimacionCargando from "../../../components/animacionCargando.vue";
import router from "@/router";

@Component({
  components: {
    AnimacionCargando,
    BaseCheckbox,
    Multiselect,
  },
})
export default class CrearVocero extends Vue {
  persona = {
    persona_id: null,
    primer_nombre: "",
    segundo_nombre: "",
    primer_apellido: "",
    segundo_apellido: "",
    tipo_documento: "",
    identificacion: null,
    modalidad: "",
    jornada: "",
    programa_formacion: "",
    ficha_caracterizacion_id: null,
    id_tipo_vocero: [],
  };
  ver: any = true;
  crear: any = true;
  btnbuscar = false;
  tiposdevoceros: any[] = [];
  tipoEscogido: any[] = [];
  estadoPeticionRegistrar = false;
  limpiarDatosVocero() {
    this.btnbuscar = false;
    this.persona.persona_id = null;
    this.persona.primer_nombre = "";
    this.persona.segundo_nombre = "";
    this.persona.primer_apellido = "";
    this.persona.segundo_apellido = "";
    this.persona.tipo_documento = "";
    this.persona.modalidad = "";
    this.persona.jornada = "";
    this.persona.programa_formacion = "";
    this.persona.ficha_caracterizacion_id = null;
  }
  mounted() {
    this.ver = this.$route.params.ver;
    this.crear = this.$route.params.crear;
    this.vocero.persona_id = this.$route.params.id;
    this.obtenerTiposVoceros();
  }
  obtenerPersona(id) {
   this.obtenerTiposVoceros();
    Axios()
      .post("buscar_persona_v", { identificacion: id })
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.persona = respuesta.data.results;
          this.btnbuscar = true;
        } else {
          Swal.fire({
            title: "No se encontró la persona",
            icon: "error",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
            text: "El número de identificación no corresponde a una persona registrada en el sistema.",
          }).then(() => {
          
          });
        }
      });
  }

  obtenerTiposVoceros() {
    Axios()
      .get("listar_tipos_voceros")
      .then((respuesta) => {
        this.tiposdevoceros = respuesta.data.results;
      });
  }
 
  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "No pude ser negativo",
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }
  validarFormulario(){
    this.$validator.validate().then((valid)=>{
      if(valid){
       this.almacenarVocero();
        
      }else{
         Swal.fire({
            title: "Vocero no registrado!",
            icon: "error",
            text: "Debe escoger el tipo de vocero",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
      }
    })
  }
  almacenarVocero(){
    this.estadoPeticionRegistrar=true;
    Axios()
    .post("almacenar_vocero", 
    {
      persona_id: this.persona.id_persona,
      ficha_caracterizacion_id: this.persona.id_ficha_caracterizacion,
      tipo_vocero_id:this.tiposdevoceros.id_tipo_vocero
    }
    )
    .then((resp)=>{
      this.estadoPeticionRegistrar=false;
        if (resp.data.status == "success") {
          Swal.fire({
            title: "Vocero Registrado!",
            text: "Se ha registrado el vocero exitosamente",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.$router.push({ name: "GestionarVoceros" });
        }else {
          Swal.fire({
            title: "Error!",
            text: "El vocero ya se encuentra registrado!",
            icon: resp.data.status,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
            this.$router.push({ name: "GestionarVoceros" });
        }
    })
    .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
       .finally(() => {
        this.estadoPeticionRegistrar = false;
      });
  }
  datosVacios(dato) {
    if (dato == null || dato == "" || dato == " ") {
      return "No hay datos registrados";
    } else {
      return dato;
    }
 }
   datoPersonalVacio(datos){
    if(datos==null || datos== "" || datos== " "){
      return ' '
    }else{
      return datos;
    }
   }
}
