
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";
/* --------------------------------------------- COMPONENTS-------------------------------------------------*/
@Component({
  components: {
    AnimacionCargando,
  },
})
export default class RegistrarPrimeraEvidencia extends Vue {
  /* --------------------------------------------- DATA -------------------------------------------------*/
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;
  estadoPeticionRegister = false;
  documentos = {
    persona_id: this.id_persona_logeada,
    ruta: "",
    documento: "",
    id_indicador: "",
  };
  fechas_evidencia = {
    fecha_inicio: "",
    fecha_fin: "",
  };
  docResolucion = null;
  docValido = false;
  estadoPeticion = false;
  /* VALIDACION DE FECHAS */
  /* 1 */ fechas_evidencia_fin = true;
  /* 2 */ fechas_evidencia_inicio = false;

  /* --------------------------------------------- MOUNTED-------------------------------------------------*/
  async mounted() {}

  /* --------------------------------------------- METODOS -------------------------------------------------*/
  fechasHabilitar() {
    if (this.fechas_evidencia.fecha_inicio != "") {
      this.fechas_evidencia_fin = false;
    } else {
      this.fechas_evidencia_fin = true;
    }
  }

  obtenerEvidencia(event) {
    if (event.target.files[0].size <= 5000000) {
      //5mb tope
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.documentos.ruta = event.target.files[0];
        Swal.fire({
          title: "Evidencia Cargada!",
          text: "Archivo cargado exitosamente!",
          icon: "success",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#007bb8",
        });
        this.docValido = true;
      } else {
        this.docValido = false;
        this.docResolucion = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.docResolucion = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  }

  subirEvidencia() {
    this.estadoPeticionRegister = true;
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    var data = new FormData();
    data.append("id_indicador", this.$route.params.id_indicador);
    data.append("ruta", this.documentos.ruta);
    data.append("documento", this.documentos.documento);
    data.append("persona_id", this.id_persona_logeada);

    data.append("fecha_inicio", this.fechas_evidencia.fecha_inicio);
    data.append("fecha_fin", this.fechas_evidencia.fecha_fin);

    Axios()
      .post("almacenar_evidencia", data, { headers: headers })
      .then((respuesta) => {
        if ((respuesta.data.status = "success")) {
          Swal.fire({
            title: "Se subió la Evidencia!",
            text: "Archivo cargado exitosamente!",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        } else {
          Swal.fire("ERROR!", "No se creo la evidencia ", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionRegister = false;
        this.$router.push({
          name: "MisIndicadores",
        });
      });
  }

  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        Axios()
          .get("verificacion_evidencia/" + this.$route.params.id_indicador)
          .then((respuesta) => {
            if (respuesta.data.results != null) {
              this.subirEvidencia();
            } else {
              Swal.fire({
                title: "Evidencia No Creada",
                text: respuesta.data.message,
                icon: "warning",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#007bb8",
              });
            }
          })
          .finally(() => {
            this.estadoPeticionRegister = false;
            this.$router.push({
              name: "MisIndicadores",
            });
          });
      }
    });
  }
}
