
import Axios from "@/Axios";
import { login } from "@/store/modules/auth/actions";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import AnimacionCargando from "../../../components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class VerDetalleEvaluacionCpacitacion extends Vue {
  estadoPeticion = false;
  areasAsociadas = [];
  ver: any = false;
  areas: any[] = [];
  picTipos: any[] = [];
  redesConocimiento: any[] = [];
  capacitacion = {
    capacitacion: "",
    componentes: "",
    id_area_ruta: "",
    id_pic: "",
    plan_institucional_id: "",
    tipo_pic: "",
    eje: "",
    id_eje: "",
  };
  requisito = "";
  id_capacitacion = "";
  listaredes = [] ;
  listaareas = [] ;
  listNetworksAndAreas = [] ;
  estadoPeticionTable = false



  async mounted() {
    await Axios()
      .get("/crear_pic")
      .then((respuesta) => {
        this.areas = respuesta.data.results.areas;
        this.picTipos = respuesta.data.results.picTipos;
        this.redesConocimiento = respuesta.data.results.redesConocimiento;
      });
    // this.capacitacion.id_pic = this.$route.params.id;
    await this.obtenerPic(this.$route.params.id);
    await this.getNetwordAndArea(this.$route.params.id);
    // await this.verRedesPIC();
  }

  async getNetwordAndArea (id) {
    await Axios()
    .get("show_pic/" + id)
    .then(respuesta => {
 if (respuesta.data.status == "success") {
          this.listaredes = respuesta.data.results.redes;
          this.listaareas = respuesta.data.results.areas;
        } else {
          Swal.fire({
            title: "No se obtuvo Capacitación",
            text: respuesta.data.message,
            icon: respuesta.data.status,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          })
        }
      })
      .finally(() => {
        this.estadoPeticionTable = true
        this.tratarjson();
      });
  }


  async obtenerPic(id) {
    await Axios()
      .get("detalle_pic/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.capacitacion = respuesta.data.results;
        } else {
          Swal.fire({
            title: "No se obtuvo Capacitación",
            text: respuesta.data.message,
            icon: respuesta.data.status,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          }).then(() => {
            this.verRedesPIC(this.capacitacion.id_pic);
            this.$router.back();
          });
        }
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }

  verRedesPIC(id_pic) {
    Axios()
      .get("listar_redes/" + id_pic)
      .then((respuesta) => {
        this.areasAsociadas = respuesta.data.results;
      });
  }
  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "Fecha mayor o igual a 2020",
        // max: () => "La suma de los cupos no puede ser mayor a " + this.convocatoria.cupo_total,
        alpha_spaces: () => "No permite números ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }

  verPIC() {
    Swal.fire({
      title: "PIC visto",
      icon: "success",
      confirmButtonText: "Aceptar",
      customClass: {
        confirmButton: "btn btn-azul-sena",
      },
    });
  }

  async tratarjson() {
    let object = {}
    let stringareas = ""
    let arrayfinal =  []
    let arregloFiltrado = this.listaareas
    this.listaredes.map(function (data ){
    let arrarAreas = []
      arregloFiltrado.map(d => {
      if (d.red_conocimiento_id == data.id_red_conocimiento) {
        arrarAreas.push(d.area_ruta)
    stringareas = arrarAreas.toString()

      }
    })
    
    object = {
        network : data.red_conocimiento ,
        areas : stringareas
        }
      arrayfinal.push(object)
    })

    this.listNetworksAndAreas = arrayfinal
  }


}
