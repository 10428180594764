
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";
import AnimacionTablasCargando from "@/components/animacionTablasCargando.vue";

@Component({
  components: {
    AnimacionCargando,
    AnimacionTablasCargando,
  },
})
export default class Convenio extends Vue {
  listarViabilidad = [];
  listaViabilidadFiltrado: any[] = [];
  paginate = ["listaViabilidadFiltrado"];
  filter = "";
  id_convenio = null;

  estadoPeticionTabla = false;
  viabilidad = {
    id_viabilidad: "",
    convenio_id: "",
    convenio: "",
    colegio_id: "",
    colegio: "",
    apto: "",
    fecha: "",
  };
  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        min: () => "Mínimo 8 caracteres",
      },
    };
    this.$validator.localize("es", dict);
  }
  mounted() {
    this.obtenerConvenios(this.$route.params.id_convenio);
    this.id_convenio = this.$route.params.id_convenio;
    
  }
  cambiarFaseViabilidad() {
    if (this.listaViabilidadFiltrado == null) {
      Swal.fire({
        title: "Informacion",
        text: "Para ejecutar debe tener almenos 1",
        icon: "warning",
        confirmButtonText: "Aceptar",
        customClass: {
          confirmButton: "btn btn-azul-sena",
        },
      });
    } else {
      Axios()
        .put("modificar_fase_convenio/" + this.id_convenio, {
          id_convenio: this.id_convenio,
          fase_id: 6,
          id_actualiza: JSON.parse(sessionStorage.getItem("usuario")),
        })
        .then(() => {
          Swal.fire({
            title: "Cambio de fase correcto!",
            text: "El Convenio pasó a Ejecutar exitosamente!",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
            icon: "success",
          });
          this.$router.push("GestionarConvenio");
        })
        .catch(() => {
          Swal.fire({
            title: "ERROR",
            text: "No se puede cambiar de fase",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
            icon: "error",
          });
        });
    }
  }
  obtenerConvenios(id) {
    Axios()
      .get("listar_viabilidad/" + id)
      .then((respuesta) => {
        this.listarViabilidad = respuesta.data.results;
        this.listaViabilidadFiltrado = respuesta.data.results;
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }

  eliminarConvenio(id, index) {
    this.eliminarConvenioColegios(id);
    Swal.fire({
      title: "Eliminar Convenio ",
      text: this.listaViabilidadFiltrado[index].colegio_cargo + "?",
      icon: "warning",
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((respuesta) => {
      if (respuesta.isConfirmed) {
        Axios()
          .delete("eliminar_convenio/" + id)
          .then((respuesta) => {
            if (respuesta.data.status == "success") {
              Swal.fire({
                title: "Convenio Eliminado",
                icon: "success",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.listaViabilidadFiltrado.splice(index, 1);
            } else {
              Swal.fire({
                title: "No se pudo eliminar el convenio",
                text: respuesta.data.message,
                icon: "error",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.$router.back();
            }
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }

  eliminarConvenioColegios(id) {
    Axios()
      .delete("eliminar_convenio_colegio_escogidas/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
        } else {
        }
      });
  }

  viabilidadFiltrado() {
    let viabilidadFilter = [];
    // this.listaConvocatoriasFiltrado = []

    for (let viabilidad of this.listarViabilidad) {
      let colegio = viabilidad.colegio.toLowerCase();
      let fases = viabilidad.convenio.toLowerCase();

      if (
        colegio.indexOf(this.filter) >= 0 ||
        fases.indexOf(this.filter) >= 0
      ) {
        viabilidadFilter.push(viabilidad);
      }
    }

    this.listaViabilidadFiltrado = viabilidadFilter;
    if (this.listaViabilidadFiltrado.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró el convenio, que coincida con la búsqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#007bb8",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filter = "";
      this.listaViabilidadFiltrado = this.listarViabilidad;
    }
  }
}
