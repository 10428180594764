
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";
import AnimacionTablasCargando from "../../../components/animacionTablasCargando.vue";
import Multiselect from "vue-multiselect";
@Component({
  components: {
    AnimacionCargando,
    AnimacionTablasCargando,
    Multiselect,
  },
})
export default class listaHistorialEvidencia extends Vue {
  estadoPeticion = true;
  listaHistorialEvidencias: any[] = [];
  HistorialEvidencias: any[] = [];
  buscarIndicadores: any = [];
  rutadocumento = "";
  modalRequisito = false;
  estadoPeticionTabla = false;
  evidenciaSeleccionada: any = {};
  frecuencias = [];
  giresponsables = [];
  paginate = ["listaHistorialEvidencias"];

  /* FILTRO DE INDICADOR */
  /* 2 */ filtroIndicador = "";
  /* 3 */ filtroIndi = [];
  /* FILTRO DE GRUPO RESPONSABLE */
  /* 1 */ filtroGIResponsable = "";
  /* 2 */ filtroGrupoInternoResponsable = [];
  /* FILTRO FECHA */
  /* 1 */ fechayear = "";
  /* 2 */ fecha = [];
  /* FILTRO DE FRECUENCIA */
  /* 1 */ filtroFrecuencia = [];
  /* 2 */ filtroFrecuen = "";

  async mounted() {
    await this.obtenerHistorialEvidencias();
    this.ObtenerDatosParaFiltros();
    this.obtenerCreate();
  }

  mostrarDocumento(evidencia) {
    this.evidenciaSeleccionada = evidencia;
    this.rutadocumento =
      this.$urlAPI + "visualizarDucumentoEvidencia/" + evidencia.id_documento;
  }

  async obtenerHistorialEvidencias() {
    await Axios()
      .get("listar_historial_evidencias")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listaHistorialEvidencias = respuesta.data.results;
          this.HistorialEvidencias = respuesta.data.results;
          console.log(this.listaHistorialEvidencias);
          
        } else {
          this.listaHistorialEvidencias = null;
        }
        this.estadoPeticion = false;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }

  ObtenerDatosParaFiltros() {
    Axios()
      .get("crear_sigea_indicador")
      .then((respuesta) => {
        this.frecuencias = respuesta.data.results.frecuencias;
        this.giresponsables = respuesta.data.results.gi_responsables;
      });
  }

  filtroIndicadores() {
    let filtroIndi = [];
    for (let indi of this.HistorialEvidencias) {
      let nombre = indi.nombre_indicador.toLowerCase();
      if (nombre.indexOf(this.filtroIndicador) >= 0) {
        filtroIndi.push(indi);
      }
    }
    this.listaHistorialEvidencias = filtroIndi;
    if (this.listaHistorialEvidencias.length == 0) {
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 2000,
        text: "No se encontró convocatorias que coincidan con las busqueda",
        showConfirmButton: false,
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filtroIndicador = "";
      this.listaHistorialEvidencias = this.HistorialEvidencias;
    }
  }

  filtrarPorGIResponsable() {
    let filtroGrupoInternoResponsable = [];
    for (let giresponsable of this.HistorialEvidencias) {
      let responsable = giresponsable.nombre_gi_responsable;
      if (responsable.indexOf(this.filtroGIResponsable) >= 0) {
        filtroGrupoInternoResponsable.push(giresponsable);
      }
    }
    this.listaHistorialEvidencias = filtroGrupoInternoResponsable;
    if (this.listaHistorialEvidencias.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró convocatorias que coincidan con las busqueda",
        showConfirmButton: false,
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filtroGIResponsable = "";
      this.listaHistorialEvidencias = this.HistorialEvidencias;
    }
  }

  filtrarFrecuencia() {
    let filtroFrecuencia = [];
    for (let responsable of this.HistorialEvidencias) {
      let respon = responsable.nombre_frecuencia;
      if (respon.indexOf(this.filtroFrecuen) >= 0) {
        filtroFrecuencia.push(responsable);
      }
    }
    this.listaHistorialEvidencias = filtroFrecuencia;

    if (this.listaHistorialEvidencias.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró convocatorias que coincidan con las busqueda",
        showConfirmButton: false,
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filtroFrecuen = "";
      this.listaHistorialEvidencias = this.HistorialEvidencias;
    }
  }
  

  obtenerCreate() {
    let create = [];
    let splitxd = [];
    let arrayReal = [];
    let contador = 1;
    let listaDefinitive = [];
    for (let array of this.listaHistorialEvidencias) {
      create.push(array.creado);
    }
    for (let iterator in create) {
      splitxd.push(create[iterator].split("-"));
    }
     for (let index in splitxd) {
      arrayReal.push(splitxd[index][0]);
    }
    for (let data of arrayReal) {
      if (contador === 0) {
        listaDefinitive.push(data);
      } else {
        if (listaDefinitive[0] != data) {
          listaDefinitive.push(data);
        }
      }
      contador++;
    }
    this.fecha = listaDefinitive;
  }

  filtroAnio() {
    let filtradoAnio = [];
    this.HistorialEvidencias.map((data) => {
      if (data.creado.indexOf(this.fechayear) >= 0) {
        filtradoAnio.push(data);
      }
    });
    this.listaHistorialEvidencias = filtradoAnio;
    if (this.listaHistorialEvidencias.length == 0) {
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró convocatorias que coincidan con las busqueda",
        showConfirmButton: false,
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.fechayear = "";
      this.listaHistorialEvidencias = this.HistorialEvidencias;
    }
  }

  cambiarEstadoAprobado(id) {
    Swal.fire({
      title: "Aprobar",
      text: "¿Desea cambiar el estado de este historial a Aprobado?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Aprobar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios()
          .put("cambiar_estado_aprobado/" + id)
          .then((respuesta) => {
            this.obtenerHistorialEvidencias();
            Swal.fire({
              title: "Se cambio el Estado!",
              text: "El Estado ha sido actualizado exitosamente!",
              icon: "success",
              cancelButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
          })
          .catch((error) => {
            Swal.fire(
              "ERROR!",
              "Se ha detectado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }

  cambiarEstadoRechazado(id) {
    Swal.fire({
      title: "Rechazar",
      text: "¿Desea cambiar el estado de este historial a Rechazado?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Rechazar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios()
          .put("cambiar_estado_rechazado/" + id)
          .then((respuesta) => {
            this.obtenerHistorialEvidencias();
            Swal.fire({
              title: "Se cambio el Estado!",
              text: "El Estado ha sido actualizado exitosamente!",
              icon: "success",
              cancelButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
          })
          .catch((error) => {
            Swal.fire(
              "ERROR!",
              "Se ha detectado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }
}
