var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-5 container-fluid"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[(!_vm.estadoPeticionTabla)?_c('div',{staticClass:"text-center"},[_c('AnimacionTablasCargando')],1):_vm._e(),(_vm.estadoPeticionTabla)?_c('div',[_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-md-3 col-lg-3 mt-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"form-control shadow p-2",attrs:{"type":"text","placeholder":"Buscar Convenio"},domProps:{"value":(_vm.filter)},on:{"keyup":function($event){$event.preventDefault();return _vm.convenioFiltrado()},"input":function($event){if($event.target.composing)return;_vm.filter=$event.target.value}}})]),_c('div',{staticClass:"col-md-9 col-lg-9 mt-4"},[_c('paginate-links',{staticClass:"pagination justify-content-end",attrs:{"for":"listaConveniosFiltrado","limit":2,"hide-single-page":true,"show-step-links":true,"full-page":true,"classes":{
                ul: 'simple-links-container',
                li: 'simple-links-item',
                liActive: ['simple-links-item', 'active1'],
                'li.active': 'active1',
              }}})],1)]),_c('div',{staticClass:"table-responsive"},[_c('paginate',{ref:"paginator",attrs:{"name":"listaConveniosFiltrado","list":_vm.listaConveniosFiltrado,"per":10}},[_c('table',{staticClass:"table table-hover"},[_c('thead',{attrs:{"id":"listado"}},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Convenio")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Regional")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Centro de formación")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Fase")]),_c('th',{staticClass:"col-sm-2",attrs:{"scope":"col"}},[_vm._v("Acciones")])])]),_c('tbody',[(
                    _vm.listaConveniosFiltrado != null &&
                    _vm.listaConveniosFiltrado.length != 0
                  )?_vm._l((_vm.paginated(
                      'listaConveniosFiltrado'
                    )),function(convenio,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(convenio.convenio))]),_c('td',[_vm._v(_vm._s(convenio.regional))]),_c('td',[_vm._v(_vm._s(convenio.centro_formacion))]),_c('td',[_vm._v(_vm._s(convenio.fase))]),_c('td',{attrs:{"scope":"row"}},[_c('div',{staticClass:"btn-toolbar btn-responsive-table",attrs:{"role":"toolbar"}},[_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Ver Convenio","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"to":{
                              name: 'VerDetalleConvenio',
                              params: {
                                id: convenio.id_convenio,
                              },
                            }}},[_c('i',{staticClass:"fas fa-eye"})])],1),(convenio.fase_id == 1)?_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Modificar Convenio","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"to":{
                              name: 'ModificarConvenio',
                              params: {
                                id: convenio.id_convenio,
                              },
                            }}},[_c('i',{staticClass:"fas fa-edit"})])],1):_vm._e(),(convenio.fase_id == 1)?_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Eliminar Convenio","tooltip-flow":"top"}},[_c('a',{staticClass:"btn btn-sm bg-oscuro-sena text-white",on:{"click":function($event){return _vm.eliminarConvenio(convenio.id_convenio, key)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e(),(convenio.fase_id == 2)?_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Proyectado","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"to":{
                              name: 'GestionarProgramasProyectados',
                              params: {
                                id_convenio: convenio.id_convenio,
                              },
                            }}},[_c('i',{staticClass:"fas fa-clipboard-list"})])],1):_vm._e(),(convenio.fase_id == 3)?_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Lista sensibilización","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"to":{
                              name: 'GestionarSensibilizacion',
                              params: {
                                id_convenio: convenio.id_convenio,
                              },
                            }}},[_c('i',{staticClass:"fas fa-clipboard-check"})])],1):_vm._e(),(convenio.fase_id == 3)?_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Registrar sensibilización","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"to":{
                              name: 'RegistrarSensibilizacionDocumento',
                              params: {
                                id_convenio: convenio.id_convenio,
                              },
                            }}},[_c('i',{staticClass:"fas fa-bullhorn"})])],1):_vm._e(),(convenio.fase_id == 4)?_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Registro Colegios","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"to":{
                              name: 'GestionarProgramasColegios',
                              params: {
                                id_convenio: convenio.id_convenio,
                              },
                            }}},[_c('i',{staticClass:"fas fa-running"})])],1):_vm._e(),(convenio.fase_id == 5)?_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Lista viabilidad","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"to":{
                              name: 'GestionarViabilidad',
                              params: {
                                id_convenio: convenio.id_convenio,
                              },
                            }}},[_c('i',{staticClass:"fas fa-clipboard-check"})])],1):_vm._e(),(convenio.fase_id == 5)?_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Registro viabilidad","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"to":{
                              name: 'RegistrarViabilidadDocumento',
                              params: {
                                id_convenio: convenio.id_convenio,
                              },
                            }}},[_c('i',{staticClass:"fas fa-stream"})])],1):_vm._e(),(convenio.fase_id == 6)?_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Registrar Ejecución","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"to":{
                              name: 'GestionarEjecucionConvenioColegios',
                              params: {
                                id_convenio: convenio.id_convenio,
                              },
                            }}},[_c('i',{staticClass:"fas fa-play-circle"})])],1):_vm._e(),(convenio.fase_id == 7)?_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Finalizado","tooltip-flow":"top"}},[_c('a',{staticClass:"btn btn-sm bg-oscuro-sena text-white"},[_c('i',{staticClass:"fas fa-play-circle"})])]):_vm._e(),(convenio.fase_id == 8)?_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Cancelado ","tooltip-flow":"top"}},[_c('a',{staticClass:"btn btn-sm bg-azul-sena text-white"},[_c('i',{staticClass:"fas fa-minus-circle"})])]):_vm._e(),(convenio.fase_id == 9)?_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Suspendido","tooltip-flow":"top"}},[_c('a',{staticClass:"btn btn-sm bg-oscuro-sena text-white"},[_c('i',{staticClass:"fas fa-history"})])]):_vm._e()])])])}):_c('tr',[_vm._v(" No hay convenios ")])],2)])])],1),_c('paginate-links',{staticClass:"pagination justify-content-end",attrs:{"for":"listaConveniosFiltrado","limit":2,"hide-single-page":true,"show-step-links":true,"full-page":true,"classes":{
            ul: 'simple-links-container',
            li: 'simple-links-item',
            liActive: ['simple-links-item', 'active1'],
            'li.active': 'active1',
          }}})],1):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"border-radius-lg bg-gradient-primary card-header p-0 position-relative mt-n4 mx-3 z-index-2 shadow-3"},[_c('div',{staticClass:"pt-4 pb-3"},[_c('h1',{staticClass:"text-white text-capitalize ps-3 ms-3 title-header"},[_vm._v(" LISTA CONVENIOS ")])])])
}]

export { render, staticRenderFns }