
  import { Component, Vue } from "vue-property-decorator";
  // import { mapState, mapActions} from "vuex"
  import Axios from "@/Axios";
  import Swal from "sweetalert2";
  import AnimacionCargando from '../../src/components/animacionCargando.vue';

  @Component({
    components: {
      AnimacionCargando
    }
  })
  export default class Login extends Vue {
    usuario = { usuario: "", password: "" };
    // usuario = { usuario: "meerazo7", password: "sigpa2021" };
    prueba = {
      usuario: "meerazo7",
      password: "sigpa2021",
      id_usuario: 62,
      id_persona: 26329106,
      primer_nombre: "MANUEL",
      segundo_nombre: "ESTEBAN",
      primer_apellido: "ERAZO",
      segundo_apellido: "MEDINA",
      id_perfil: 1,
      perfil: "Administrador",
      ruta_foto: "FotoUsuario/IRe7jIqmq7NFxEbSUZovKYcUiPYD9C4vM3pNuTQ4.png",
      id_centro_formacion: 9221,
      regional_id: 19,
      regional: "REGIONAL CAUCA",
      centro_formacion: "CENTRO DE TELEINFORMÁTICA Y PRODUCCIÓN INDUSTRIAL"
    };
    estadoPeticion = false;
    constructor() {
      super();

      const dict = {
        messages: {
          required: () => "Campo obligatorio",
          min: () => "Mínimo 8 caracteres",
          // email: () => "Correo invalido",
        },
      };
      this.$validator.localize("es", dict);
    }

    mounted() {      
    }
    
    login() {

      // sessionStorage.setItem(
      //   "usuario",
      //   JSON.stringify(this.prueba)
      // );
      this.estadoPeticion = true;
      Axios()
        .post("login", this.usuario)
        .then((respuesta) => {

          try {

            if (respuesta.data.status == "success") {
              sessionStorage.setItem(
                "usuario",
                JSON.stringify(respuesta.data.results.usuario)
              );
              // this.$store.state.account.dispatch("login", JSON.stringify(respuesta.data.results.usuario))
              sessionStorage.setItem(
                "modulos",
                JSON.stringify(respuesta.data.results.modulos)
              );

              this.$router.push({ name: "dashboard" });

            } else {
              // Swal.fire("Error inicio sesión", respuesta.data.message, respuesta.data.status);
              this.estadoPeticion = false;
              Swal.fire(
                (respuesta.data.status + "!").toString().toUpperCase(),
                respuesta.data.message,
                respuesta.data.status
              );
              this.estadoPeticion = false;
            }
            //estado de petición
            this.estadoPeticion = false;
          } catch (error) {
            this.estadoPeticion = false;
          }
          // sdadsasdasd
        })
        .catch((err) => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
          this.estadoPeticion = false;
        });
    }

    public validarFormulario() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          // el formulario se validó y tiene los datos que corresponden por campo
          this.login();
        }
      });
    }
  }
