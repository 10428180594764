
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class Login extends Vue {
  estadoPeticion = true;
  listaConvocatorias = [];
  filter = "";
  fecha_pic = "2021";
  id_convocatoria_pic = 0;
  fechas_pic = [];

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        min: () => "Mínimo 8 caracteres",
      },
    };
    this.$validator.localize("es", dict);
  }

  mounted() {
    this.obtenerConvocatorias();
    this.ontenerPlanInstitucional();
  }

  // obtenerConvocatorias(){
  //   Axios().get('listar_convocatoria_pics/' + this.fecha_pic).then(respuesta => {
  //   //filtrar solo las convocatorias que esten en etapa de validación -----------------------------------------------------------------------------------
  //     this.listaConvocatorias = respuesta.data.results
  //     this.estadoPeticion = false;
  //   })
  // }
  obtenerConvocatorias() {
    Axios()
      .get("listar_convocatoria_pics/" + this.fecha_pic)
      .then((respuesta) => {
        this.listaConvocatorias = respuesta.data.results;

        if (this.listaConvocatorias != null) {
          this.listaConvocatorias = this.listaConvocatorias.filter(
            (item) => item.pic_fase_id == 3
          );
        }
        this.estadoPeticion = false;
      });
  }
  ontenerPlanInstitucional() {
    Axios()
      .get("listar_plan_institucional")
      .then((respuesta) => {
        this.fechas_pic = respuesta.data.results;
      });
  }
  //eliminar convocatoria
  eliminarConvocatoria(id, index) {
    Swal.fire({
      title:
        "Eliminar Convocatoria " + this.listaConvocatorias[index].id_pic + "?",
      icon: "warning",
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((respuesta) => {
      if (respuesta.isConfirmed) {
        Axios()
          .delete("eliminar_convocatoria_pic/" + id)
          .then((respuesta) => {
            Swal.fire({
              title: "Convocatoria Eliminada",
              icon: "success",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
              cancelButtonColor: "#2d2d2d",
            });
            this.listaConvocatorias.splice(index, 1);
          });
      }
    });
  }

  filtrar(lista: any[]) {
    let resp;
    if (this.filter != "") {
      resp = lista.filter(
        (item) =>
          item.capacitacion
            .substring(0, this.filter.length)
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "") === this.filter.toLowerCase()
      );
    } else {
      resp = lista;
    }
    return resp;
  }
  ordenar(n: number, type: string) {
    let rows,
      switching,
      i,
      x,
      y,
      shouldSwitch,
      dir,
      switchcount = 0;

    const table: any = document.getElementById("listado");
    switching = true;
    dir = "asc";

    while (switching) {
      switching = false;
      rows = table.rows;
      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];
        if (dir == "asc") {
          if (
            (type == "str" &&
              x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) ||
            (type == "int" && parseFloat(x.innerHTML) > parseFloat(y.innerHTML))
          ) {
            shouldSwitch = true;
            break;
          }
        } else if (dir == "desc") {
          if (
            (type == "str" &&
              x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) ||
            (type == "int" && parseFloat(x.innerHTML) < parseFloat(y.innerHTML))
          ) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount == 0 && dir == "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }
}
