
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import AnimacionTablasCargando from "../../../components/animacionTablasCargando.vue";

@Component({
  components: { AnimacionTablasCargando },
})
export default class ListarRepresentante extends Vue {
  crearRrepresentante: any = null;
  listaRepresentantes = [];
  listaRepresentantesFiltrado = [];
  estadoPeticionTabla = false;
  filter = "";
  hayDatos = false;
  paginate = ["listaRepresentantesFiltrado"];

  constructor() {
    super();
    const dict = {
      messages: {
        decimal: () => "Campo obligatorio",
        min: () => "Mínimo 8 caracteres",
      },
    };
    this.$validator.localize("es", dict);
  }

  async mounted() {
    this.listarRepresentantes();
  }
  eliminarRepresentante(id, key, nombre) {
    Swal.fire({
      title: "Eliminar representante",
      text: "¿Desea eliminar al representante: " + nombre + "?",
      icon:"question",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios()
          .delete("eliminar_representante/" + id)
          .then((respuesta) => {
            this.listarRepresentantes();
            Swal.fire({
              title: "Representante Eliminado",
              text:"El representate ha sido eliminado exitosamente!",
              icon:"success",
              cancelButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
          });
      }
    });
  }
  async listarRepresentantes() {
    await Axios()
      .get("listar_representantes")
      .then((respuesta) => {
        this.listaRepresentantes = respuesta.data.results;
        this.listaRepresentantesFiltrado = respuesta.data.results;

        if (this.listaRepresentantesFiltrado.length > 0) {
          this.hayDatos = true;
        }
      })
      .catch((error) => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }
  representantesFiltrado() {
    let repre_filter = [];

    for (let repre of this.listaRepresentantesFiltrado) {
      let identificacion = repre.identificacion.toLowerCase();
      let Pnombre = repre.primer_nombre.toLowerCase();
      // let Snombre = repre.segundo_nombre.toLowerCase();
      let Papellido = repre.primer_apellido.toLowerCase();
      let Sapellido = repre.segundo_apellido.toLowerCase();
      // let FichaCaracterizacion = repre.id_ficha_caracterizacion;
      let Regional = repre.regional.toLowerCase();
      let centro_formacion = repre.centro_formacion.toLowerCase();

      if (
        identificacion.indexOf(this.filter.toLowerCase()) >= 0 ||
        Pnombre.indexOf(this.filter.toLowerCase()) >= 0 ||
        // Snombre.indexOf(this.filter) >= 0 ||
        Papellido.indexOf(this.filter.toLowerCase()) >= 0 ||
        Sapellido.indexOf(this.filter.toLowerCase()) >= 0 ||
        // FichaCaracterizacion.indexOf(this.filter.toLowerCase()) >= 0 ||
        Regional.indexOf(this.filter.toLowerCase()) >= 0 ||
        centro_formacion.indexOf(this.filter.toLowerCase()) >= 0
      ) {
        repre_filter.push(repre);
      }
    }

    this.listaRepresentantesFiltrado = repre_filter;
    if (this.listaRepresentantesFiltrado.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#007bb8",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filter = "";
      this.listaRepresentantesFiltrado = this.listaRepresentantes;
      // this.paginate = ['listaConvocatoriasFiltrado']
    }
  }
}
