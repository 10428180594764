
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionTablasCargando from "../../../../components/animacionTablasCargando.vue";

@Component({
  components: {
    AnimacionTablasCargando,
  },
})
export default class Modificarsensibilizacion extends Vue {
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;
  id_convenio = null;

  estadoPeticion = false;
  lista_prioridad_uno: any[] = [];
  lista_convenio: any[] = [];

  sensibilizacion = {
    id_sensibilizacion_convenio: "",
    fecha_socilizacion: "",
    resultados: "",
    compromisos: "",
    prioridad_uno: "",
    prioridad_dos: "",
    url_acta_sensibilizacion: "",
    url_carta_intension: "",
    url_asistencia: "",
    cantidad_asistentes: "",
    convenio_id: "",
  };

  filter = "";
  listaProgramas: any[] = [];
  listaProgramasFiltrado: any[] = [];
  programasProyectTemp: any[] = [];
  // agregarPrograma = "";

  programa = "";
  todosProgramas = "";
  estadoPeticionTabla = false;

  paginate = ["listaProgramasFiltrado"];

  mounted() {
    this.obtenerConvenio();
    this.obtenerRedConocimiento();
    this.obtenerSensibilizacion(this.$route.params.id);
  }

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "El valor no puede ser menor a cero",
        email: () => "El correo no es válido",
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }

  obtenerSensibilizacion(id) {
    Axios()
      .get("ver_detalle_sensibilizacion/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.sensibilizacion = respuesta.data.results;
          //   this.mostrarActaSensibilizacion(id);
        }
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }

  modificarSensibilizacion() {
    this.estadoPeticion = true;
    // var data = new FormData();

    // Añadimos la docinscrip seleccionada
    // data.append("fecha_socilizacion", this.sensibilizacion.fecha_socilizacion);
    // data.append("resultados", this.sensibilizacion.resultados);
    // data.append("compromisos", this.sensibilizacion.compromisos);
    // data.append("prioridad_uno", this.sensibilizacion.prioridad_uno);
    // data.append("prioridad_dos", this.sensibilizacion.prioridad_dos);
    // data.append("cantidad_asistentes",this.sensibilizacion.cantidad_asistentes);
    // data.append("convenio_id", this.sensibilizacion.convenio_id);
    // const headers = {
    //   "Content-Type": "multipart/form-data",
    // };
    Axios()
      .post(
        "modificar_sensibilizacion/" +
          this.sensibilizacion.id_sensibilizacion_convenio,
        this.sensibilizacion
      )
      .then((respuesta) => {
        if ((respuesta.data.status = "success")) {
          // this.modalBuscarColegio = false;
          Swal.fire({
            title: "Sensibilización Modificada",
            text: "Se guardo la sensibilización",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          //   this.registrarColegios(respuesta.data.results.id_convenio);
          this.$router.push({ name: "GestionarSensibilizacion" });
        } else {
          Swal.fire("ERROR!", "No se Guardo la sensibilizacion", "error");
        }
        // this.estadoPeticion = false;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        // el formulario se validó y tiene los datos que corresponden por campo
        this.modificarSensibilizacion();
      }
    });
  }

  obtenerConvenio() {
    Axios()
      .get("listar_convenio")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_convenio = respuesta.data.results;
        }
      });
  }

  obtenerRedConocimiento() {
    Axios()
      .get("listar_red_conocimiento")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_prioridad_uno = respuesta.data.results;
        }
      });
  }
}
