
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import axios from "axios";
import AnimacionCargando from "../../components/animacionGuardando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class CrearFormacion extends Vue {
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;
  id_usuario_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_usuario;

  areas: any[] = [];
  picTipos: any[] = [];
  redesConocimiento: any[] = [];
  listaUsuario: any[] = [];
  listaUsuariostring: any[] = [];
  docValidouno = false;
  docValidodos = false;
  docValidotres = false;

  estadoPeticion = true;

  usuario = null;

  capacitacion = {
    id_pic: null,
    capacitacion: "",
    pertenece_a_red: "",
    plan_institucional_id: "",
    id_area_ruta: "",
    id_pic_tipo: "",
  };
  area = {
    area_ruta_id: null,
  };
  areaRuta = {
    id_usuario: null,
    persona_id: null,
    id_area_ruta: "",
    area_ruta: "",
    red_conocimiento: "",
    id_red_conocimiento: "",
  };
  // listaUsuarios = {
  //   id_historial_usuario: null,
  //   usuario_id: null,
  //   antigua_area_ruta_id: null,
  //   antigua_area_ruta: this.areaRuta.id_area_ruta,
  //   antigua_red: null,
  //   id_antigua_red: null,
  //   nueva_area_ruta_id: null,
  //   nueva_area_ruta: this.area.area_ruta_id,
  //   nueva_red: null,
  //   id_nueva_red: null,
  //   justificacion: null,
  //   anexo: null,
  //   creado: null,
  // };
  historial = {
    usuario_id: "",
    antigua_area_ruta_id: "",
    nueva_area_ruta_id: "",
    justificacion: "",
  };

  anexo_justificacion_red: null;
  resolucion_nombramiento_red: null;
  diploma_estudio_red: null;

  mounted() {
    this.listarUsuario();
    this.obtenerUsuario(this.$route.params.id);
    // this.historial1111();
    Axios()
      .get("/crear_pic")
      .then((respuesta) => {
        this.areas = respuesta.data.results.areas;
        this.picTipos = respuesta.data.results.picTipos;
        this.redesConocimiento = respuesta.data.results.redesConocimiento;
      });
  }
  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "No pude ser negativo",
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }
  actualizarFormacion(idUsuario) {
    Axios()
      .put("actualizar_area_red/" + idUsuario, this.area)
      .then((respuesta) => {
        if ((respuesta.data.status = "success")) {
          Swal.fire({
            title: "Area y Red de conocimiento",
            text: "Se actualizo correctamente!",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.$router.push({ name: "GestionarUsuario" });
        } else {
          Swal.fire({
            title: "Formación No Creada",
            text: "No Se Pudo Guardar La Formación!",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  listarUsuario() {
    Axios()
      .get("listar_historial_area_conocimiento/" + this.$route.params.id)
      .then((respuesta) => {
        this.listaUsuario = respuesta.data.results;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.actualizarFormacion(this.$route.params.id);
        this.subirDocumento();
      }
    });
  }

  obtenerAreaRuta(idUsuario) {
    Axios()
      .get("ver_area_red_usuario/" + idUsuario)
      .then((respuesta) => {
        this.areaRuta = respuesta.data.results;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  obtenerUsuario(idUsuario) {
    Axios()
      .get("listar_usuario/" + idUsuario)
      .then((respuesta) => {
        this.usuario = respuesta.data.results;
        if (this.usuario.area_ruta_id != null) {
          this.obtenerAreaRuta(this.$route.params.id);
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  obtenerDocumentoAnexoJustificacion(event) {
    if (event.target.files[0].size <= 5000000) {
      //5mb tope
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.anexo_justificacion_red = event.target.files[0];
        Swal.fire("PDF Cargado!", "Archivo cargado exitosamente!", "success");
        this.docValidouno = true;
      } else {
        this.docValidouno = false;
        this.anexo_justificacion_red = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValidouno = false;
      this.anexo_justificacion_red = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  }

  obtenerDocumentoResolucionNombramiento(event) {
    if (event.target.files[0].size <= 5000000) {
      //5mb tope
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.resolucion_nombramiento_red = event.target.files[0];
        Swal.fire("PDF Cargado!", "Archivo cargado exitosamente!", "success");
        this.docValidodos = true;
        // this.docResolucon = null;
      } else {
        this.docValidodos = false;
        this.resolucion_nombramiento_red = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValidodos = false;
      this.resolucion_nombramiento_red = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  }

  obtenerDocumentoDiplomaEstudioRed(event) {
    if (event.target.files[0].size <= 5000000) {
      //5mb tope
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.diploma_estudio_red = event.target.files[0];
        Swal.fire("PDF Cargado!", "Archivo cargado exitosamente!", "success");
        this.docValidotres = true;
        // this.docResolucon = null;
      } else {
        this.docValidotres = false;
        this.diploma_estudio_red = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValidotres = false;
      this.diploma_estudio_red = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  }

  subirDocumento() {
    this.estadoPeticion = true;
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    //Creamos el formData
    var data = new FormData();
    //Añadimos la docinscrip seleccionada
    data.append("usuario_id", this.$route.params.id);
    data.append("antigua_area_ruta_id", this.areaRuta.id_area_ruta);
    data.append("nueva_area_ruta_id", this.area.area_ruta_id);
    data.append("justificacion", this.historial.justificacion);
    data.append("anexo_justificacion_red", this.anexo_justificacion_red);
    data.append(
      "resolucion_nombramiento_red",
      this.resolucion_nombramiento_red
    );
    data.append("diploma_estudio_red", this.diploma_estudio_red);
    //Enviamos la petición

    Axios()
      .post("almacenar_item_historial_area_conocimiento", data, {
        headers: headers,
      })
      .then((respuesta) => {
        if ((respuesta.data.status = "success")) {
          // Swal.fire({
          //   title: "Se subió la resolución!",
          //   text: "Archivo cargado exitosamente!",
          //   icon: "success",
          //   confirmButtonText: "Aceptar",
          //   confirmButtonColor: "#007bb8",
          // });
        } else {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        }
        this.estadoPeticion = false;
        // this.$router.push({ name: "GestionarConvocatoria" });
      });
  }

  //-------------------------------------------------------------------------------
}
