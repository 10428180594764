<template>
  <div class="mt-5 container-fluid">
    <div class="card">
      <div
        class="border-radius-lg bg-gradient-primary card-header p-0 position-relative mt-n4 mx-3 z-index-2 shadow-3"
      >
        <div class="pt-4 pb-3">
          <h1 class="text-white text-capitalize ps-3 ms-3 title-header">
            LISTA ACTIVIDADES CENTRO
          </h1>
        </div>
      </div>
      <div class="card-body">
        <div class="row my-3">
          <!-- ---------------------------- BUSQUEDA CENTRO DE FORMACION -------------------->
          <div class="col-md-5 col-lg-4 mt-2">
            <select
              class="form-control shadow p-2"
              name="fecha"
              id="fecha"
              v-model="centro_formacion_id"
              @change="obtenerActividadesCentro()"
            >
              <option :value="nada" selected>
                Seleccione un centro de formación
              </option>
              <option
                v-for="(centro, index) in centros"
                :key="index"
                :value="centro.id_centro_formacion"
              >
                {{ centro.centro_formacion }}
              </option>
            </select>
          </div>
          <!-- ---------------------------- FILTROS DE BUSQUEDA EJE ----------------------------->
          <div class="col-md-4 col-lg-3 col-sm-12 mt-2 pb-3">
            <select
              class="form-control p-2"
              v-model="filtroEje"
              @change="filtrarEjes()"
              :disabled="!centro_formacion_id"
            >
              <option value="" Disabled>Eje</option>
              <option value="">Todas</option>
              <option>CUALIFICACIÓN OCUPACIONAL</option>
              <option>INTERMEDIACIÓN Y PROMOCIÓN LABORAL</option>
              <option>EMPRENDIMIENTO, INNOVACIÓN E INVESTIGACIÓN</option>
              <option>IDENTIFICACIÓN EGRESADO EN EL MERCADO LABORAL</option>
              <option>PERTENENCIA Y VINCULACION DEL EGRESADO SENA</option>
              <option>GESTIÓN DE INFORMACIÓN DEL EGRESADO</option>
              <option>COMUNICACIÓN INSTITUCIONAL</option>
            </select>
          </div>
          <!-------------------------------- FILTRO DE RESPONSABLES ----------------------------->
          <div class="col-md-4 col-lg-3 col-sm-12 mt-2 pb-3">
            <select
              class="form-control p-2"
              v-model="filtroResponsable"
              @change="filtrarResponsable()"
              :disabled="!centro_formacion_id"
            >
              <option value="" Disabled>Responsable</option>
              <option value="">Todas</option>
              <option>
                Coordinador Grupo de Bienestar al aprendiz y atención al
                egresado
              </option>
              <option>
                Coordinador grupo formación continua especializada
              </option>
              <option>Coordinador SENNOVA</option>
              <option>Coordinador Nacional de Emprendimiento</option>
              <option>Coordinador grupo de Relaciones laborales</option>
              <option>Coordinador de Gestión curricular - Redes</option>
              <option>
                Coordinador de Gestión curricular - Redes - Dirección de
                relacionamiento corporativo
              </option>
              <option>
                Coordinador de la Escuela Nacional de Instructores
              </option>
              <option>Coordinador Nacional de Emprendimiento</option>
              <option>
                Coordinador Nacional de la Agencia Pública de Empleo
              </option>
              <option>Coordinador Nacional Agencia Pública de Empleo</option>
              <option>
                Grupo de Gestión de Competencias Laborales GGCL - Mesas
                Sectoriales
              </option>
              <option>Coordinador Nacional APE</option>
              <option>Coodinador de Formación o Misional</option>
              <option>Coordinador misional</option>
              <option>
                Coordinador misional/líder de emprendimiento regional
              </option>
              <option>Director Regional</option>
              <option>
                Directores Regionales, Coordinador de formacion regional
              </option>
              <option>
                Líderes Regionales de los Centros de Desarrollo Empresarial
              </option>
              <option>Directores Regionales</option>
              <option>Gestor Regional SER</option>
              <option>
                Apoyo Administrativo y Secretaría Técnica de Mesa Sectorial del
                Centro de Formación respectivo
              </option>
              <option>
                Coordinadores regionales Agencia Pública de Empleo SENA
              </option>
              <option>Subdirector del Centro.</option>
              <option>Subdirector de Centro, Director Regional.</option>
              <option>Subdirector de Centro , Coordinador de formación</option>
              <option>
                subdirector del Centro, Coordinaciones de formación,
                Coordinaciones académicas
              </option>
              <option>Coordinador de formación</option>
              <option>Dinamizadores Tecnoparque</option>
              <option>
                Líder SENNOVA, líderes de Grupo y de Semilleros de Investigación
              </option>
              <option>Coordinador/Gestor empresarial</option>
              <option>
                Subdirector de Centro - Redes - Profesionales de diseño
                curricular
              </option>
              <option>Subdirectores, Coordinadores de centro</option>
              <option>Subdirectores, Coordinadores de centro</option>
              <option>Subdirector de Centro de Formación</option>
              <option>Gestor de Centro SER</option>
              <option>Director de Formación Profesional</option>
              <option>Director de Empleo, Trabajo y Emprendimiento</option>
              <option>Director de Empleo y Trabajo</option>
              <option>Director de Formación Profesional</option>
              <option>
                Director del Sistema Nacional de Formación para el Trabajo
              </option>
              <option>Secretaria General</option>
            </select>
          </div>
          <!---------------------------------- FILTRO PERIODICIDAD ------------------------->
          <div class="col-md-4 col-lg-2 col-sm-12 mt-2 pb-3">
            <select
              class="form-control p-2"
              v-model="filtroPeriodicidad"
              @change="filtrarPeriocidad()"
              :disabled="!centro_formacion_id"
            >
              <option value="" Disabled>Periodicidad</option>
              <option value="">Todas</option>
              <option>Semanal</option>
              <option>Quincenal</option>
              <option>Mensual</option>
              <option>Bimestral</option>
              <option>Trimestral</option>
              <option>Cuatrimestral</option>
              <option>Semestral</option>
              <option>Anual</option>
            </select>
          </div>
        </div>
        <!-- ----------------------------------- PAGINACION ------------------------------->
        <div v-if="actividadesCentro != null">
          <paginate-links
            class="pagination justify-content-end"
            for="actividadesCentro"
            :limit="3"
            :hide-single-page="true"
            :show-step-links="true"
            :full-page="true"
            :classes="{
              ul: 'simple-links-container',
              li: 'simple-links-item',
              liActive: ['simple-links-item', 'active1'],
              'li.active': 'active1',
            }"
          ></paginate-links>
        </div>
      </div>
      <!-- ------------------------------------ TABLA ------------------------------------>
      <div class="table-responsive">
        <paginate
          ref="paginator"
          name="actividadesCentro"
          :list="actividadesCentro"
          :per="8"
        >
          <table class="table table-hover table-inverse">
            <thead class="bg-gradient-azul-sena text-white" id="listado">
              <tr>
                <th>EJE</th>
                <th>ESTRATEGIA DE IMPLEMENTACIÓN</th>
                <th>ACCIÓN</th>
                <th>META/ INDICADOR</th>
                <th>RESPONSABLE</th>
                <th>FRECUENCIA</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <div class="text-center" v-if="estadoPeticion">
              <AnimacionCargando></AnimacionCargando>
            </div>
            <template v-if="!estadoPeticion">
              <tbody v-if="actividadesCentro != null">
                <tr
                  v-for="(actividad, key) in paginated('actividadesCentro')"
                  :key="key"
                >
                  <td class="campos">
                    <div class="actividad">{{ actividad.eje }}</div>
                  </td>

                  <td>{{ actividad.pes_estrategia }}</td>
                  <td>{{ actividad.actividad }}</td>
                  <td>{{ actividad.indicador }}</td>
                  <td>{{ actividad.rol_operativo }}</td>
                  <td>{{ actividad.periodicidad }}</td>

                  <td>
                    <span tooltip="Lista seguimientos" tooltip-flow="top">
                      <a
                        @click="listaSeguimiento(actividad.id_actividad)"
                        class="btn btn-sm bg-oscuro-sena text-white mr-1"
                      >
                        <i class="fas fa-paste"></i>
                      </a>
                    </span>
                  </td>
                </tr>
              </tbody>
              <p v-else>No hay actividades registradas para este centro!</p>
            </template>
          </table>
        </paginate>
      </div>
      <div v-if="actividadesCentro != null">
        <paginate-links
          class="pagination justify-content-end"
          for="actividadesCentro"
          :limit="3"
          :hide-single-page="true"
          :show-step-links="true"
          :full-page="true"
          :classes="{
            ul: 'simple-links-container',
            li: 'simple-links-item',
            liActive: ['simple-links-item', 'active1'],
            'li.active': 'active1',
          }"
        ></paginate-links>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";
export default {
  components: {
    AnimacionCargando,
  },
  mounted() {
    this.centro_formacion_id = this.$route.params.centro_formacion_id;
    this.cargarCentros();
    this.obtenerActividadesCentro();
    this.disable();
  },

  data() {
    return {
      id_usuario: JSON.parse(sessionStorage.getItem("usuario")).id_usuario,
      centros: [],
      centro_formacion_id: "",
      actividadesCentro: [],
      paginate: ["actividadesCentro"],
      estadoPeticion: false,
      /* Filtro eje */
      /* 1 */ filtradoEje: [],
      /* 2 */ filtroEje: "",
      /* Filtro Responsable*/
      /* 1 */ filtradoResponsable: [],
      /* 2 */ filtroResponsable: "",
      /* Filtro Periodicidad 1 */
      /* 1 */ filtroPeriodicidad: "",
      /* 2 */ periocidadFiltrado: [],
    };
  },

  methods: {
    cargarCentros() {
      Axios()
        .get("listar_centros_regional/" + this.id_usuario)
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            this.centros = respuesta.data.results;
            this.estadoPeticion = false;
          }
          if (respuesta.data.status == "info") {
            Swal.fire({
              title: "Informacion",
              text: respuesta.data.message,
              icon: "warning",
              confirmButtonText: "Aceptar",
              customClass: {
                confirmButton: "btn btn-azul-sena",
              },
            });
            this.estadoPeticion = false;
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        });
    },

    obtenerActividadesCentro() {
      this.estadoPeticion = true;
      Axios()
        .post("actividades_centros", {
          id_usuario: this.id_usuario,
          id_centro_formacion: this.centro_formacion_id,
        })
        .then((respuesta) => {
          this.actividadesCentro = respuesta.data.results;
          this.activida = respuesta.data.results;
          this.estadoPeticion = false;
        });
    },

    listaSeguimiento(id, actividad) {
      const actividad_id = id;
      const nombreActividad = actividad;
      const centro_formacion_id = this.centro_formacion_id;
      this.$router.push({
        name: "SeguimientoActividades",
        params: { actividad_id, nombreActividad, centro_formacion_id },
      });
    },

    filtrarEjes() {
      let filtradoEje = [];

      for (let nombreejes of this.activida) {
        let nombre = nombreejes.eje;
        if (nombre.indexOf(this.filtroEje) >= 0) {
          filtradoEje.push(nombreejes);
        }
      }
      this.actividadesCentro = filtradoEje;

      if (this.actividadesCentro.length == 0) {
        //busco no encontro
        Swal.fire({
          title: "No hay coincidencias",
          position: "center",
          timer: 1000,
          text: "No se encontró convocatorias que coincidan con las busqueda",
          showConfirmButton: false,
          backdrop: "rgba(0,0,0,0)",
          background: "#eeeeee",
        });
        this.filtroEje = "";
        this.actividadesCentro = this.activida;
      }
    },

    filtrarResponsable() {
      let filtradoResponsable = [];
      for (let responsable of this.activida) {
        let respon = responsable.rol_operativo;
        if (respon.indexOf(this.filtroResponsable) >= 0) {
          filtradoResponsable.push(responsable);
        }
      }
      this.actividadesCentro = filtradoResponsable;
      if (this.actividadesCentro.length == 0) {
        //busco no encontro
        Swal.fire({
          title: "No hay coincidencias",
          position: "center",
          timer: 1000,
          text: "No se encontró convocatorias que coincidan con las busqueda",
          showConfirmButton: false,
          backdrop: "rgba(0,0,0,0)",
          background: "#eeeeee",
        });
        this.filtroResponsable = "";
        this.actividadesCentro = this.activida;
      }
    },

    filtrarPeriocidad() {
      let periocidadFiltrado = [];
      for (let periodicidad of this.activida) {
        let periodo = periodicidad.periodicidad;
        if (periodo.indexOf(this.filtroPeriodicidad) >= 0) {
          periocidadFiltrado.push(periodicidad);
        }
      }
      this.actividadesCentro = periocidadFiltrado;

      if (this.actividadesCentro.length == 0) {
        //busco no encontro
        Swal.fire({
          title: "No hay coincidencias",
          position: "center",
          timer: 1000,
          text: "No se encontró convocatorias que coincidan con las busqueda",
          showConfirmButton: false,
          backdrop: "rgba(0,0,0,0)",
          background: "#eeeeee",
        });
        this.filtroPeriocidad = "";
        this.actividadesCentro = this.activida;
      }
    },
  },
};
</script>

<style scoped>
.campos .actividad {
  width: 500px;
}
</style>
