
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import AnimacionCargando from "@/components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class VerDetalleDocs extends Vue {
  estadoPeticion = false;
  docValido = false;
  rutadocumento = null;
  verModal = false;

  documentos = {
    id_documento: "",
    ruta: "",
    documento: "",
    persona_id: "",
    primer_nombre: "",
    segundo_nombre: "",
    primer_apellido: "",
    segundo_apellido: "",
    fecha: "",
  };
  mounted() {
    this.obtenerDocs(this.$route.params.id);
  }

  obtenerDocs(id) {
    Axios()
      .get("ver_detalle_documento/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.documentos = respuesta.data.results[0];
          this.mostrarDocumento(id);
        }
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }
  mirarModal() {
    this.verModal = true;
  }
  obtenerDocumento(event) {
    if (event.target.files[0].size <= 5000000) {
      //5mb tope
      let extDoc = event.target.files[0].name
        .toString()
        .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
      if (extDoc == ".pdf") {
        this.documentos.ruta = event.target.files[0];
        Swal.fire("PDF Cargado!", "Archivo cargado exitosamente!", "success");
        this.docValido = true;
        // this.docResolucon = null;
      } else {
        this.docValido = false;
        this.documentos.ruta = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado no es un PDF!",
          "error"
        );
      }
    } else {
      this.docValido = false;
      this.documentos.ruta = null;
      Swal.fire(
        "Algo salió mal!",
        "El archivo cargado pesa más de 5 MegaBytes!",
        "error"
      );
    }
  }

  mostrarDocumento(id_documento) {
    this.rutadocumento =
      this.$urlAPI + "visualizar_documento/" +
      id_documento;
  }
}
