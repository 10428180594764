var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-5 container-fluid"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row"},[_c('div',[_c('small',{staticClass:"text-azul-sena mr-1"},[_vm._v(" Convocatoria: "+_vm._s(this.convocatoria.convocatoria)+" ")])]),_c('div',[_c('small',{staticClass:"text-azul-sena"},[_vm._v(" Capacitación: "+_vm._s(this.convocatoria.capacitacion)+" ")])])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"mb-3"},[(_vm.btnIniciarValidaciones)?_c('div',[(_vm.listaInscripciones != [])?_c('button',{staticClass:"btn btn-azul-sena col-12 col-md-6 col-lg-4 shadow-3 p-2",on:{"click":function($event){return _vm.cambiarEstadoConvocatoria()}}},[_vm._v(" Finalizar adjudicaciones ")]):_vm._e()]):_vm._e(),(!_vm.btnIniciarValidaciones)?_c('div',{staticClass:"text-center"},[_c('AnimacionCargando')],1):_vm._e(),_c('paginate-links',{staticClass:"pagination justify-content-end",attrs:{"for":"listaInscripciones","limit":2,"hide-single-page":true,"show-step-links":true,"full-page":true,"classes":{
            ul: 'simple-links-container',
            li: 'simple-links-item',
            liActive: ['simple-links-item', 'active1'],
            'li.active': 'active1',
          }}})],1),(_vm.estadoPeticion)?_c('div',{staticClass:"text-center"},[_c('AnimacionCargando')],1):_vm._e(),(_vm.listaInscripciones == null)?_c('div',[_c('h5',{staticClass:"text-azul-sena"},[_vm._v("No hay Inscritos en esta convocatoria")])]):_vm._e(),(!_vm.estadoPeticion)?_c('div',{staticClass:"table-responsive"},[_c('paginate',{ref:"paginator",attrs:{"name":"listaInscripciones","list":_vm.listaInscripciones,"per":10}},[(_vm.listaInscripciones != [])?_c('table',{staticClass:"table table-hover"},[_c('thead',{attrs:{"id":"listado"}},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Identificación")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Nombres")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Prioridad")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Estado")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Acciones")])])]),_c('tbody',_vm._l((_vm.filtrar(
                  _vm.paginated('listaInscripciones')
                )),function(inscrito,key){return _c('tr',{key:key},[_c('td',[_vm._v(" "+_vm._s(inscrito.persona.identificacion)+" ")]),_c('td',[_vm._v(" "+_vm._s(inscrito.persona.primer_nombre)+" "+_vm._s(inscrito.persona.segundo_nombre)+" "+_vm._s(inscrito.persona.primer_apellido)+" "+_vm._s(inscrito.persona.segundo_apellido)+" ")]),(inscrito.categorizado <= 3)?_c('td',[_vm._v(" "+_vm._s(inscrito.categorizado)+" ")]):_vm._e(),(inscrito.categorizado > 3)?_c('td',[_vm._v("3")]):_vm._e(),_c('td',[_vm._v(" "+_vm._s(inscrito.pic_estado_inscripcion.pic_estado_inscripcion)+" ")]),_c('td',{attrs:{"scope":"row"}},[_c('div',{staticClass:"btn-toolbar btn-responsive-table"},[(
                        inscrito.pic_estado_inscripcion
                          .pic_estado_inscripcion == 'Inscrito'
                      )?_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Adjudicar","tooltip-flow":"top"}},[_c('button',{staticClass:"btn btn-sm bg-oscuro-sena text-white shadow",on:{"click":function($event){(_vm.modalAdjudicar = true),
                            (_vm.idPersonaModal = inscrito.persona.id_persona),
                            (_vm.observacion = null);
                          _vm.obtenerRequisitos(
                            //mandar de la lista con el index del vector para que quede bien
                            inscrito.persona.id_persona,
                            inscrito.id_pic_inscripcion,
                            inscrito
                          );
                          _vm.obtenerInscritos(inscrito.persona.id_persona, true);}}},[_c('i',{staticClass:"fas fa-check-square"})]),_c('modal',{attrs:{"show":_vm.modalAdjudicar,"body-classes":"p-0","modal-classes":"modal-dialog-centered modal-sm modal-lg"},on:{"update:show":function($event){_vm.modalAdjudicar=$event}}},[_c('template',{slot:"header"},[_c('h1',{staticClass:"text-azul-sena modal-title",attrs:{"id":"modalNovedadesAprendiz"}},[_vm._v(" Lista requisitos Instructor ")])]),_c('card',{staticClass:"border-0",attrs:{"type":"white","shadow":"","header-classes":"bg-white","body-classes":"px-lg-5 py-lg-5"}},[_c('div',[_c('span',{staticClass:"text-azul-sena"},[_vm._v(" Nombre: "+_vm._s(_vm.inscritoPersonaActual.primer_nombre)+" "+_vm._s(_vm.inscritoPersonaActual.segundo_nombre)+" "+_vm._s(_vm.inscritoPersonaActual.primer_apellido)+" "+_vm._s(_vm.inscritoPersonaActual.segundo_apellido)+" ")]),_c('span',{staticClass:"text-azul-sena"},[_vm._v(" Cédula: "+_vm._s(_vm.inscritoPersonaActual.identificacion)+" ")])]),[_c('div',{attrs:{"id":"campo"}},[_c('table',{staticClass:"table table table-hover table-inverse"},[_c('thead',{staticClass:"bg-gradient-naranja-sena text-white",attrs:{"id":"listado"}},[_c('tr',[_c('th',{staticClass:"text-nowrap"},[_vm._v("Requisito")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Acciones")])])]),_c('tbody',_vm._l((_vm.requisitosInstructor),function(requisito,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(requisito.anexo))]),_c('td',[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",modifiers:{"hover":true,"top":true}}],attrs:{"title":"Ver documento"}},[_c('b-button',{staticClass:"btn btn-sm bg-oscuro-sena text-white shadow-3",on:{"click":function($event){(_vm.modalRequisito = true),
                                              _vm.mostrarDocumento(
                                                requisito.id_pic_requisito_instructor
                                              )}}},[_c('i',{staticClass:"fas fa-eye"})])],1)])])}),0)]),_c('div',{attrs:{"id":"campo"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(" ¿Por qué es o no aceptado? "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" : ")]),_c('div',[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"input-group mb-3",attrs:{"placeholder":"Observación*","addon-left-icon":"","name":"observacion","id":"observacion"},model:{value:(_vm.observacion),callback:function ($$v) {_vm.observacion=$$v},expression:"observacion"}})],1),_c('small',[_vm._v(" Importante: Está acción no tiene vuelta atrás. ")])]),_c('button',{staticClass:"btn btn-azul-sena col-12 col-md-6 col-lg-4 mt-2 mr-4 shadow-3 p-2",on:{"click":function($event){_vm.adjudicarInstructorConvocatoria(
                                    _vm.idPersonaModal,
                                    true
                                  ),
                                    (_vm.esAdjudicado = true)}}},[_vm._v(" Adjudicar ")]),_c('button',{staticClass:"btn btn-azul-sena col-12 col-md-6 col-lg-4 mt-2 shadow-3 p-2",on:{"click":function($event){_vm.adjudicarInstructorConvocatoria(
                                    _vm.listaInscripciones[key].persona
                                      .id_persona,
                                    false
                                  ),
                                    (_vm.noAprobado = true)}}},[_vm._v(" No aprobado ")])])]],2)],2)],1):_vm._e(),(
                        inscrito.pic_estado_inscripcion
                          .pic_estado_inscripcion == 'Adjudicado'
                      )?_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Desadjudicar","tooltip-flow":"top"}},[_c('button',{staticClass:"btn btn-sm bg-oscuro-sena text-white shadow-3",on:{"click":function($event){_vm.adjudicarInstructorConvocatoria(
                            inscrito.persona.id_persona,
                            true
                          ),
                            (_vm.esAdjudicado = false)}}},[_c('i',{staticClass:"fas fa-minus-square"})])]):_vm._e(),_c('div',{staticClass:"btn-group btn-spc",attrs:{"tooltip":"Ver Inscrito","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white shadow",attrs:{"to":{
                          name: 'VerDetallePostulado',
                          params: {
                            id: inscrito.persona_id,
                          },
                        }}},[_c('i',{staticClass:"fas fa-eye"})])],1)]),_c('modal',{attrs:{"show":_vm.modalRequisito,"body-classes":"p-0","modal-classes":"modal-dialog-centered modal-sm modal-lg"},on:{"update:show":function($event){_vm.modalRequisito=$event}}},[_c('template',{slot:"header"},[_c('h1',{staticClass:"text-azul-sena modal-title",attrs:{"id":"modalNovedadesAprendiz"}},[_vm._v(" Requisito ")])]),_c('embed',{attrs:{"src":_vm.rutadocumento,"type":"application/pdf","width":"100%","height":"600px"}})],2)],1)])}),0)]):_vm._e()])],1):_vm._e(),_c('paginate-links',{staticClass:"pagination justify-content-end",attrs:{"for":"listaInscripciones","limit":2,"hide-single-page":true,"show-step-links":true,"full-page":true,"classes":{
          ul: 'simple-links-container',
          li: 'simple-links-item',
          liActive: ['simple-links-item', 'active1'],
          'li.active': 'active1',
        }}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"border-radius-lg bg-gradient-primary card-header p-0 position-relative mt-n4 mx-3 z-index-2 shadow-3 P-2"},[_c('div',{staticClass:"pt-4 pb-3"},[_c('h1',{staticClass:"text-white text-capitalize ps-3 ms-3 title-header"},[_vm._v(" LISTA INSCRITOS ")])])])
}]

export { render, staticRenderFns }