
  import { Component, Vue } from "vue-property-decorator";
  import Axios from "@/Axios";
  import Swal from "sweetalert2";
  import AnimacionCargando from "../../../components/animacionCargando.vue";
  import AnimacionTablasCargando from "../../../components/animacionTablasCargando.vue";

  @Component({
    components: {
      AnimacionCargando,
      AnimacionTablasCargando,
    },
  })
  export default class GestionarEjecucionColegio extends Vue {
    listaColegios = [];
    listaColegiosFiltrado: any[] = [];
    paginate = ["listaColegiosFiltrado"];
    filter = "";
    id_secretaria = "";
    // id_secretaria: any[] = [];
    secretaria = [];

    lista_secretaria: any[] = [];

    buscarColegio;
    id_convenio = ""
    buscarColegiosDane;
    dane = "";

    estadoPeticionTabla = false;
    colegio = {
      id_colegio: "",
      colegio_modalidad_id: "",
      zona_id: "",
      jornada_id: "",
      municipio_id: "",
      primer_nombre: "",
      coord_persona_id: "",
      rector_persona_id: "",
      // 'personas.id_persona',
      // 'personas.primer_nombre',
      // 'personas.id_persona',
      // 'rector.segundo_nombre',
      colegio: "",
      nit: "",
      direccion: "",
      telefono: "",
      cod_dane: "",
      licencia: "",
      sector_colegio: "",
      secretaria_id: "19",
      estado: "",
      resguardo: "",
      correo: "",
      calendario: "",
      genero: "",
      sedes: "",
      jornadas: "",
      rector: "",
    };

    constructor() {
      super();
      const dict = {
        messages: {
          required: () => "Campo obligatorio",
          min: () => "Mínimo 8 caracteres",
        },
      };
      this.$validator.localize("es", dict);
    }

    mounted() {
      this.obtenerColegios();
      this.obtenerSecretariaColegio();
      this.id_convenio = this.$route.params.id_convenio;
    }

    updated() {
      // this.obtenerColegios();
    }
    // Matchetazo aspero pero incompleto
    obtenerColegios() {
      Axios()
        .get("listar_colegios/" + this.colegio.secretaria_id)
        .then((respuesta) => {
          this.listaColegios = respuesta.data.results;
          this.listaColegiosFiltrado = respuesta.data.results;
          if (respuesta.data.status == "success") {
            this.estadoPeticionTabla = true;
            this.colegio.secretaria_id = this.id_secretaria;
            this.id_secretaria = this.colegio.secretaria_id;
          }
        })
        .catch(() => {
          if (this.id_secretaria != "") {
            Swal.fire({
              title: "Error de servidor!",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
              icon: "error",
            });
          } else {
          }
        })
        .finally(() => {
          this.estadoPeticionTabla = true;
        });
    }

    obtenerSecretariaColegio() {
      Axios()
        .get("listar_secretaria_colegio")
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            this.lista_secretaria = respuesta.data.results;
          }
        });
    }

    eliminarColegio(id, index) {
      Swal.fire({
        title: "Eliminar Colegio ",
        text: this.listaColegiosFiltrado[index].colegio + "?",
        icon: "warning",
        confirmButtonText: "Eliminar",
        confirmButtonColor: "#007bb8",
        cancelButtonColor: "#2d2d2d",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
      }).then((respuesta) => {
        if (respuesta.isConfirmed) {
          Axios()
            .delete("eliminar_colegios/" + id)
            .then((respuesta) => {
              if (respuesta.data.status == "success") {
                Swal.fire({
                  title: "Colegio Eliminado",
                  icon: "success",
                  confirmButtonText: "Aceptar",
                  customClass: {
                    confirmButton: "btn btn-azul-sena",
                  },
                });
                this.listaColegiosFiltrado.splice(index, 1);
              } else {
                Swal.fire({
                  title: "No se pudo eliminar el colegio",
                  text: respuesta.data.message,
                  icon: "error",
                  confirmButtonText: "Aceptar",
                  customClass: {
                    confirmButton: "btn btn-azul-sena",
                  },
                });
                this.$router.back();
              }
            })
            .catch(() => {
              Swal.fire(
                "ERROR!",
                "Se ha presentado un error en el servidor!",
                "error"
              );
            });
        }
      });
    }

    colegioFiltrado() {
      let colegioFilter = [];
      // this.listaConvocatoriasFiltrado = []

      for (let colegi of this.listaColegios) {
        let colegio = colegi.colegio.toLowerCase();
        let municipio = colegi.municipio.toLowerCase();
        let rector = colegi.rector.toLowerCase();
        let dane = colegi.cod_dane.toLowerCase();

        if (
          colegio.indexOf(this.filter) >= 0 ||
          municipio.indexOf(this.filter) >= 0 ||
          rector.indexOf(this.filter) >= 0 ||
          dane.indexOf(this.filter) >= 0
        ) {
          colegioFilter.push(colegi);
        }
      }

      this.listaColegiosFiltrado = colegioFilter;
      if (this.listaColegiosFiltrado.length == 0) {
        //busco no encontro
        Swal.fire({
          title: "No hay coincidencias",
          position: "center",
          timer: 1000,
          text: "No se encontró el colegio, que coincida con la búsqueda",
          showConfirmButton: false,
          // confirmButtonText: "Aceptar",
          // confirmButtonColor: "#238276",
          backdrop: "rgba(0,0,0,0)",
          background: "#eeeeee",
        });
        this.filter = "";
        this.listaColegiosFiltrado = this.listaColegios;
      }
    }

      async buscarColegios() {
    await Axios()
      .get("buscar_colegio/" + this.dane)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.buscarColegiosDane = respuesta.data.results;
          this.listaColegiosFiltrado = [];
          this.listaColegiosFiltrado.push(this.buscarColegiosDane);
          // this.listaUsuarios = []
          // this.listaUsuarios.push(respuesta.data.results.data)

          // this.estadoPeticion = false;
        } else {
          Swal.fire({
            title: "No existe el codigo dane",
            text: " Para el colegio!",
            icon: "error",
          });
        }
      })
      .catch(() => {});
  }

    filtrar() {
    if (this.dane == "") {
      this.listaColegiosFiltrado = this.listaColegios;
      return this.listaColegiosFiltrado;
    } else {
      return this.listaColegiosFiltrado;
    }
  }

  }
