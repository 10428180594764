
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando
  },
})
export default class VerDetalleCapacitacion extends Vue {
  datosCapacitacion = {
    capacitacion: "",
    competencia_programa_oferta: "",
    componentes: "",
    convocatoria: "",
    eje: "",
    entidad_capacitacion: "",
    fecha_final: "",
    fecha_inicio: "",
    financiacion: "",
    id_pic_convocatoria: "",
    id_pic_fase: "",
    id_usuario_actualiza: "",
    linea_tecnologica: "",
    lugar: "",
    para_funcionarios: "",
    perfiles_requisitos: "",
    pertenece_a_red: "",
    pic_fase_convocatoria: "",
    pic_id: "",
    plan_institucional_id: "",
    resultado_aprendizaje: "",
    tipo_financiacion: "",
  };

  estadoanimacion = false;

  mounted() {
    this.obtenerCapacitacion(this.$route.params.id);
  }
  obtenerCapacitacion(id) {
    this.estadoanimacion = true;
    Axios()
      .get("editar_convocatoria_pic/" + id)
      .then((respuesta) => {
        this.datosCapacitacion = respuesta.data.results;
        // .log(respuesta.data.results);
        this.estadoanimacion = false;
      })
      .catch(() => {
        //
        this.estadoanimacion = false;
      })
      .finally(() => {
        this.estadoanimacion = false;
      });
  }
}
