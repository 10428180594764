
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";
import AnimacionTablasCargando from "../../../components/animacionTablasCargando.vue";


@Component({
  components: {
    AnimacionCargando,
    AnimacionTablasCargando,
  },
})
export default class Login extends Vue {
  modalRegistroCriterio = false;
  estadoPeticion = true;
  listaApoyos: any[] = [];
  listaCriterios: any[] = [];
  estadoPeticionTabla = false;
  paginate = ["listaCriteriosApoyo"];
  listaCriteriosApoyo = [];
  listaCriteriosNoRepetidos = [];
  estadoCriterio=false;
  criterios = {
    tipo_apoyo_id: null,
    criterio_priorizacion_id: null,
    criterio_priorizacion: null,
    puntaje_criterio: null,
    id_criterio_tipos_apoyo: null,
  };

  async mounted() {
    await this.obtenerApoyosCriterios();
  }

  obtenerApoyosCriterios() {
    Axios()
      .get("crear_tipos_apoyo")
      .then((respuesta) => {
        this.listaApoyos = respuesta.data.results.tipos_apoyo;
        this.listaCriterios = respuesta.data.results.criterios_priorizacion;
        this.estadoPeticion = false;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }
  // obtener los criterios que son pertenecientes a (x) apoyo
  async obtenerCriteriosApoyo(id_apoyo) {
    await Axios()
      .get("listar_criterios/" + id_apoyo)
      .then((respuesta) => {
        this.listaCriteriosApoyo = respuesta.data.results;
        this.estadoPeticion = false;
        this.obtenerDatosNoRepetidosCriteriosPriorizacion();
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }

  obtenerDatosNoRepetidosCriteriosPriorizacion() {
    this.listaCriteriosNoRepetidos = [];
    let repetido = false;
    for (const criterios in this.listaCriterios) {
      repetido = false;
      for (const criteriosApoyo in this.listaCriteriosApoyo) {
        if (
          this.listaCriteriosApoyo[criteriosApoyo].criterio_priorizacion_id ==
          this.listaCriterios[criterios].id_criterio_priorizacion
        ) {
          repetido = true;
          break;
        }
      }
      if (repetido == false) {
        this.listaCriteriosNoRepetidos.push(this.listaCriterios[criterios]);
      }
    }
  }

  //registrarCriterio
  registrarCriterio() {
    Axios()
      .post("almacenar_crititerio", {
        criterio_priorizacion: this.criterios.criterio_priorizacion,
        puntaje: this.criterios.puntaje_criterio,
      })
      .then((respuesta) => {
        if ((respuesta.data.status = "success")) {
          this.registrarCriterioTipoApoyo(
            respuesta.data.results.id_criterio_priorizacion
          );
        } else {
          Swal.fire("ERROR!", "No se creo el criterio ", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
       this.criterios.criterio_priorizacion=null;
        this.criterios.puntaje_criterio=null
      });
  }
  // registar criterio relacionado con el apoyo
  registrarCriterioTipoApoyo($id_criterio) {
    this.estadoCriterio=true;
    Axios()
      .post("almacenar_crititerio_tipo_apoyo", {
        tipo_apoyo_id: this.criterios.tipo_apoyo_id,
        criterio_priorizacion_id: $id_criterio,
      })
      .then((respuesta) => {
        if ((respuesta.data.status = "success")) {
          this.estadoCriterio=false;
          Swal.fire({
            title: "Criterio Guardado",
            text: "Se guardo el Criterio en el apoyo",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        } else {
          Swal.fire("ERROR!", "No se creo el criterio ", "error");
        }
        this.modalRegistroCriterio = false;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
        this.actualizarListaCriteriosApoyo();
      });
  }
  eliminarCriterioApoyo(id, index) {
    Swal.fire({
      title: "Eliminar Criterio ",
      icon: "warning",
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((respuesta) => {
      if (respuesta.isConfirmed) {
        Axios()
          .delete("eliminar_criterio/" + id)
          .then((respuesta) => {
            if (respuesta.data.status == "success") {
              Swal.fire({
                title: "Criterio Eliminado",
                icon: "success",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
            } else {
              Swal.fire({
                title: "No se pudo eliminar el criterio",
                text: respuesta.data.message,
                icon: "error",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.$router.back();
            }
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          })
          .finally(() => {
            this.actualizarListaCriteriosApoyo();
          });
      }
    });
  }

  async actualizarListaCriteriosApoyo() {
    await this.obtenerCriteriosApoyo(this.criterios.tipo_apoyo_id);
    await this.obtenerApoyosCriterios();
  }

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "El valor no puede ser menor a cero",
        max_value: () => "El valor no puede ser menor a cien",
        email: () => "El correo no es válido",
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }
}
