
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionTablasCargando from "../../../components/animacionTablasCargando.vue";
import XLSX from "xlsx";

@Component({
  components: {
    AnimacionTablasCargando,
  },
})
export default class ListarVocero extends Vue {
  listaVoceroB: any[] = [];
  listaVocero: any[] = [];
  tiposdevocerosB: any[] = [];
  paginate = ["listaVoceroB"];
  hayDatos = false;
  estadoPeticionTabla = false;
  filter = "";
  filterTipo = "";
   tiposdevoceros: any[] = [];
  constructor() {
    super();
    const dict = {
      messages: {
        decimal: () => "Campo obligatorio",
        min: () => "Mínimo 8 caracteres",
      },
    };
    this.$validator.localize("es", dict);
  }

  async mounted() {
    this.listarVoceros();
    this.obtenerTiposVoceros();
  }
  async listarVoceros() {
    await Axios()
      .get("listar_voceros")
      .then((respueta) => {
        this.listaVocero = respueta.data.results;
        this.listaVoceroB = respueta.data.results;
        if (this.listaVocero.length > 0) {
          this.hayDatos = true;
        }
      })
      .catch((error) => {
        Swal.fire(
          "ERROR!",
          "Se ha detectado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }
  eliminarVocero(id, key, nombre) {
    Swal.fire({
      title: "Eliminar vocero",
      text: "¿Desea eliminar al vocero: " + nombre + "?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios()
          .delete("eliminar_vocero/" + id)
          .then((respuesta) => {
            this.listarVoceros();
            Swal.fire({
              title: "Representante Eliminado",
              text: "El representate ha sido eliminado exitosamente!",
              icon: "success",
              cancelButtonText: "Aceptar",
              confirmButtonColor: "#007bb8",
            });
          });
      } else {
        Swal.fire({
          title: "Error",
          text: "No se pudo eliminar el vocero!",
          icon: "error",
          cancelButtonText: "Aceptar",
          confirmButtonColor: "#007bb8",
        });
      }
    });
  }
  voceroFiltrado() {
    let filterVocero = [];
    for (let vocero of this.listaVocero) {
      let identificacion = vocero.identificacion.toLowerCase();
      let primer_nombre = vocero.primer_nombre.toLowerCase();
      let segundo_nombre = "";
      if(vocero.segundo_nombre != null){
        segundo_nombre = vocero.segundo_nombre.toLowerCase();
      }
      
      let primer_apellido = "";
      let segundo_apellido = "";
      if (vocero.segundo_pellido != null) {
        segundo_apellido = vocero.segundo_apellido.toLowerCase();
      }
      if (vocero.primer_apellido != null) {
        primer_apellido = vocero.primer_apellido.toLowerCase();
      }

      if (
        primer_nombre.indexOf(this.filter.toLowerCase()) >= 0 ||
        segundo_nombre.indexOf(this.filter.toLowerCase()) >= 0 ||
        primer_apellido.indexOf(this.filter.toLowerCase()) >= 0 ||
        segundo_apellido.indexOf(this.filter.toLowerCase()) >= 0 ||
        identificacion.indexOf(this.filter.toLowerCase()) >= 0
      ) {
        filterVocero.push(vocero);
      }
    }
    this.listaVoceroB = filterVocero;
    if (this.listaVoceroB.length == 0) {
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró el vocero, que coincida con la búsqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#007bb8",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filter = "";
      this.listaVoceroB = this.listaVocero;
    }
  }
  exportExcel() {
    let data = XLSX.utils.json_to_sheet(this.listaVoceroB, {
      header: [
        "identificacion",
        "primer_nombre",
        "segundo_nombre",
        "primer_apellido",
        "segundo_apellido",
        "id_ficha_caracterizacion",
      ],
    });
    (data["A1"].v = "identificacion"),
      (data["B1"].v = "primer_nombre"),
      (data["C1"].v = "segundo_nombre"),
      (data["D1"].v = "primer_apellido"),
      (data["E1"].v = "segundo_apellido"),
      (data["F1"].v = "id_ficha_caracterizacion");
    const workbook = XLSX.utils.book_new();
    const filename =
      "Reporte Voceros" +
      new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1);
    XLSX.utils.book_append_sheet(workbook, data, filename);
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  }
  filtrarTipo() {
    let filtrarTipo = [];
    for (let tipo of this.listaVocero) {
      let tipo_vocero = tipo.tipo_vocero;

      if (tipo_vocero.indexOf(this.filterTipo) >= 0) {
        filtrarTipo.push(tipo);
      }
    }
    this.listaVoceroB = filtrarTipo;
    if (this.listaVoceroB.length == 0) {
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró el centro que coincida con la búsqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#007bb8",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filterTipo = "";
      this.listaVoceroB = this.listaVoceroB;
    }
  }
   obtenerTiposVoceros() {
    Axios()
      .get("listar_tipos_voceros")
      .then((respuesta) => {
        this.tiposdevoceros = respuesta.data.results;
        this.tiposdevocerosB= respuesta.data.results;
      });
  }
}
