
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import AnimacionCargando from "../../../../components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class VerDetalleColegio extends Vue {
  estadoPeticion = false;

  rutadocumentoActaSensibilizacion = null;
  verModalActaSensibilizacion = false;

  rutadocumentoCartaIntencion = null;
  verModalCartaIntencion = false;

  sensibilizacion = {
    id_sensibilizacion_convenio: "",
    fecha_socilizacion: "",
    resultados: "",
    compromisos: "",
    prioridad_uno: "",
    prioridad_dos: "",
    url_acta_sensibilizacion: "",
    url_carta_intension: "",
    url_asistencia: "",
    cantidad_asistentes: "",
    convenio_id: "",
  };
  mounted() {
    this.obtenerSensibilizacion(this.$route.params.id);
  }

  obtenerSensibilizacion(id) {
    Axios()
      .get("ver_detalle_sensibilizacion/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.sensibilizacion = respuesta.data.results;
          this.mostrarActaSensibilizacion(id);
        }
      })
      .finally(() => {
        this.estadoPeticion = true;
      });
  }

  mirarActaSensibilizacion() {
    this.verModalActaSensibilizacion = true;
  }
  mirarCartaIntencion() {
    this.verModalCartaIntencion = true;
  }

  mostrarActaSensibilizacion(id_sensibilizacion_convenio) {
    this.rutadocumentoActaSensibilizacion =
      this.$urlAPI + "ver_acta_sensibilizacion/" +
      id_sensibilizacion_convenio;
  }
  mostrarCartaIntencion(id_sensibilizacion_convenio) {
    this.rutadocumentoCartaIntencion =
      this.$urlAPI + "ver_carta_intension/" +
      id_sensibilizacion_convenio;
  }
}
