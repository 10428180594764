<template>
  <div class="mt-5 container-fluid">
    <div class="card">
      <div class="border-radius-lg bg-gradient-primary card-header p-0 position-relative mt-n4 mx-3 z-index-2 shadow-3">
        <div class="pt-4 pb-3">
          <h1 class="text-white text-capitalize ps-3 ms-3 title-header">
            GESTIONAR ACTIVIDADES REGIONAL
          </h1>
        </div>
      </div>

      <div class="card-body">
        <!--------------------------- BUSQUEDA DE ACTIVIDAD -------------------------->
        <div class=" " id="contenedor_todo">
          <div class="col-md-6 col-lg-5 col-xl-4 mt-3 pb-3">
            <input
              action="search/"
              type="text"
              class="form-control"
              placeholder="Buscar Actividad "
              v-model="filtro"
              @keyup.prevent="filtrarActividad()"
            />
          </div>
          <!-------------------------------- FILTRO DE EJES ----------------------------->
          <div class="col-md-6 col-lg-5 col-xl-4 mt-3 pb-3">
            <select
              class="form-control p-2"
              v-model="filtroEje"
              @change="filtrarEjes()"
            >
              <option value="" Disabled>Buscar por Eje</option>
              <option value="">Todas</option>
              <option v-for="(eje, key) in lista_Eje" :key="key">
                {{ eje.eje }}
              </option>
            </select>
          </div>
          <div
            class="row justify-content-end col-md-6 col-lg-5 col-xl-4 mt-2 pb-3"
          >
            <div class="agregar mt-1 mb-2 mr-3">
              <router-link class="btn btn-block bg-gradient-primary text-white shadow-3 p-2" :to="{
                name: 'RegistrarActividadRegional',
              }">
                Seleccionar Actividad
              </router-link>
            </div>
          </div>
        </div>
        <paginate-links
          class="pagination mt-2 justify-content-end"
          for="actividades"
          :limit="2"
          :hide-single-page="true"
          :show-step-links="true"
          :full-page="true"
          :classes="{
            ul: 'simple-links-container',
            li: 'simple-links-item',
            liActive: ['simple-links-item', 'active1'],
            'li.active': 'active1',
          }"
        ></paginate-links>

        <div class="text-center" v-if="estadoPeticion">
          <AnimacionCargando></AnimacionCargando>
        </div>
        <div class="table-responsive">
          <paginate ref="paginator" name="actividades" :list="actividades" :per="10">
            <table class="table table-hover table-inverse" v-if="!estadoPeticion">
              <thead class="bg-gradient-azul-sena text-white" id="listado">
                <tr class="campos">
                  <th class="tercera-seccion">Eje</th>
                  <th class="actividad">Actividad</th>
                  <th>Acciones</th>
                </tr>
              </thead>

              <tbody v-if="actividades != ''">
                <tr v-for="(actividad, key) in paginated('actividades')" :key="key">
                  <td class="campos">
                    <div class="tercera-seccion">{{ actividad.eje }}</div>
                  </td>
                  <td class="campos">
                    <div class="actividad">{{ actividad.actividad }}</div>
                  </td>

                  <td>
                    <div class="btn-group btn-spc" tooltip="Eliminar Actividad" tooltip-flow="top">
                      <a @click="
                        eliminarActividad(
                          actividad.id_actividad,
                          actividad.actividad
                        )
                      " class="btn btn-sm bg-oscuro-sena text-white">
                        <i class="fas fa-trash"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <p v-else>No hay actividades registradas!</p>
            </table>
          </paginate>
        </div>

        <paginate-links class="pagination justify-content-end mt-3" for="actividades" :limit="2"
          :hide-single-page="true" :show-step-links="true" :full-page="true" :classes="{
            ul: 'simple-links-container',
            li: 'simple-links-item',
            liActive: ['simple-links-item', 'active1'],
            'li.active': 'active1',
          }"></paginate-links>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";

export default {
  components: {
    AnimacionCargando,
  },
  mounted() {
    this.cargarActividades();
    this.listaEjes();
    this.filtrarEjes();
  },

  data() {
    return {
      estadoPeticion: true,
      id_usuario: JSON.parse(sessionStorage.getItem("usuario")).id_usuario,
      actividades: [],
      lista_Eje: [],
      paginate: ["actividades"],
      /* Filtro Activiades */
      /* 1 */ fitroActividad: [],
      /* 2 */ filtro: "",
      /* Filtro eje */
      /* 1 */ filtradoEje: [],
      /* 2 */ filtroEje: "",
    };
  },

  methods: {
    cargarActividades() {
      Axios()
        .get("listar_actividades_regional/" + this.id_usuario)
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            this.actividades = respuesta.data.results;
            console.log(this.Activiades);
            this.activida = respuesta.data.results;
            this.estadoPeticion = false;
          }
          if (respuesta.data.status == "info") {
            Swal.fire({
              title: "Informacion",
              text: respuesta.data.message,
              icon: "warning",
              confirmButtonText: "Aceptar",
              customClass: {
                confirmButton: "btn btn-azul-sena",
              },
            });
            this.estadoPeticion = false;
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        });
    },

    eliminarActividad(id, Nombreactividad, index) {
      Swal.fire({
        title: "Eliminar Actividad",
        text: "Eliminar Actividad " + Nombreactividad + "?",
        icon: "warning",
        confirmButtonText: "Eliminar",
        confirmButtonColor: "#007bb8",
        cancelButtonColor: "#2d2d2d",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
      }).then((respuesta) => {
        if (respuesta.isConfirmed) {
          Axios()
            .delete("eliminar_actividad_regional/" + id)
            .then((respuesta) => {
              if (respuesta.data.status == "success") {
                Swal.fire({
                  title: "Actividad Eliminada",
                  icon: "success",
                  confirmButtonText: "Aceptar",
                  customClass: {
                    confirmButton: "btn btn-azul-sena",
                  },
                });
              } else {
                Swal.fire({
                  title: "INFO",
                  text: respuesta.data.message,
                  icon: "warning",
                  confirmButtonText: "Aceptar",
                  customClass: {
                    confirmButton: "btn btn-azul-sena",
                  },
                });
              }
            })
            .catch(() => {
              Swal.fire(
                "ERROR!",
                "Se ha presentado un error en el servidor!",
                "error"
              );
            })
            .finally(() => {
              this.cargarActividades();
            });
        }
      });
    },

    filtrarActividad() {
      let filtroActividad = [];
      for (let activi of this.activida) {
        let nombre = activi.actividad.toLowerCase();
        if (nombre.indexOf(this.filtro) >= 0) {
          filtroActividad.push(activi);
        }
      }
      this.actividades = filtroActividad;
      if (this.actividades.length == 0) {
        //busco no encontro
        Swal.fire({
          title: "No hay coincidencias",
          position: "center",
          timer: 1000,
          text: "No se encontró convocatorias que coincidan con las busqueda",
          showConfirmButton: false,
          backdrop: "rgba(0,0,0,0)",
          background: "#eeeeee",
        });
        this.filtro = "";
        this.actividades = this.activida;
      }
    },

    filtrarEjes() {
      let filtradoEje = [];

      for (let nombreejes of this.activida) {
        let nombre = nombreejes.eje;
        if (nombre.indexOf(this.filtroEje) >= 0) {
          filtradoEje.push(nombreejes);
        }
      }
      this.actividades = filtradoEje;

      if (this.actividades.length == 0) {
        //busco no encontro
        Swal.fire({
          title: "No hay coincidencias",
          position: "center",
          timer: 1000,
          text: "No se encontró convocatorias que coincidan con las busqueda",
          showConfirmButton: false,
          backdrop: "rgba(0,0,0,0)",
          background: "#eeeeee",
        });
        this.filtroEje = "";
        this.actividades = this.activida;
      }
    },

    listaEjes() {
      Axios()
        .get("/listaEje")
        .then((respuesta) => {
          this.lista_Eje = respuesta.data.results;
          console.log(respuesta);
        });
    },
  },
};
</script>

<style scoped>
.campos .actividad {
  width: 800px;
}
.campos .tercera-seccion {
  width: 600px;
}

.scroll-vertical {
  overflow: scroll;
  max-height: 400px;
}

.encabezado {
  position: fixed;
}
#contenedor_todo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
#uno {
  width: 10%;
}
</style>
