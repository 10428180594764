
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import guardando from "@/components/animacionGuardando.vue";
import cargando from "@/components/animacionCargando.vue";
@Component({
  components: {
    guardando,
    cargando,
  },
})
export default class CrearUsuario extends Vue {
  crearUsuario: any = null;
  ver: any = true;
  crear: any = true;
  usuario = null;
  //id_usuario_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_usuario;

  nombresPrueba = "";

  persona = {
    primer_nombre: "",
    segundo_nombre: "",
    primer_apellido: "",
    segundo_apellido: "",
    identificacion: "",
    tipo_identificacion_id: "",
    fecha_identificacion: "",
    fecha_nacimiento: "",
    direccion: "",
    correo: "",
    correo_aternativo: "",
    telefono_fijo: "",
    telefono_movil: "",
    tipo_sangre: "",
    genero_id: "",
    estado_civil_id: "",
    tipo_libreta_militar_id: "",
  };

  usuarioDatos = {
    persona_id: "",
    perfil_id: "",
    centro_formacion_id: "",
    nombres: "",
    apellidos: "",
    identificacion: "",
    correo: "",
    contrasena: "",
  };

  btnbuscar = false;
  listaRegionales: any[] = [];
  regional_escogida = "";
  lista_regionales_escogidas_centro: any[] = [];

  idPersonaObtenido = "";
  correoPersonaObtenido = "";
  identificacionPerosonaObtenido = "";
  nombresPersonaObtenido = "";
  apellidosPersonaObtenido = "";
  idCentroFormacionObtenido = "";

  existePersona = null;
  existeUsuario = null;

  listaCentroFormacion: any[] = [];
  listaPerfiles: any[] = [];
  listaAreaRuta: any[] = [];
  listaTipoIddntificacion: any[] = [];
  listaGenero: any[] = [];
  perfil = "";
  contrasena = "";

  arrayDatos = "";
  idPersona = "";
  correoPersonObtenido = "";

  correoAlterPersonObtenido = "";
  estadoRegistrar = false;
  estadoCargando = false;
  corrreo: any = "correo@gmail.com";

  //           'tipo_libreta_militar_id' => 'required', //foranea

  async mounted() {
    this.obtenerDatosCentroPerfilAreaRuta();
    this.obtenerRegionales();
  }
  vaciar() {
    this.persona = {
      primer_nombre: "",
      segundo_nombre: "",
      primer_apellido: "",
      segundo_apellido: "",
      identificacion: "",
      tipo_identificacion_id: "",
      fecha_identificacion: "",
      fecha_nacimiento: "",
      direccion: "",
      correo: "",
      correo_aternativo: "",
      telefono_fijo: "",
      telefono_movil: "",
      tipo_sangre: "",
      genero_id: "",
      estado_civil_id: "",
      tipo_libreta_militar_id: "",
    };

    this.usuarioDatos = {
      persona_id: "",
      perfil_id: "",
      centro_formacion_id: "",
      nombres: "",
      apellidos: "",
      identificacion: "",
      correo: "",
      contrasena: "",
    };
  }
  obtenerPersona(id) {

    Axios()
      .post("buscar_persona_por_identificacion", { identificacion: id })
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.persona = respuesta.data.results;

          this.existePersona = true;

          this.idPersonaObtenido = respuesta.data.results.id_persona;
          this.usuarioDatos.persona_id = this.idPersonaObtenido;
          // alert(this.idPersonaObtenido);

          this.correoPersonObtenido = respuesta.data.results.correo;
          this.correoAlterPersonObtenido =
            respuesta.data.results.correo_aternativo;
          this.identificacionPerosonaObtenido =
            respuesta.data.results.identificacion;
          // alert(this.identificacionPerosonaObtenido);

          this.nombresPersonaObtenido =
            respuesta.data.results.primer_nombre +
            " " +
            respuesta.data.results.segundo_nombre;
          // alert(this.nombresPersonaObtenido);

          this.apellidosPersonaObtenido =
            respuesta.data.results.primer_apellido +
            " " +
            respuesta.data.results.segundo_apellido;
          // alert(this.apellidosPersonaObtenido);
          this.correoPersonaObtenido = respuesta.data.results.correo;
          // alert(this.correoPersonaObtenido)
          this.idCentroFormacionObtenido =
            respuesta.data.results.centro_formacion_id;
          this.estadoCargando = false;
        } else {
          this.existePersona = false;          
        }
      })
      .finally(() => {
        this.estadoCargando = false;
      });
  }

  obtenerUsario(id) {
    // si no te registra comentalo y sale
    this.estadoCargando = true;
    Axios()
      .post("buscar_usuario", { identificacion: id })
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.usuario = respuesta.data.results;
          this.existeUsuario = true;
          // this.estadoCargando = false;
          this.btnbuscar = true;
          this.estadoCargando = false;
        } else {
          this.existeUsuario = false;
        }
      });
    // .finally(() => {
    //   this.estadoCargando = true;
    // });
  }

  data() {
    return {
      id_usuario: JSON.parse(sessionStorage.getItem("usuario")).id_usuario,
      centros: [],
      centro_formacion_id: "",
    };
  }

  obtenerRegionales() {
    Axios()
      .get("listar_regionales")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listaRegionales = respuesta.data.results;
          // this.lista_regionales_escogidas_centro= respuesta.data.results.id_regional;
          this.regional_escogida = respuesta.data.results.id_regional;
        } else {
          Swal.fire({
            title: "No se encontró regionales",
            text: respuesta.data.message,
            icon: respuesta.data.status,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        }
      });
  }

  obtenerCentroPorRegional() {
    Axios()
      .get("listar_centro_formacion_regional/" + this.regional_escogida)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listaCentroFormacion = respuesta.data.results;
        } else {
          Swal.fire({
            title: "No se encontró regionales",
            text: respuesta.data.message,
            icon: respuesta.data.status,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        }
      });
  }

  obtenerDatosCentroPerfilAreaRuta() {
    Axios()
      .get("/crear_usuario")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listaCentroFormacion = respuesta.data.results.centrosFormacion;
          this.listaAreaRuta = respuesta.data.results.areasRuta;
          this.listaPerfiles = respuesta.data.results.perfiles;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "El valor no puede ser menor a cero",
        email: () => "El correo no es válido",
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }

  async almacenarPersona() {
    this.estadoRegistrar = true;
    Axios()
      .post("almacenar_persona", this.persona)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.obtenerPersona(this.persona.identificacion);
          this.estadoRegistrar = false;
        } else if (respuesta.data.status == "info") {
          Swal.fire({
            title: "Campo Invalido",
            text: "El valor del campo identificación o correo ya está en uso",
            icon: "info",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });

          this.estadoRegistrar = false;
        } else {
          Swal.fire("ERROR!", "Persona no registrado", "error");
        }
        return respuesta;
      })
      .then((res) => {
        this.idPersona = res.data.results.id_persona;
        this.usuarioDatos.persona_id = this.idPersona;
        this.almacenarUsuario();
        return res;
      })
      .catch((resp) => {
        if (resp.data.status == "info") {
          Swal.fire({
            title: "no",
            icon: "info",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        } else {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        }
      });
  }

  async almacenarUsuario() {
    Axios()
      .post("almacenar_usuario", this.usuarioDatos)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          // Swal.fire({
          //   title: "Usuario guardado",
          //   icon: "success",
          //   confirmButtonText: "Aceptar",
          //   confirmButtonColor: "#007bb8",
          // });
          this.estadoRegistrar = false;

          this.$router.push({ name: "GestionarUsuario" });
        } else {
          Swal.fire("ERROR!", "Usuario no registrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  actualizarPersona(_id: any, correo: any, correo_aternativo: any) {
    Axios()
      .put("actualizarcorreospersona/" + _id, { correo, correo_aternativo })
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Usuario registrado",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.almacenarUsuario();
        } else {
          Swal.fire({
            title: "Usuario no registrado",
            icon: "error",
            text: respuesta.data.message.correo,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  // limpiarDatosRepresentante() {
  //   this.btnbuscar = false;
  //   this.persona.id_persona = null;
  //   this.persona.primer_nombre = "";
  //   this.persona.segundo_nombre = "";
  //   this.persona.primer_apellido = "";
  //   this.persona.segundo_apellido = "";
  // }

  updated() {
    // this.usuarioDatos.persona_id=this.idPersona
    this.corrreo = this.persona.correo;
    this.usuarioDatos.nombres =
      this.persona.primer_nombre + " " + this.persona.segundo_nombre;
    this.usuarioDatos.apellidos =
      this.persona.primer_apellido + " " + this.persona.segundo_apellido;
    this.usuarioDatos.identificacion = this.persona.identificacion;
    this.usuarioDatos.correo = this.persona.correo;
  }
  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        if (this.existePersona == true && this.existeUsuario == false) {
          this.actualizarPersona(
            this.idPersonaObtenido,
            this.persona.correo,
            this.persona.correo_aternativo
          );
        } else if (this.existePersona == false && this.existeUsuario == false) {
          this.almacenarPersona();
        } else if (this.existePersona == true && this.existeUsuario == false) {
          this.almacenarUsuario();
        }
      } else {
        this.estadoCargando = false;
      }
    });
  }
}
