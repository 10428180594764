
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionTablasCargando from "../../../components/animacionTablasCargando.vue";

@Component({
  components: {
    AnimacionTablasCargando,
  },
})
export default class ModificarProgramaProyectado extends Vue {
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;
  id_convenio = null;

  programaProyectado = {
    programa_formacion_id: "",
    convenio_id: this.id_convenio,
    estado_programa_proyectado_id: "1",
  };
  filter = "";
  listaProgramas: any[] = [];
  listaProgramasFiltrado: any[] = [];
  programasProyectTemp: any[] = [];
  // agregarPrograma = "";

  programa = "";
  todosProgramas = "";
  estadoPeticionTabla = false;

  paginate = ["listaProgramasFiltrado", "programasProyectTemp"];
  mounted() {
    this.obtenerConvenios();
  }
  eliminar(id) {
    this.programasProyectTemp.splice(this.programasProyectTemp.indexOf(id), 1);
  }
  ProgramaFiltrado() {
    let picFilter = [];
    for (let programa of this.listaProgramas) {
      let programaP = programa.programa_formacion.toLowerCase();
      if (programaP.indexOf(this.filter) >= 0) {
        picFilter.push(programa);
      }
    }
    this.listaProgramasFiltrado = picFilter;
    if (this.listaProgramasFiltrado.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró la capacitación, que coincida con la búsqueda",
        showConfirmButton: false,
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filter = "";
      this.listaProgramasFiltrado = this.listaProgramas;
    }
  }
  registrarProgramaProyectado(id_programa) {
    this.programaProyectado.convenio_id = this.$route.params.id_convenio;
    this.programaProyectado.programa_formacion_id = id_programa;
    Axios()
      .post("almacenar_programa_proyectado", this.programaProyectado)

      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Programa Proyectado Guardado",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          //   this.estadoPeticion = false;
          this.$router.push({ name: "GestionarProgramasProyectados" });
        } else {
          Swal.fire({
            title: "Programa proyectado no guardado",
            text: respuesta.data.message,
            icon: respuesta.data.status.toLowerCase(),
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  agregarProgramaLista(programaProyectado) {
    let encontrado = false;
    let cont = 0;
    if (this.programasProyectTemp.length > 0) {
      for (let prog of this.programasProyectTemp) {
        if (
          prog.id_programa_formacion == programaProyectado.id_programa_formacion
        ) {
          encontrado = true;
          Swal.fire({
            title: "Programa repetido",
            text: "Intentelo nuevamente con otro programa.",
            icon: "info",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          cont += 1;
          return;
        }
      }
      if (!encontrado) {
        this.programasProyectTemp.push(programaProyectado);
      }
    } else {
      this.programasProyectTemp.push(programaProyectado);
    }

    // this.programasProyectTemp.push(programaProyectado);
    // this.listaProgramasFiltrado.splice(pos, 1);
  }
  guardarListaProgramasProyectados() {
    if (this.programasProyectTemp.length > 0) {
      for (let i = 0; i < this.programasProyectTemp.length; i++) {
        this.registrarProgramaProyectado(
          this.programasProyectTemp[i].id_programa_formacion
        );
      }
    } else {
      Swal.fire({
        title: "No hay programas seleccionados",
        text: "No hay programas para guardar, por favor seleccione al menos un programa de formación.",
        icon: "info",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#007bb8",
      });
    }
  }

  obtenerConvenios() {
    Axios()
      .get("listar_programa_formacion_activos")
      .then((respuesta) => {
        this.listaProgramas = respuesta.data.results;
        this.listaProgramasFiltrado = respuesta.data.results;
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }
}
