
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";
import AnimacionTablasCargando from "../../../components/animacionTablasCargando.vue";

@Component({
  components: {
    AnimacionCargando,
    AnimacionTablasCargando,
  },
})
export default class Convenio extends Vue {
  listaConvenios = [];
  listaConveniosFiltrado: any[] = [];
  paginate = ["listaConveniosFiltrado"];
  filter = "";

  estadoPeticionTabla = false;
  convenio = {
    convenio: "",
    id_convenio: "",
    regional_id: "",
    regional: "",
    id_centro_formacion: "",
    centro_formacion: "",
    fase_id: "",
    fase: "",
    sena_persona_id: "",
    numero_convenio: "",
    fecha_firma: "",
    fecha_inicio: "",
    fecha_final: "",
    sena_cargo: "",
    colegio_cargo: "",
    // minuta: "",
  };
  listaProgramasProyectados = "";
  programasProyectados = {
    id_convenio_programa_proyectado: "",
    convenio_id: "",
    programa_formacion_id: "",
    fase_id: "",
  };

  obtenerProgramas() {
    Axios()
      .get("listar_programa_proyectado")
      .then((respuesta) => {
        this.listaProgramasProyectados = respuesta.data.results;
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }
  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        min: () => "Mínimo 8 caracteres",
      },
    };
    this.$validator.localize("es", dict);
  }
  mounted() {
    this.obtenerConvenios();
  }
  obtenerConvenios() {
    Axios()
      .get("listar_convenio")
      .then((respuesta) => {
        this.listaConvenios = respuesta.data.results;
        this.listaConveniosFiltrado = respuesta.data.results;
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }

  eliminarConvenio(id, index) {
    Swal.fire({
      title: "Eliminar Convenio ",
      text: this.listaConveniosFiltrado[index].convenio + "?",
      icon: "warning",
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#007bb8",
      cancelButtonColor: "#2d2d2d",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((respuesta) => {
      if (respuesta.isConfirmed) {
        Axios()
          .delete("eliminar_convenio/" + id)
          .then((respuesta) => {
            if (respuesta.data.status == "success") {
              Swal.fire({
                title: "Convenio Eliminado",
                icon: "success",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.listaConveniosFiltrado.splice(index, 1);
            } else {
              Swal.fire({
                title: "No se pudo eliminar el convenio",
                text: respuesta.data.message,
                icon: "error",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: "btn btn-azul-sena",
                },
              });
              this.$router.back();
            }
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }

  eliminarConvenioColegios(id) {
    Axios()
      .delete("eliminar_convenio_colegio_escogidas/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
        } else {
        }
      });
  }

  convenioFiltrado() {
    let convenioFilter = [];
    // this.listaConvocatoriasFiltrado = []

    for (let conve of this.listaConvenios) {
      let colegio = conve.colegio_cargo.toLowerCase();
      let centro_formacion = conve.centro_formacion.toLowerCase();
      let fases = conve.fase.toLowerCase();
      let regional = conve.regional.toLowerCase();
      let convenio = conve.convenio.toLowerCase();

      if (
        colegio.indexOf(this.filter.toLowerCase()) >= 0 ||
        convenio.indexOf(this.filter.toLowerCase()) >= 0 ||
        fases.indexOf(this.filter.toLowerCase()) >= 0 ||
        centro_formacion.indexOf(this.filter.toLowerCase()) >= 0 ||
        regional.indexOf(this.filter.toLowerCase()) >= 0
      ) {
        convenioFilter.push(conve);
      }
    }

    this.listaConveniosFiltrado = convenioFilter;
    if (this.listaConveniosFiltrado.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró el convenio, que coincida con la búsqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#238276",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filter = "";
      this.listaConveniosFiltrado = this.listaConvenios;
    }
  }
}
