
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import ReportesConvenios from "@/components/ReportesArticulacionMedia/ReportesConvenios.vue";
import BarChart from "@/components/Charts/BarChart";
import Chart from "chart.js";
import Swal from "sweetalert2";

@Component({
  components: {
    ReportesConvenios,
    BarChart,
  },
})
export default class IndexReportes extends Vue {
   estadoPeticion = true;
  listaConvenios = [];
  listacantidadFases = 0;
  listaFases = [];
  esReportesConvenios = false;

canvas = document.getElementsByTagName("canvas");
 
 Creacion = 0;
 Proyectarprogramas = 0;
 Ejecucion = 0;
 Finalizado = 0;
 Cancelado = 0;
 Suspendido = 0;
 Sensibilizarconvenio = 0;
 Aprobarprogramascolegios = 0;
 Viabilidad = 0;
// programasColegio=0;
 

  cambiarEstadosComponentes() {
  
   this. esReportesConvenios = false;

  }

  mounted() {

       this.obtenerFases();   
  }

    obtenerFases() {
    Axios()
      .get("listar_convenios")
      .then((respuesta) => {
        this.listacantidadFases = respuesta.data.results.length;
        this.listaFases = respuesta.data.results;

        for (let listaConv of this.listaFases) {
          
          switch (
            listaConv.fase
            
          ) {
            
               case "Creación":
              this.Creacion++;
              break;
               case "Proyectar programas":
              this.Proyectarprogramas++;
              break;
               case "Ejecucion":
              this.Ejecucion++;
              break;
               case "Finalizado":
              this.Finalizado++;
              break;
               case "Cancelado":
              this.Cancelado++;
              break;
              case "Suspendido":
                this.Suspendido++;
                break;
              case "Sensibilizar Convenio":
                this.Sensibilizarconvenio++;
                break;
               case "Aprobar programas colegios":
              this.Aprobarprogramascolegios++;
              break;
              case "Viabilidad":
                this.Viabilidad++;
                break;
            
              
            default:
              break;
              
          }
        }

        new Chart(this.canvas[0], {
          type: "bar",
          data: {
            labels: [
              "Total Convenios",
              "Creacion",
              "Proyectar programas",
              "Ejecucion",
              "Finalizado",
              "Cancelado",
              "Suspendido",
              "Sensibilizar Convenio",
              "Aprobar programas colegios",
              "Viabilidad"
            ],
            datasets: [
              {
                label: "Convenios por Fases",
                data: [
                  this.listaFases.length,
                  this.Creacion,
                  this.Proyectarprogramas,
                  this.Ejecucion,
                  this.Finalizado,
                  this.Cancelado,
                  this.Suspendido,
                  this.Sensibilizarconvenio,
                  this.Aprobarprogramascolegios,
                  this.Viabilidad
                ],
                backgroundColor: [
                  "rgba(255, 99, 132, 0.2)",
                  "rgba(255, 159, 64, 0.2)",
                  "rgba(255, 205, 86, 0.2)",
                  "rgba(75, 192, 192, 0.2)",
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                  "rgba(201, 203, 207, 0.2)",
                  "rgba(205, 203, 207, 0.2)",
                  "rgba(163, 203, 207, 0.2)",
                  "rgba(54, 203, 207, 0.2)",
                  "rgba(223, 203, 207, 0.2)",
                ],
                borderColor: [
                  "rgb(255, 99, 132)",
                  "rgb(255, 159, 64)",
                  "rgb(255, 205, 86)",
                  "rgb(75, 192, 192)",
                  "rgb(54, 162, 235)",
                  "rgb(153, 102, 255)",
                  "rgb(201, 203, 207)",
                ],
                borderWidth: 1,
              },
            ],
          },
        });
      });
    this.estadoPeticion = false;
  }
   
   }

