
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import Multiselect from "vue-multiselect";
import AnimacionCargando from "../../../components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
    Multiselect,
  },
})
export default class RegistrarCapacitacionEni extends Vue {
  list_table = []
  estadoPeticionCargando = false;
  areas: any[] = [];
  picTipos: any[] = [];
  redesConocimiento: any[] = [];
  listarPicEje: any[] = [];
  estadoPeticionRegistrar = false;
  estadoPeticion = true;
  capacitacion = {
    id_pic: null,
    capacitacion: "",
    pertenece_a_red: "",
    plan_institucional_id: "",
    id_area_ruta: "",
    id_pic_tipo: "",
    eje: "",
    eje_id: "",
    pic_rel_capacitacion_red: [],
  };

  picEje = {
    id_eje: "",
    eje: "",
  };
  todasAreas = "";
  todas: any[] = [];
  redesTodas = "";

  // nuevo
  areasEscogidas: any[] = [];

  async mounted() {
    await this.obtenerAreasConocimiento();
    this.obtenerPicEje();
    this.estadoPeticionCargando = true;
  }

  updated() {
    if (this.capacitacion.id_pic_tipo != "1") {
      this.capacitacion.pertenece_a_red = "0";
    }
  }

  async obtenerAreasConocimiento() {
    await Axios()
      .get("/crear_pic")
      .then((respuesta) => {
        this.areas = respuesta.data.results.areas;

        this.picTipos = respuesta.data.results.picTipos;
        this.redesConocimiento = respuesta.data.results.redesConocimiento;

        // this.redesTodas = this.redesConocimiento;
      })
      .finally(() => {
        this.estadoPeticion = false;
      });
  }

  obtenerAreasPorRed() {
    Axios()
      .get("listar_areas_filtrado/" + this.capacitacion.pertenece_a_red)
      .then((respuesta) => {
        this.areas = respuesta.data.results;

        if (this.capacitacion.pertenece_a_red == "0") {
          this.areasEscogidas = [];
          this.todasAreas = this.areas[0];
          this.areasEscogidas.push(this.todasAreas);
        } else {
        }
      });
  }

  ponerTodasAreas() {
    if (this.capacitacion.id_pic_tipo != "1") {
      Axios()
        .get("listar_areas_filtrado/0")
        .then((respuesta) => {
          this.areas = respuesta.data.results;

          this.areasEscogidas = [];
          this.todasAreas = this.areas[0];
          this.areasEscogidas.push(this.todasAreas);
        });
    } else {
      this.areasEscogidas = [];
    }
  }

  addTag(newTag) {
    const tag = {
      area_ruta: newTag,
      red_conocimiento_id: "",
      componentes: "",
      id_area_ruta:
        newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
    };
    this.areas.push(tag);
    this.areasEscogidas.push(tag);
  }

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "Fecha mayor o igual a 2021",
        // max: () => "La suma de los cupos no puede ser mayor a " + this.convocatoria.cupo_total,
        alpha_spaces: () => "No permite números ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }

  crearPIC() {
    this.estadoPeticionRegistrar = true;

    Axios()
      .post("/almacenar_pic", this.capacitacion)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Capacitación registrada",
            text: "La capacitación fue registrada exitosamente.",
            icon: respuesta.data.status,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.registroTodasAreas(respuesta.data.results.id_pic);
          this.$router.push({ name: "GestionarPICENI" });
        } else {
          Swal.fire({
            title: "Capacitación no registrada",
            text: "La capacitación no fue registrada.",
            icon: respuesta.data.status,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.$router.push({ name: "VerDetalleCapacitacionENI" });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionRegistrar = false;
      });
  }

  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        // el formulario se validó y tiene los datos que corresponden por campo
        this.crearPIC();
      }
    });
  }

  registrarAreas(id_pic) {
    for (let area of this.areasEscogidas) {
      setTimeout(() => {
        Axios()
          .post("almacenar_red", {
            id_area: area.id_area_ruta,
            id_pic: id_pic,
          })
          .then((respuesta) => {
            if (respuesta.data.status == "success") {
            } else {
            }
          });
      }, 100);
    }
  }

  // Se podria considerar un machetazo para registrar todas las areas de una vez, pero funciona
  registroTodasAreas(id_pic) {
    if (this.capacitacion.id_pic_tipo != "1") {
      this.capacitacion.id_area_ruta = "0";
      this.areasEscogidas = this.areas;
      this.areas.push(id_pic);
      this.registrarAreas(id_pic);
    } else {
      this.registrarAreas(id_pic);
    }
  }

  registrarPicEje() {
    Axios()
      .post("almacenar_eje", this.picEje)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Pic Eje Guardado",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          //   this.estadoPeticion = false;
          this.estadoPeticionRegistrar = true;

          this.$router.push({ name: "GestionarPICEje" });
        } else {
          Swal.fire("ERROR!", "No Se registro el Pic Eje", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }

  obtenerPicEje() {
    Axios()
      .get("/listar_eje")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listarPicEje = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  filtroBase(arra1 ,arra2 ) {
    this.list_table = []
    let contador = 0

    for (let areas_escogiadas of arra1 ) {
    let red_id = areas_escogiadas.red_conocimiento_id

    for(let redesconocimiento of arra2 ){

      let id_red_redes = redesconocimiento.id_red_conocimiento
      let red_name = redesconocimiento.red_conocimiento

    if (red_id == id_red_redes) {
      if (this.list_table.length == 0){
          this.list_table.push(redesconocimiento.red_conocimiento)
        }else{
          for(let table of this.list_table){
          if (table == red_name) {
            contador ++
          }
        }
        if (contador == 0) {
          this.list_table.push(redesconocimiento.red_conocimiento)
        }
        contador = 0
        }
    }
  }
}
  }

}
