
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "@/components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class RepresentanteVerEditar extends Vue {
  estado = false;

  usuario = "";
  representante = {
    id_persona: null,
    primer_nombre: "",
    segundo_nombre: "",
    primer_apellido: "",
    segundo_apellido: "",
    tipo_documento: "",
    identificacion: null,
    modalidad: "",
    jornada: "",
    programa_formacion: "",
    id_ficha_caracterizacion: null,
  };

  mounted() {
    this.representante.id_persona = this.$route.params.id;
    this.obtenerPersona(this.representante.id_persona);
    this.buscarUsuario(this.representante.id_persona);
  }
  obtenerPersona(id) {
    Axios()
      .post("crear_representante", { identificacion: id })
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.representante = respuesta.data.results;
        } else {
          Swal.fire({
            title: respuesta.data.message,
          }).then(() => {
            this.$router.back();
          });
        }
      })
      .finally(() => {
        this.estado = true;
      });
  }
  datosVacios(dato) {
    if (dato == null || dato == "" || dato == " ") {
      return "No hay datos registrados";
    } else {
      return dato;
    }
  }
  async buscarUsuario(id_cedula) {
    await Axios()
      .post("buscar_usuario", { identificacion: id_cedula })
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.obtenerUsuario(respuesta.data.results.id_usuario);
        } else {
          Swal.fire({
            title: "perfil no existe",
            icon: "error",
          });
        }
      })
      .catch(() => {});
  }

  async obtenerUsuario(idUsuario) {
    await Axios()
      .get("listar_usuario/" + idUsuario)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.usuario = respuesta.data.results;
        } else {
          Swal.fire("ERROR!", "No se ha encontrado", "error");
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
}
