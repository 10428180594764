var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-5 container-fluid"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[(!_vm.estadoPeticionTabla)?_c('div',{staticClass:"text-center"},[_c('AnimacionTablasCargando')],1):_vm._e(),(_vm.estadoPeticionTabla)?_c('div',[_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-md-6 col-lg-3 col-xl-2 mt-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"form-control shadow p-2",attrs:{"type":"text","placeholder":"Buscar"},domProps:{"value":(_vm.filter)},on:{"keyup":function($event){$event.preventDefault();return _vm.convocatoriaFiltrado()},"input":function($event){if($event.target.composing)return;_vm.filter=$event.target.value}}})]),_c('div',{staticClass:"col-md-6 col-lg-3 mt-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.filterFase),expression:"filterFase"}],staticClass:"form-control p-1",attrs:{"aria-placeholder":"Tipo Fase"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.filterFase=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.FiltrarFase()}]}},[_c('option',{attrs:{"value":"","Disabled":""}},[_vm._v("Tipo Fase")]),_c('option',{attrs:{"value":""}},[_vm._v("Todas")]),_c('option',[_vm._v("Creación")]),_c('option',[_vm._v("Inscripción")]),_c('option',[_vm._v("Adjudicación")]),_c('option',[_vm._v("Ejecución")]),_c('option',[_vm._v("Finalizada")])])]),_c('div',{staticClass:"justify-content-lg-end col-md-5 col-lg-8 mt-2"},[_c('paginate-links',{staticClass:"pagination justify-content-end",attrs:{"for":"listaConvocatoriasB","limit":2,"hide-single-page":true,"show-step-links":true,"full-page":true,"classes":{
                ul: 'simple-links-container',
                li: 'simple-links-item',
                liActive: ['simple-links-item', 'active1'],
                'li.active': 'active1',
              }}})],1)]),(_vm.estadoPeticion)?_c('div',{staticClass:"text-center"},[_c('AnimacionCargando')],1):_vm._e(),(!_vm.estadoPeticion)?_c('div',{staticClass:"table-responsive"},[_c('paginate',{ref:"paginator",attrs:{"name":"listaConvocatoriasB","list":_vm.listaConvocatoriasB,"per":10}},[(!_vm.estadoPeticion)?_c('table',{staticClass:"table table-hover"},[_c('thead',{attrs:{"id":"listado"}},[_c('tr',[_c('th',{staticClass:"text-nowrap"},[_vm._v("Centro de Formación")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Nombre")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Inicio - Cierre")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Fase")]),_c('th',[_vm._v("Acciones")])])]),_c('tbody',[(_vm.listaConvocatoriasB != null)?_vm._l((_vm.paginated(
                      'listaConvocatoriasB'
                    )),function(convocatoria,key){return _c('tr',{key:key},[_c('td',[_vm._v(" "+_vm._s(convocatoria.centro_formacion_id)+" ")]),_c('td',[_vm._v(" "+_vm._s(convocatoria.convocatoria)+" ")]),_c('td',[_vm._v(" "+_vm._s(convocatoria.fecha_inicio_convoca)+" - "+_vm._s(convocatoria.fecha_final_convoca)+" ")]),_c('td',[_vm._v(_vm._s(convocatoria.fase_bienestar))]),_c('td',{attrs:{"scope":"row"}},[_c('div',{staticClass:"btn-toolbar btn-responsive-table",attrs:{"role":"toolbar"}},[_c('div',{staticClass:"btn-group btn-spc",attrs:{"div":"","tooltip":"Ver Convocatoria","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"to":{
                              name: 'VerDetalleConvocatoria',
                              params: { id: convocatoria.id_convocatoria },
                            }}},[_c('i',{staticClass:"fas fa-eye"})])],1),(
                            convocatoria.fase_bienestar != 'Ejecución' &&
                            convocatoria.fase_bienestar != 'Finalizada' &&
                            convocatoria.fase_bienestar == 'Creación' &&
                            convocatoria.fase_bienestar != 'Adjudicación'
                          )?_c('div',{staticClass:"btn-group btn-spc",attrs:{"div":"","tooltip":"Adjuntar circular","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"alt":"Adjuntar circular","to":{
                              name: 'AdjuntarResolucion',
                              params: { id: convocatoria.id_convocatoria },
                            }}},[_c('i',{staticClass:"far fa-file-alt"})])],1):_vm._e(),(convocatoria.fase_bienestar == 'Creación')?_c('div',{staticClass:"btn-group btn-spc",attrs:{"div":"","tooltip":"Modificar Convocatoria","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"to":{
                              name: 'ModificarConvocatoria',
                              params: { id: convocatoria.id_convocatoria },
                            }}},[_c('i',{staticClass:"fas fa-edit"})])],1):_vm._e(),(convocatoria.fase_bienestar == 'Creación')?_c('div',{staticClass:"btn-group btn-spc",attrs:{"div":"","tooltip":"Eliminar Convocatoria","tooltip-flow":"top"}},[_c('a',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"alt":"eliminar convocatoria"},on:{"click":function($event){return _vm.eliminarConvocatoria(
                                convocatoria.id_convocatoria,
                                convocatoria.convocatoria
                              )}}},[_c('i',{staticClass:"fas fa-trash-alt"})])]):_vm._e(),(convocatoria.fase_bienestar == 'Inscripción')?_c('div',{staticClass:"btn-group btn-spc",attrs:{"div":"","tooltip":"Inscribir Aprendices","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"alt":"adjudicar","to":{
                              name: 'Inscribir',
                              params: { id: convocatoria.id_convocatoria },
                            }}},[_c('i',{staticClass:"fas fa-user-plus"})])],1):_vm._e(),(convocatoria.fase_bienestar == 'Adjudicación')?_c('div',{staticClass:"btn-group btn-spc",attrs:{"div":"","tooltip":"Adjudicar Aprendices","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"alt":"adjudicar","to":{
                              name: 'AdjudicarApredizConvocatoria',
                              params: { id: convocatoria.id_convocatoria },
                            }}},[_c('i',{staticClass:"fas fa-list"})])],1):_vm._e(),(convocatoria.fase_bienestar == 'Ejecución')?_c('div',{staticClass:"btn-group btn-spc",attrs:{"div":"","tooltip":"Novedades Adjudicados","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"alt":"novedades","to":{
                              name: 'GestionarAdjudicados',
                              params: { id: convocatoria.id_convocatoria },
                            }}},[_c('i',{staticClass:"fas fa-user-edit"})])],1):_vm._e(),(
                            !(convocatoria.fase_bienestar == 'Creación') ||
                            convocatoria.fase_bienestar == 'Inscripción' ||
                            convocatoria.fase_bienestar == 'Adjudicación' ||
                            convocatoria.fase_bienestar == 'Ejecución'
                          )?_c('div',{staticClass:"btn-group btn-spc",attrs:{"div":"","tooltip":"Ver listas resumen","tooltip-flow":"top"}},[_c('router-link',{staticClass:"btn btn-sm bg-oscuro-sena text-white",attrs:{"to":{
                              name: 'Index_Finalizado',
                              params: { id: convocatoria.id_convocatoria },
                            }}},[_c('i',{staticClass:"fas fa-award"})])],1):_vm._e(),_c('div',{staticClass:"btn-group btn-spc",attrs:{"div":"","tooltip":"Cambiar fase"}},[_c('a',{staticClass:"btn btn-sm bg-oscuro-sena text-white",on:{"click":function($event){return _vm.cambiarFase(convocatoria)}}},[_c('i',{staticClass:"fas fa-arrow-right"})])])])])])}):_c('tr',[_vm._v(" No hay convocatorias ")])],2)]):_vm._e()])],1):_vm._e(),_c('paginate-links',{staticClass:"pagination justify-content-end",attrs:{"for":"listaConvocatoriasB","limit":2,"hide-single-page":true,"show-step-links":true,"full-page":true,"classes":{
            ul: 'simple-links-container',
            li: 'simple-links-item',
            liActive: ['simple-links-item', 'active1'],
            'li.active': 'active1',
          }}})],1):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"border-radius-lg bg-gradient-primary card-header p-0 position-relative mt-n4 mx-3 z-index-2 shadow-3"},[_c('div',{staticClass:"pt-4 pb-3"},[_c('h1',{staticClass:"text-white text-capitalize ps-3 ms-3 title-header"},[_vm._v(" LISTA DE CONVOCATORIAS BIENESTAR ")])])])
}]

export { render, staticRenderFns }