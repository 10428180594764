
import Axios from "@/Axios";
import Swal from "sweetalert2";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import AnimacionCargando from "../../../components/animacionCargando.vue";
@Component({
  components: {
    AnimacionCargando,
  },
})
export default class CrearRepresentante extends Vue {
  crearRrepresentante: any = null;
  estadoPeticionRegistrar = false;
  ver: any = true;
  crear: any = true;
  representante = {
    id_persona: null,
    primer_nombre: "",
    segundo_nombre: "",
    primer_apellido: "",
    segundo_apellido: "",
    tipo_documento: "",
    identificacion: null,
    modalidad: "",
    jornada: "",
    programa_formacion: "",
    id_ficha_caracterizacion: null,
  };
  btnbuscar = false;

  mounted() {
    this.ver = this.$route.params.ver;
    this.crear = this.$route.params.crear;
    this.representante.id_persona = this.$route.params.id;
  }
  obtenerPersona(id) {
    Axios()
      .post("crear_representante", { identificacion: id })
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.representante = respuesta.data.results;
          this.btnbuscar = true;
        } else {
          Swal.fire({
            title: "No se encontró la persona",
            icon: "error",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
            text: "El número de identificación no corresponde a una persona registrada en el sistema.",
          }).then(() => {
            this.$router.back();
          });
        }
      });
  }

  almacenarRepresentante() {
    this.estadoPeticionRegistrar = true;
    Axios()
      .post("almacenar_representante", {
        persona_id: this.representante.id_persona,
        ficha_caracterizacion_id: this.representante.id_ficha_caracterizacion,
      })
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          Swal.fire({
            title: "Representante registrado!",
            text: "Nuevo representante ha sido registrado exitosamente!",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          this.$router.push({ name: "Representantes" });
        } else {
          Swal.fire({
            title: "Representante No registrado!",
            text: respuesta.data.message,
            icon: respuesta.data.status,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          }).then(() => {
            this.$router.push({ name: "Representantes" });
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionRegistrar = false;
      });
  }
  limpiarDatosRepresentante() {
    this.btnbuscar = false;
    this.representante.id_persona = null;
    this.representante.primer_nombre = "";
    this.representante.segundo_nombre = "";
    this.representante.primer_apellido = "";
    this.representante.segundo_apellido = "";
    this.representante.tipo_documento = "";
    this.representante.modalidad = "";
    this.representante.jornada = "";
    this.representante.programa_formacion = "";
    this.representante.id_ficha_caracterizacion = null;
  }

  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        // el formulario se validó y tiene los datos que corresponden por campo
        ({
          params: { id: this.crearRrepresentante, ver: "true", crear: "true" },
        });
      }
    });
  }
}
