
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";

@Component({
  components: {
    AnimacionCargando,
  },
})
export default class registrarColegio extends Vue {
  id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;
  estadoPeticionRegistrar = false;

  listarIdiomas: any[] = [];
  listarIdiomasString: any[] = [];
  lista_modalidad: any[] = [];
  lista_zona: any[] = [];
  lista_jornada: any[] = [];
  lista_municipio: any[] = [];
  lista_departamentos: any[] = [];
  departamento_actual = null;
  lista_secretaria: any[] = [];
  lista_sector: any[] = [];

  infoSenaPersona = "";
  modalSenaPersona = false;
  buscarSenaPersona;

  lista_municipios = null;

  lista_idiomas: any[] = [];

  docValido = false;
  estadoPeticion = false;

  inactivo = false;

  contIdiomaGuardada = 0;

  colegio = {
    id_colegio: "",
    zona_id: "",
    municipio_id: "",
    municipio: "",
    colegio: "",
    direccion: "",
    telefono: "",
    cod_dane: "",
    sector_colegio: "",
    secretaria_id: "",
    estado: "",
    resguardo: "",
    correo: "",
    calendario: "",
    genero: "",
    sedes: "",
    jornadas: "",
    rector: "",
  };

  mounted() {
    this.obtenerModalidadColegio();
    this.obtenerZonaColegio();
    this.obtenerJornadaColegio();
    this.obtenerDepartamento();
    this.obtenerSecretariaColegio();
    this.obtenerSectorColegio();
  }

  //--------------------------------------------------------------------
  registrarColegio() {
    this.estadoPeticionRegistrar = true;
    // var data = new FormData();

    // //Añadimos la docinscrip seleccionada
    // // data.append("colegio_modalidad_id", this.colegio.colegio_modalidad_id);
    // data.append("zona_id", this.colegio.zona_id);
    // // data.append("jornada_id", this.colegio.jornada_id);
    // data.append("municipio_id", this.colegio.municipio_id);
    // // data.append("coord_persona_id", this.colegio.coord_persona_id);
    // // data.append("rector_persona_id", this.colegio.rector_persona_id);
    // data.append("colegio", this.colegio.colegio);
    // // data.append("nit", this.colegio.nit);
    // data.append("direccion", this.colegio.direccion);
    // data.append("telefono", this.colegio.telefono);
    // data.append("cod_dane", this.colegio.cod_dane);
    // // data.append("licencia", this.colegio.licencia);
    // data.append("sector_colegio", this.colegio.sector_colegio);

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    Axios()
      .post("almacenar_colegios")
      .then((respuesta) => {
        if ((respuesta.data.status = "success")) {
          // this.modalBuscarColegio = false;
          Swal.fire({
            title: "colegio Guardado",
            text: "Se guardo el colegio",
            icon: "success",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#007bb8",
          });
          //   this.$router.push({ name: "GestionarHojaDeVidaENI" });
        } else {
          Swal.fire("ERROR!", "No se Guardo El colegio", "error");
        }
        // this.estadoPeticion = false;
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionRegistrar = false;
      });
  }

  validarFormulario() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        // el formulario se validó y tiene los datos que corresponden por campo
        this.registrarColegio();
      }
    });
  }

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        date_format: () => "Campo obligatorio",
        decimal: () => "Campo obligatorio",
        min_value: () => "El valor no puede ser menor a cero",
        email: () => "El correo no es válido",
        alpha_spaces: () => "No permite numeros ni caracteres especiales",
        alpha_num: () => "No permite caracteres especiales",
      },
    };
    this.$validator.localize("es", dict);
  }

  obtenerModalidadColegio() {
    Axios()
      .get("listar_colegio_modalidad")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_modalidad = respuesta.data.results;
        }
      });
  }

  obtenerZonaColegio() {
    Axios()
      .get("listar_zona_colegio")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_zona = respuesta.data.results;
        }
      });
  }

  obtenerJornadaColegio() {
    Axios()
      .get("listar_jornada_colegio")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_jornada = respuesta.data.results;
        }
      });
  }

  obtenerDepartamento() {
    Axios()
      .get("listar_departamentos/57")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_departamentos = respuesta.data.results;
        } else {
          Swal.fire({
            title: respuesta.data.message,
          });
        }
      });
  }

  obtenerMunicipio(id) {
    Axios()
      .get("listar_municipios/" + id)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_municipios = respuesta.data.results.municipios;
        } else {
          Swal.fire({
            title: respuesta.data.message,
          });
        }
      });
  }

  obtenerSecretariaColegio() {
    Axios()
      .get("listar_secretaria_colegio")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_secretaria = respuesta.data.results;
        }
      });
  }

  obtenerSectorColegio() {
    Axios()
      .get("listar_sector_colegio")
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.lista_sector = respuesta.data.results;
        }
      });
  }
}
