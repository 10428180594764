
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionTablasCargando from "../../components/animacionTablasCargando.vue";

@Component({
  components: {
    AnimacionTablasCargando,
  },
})
export default class PerfilLista extends Vue {
  perfiles = [];
  filter = "";
  estadoPeticionTabla = false;

  paginate = ["perfiles"];

  constructor() {
    super();

    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        min: () => "Mínimo 8 caracteres",
        // email: () => "Correo invalido",
      },
    };
    this.$validator.localize("es", dict);
  }

  mounted() {
    this.llenarPerfiles();
  }
  llenarPerfiles() {
    Axios()
      .get("listar_perfiles")
      .then((respuesta) => {
        this.perfiles = respuesta.data.results;

        // this.$router.push({name: "dashboard"})
      })
      .catch((respuesta) => {
        //  Swal.fire((respuesta.data.status+"!").toString().toUpperCase(), respuesta.data.message, respuesta.data.status);
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }

  //   public validarFormulario(){
  //     this.$validator.validate().then((valid) => {
  //       if (valid) {
  //         // el formulario se validó y tiene los datos que corresponden por campo
  //         this.login();
  //       }
  //     });
  //   }

  eliminarPerfil(id: number, nombre: string) {
    Swal.fire({
      title: "Eliminar Perfil " + nombre + "?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((resp) => {
      if (resp.isConfirmed) {
        Axios()
          .delete("eliminar_perfil/" + id)
          .then((respuesta) => {
            Swal.fire({
              title: "Perfil Eliminado",
              icon: "success",
              confirmButtonText: "Aceptar",
            });
            this.llenarPerfiles();
          })
          .catch((error) => {
            Swal.fire({
              title: "No se pudo elimar perfil",
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          });
      }
    });
  }

  filtrar(lista: any[]) {
    let resp;
    if (this.filter != "") {
      resp = lista.filter(
        (item) =>
          item.perfil
            .substring(0, this.filter.length)
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "") === this.filter.toLowerCase()
      );
    } else {
      resp = lista;
    }
    return resp;
  }
  ordenar(n: number, type: string) {
    let rows,
      switching,
      i,
      x,
      y,
      shouldSwitch,
      dir,
      switchcount = 0;

    const table: any = document.getElementById("listado");
    switching = true;
    dir = "asc";

    while (switching) {
      switching = false;
      rows = table.rows;
      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];
        if (dir == "asc") {
          if (
            (type == "str" &&
              x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) ||
            (type == "int" && parseFloat(x.innerHTML) > parseFloat(y.innerHTML))
          ) {
            shouldSwitch = true;
            break;
          }
        } else if (dir == "desc") {
          if (
            (type == "str" &&
              x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) ||
            (type == "int" && parseFloat(x.innerHTML) < parseFloat(y.innerHTML))
          ) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount == 0 && dir == "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }
}
