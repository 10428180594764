
import { Component, Vue } from "vue-property-decorator";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimacionCargando from "../../../components/animacionCargando.vue";
import AnimacionTablasCargando from "../../../components/animacionTablasCargando.vue";
import router from "@/router";

@Component({
  components: {
    AnimacionCargando,
    AnimacionTablasCargando,
  },
})
export default class Login extends Vue {
  estadoPeticion = true;
  listaConvocatorias = [];
  filter = "";
  filterPicTipo = "";
  fecha_pic ;
  id_convocatoria_pic = 0;
  fechas_pic = [];
  docSubidosCont = 0;
  estadoPeticionTabla = false;
  paginate = ["listaConvocatoriasFiltrado"];
  usuario = JSON.parse(sessionStorage.getItem("usuario"));

  existe_tipo_tecnico = false;
  existe_tipo_pedagogico = false;
  existe_tipo_clave = false;

  listaConvocatoriasFiltrado = [];

  tipoPicFilter = "";

  capacitacion = {
    area_ruta: "",
    capacitacion: "",
    componentes: "",
    id_area_ruta: "",
    id_pic: "",
    plan_institucional_id: "",
    red_conocimiento_id: "",
    tipo_pic: "",
    eje: "",
  };

  docResolucon: any[] = null;
  docValido = false;
  idConvocatoriaActual = null;

  constructor() {
    super();
    const dict = {
      messages: {
        required: () => "Campo obligatorio",
        min: () => "Mínimo 8 caracteres",
      },
    };
    this.$validator.localize("es", dict);
  }
  async mounted() {
    this.obtenYearNow();
    await this.obtenerConvocatorias();
    await this.ontenerPlanInstitucional();
  }
  async updated() {
    await this.listarTiposPic();
  }
  async obtenerConvocatorias() {
    this.filter = "";
    this.filterPicTipo = "";
    this.estadoPeticionTabla = false;
    await Axios()
      .post("capacitacionesEni", {
        id_persona: this.usuario.id_persona,
        id_plan_institucional: this.fecha_pic,
      })
      .then((respuesta) => {
        if (respuesta.data.status.toLowerCase() == "success") {
          this.listaConvocatorias = respuesta.data.results;
          this.listaConvocatoriasFiltrado = respuesta.data.results;
          this.estadoPeticion = false;
          this.estadoPeticionTabla = true;
          console.log(this.listaConvocatorias);
        }else {
          Swal.fire({
            title: "No hay Convocatorias activas",
            position: "center",
            timer: 1000,
            showConfirmButton: false,
            // confirmButtonText: "Aceptar",
            // confirmButtonColor: "#238276",
            backdrop: "rgba(0,0,0,0)",
            background: "#eeeeee",
          });
          this.listaConvocatorias = [];
          this.listaConvocatoriasFiltrado = [];
          
        }
         if (respuesta.data.status.toLowerCase() == "error" && respuesta.data.message == "Este usuario no tiene asignado ninguna red de conocimiento") {
          Swal.fire({
            title: "No tiene asignada una red de conocimiento",
            position: "center",
            timer: 4000,
            text: "Contactese con algun administrador para solucionar este inconveniente",
            showConfirmButton: false,
            // confirmButtonText: "Aceptar",
            // confirmButtonColor: "#238276",
            backdrop: "rgba(0,0,0,0)",
            background: "#eeeeee",
          });
        }
      })
      .then(() => {
        this.listarTiposPic();
      })
      .catch(() => {
        // Swal.fire({
        //   title: "No hay Convocatorias activas",
        //   position: "center",
        //   text:"error en el servidor",
        //   showConfirmButton: true,
        //   confirmButtonText: "Aceptar",
        //   confirmButtonColor: "#238276",
        // });
      })
      .finally(()=>
      {
        this.estadoPeticionTabla = true;
      }
      );
  }
   obtenYearNow ()
  {
    this.fecha_pic = new  Date().getFullYear().toString();
    // console.log(this.fecha_pic);
  }
  ontenerPlanInstitucional() {
    Axios()
      .get("listar_plan_institucional")
      .then((respuesta) => {
        this.fechas_pic = respuesta.data.results;
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }

  filtrarTipoPic(lista: any[]) {
    let listaRetornar = [];

    if (this.tipoPicFilter != "") {
      for (let i = 0; i < lista.length; i++) {
        if (lista[i].pic_tipo_id == this.tipoPicFilter) {
          listaRetornar.push(lista[i]);
        }
      }
      return listaRetornar;
    } else {
      return lista;
    }
  }

  picFiltrado() {
    let arregloFiltrado = [];
    // this.listaConvocatoriasFiltrado = []

    for (let conv of this.listaConvocatorias) {
      let convocatoria = conv.convocatoria.toLowerCase();
      let capacitacion = conv.capacitacion.toLowerCase();
      let tipo = conv.pic_tipo.toLowerCase();
      let codigo = conv.codigo.toLowerCase();

      if (
        convocatoria.indexOf(this.filter) >= 0 ||
        capacitacion.indexOf(this.filter) >= 0 ||
        tipo.indexOf(this.filter) >= 0 ||
        codigo.indexOf(this.filter) >= 0 ||
        this.filter == ""
      ) {
        arregloFiltrado.push(conv);
      }
    }

    this.listaConvocatoriasFiltrado = arregloFiltrado;
    if (this.listaConvocatoriasFiltrado.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró convocatorias que coincidan con las busqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#238276",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filter = "";
      this.listaConvocatoriasFiltrado = this.listaConvocatorias;
      // this.paginate = ['listaConvocatoriasFiltrado']
    }
  }

  async picTipoFiltrado() {
    this.listaConvocatoriasFiltrado = [];
    this.listarTiposPic();
    for (let conv of this.listaConvocatorias) {
      let picTipo = conv.pic_tipo;
      if (this.filterPicTipo != "") {
        if (picTipo.indexOf(this.filterPicTipo) >= 0) {
          this.listaConvocatoriasFiltrado.push(conv);
        } else {
          // no hay coincidencias
        }
      } else {
        this.listaConvocatoriasFiltrado = this.listaConvocatorias;
      }
    }
  }

  listarTiposPic() {
    for (let conv of this.listaConvocatorias) {
      if (conv.pic_tipo == "Técnico") {
        this.existe_tipo_tecnico = true;
      } else if (conv.pic_tipo == "Pedagógico") {
        this.existe_tipo_pedagogico = true;
      } else if (conv.pic_tipo == "Clave y Transversal") {
        this.existe_tipo_clave = true;
      }
    }
  }

  // updated(){
  //   this.obtenerConvocatorias()
  // }
}
