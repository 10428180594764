
import Axios from "@/Axios";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import Swal from "sweetalert2";
import XLSX from "xlsx";
import BarChart from "@/components/Charts/BarChart";
import AnimacionTablasCargando from "@/components/animacionCargando.vue";
import Chart from "chart.js";

@Component({
  components: {
    AnimacionTablasCargando,
    BarChart,
  },
})
export default class reporteVoceros extends Vue {
  canvas= document.getElementsByTagName("canvas");
  listaVoceroB: any[] = [];
  verGraficas=false;
  listaVocero: any[] = [];
  tiposdevocerosB: any[] = [];
  listarRegional: any[] = [];
  paginate = ["listaVoceroB"];
  hayDatos = false;
  listarCentro:any[] = [];
  estadoPeticionTabla = false;
  filter = "";
  filterRegional = null;
  filterCentro = null;
  filtro="";
  listaCentros:any[] = [];
  tiposdevoceros:any[] = [];
  listaRegionales=[];
  filterTipo = "";
  contVocero= 0;
  contVoceroEnfoque=0;
  async mounted() {
    this.listarVoceros();
    this.obtenerRegionales();
    this.obtenerTiposVoceros();
  }
  async listarVoceros() {
    await Axios()
      .get("listar_voceros")
      .then((respueta) => {
        this.listaVocero = respueta.data.results;
        this.listaVoceroB = respueta.data.results;
        this.generarContadores();
        if (this.listaVocero.length > 0) {
          this.hayDatos = true;
        }
      })
      .catch((error) => {
        Swal.fire(
          "ERROR!",
          "Se ha detectado un error en el servidor!",
          "error"
        );
      })
      .finally(() => {
        this.estadoPeticionTabla = true;
      });
  }
  listarRegionales() {
      Axios()
        .get("listar_regionales")
        .then((respuesta) => {
          this.listarRegional = respuesta.data.results;
        });
    }
    listarCentros() {
      Axios()
        .get("listar_centros")
        .then((respuesta) => {
          this.listarCentro = respuesta.data.results;
        });
    }
    listarTiposVoceros() {
      Axios()
        .get("listar_tipos_voceros")
        .then((respuesta) => {
          this.tiposdevoceros = respuesta.data.results;
        });
    }
    filtradoRegional(){
        let arregloFiltradoRegional = [];

        for(let regionales of this.listaVoceroB){
            let nombre = regionales.regional;
            if(nombre.indexOf(this.filtro)>= 0){
                arregloFiltradoRegional.push(regionales);
            }
        }
        this.listaVocero=arregloFiltradoRegional;
        console.log("hola",arregloFiltradoRegional)
        if (this.listaVocero.length == 0) {
        //busco no encontro
        Swal.fire({
          title: "No hay coincidencias",
          position: "center",
          timer: 1000,
          text: "No se encontró convocatorias que coincidan con las busqueda",
          showConfirmButton: false,
          // confirmButtonText: "Aceptar",
          // confirmButtonColor: "#238276",
          backdrop: "rgba(0,0,0,0)",
          background: "#eeeeee",
        });
        this.filtro= "";
        this.listaVocero = this.listaVoceroB;
      }
    }
  obtenerTiposVoceros() {
    Axios()
      .get("listar_tipos_voceros")
      .then((respuesta) => {
        this.tiposdevoceros = respuesta.data.results;
        this.tiposdevocerosB= respuesta.data.results;
      });
  }
  filtrarTipo() {
    let filtrarTipo = [];
    for (let tipo of this.listaVocero) {
      let tipo_vocero = tipo.tipo_vocero;

      if (tipo_vocero.indexOf(this.filterTipo) >= 0) {
        filtrarTipo.push(tipo);
      }
    }
    this.listaVoceroB = filtrarTipo;
    if (this.listaVoceroB.length == 0) {
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró el centro que coincida con la búsqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#007bb8",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filterTipo = "";
      this.listaVoceroB = this.listaVoceroB;
    }
  }
  exportExcel() {
    let data = XLSX.utils.json_to_sheet(this.listaVoceroB, {
      header: [
        "identificacion",
        "primer_nombre",
        "segundo_nombre",
        "primer_apellido",
        "segundo_apellido",
        "id_ficha_caracterizacion",
      ],
    });
    (data["A1"].v = "identificacion"),
      (data["B1"].v = "primer_nombre"),
      (data["C1"].v = "segundo_nombre"),
      (data["D1"].v = "primer_apellido"),
      (data["E1"].v = "segundo_apellido"),
      (data["F1"].v = "id_ficha_caracterizacion");
    const workbook = XLSX.utils.book_new();
    const filename =
      "Reporte Voceros" +
      new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1);
    XLSX.utils.book_append_sheet(workbook, data, filename);
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  }
  buscarCentrosSegunRegional(id_regional) {
    Axios()
      .get("listar_centro_formacion_regional/ " + id_regional)
      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listaCentros = respuesta.data.results;
        } else {
          Swal.fire({
            title: "Hubo un error obteniendo las convocatorias",
            icon: respuesta.data.status,
            text: respuesta.data.message,
            confirmButtonText: "Aceptar",
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  obtenerRegionales() {
    Axios()
      .get("listar_regionales")

      .then((respuesta) => {
        if (respuesta.data.status == "success") {
          this.listaRegionales = respuesta.data.results;
        } else {
          Swal.fire({
            title: "Hubo un error obteniendo las regionales",
            icon: respuesta.data.status,
            text: respuesta.data.message,
            confirmButtonText: "Aceptar",
          });
        }
      })
      .catch(() => {
        Swal.fire(
          "ERROR!",
          "Se ha presentado un error en el servidor!",
          "error"
        );
      });
  }
  picFiltrado() {
    let arregloFiltrado = [];
    // this.listaConvocatoriasFiltrado = []

    for (let adj of this.listaVocero) {
      let regional = adj.regional;

      if (regional.indexOf(this.filterRegional.regional) >= 0) {
        arregloFiltrado.push(adj);
      }
    }

    this.listaVoceroB = arregloFiltrado;
    if (this.listaVoceroB.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró ninguna coincidencia con la busqueda",
        showConfirmButton: false,
        // confirmButtonText: "Aceptar",
        // confirmButtonColor: "#238276",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      // this.filter = "";
      this.listaVoceroB = this.listaVocero;
      // this.paginate = ['listaConvocatoriasFiltrado']
    }
  }
  filtroCentro() {
    let arregloFiltrado = [];

    for (let conv of this.listaVocero) {
      let centro = conv.centro_formacion;
      if (centro.indexOf(this.filterCentro) >= 0) {
        arregloFiltrado.push(conv);
      }
      this.listaVoceroB = arregloFiltrado;
    }
    if (this.listaVoceroB.length == 0) {
      //busco no encontro
      Swal.fire({
        title: "No hay coincidencias",
        position: "center",
        timer: 1000,
        text: "No se encontró ninguna coincidencia con la busqueda",
        showConfirmButton: false,
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#007bb8",
        backdrop: "rgba(0,0,0,0)",
        background: "#eeeeee",
      });
      this.filterCentro = null;
      this.listaVoceroB = this.listaVocero;
    }
  }
  async generarContadores(){
    for(let i of this.listaVocero){
      if(i.tipo_vocero== "Vocero Ficha"){
        this.contVocero= this.contVocero + 1;
      }else if (i.tipo_vocero == "Vocero Enfoque Diferencial"){
        this.contVoceroEnfoque = this.contVoceroEnfoque + 1;
        console.log(this.contVoceroEnfoque);
        
      }
    }
    this.graficas();
  
     
  }
  graficas(){
    
    new Chart(this.canvas[0],{
      type: "pie",
      data:{
        labels:[
          "Vocero Ficha",
          "Vocero Enfoque Diferencial",
        ],
        datasets:[
          {
            label: "Tipo Voceros",
            data:[
            this.contVocero,
            this.contVoceroEnfoque,
            ],
            backgroundColor:[
            "#F2A633",
           "#33DDF2", 
          ],
          borderColor:[
          "rgb(255, 99, 132)",
          "#247EF5",  
          ] ,
          borderWidth:1,
          }
        ]
     
        
      }
      
    })

    new Chart(this.canvas[1],{
      type: "doughnut",
      data:{
        labels:[
          "Vocero Ficha",
          "Vocero Enfoque Diferencial"
        ],
        datasets:[
          {
            label: "Voceros",
            data:[
            this.contVocero,
            this.contVoceroEnfoque            ],
            backgroundColor:[
            "#F2A633",
           "#33DDF2", 
          ],
          borderColor:[
          "rgb(255, 99, 132)",
          "#247EF5",  
          ] ,
          borderWidth:1,
          }
        ]
     
        
      }
    })
  }

}
