
  import Component from "vue-class-component";
  import { Prop, Vue } from "vue-property-decorator";

  import Axios from "@/Axios";
  import Swal from "sweetalert2";

  @Component({
    components: {
    },
    props: {
    }
  })
  export default class Documentos extends Vue {

    id_persona_logeada = JSON.parse(sessionStorage.getItem("usuario")).id_persona;
    docValido = false;
    estadoPeticion = false;
    listarDocumentos: any[] = [];
    listarDocumentosString: any[] = [];
    contDocumentos = 0;
    prueba: any[] = []; 
    cont;   

    inactivo = false;

    documentos = {
      persona_id: this.id_persona_logeada,
      ruta: "",
      documento: "",
    }

    mounted() {
      this.people();
    }
    people() {
      this.id_persona_logeada;
    }

    ListarDocs() {
      this.estadoPeticion = true;
      //Creamos el formData
      var data = new FormData();
      //Añadimos la docinscrip seleccionada
      data.append("persona_id", this.id_persona_logeada);
      data.append("ruta", this.documentos.ruta);
      data.append("documento", this.documentos.documento);

      // this.listarDocumentos.push(data);

      this.listarDocumentosString.push(this.documentos);
      this.documentos = {
        persona_id: this.id_persona_logeada,
        ruta: "",
        documento: ""
      };
    }

    obtenerDocumento(event) {
      if (event.target.files[0].size <= 5000000) {
        //5mb tope
        let extDoc = event.target.files[0].name
          .toString()
          .substring(event.target.files[0].name.toString().lastIndexOf(".")); //extension del archivo
        if (extDoc == ".pdf") {
          this.documentos.ruta = event.target.files[0];
          Swal.fire("PDF Cargado!", "Archivo cargado exitosamente!", "success");
          this.docValido = true;
          // this.docResolucon = null;
        } else {
          this.docValido = false;
          this.documentos.ruta = null;
          Swal.fire(
            "Algo salió mal!",
            "El archivo cargado no es un PDF!",
            "error"
          );
        }
      } else {
        this.docValido = false;
        this.documentos.ruta = null;
        Swal.fire(
          "Algo salió mal!",
          "El archivo cargado pesa más de 5 MegaBytes!",
          "error"
        );
      }
    }
    eliminar(id) {
      this.listarDocumentosString.splice(this.listarDocumentosString.indexOf(id), 1);
    }

    registrarDocumentos() {
      this.estadoPeticion = true;
      const headers = {
        "Content-Type": "multipart/form-data",
      };

      for (var i = 0; i < this.listarDocumentosString.length; i++) {
        var data = new FormData();
        //Añadimos la docinscrip seleccionada
        data.append("persona_id", this.listarDocumentosString[i].persona_id);
        data.append("ruta", this.listarDocumentosString[i].ruta);
        data.append("documento", this.listarDocumentosString[i].documento);
        Axios()
          .post("almacenar_documento", data, {
            headers: headers,
          })
          .then((respuesta) => {

            if (respuesta.data.status == "success") {
              Swal.fire({
                title: "Documentos Agregados",
                text: "Se Agregaron " + this.listarDocumentosString.length + " Documentos",
                icon: "success",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#007bb8",
              });
              this.cont = this.listarDocumentosString.length;
              this.prueba = this.listarDocumentos;
            } else {
              Swal.fire({
                title: "No se Agregaron los Documentos",
                icon: "error",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#007bb8",
              });
            }

            this.listarDocumentos.push(respuesta.data.results);
            this.$emit('envioDocs', this.listarDocumentos);
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
      }

    }

    validarFormulario() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.ListarDocs();
        }
      });
    }
    // onClick() {      
    //   this.$emit('clicks', this.listarDocumentosString);
    // }
    constructor() {
      super();
      const dict = {
        messages: {
          required: () => "Campo obligatorio",
          date_format: () => "Campo obligatorio",
          decimal: () => "Campo obligatorio",
          min_value: () => "No pude ser negativo",
          alpha_spaces: () => "No permite numeros ni caracteres especiales",
          alpha_num: () => "No permite caracteres especiales",
        },
      };
      this.$validator.localize("es", dict);
    }
  }
